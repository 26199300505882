<div class="modal-body">
    <div class="model_section p-3">
        <div class="cancel_button_sec text-right">
            <a class="close close_button" data-dismiss="modal" href="#"> <span class="icon-fill-close1"></span> </a>
        </div>
        <div class="model_content_section">
            <h3 class="font-weight-bold">Hi {{first_name}},</h3>
            <div class="mt-2">
                <p class="text-left">Our AI enabled assistant Smilo.ai has analyzed your teeth photos and found the below results</p>
                <p class="color_grey mt-2 text-left"> Calculated on {{created_date}}</p>
            </div>
            <div class="ranking_content_sec text-left">
                <div class="row">
                    <div class="col-sm-6 p-0" *ngFor="let data of detaileddata">
                        <div class="inner_con p-2 text-left">
                            <div class="d-flex align-items-center">
                                <div class="teeth_img">
                                    <img [defaultImage]="defaultImage" [lazyLoad]="data.image" [errorImage]="defaultImage" class="score_one">
                                </div>
                                <div class="shap_result_head">
                                    <p class="">{{data.title}}</p>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between m-1">
                                <p class="m-1 bottom-font d-flex align-items-center"> <span class="calc_sec"> DC : {{data.dental_carries_score}}</span><span [ngClass]="appservices.getColor(data.dental_carries_score)" class="icon-tooth1 align-self-center font_20"></span></p>
                                <p class="m-1 bottom-font d-flex align-items-center"> <span class="calc_sec"> PD : {{data.periodontitis_score}}</span><span [ngClass]="appservices.getColor(data.dental_carries_score)" class="icon-tooth1 align-self-center font_20"></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>