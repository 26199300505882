import { Component, OnInit, Input, TemplateRef, HostListener } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
// import { Router, ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Services } from '../helpers/Services';
import * as moment from 'moment';
import 'moment-timezone';
 
import { environment } from "src/environments/environment";

declare var gtag;
declare const fbq;


@Component({
  selector: 'app-smile-before-after-picture',
  templateUrl: './smile-before-after-picture.component.html',
  styleUrls: ['./smile-before-after-picture.component.css']
})
export class SmileBeforeAfterPictureComponent implements OnInit {
  modalRef: BsModalRef;
  smile_image_url: any;
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  input: any;
  responsedata: any;
  after_image: any;
  defaultImage: string = "assets/images/tooth_loader.svg";
  defaultImage1: string = "assets/images/loader_large.gif";
  displayImage: any;
  selected_styles: any;
  before_image: any;
  app_details: any;
  questionOneOptions: any;
  questionTwoOptions: any;
  // isSmileRatingPopupNotOpened: boolean = true;
  rating: any = '';
  isButtonDisabled: boolean = true;

  questionTwoOptionSelected: boolean = false;
  questionOneOptionSelected: boolean = false;

  beforeAfterImages: any[] = [];

  fb_pixel_id:any = ''; 
  gtm_id:any = '';

  
  displaySettings:boolean = false;
  cta_type:any = '';
  button_text:any = '';
  button_url:any = '';

  user_details: any = '';
  user_email: any = '';

  bannerTitleText: any = 'Design My Smile';


  constructor(private httpClient: HttpClient, private router: Router, private modalService: BsModalService) {
    this.appservices = new Services;
  }
  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn icon-right-arrow next-slide blue_color font_12'></div>",
    "prevArrow": "<div class='nav-btn icon-left-arrow prev-slide blue_color font_12'></div>",
    "infinite": false,
  };
  slickInit(e) {
    // console.log('slick initialized');
  }
  ngOnInit(): void {
    // if (this.appservices.hasSession("isSmileRatingPopupNotOpened")) {
    //   this.isSmileRatingPopupNotOpened = false;
    // }

    this.cta_type = this.appservices.getSession("cta_type",false);
    this.button_text = this.appservices.getSession("button_text",false);
    this.button_url = this.appservices.getSession("button_url",false);

    // console.log(" this.cta_type  " + this.cta_type);
    // console.log(" this.button_text  " + this.button_text);
    // console.log(" this.button_url  " + this.button_url);

    this.appservices.setSession("bannerTitleText", this.bannerTitleText, false);

    if((this.cta_type!=undefined&&this.cta_type!='')&&(this.button_text!=undefined&&this.button_text!='')&&(this.button_url!=undefined&&this.button_url!=''))
    {
      this.displaySettings = true;
    }

    this.fb_pixel_id = this.appservices.getSession("fb_pixel_id", false);
    this.gtm_id      = this.appservices.getSession("gtm_id", false);

    let ouput = this.appservices.getSession("selected_styles", true);
    this.selected_styles = ouput;
    this.before_image = this.appservices.getSession("before_image", false);
    this.beforeAfterImages.push({images:this.before_image});
    this.after_image = this.appservices.getSession("after_image", false);
    this.beforeAfterImages.push({images:this.after_image});
    // console.log(this.beforeAfterImages);
    this.app_details = JSON.parse(JSON.stringify(this.appservices.getSession("app_details", true)));
    // console.log(this.app_details);
    this.questionOneOptions = this.app_details.my_smile_survery_question1_options;
    this.questionTwoOptions = this.app_details.my_smile_survery_question2_options;

    if(localStorage.getItem('Input_form_data')!='')
    {
      this.user_details = JSON.parse(localStorage.getItem('Input_form_data'));
      this.user_email = this.user_details.email;
    }


  }
  closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }
  openImageViewModal(template: TemplateRef<any>, image) {
    this.displayImage = image;
    this.modalRef = this.modalService.show(template, {
      class: 'custom_notifications_modal vertical_align_center',
      animated: true,
      backdrop: 'static'
    });
  }
  openModal(template1: TemplateRef<any>) {
    this.rating = '';
    this.modalRef = this.modalService.show(template1, {
      class: 'modal-xs custom_notifications_modal vertical_align_center rating_modal',
      animated: true,
      backdrop: 'static'
    });
  }

  onClickRating(label, rating) {
    this.appservices.serviceMessages('vs-messages-model', '', '');
    this.rating = rating;
    // console.log(this.rating)
  }

  onSubmitRating(template1, label, feedback, rating) {
    this.appservices.serviceMessages('vs-messages-model', '', '');
    this.rating = rating;
    // console.log(template1, label, feedback, rating)
    // console.log(this.rating)
    if (!this.rating) {
      this.appservices.serviceMessages('vs-messages-model', 'Rating is required', 'danger');
    }
    else if (label == "feedback_section" && $('textarea#feedback').val() == '') {
      this.appservices.serviceMessages('vs-messages-model', 'Message is required', 'danger');
    }
    else if (this.rating < 4 && $('textarea#feedback').val() == '') {
      $(".rating_section").hide();
      $(".feedback_section").removeClass('display_none');
      $(".feedback_section").show();
    }
    else {
      $('.submit_btn').html('Submitting...').attr('disabled', true);
      let user_id = this.appservices.getSession("user_id", false);
      this.apirequest = []
      this.apirequest["user_id"] = user_id;
      this.apirequest["content_id"] = this.appservices.getSession("smile_id", false);;
      this.apirequest["content_type"] = 1;
      if (this.rating)
        this.apirequest["rating"] = this.rating;
      if (this.rating < 4)
        this.apirequest["feedback"] = $('textarea#feedback').val();

      this.input = this.appservices.generteJSONFromArray(this.apirequest);
      // console.log(this.input);
      this.appservices.callJSONAPI(this.appservices.OHR_DMS_RATING, this.input, 'post', this, '').subscribe(
        (data: any) => {
         // console.log(data);
          $('.submit_btn').html('Submit').attr('disabled', false);
          if (data.status) {
            this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_dms_rating',false); 
            this.navigate('rating_skip','');
          } else {
            this.appservices.serviceMessages('vs-messages-model', data.message, 'danger');
          }
        },
        (error) => {
          this.appservices.showDebugMessage(error);
        }
      );
    }
  }
  navigate(label,template1) {
    if (label == 'rating_skip') {
      $("body").removeClass("modal-open");
      this.closeAllModals();
      this.router.navigate(['email-success']);
    }
    else{
      this.openModal(template1);
    }
  }
  reportSend(template3) {
    $('.continue_btn').html('Please wait...').attr('disabled', true);
    this.apirequest = [];
    this.apirequest["time_zone"] = moment.tz.guess();
    this.apirequest["smile_id"] = this.appservices.getSession("smile_id", false);
    this.apirequest["widget_token"] = this.appservices.getSession("token", false);
    let report_input = this.appservices.generteJSONFromArray(this.apirequest);
    // console.log(report_input)
    this.appservices.callJSONAPI(this.appservices.SMILE_REPORTS, report_input, 'post', this, '').subscribe(
      (data: any) => {
        $('.continue_btn').html('Email report').attr('disabled', false);
        // this.appservices.stopLoading('button_links',"GET REPORT TO MY EMAIL")
        if (data.status) {

          this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_dms_email',true); 
        
      
          

          // this.openModal(template3);
          $('.before_after_images_sec').hide();
          $('.get_in_touch_sec').show();
        } else {
          this.appservices.serviceMessages('vs-messages', data.message, 'danger')
        }
        // this.router.navigate(['email-success']);
      },
      (error) => {
        this.appservices.showDebugMessage(error);
      }
    );
  }
  onAnswer(event, answer) {
    let rootElements = document.getElementsByClassName('oral_hygiene_btn')[0];
    let buttonElements = rootElements.getElementsByClassName("custom_btn");
    for (var i = 0; i < buttonElements.length; i++) {
      buttonElements[i].classList.remove("active");
    }
    let element = <HTMLInputElement>event.target;
    if (element.classList.contains("active"))
      element.classList.remove("active");
    else element.classList.add("active");
    this.saveQuestionAnswer(answer);
  }
  saveQuestionAnswer(answer) {
    this.apirequest = [];
    this.apirequest["is_whitening_aligner"] = answer.toString();
    this.apirequest["smile_id"] = this.appservices.getSession("smile_id", false);
    let report_input = this.appservices.generteJSONFromArray(this.apirequest);
    // console.log(report_input)
    this.appservices.callJSONAPI(this.appservices.WHITENING_ALIGNER, report_input, 'post', this, '').subscribe(
      (data: any) => {
       // console.log(data)
        if (data.status) {
        } else {
        }
      },
      (error) => {
        this.appservices.showDebugMessage(error);
      }
    );
  }
  onSubmitSurvey(template1, label) {
    var answerOne = [];
    var answerTwo = $('#yes').prop("checked") || $('#no').prop("checked") ? true : '';
    for (let i = 1; i <= 3; i++) {
      if ($('#answer' + i).prop("checked")) {
        answerOne.push($('#answer' + i).val())
      }
    }
    // console.log(answerOne)
    if (answerOne.length == 0 || answerTwo == '') {
      
      window.scrollTo(0,0);
      $(window).scrollTop(0);
      let message = "Please answer all the questions"
      this.appservices.serviceMessages('vs-messages-model', message, 'danger');
    }
    else {
      $('.submit_btn').html('Submitting...').attr('disabled', true);
      this.appservices.serviceMessages('vs-messages-model', '', '');
      // this.appservices.setSession("isSurveyDone", true, false);
      this.saveQuestions3(answerOne, template1);
    }
  }

  saveQuestions3(answerOne, template1) {
    this.apirequest = [];
    this.apirequest["smile_id"] = this.appservices.getSession("smile_id", false);
    this.apirequest["survey_question1_treatment_list"] = answerOne.join();
    this.apirequest["survey_question2_know_cost"] = $('#yes').prop("checked") ? 'yes' : 'no';
    let input = this.appservices.generteJSONFromArray(this.apirequest);
    // console.log(input);
    this.appservices.callJSONAPI(this.appservices.SURVEY_QUESTIONS, input, 'post', this, '').subscribe(
      (data: any) => {
       // console.log(data);
        if (data.status) {
          this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_dms_input',true); 
          
          // this.modalRef.hide();
          this.openModal(template1);
        } else {
          this.appservices.serviceMessages('vs-messages-model', data.message, 'danger')
        }
      },
      (error) => {
        this.appservices.showDebugMessage(error);
      }
    );
  }
  selectChangeHandler(event: any) {
    $('input.example').on('change', function () {
      $('input.example').not(this).prop('checked', false);
    }); 

    // alert($('.questionTwoOptions input:checked').length);

     
  }


  questionTwoSelectChangeHandler(event: any) {
    $('input.questionTwoOptions').on('change', function () {
     $('input.questionTwoOptions').not(this).prop('checked', false);
   });

   this.questionTwoOptionSelected = false;     
   // alert($('.questionTwoOptions input:checked').length);
   if(event.target.checked)
   {      
    this.questionTwoOptionSelected = true;
   }     
   this.checkBothOptionsSelected();
 }

 questionOneSelectChangeHandler(event: any) {    
  this.questionOneOptionSelected = false;     
  if($('input.questionOneOptions:checked').length>0)
  {
    this.questionOneOptionSelected = true;     
  } 
  this.checkBothOptionsSelected();
}

checkBothOptionsSelected()
{
  this.isButtonDisabled = true;
  if(this.questionOneOptionSelected===true&&this.questionTwoOptionSelected===true)
  {
   this.isButtonDisabled = false;
  }
}

}
