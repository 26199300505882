<div class="dynamic_wrapper">
    <div class="dms_wrapper">        
        <app-back-to-home></app-back-to-home>
    <div class="width_100">        
        <form class="main-wrapper pt-4 mb-3" [formGroup]="testForm" (ngSubmit)="onSubmit()">

            <div *ngIf="existing_user=='yes'">
                <div class="d-flex align-items-center  mb-3 justify-content-center">
                    <div class="account-created-msg-div"><span class="font_12"> Welcome to Smilo.ai </span></div>
                 </div>
            </div>

            <div *ngIf="existing_user=='no'">
                <div class="d-flex align-items-center  mb-3 justify-content-center">
                    <div class="account-created-msg-div"><span class="font_12"> Your account has been successfully created and login details sent to the email address provided. </span></div>
                 </div>
            </div>

            <!-- <ng-template #new_user>
                <div class="d-flex align-items-center justify-content-center">
                    <div class="account-created-msg-div"><span class="font_12"> Smilo.ai successfully created an account for you and send an email to <a href="mailto:{{user_email}}">{{user_email}}</a> . please use login details to view/download Oral Health Rating and Design my smile report. </span></div>
                 </div>
            </ng-template> --> 

            <div class="row main-wrapper position-relative mx-0">

                <div class="col-lg-6 col-md-6 col-sm-12 sub_wrap_1 text-center">  <!-- mb-3 mb-md-0 mb-lg-0 -->
                    <h5 class="Nunito-Bold font_12 select_opt">SELECT AN OPTION</h5>                    
                    <div class="ohs_preference position-relative">
                        <div class="ohs-sub-title position-absolute">
                            <h3 class="sub_heading  mb-0 mt-0">Oral Health Rating</h3>
                            <p class="grey_color MuseoSans-500 mb-2">Select this for an express dental check</p>
                        </div>
                        <img [defaultImage]="defaultImage" class="ohr-dms-imgs" [lazyLoad]="staticImage1" [errorImage]="defaultImage" alt="Oral Health Score">
                        <div class="d-flex justify-content-center flex_start select_btn">
                        <!--    <button class="btn btn_width_2 button_links coninue_btn btn_color mx-0"  [routerLink]="['/upload-teeth-photos']">Select</button> -->
                        <button class="btn btn_width_2 button_links coninue_btn btn_color mt-3 mb-3 mx-0"(click)="selectTestType($event,'OHR')">Select</button>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 sub_wrap_2 text-center">
                    <div class="ohs_preference position-relative">
                        <div class="dms-sub-title position-absolute">
                            <h3 class="sub_heading  mb-0 mt-0">Design My Smile</h3>
                            <p class="grey_color MuseoSans-500 mb-2">Select this to try your future smile</p>
                        </div>
                        <img [defaultImage]="defaultImage" class="ohr-dms-imgs" [lazyLoad]="staticImage2" [errorImage]="defaultImage" alt="Design My Smile">
                        <div class="d-flex justify-content-center flex_start select_btn">
                        <!-- <button class="btn btn_width_2 button_links coninue_btn btn_color mx-0"  [routerLink]="['/upload-smile-photo']">Select</button>-->
                        <button class="btn btn_width_2 button_links coninue_btn btn_color mt-3 mb-3 mx-0" (click)="selectTestType($event,'DMS')">Select</button>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>
</div>