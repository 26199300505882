import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appOnlyNumbers]",
})
export class OnlyNumbersDirective {
  constructor(private el: ElementRef) {}
  @HostListener("input", ["$event"])
  onInput(event: InputEvent): void {
    const inputElement = this.el.nativeElement;
    const value = inputElement.value;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    inputElement.value = numericValue;
  }
}
