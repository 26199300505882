<div class="dynamic_wrapper">
    <div class="inner_dynamic_sec text-center">
        <app-back-to-home></app-back-to-home>
        <div class="top_process_section">
            <div class="d-flex justify-content-center">
                <span class="icon-tick-fill1 icon_green"></span>
                <img src="assets/images/line.png" class="line_cross m-2 p-1">
                <span class="icon-tick-fill1 icon_green"></span>
                <img src="assets/images/line.png" class="line_cross m-2 p-1">
                <span class="icon-tick-fill1 icon_blue"></span>
            </div>
            <div class="container_content_sec">
                <div class="inner_container_content">
                    <div class="sec_inner_content">
                        <div class="title_sec">
                            <h4 class="heading">Upload your teeth photos <span class="icon-info1 info_icon ml-2 btn p-0" type="button" data-toggle="modal" data-target="#myModal"></span></h4>
                        </div>
                        <div class="img_section">
                            <div class="row text-center text-lg-left inner_row">
                                <div class="col-lg-4 col-md-4 col-sm-4 col-6 mb-3">
                                    <div class="d-block teeth_img_sec position-relative">
                                        <img src="assets/images/teeth1.png" class="score_one">
                                        <figcaption class="figure-caption text-center fig_cap">Teeth Front View</figcaption>
                                        <div class="on_img">
                                            <form>
                                                <label class="icon-Upload upload_icon" for="image_upload"></label>
                                                <div class="file_upload">
                                                    <input type="file" class="file" id="img" id="image_upload" hidden />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-6 mb-3">
                                    <div class="d-block teeth_img_sec position-relative">
                                        <img src="assets/images/teeth2.png" class="score_one">
                                        <figcaption class="figure-caption text-center fig_cap">Teeth Right Smile</figcaption>
                                        <div class="on_img">
                                            <form>
                                                <label class="icon-Upload upload_icon" for="image_upload"></label>
                                                <div class="file_upload">
                                                    <input type="file" class="file" id="img" id="image_upload" hidden />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-6 mb-3">
                                    <div class="d-block teeth_img_sec position-relative">
                                        <img src="assets/images/teeth3.png" class="score_one">
                                        <figcaption class="figure-caption text-center fig_cap">Teeth Left View</figcaption>
                                        <div class="on_img">
                                            <form>
                                                <label class="icon-Upload upload_icon" for="image_upload"></label>
                                                <div class="file_upload">
                                                    <input type="file" class="file" id="img" id="image_upload" hidden />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                    <div class="d-block teeth_img_sec position-relative">
                                        <img src="assets/images/teeth4.png" class="score_one">
                                        <figcaption class="figure-caption text-center fig_cap">Teeth Upper Jaw</figcaption>
                                        <div class="on_img">
                                            <form>
                                                <label class="icon-Upload upload_icon" for="image_upload"></label>
                                                <div class="file_upload">
                                                    <input type="file" class="file" id="img" id="image_upload" hidden />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                    <div class="d-block teeth_img_sec position-relative">
                                        <img src="assets/images/teeth5.png" class="score_one">
                                        <figcaption class="figure-caption text-center fig_cap">Teeth Lower Jaw</figcaption>
                                        <div class="on_img">
                                            <form>
                                                <label class="icon-Upload upload_icon" for="image_upload"></label>
                                                <div class="file_upload">
                                                    <input type="file" class="file" id="img" id="image_upload" hidden />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center col-sm-12 mt-4">
                                    <button class="btn button_links btn-block mr-1 coninue_btn btn_color " [routerLink]="['/analyzing']">Continue</button>
                                    <!-- <router-outlet></router-outlet> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-footer></app-footer>
                </div>
            </div>
        </div>
    </div>
</div>