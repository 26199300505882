<!-- <div class="dynamic_wrapper mt-4 d-none">
    <div class="inner_dynamic_sec text-center">
        <app-back-to-home></app-back-to-home>
        <div class="top_process_section">
            <div class="d-flex justify-content-center line_section">
                <span class="icon-tick-fill1 icon_green"></span>
                <img src="assets/images/line.png" class="line_cross m-2 p-1">
                <span class="icon-tick-fill1 icon_green"></span>
                <img src="assets/images/line.png" class="line_cross m-2 p-1">
                <span class="icon-tick-fill1 icon_blue"></span>
            </div>
            <div class="container_content_sec">
                <div class="sec_inner_content p-3">
                    <div class="vs-messages mb-2"></div>
                    <div class="title_sec">
                        <h4 class="score_head text-capitalize"><b>Hi {{first_name}}!</b></h4>
                        <p class="my-3">Our AI engine has analysed your uploaded images and found the below results.</p>
                    </div>
                    <div class="date_time_section">
                        <p class="text-center date_time_sec mb-2">Calculated on {{created_date}}</p>
                    </div>
                    <div class="slider_sec">
                        <ngx-slick-carousel class="carousel carousel_custom mb-0" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)">
                            <div ngxSlickItem *ngFor="let data of process_images" class="slide position-relative">
                                <div class="white_bg mb-5">
                                    <div class="slicker_images">
                                        <img *ngIf="data.output_image" [defaultImage]="defaultImage" [lazyLoad]="data.output_image" class="score_one" [errorImage]="defaultImage">
                                        <img *ngIf="!data.output_image" [defaultImage]="defaultImage" [lazyLoad]="data.image" class="score_one" [errorImage]="defaultImage">
                                    </div>
                                    <p class="carousel_title mt-4">{{getTitle(data.title,data.output_image)}}</p>
                                </div>
                                <div class="d-flex align-items-center justify-content-between height_50" *ngIf="data.is_valid_image==true&&data.output_image; else elseBlock">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span class="blue_color custom_box"></span>
                                        <span class="font_responsive">Dental decay</span>
                                        <span class="ml-2 icon-info1 custom_style_icon cursor_pointer" (click)="openViewModal(template2,'1')"></span>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span class="yellow_color custom_box"></span>
                                        <span class="font_responsive">Gum disease</span>
                                        <span class="ml-2 icon-info1 custom_style_icon cursor_pointer" (click)="openViewModal(template2,'2')"></span>
                                    </div>
                                </div>
                                <ng-template #elseBlock class="height_50">
                                    <span class="icon-fill-close1 invalid_image" *ngIf="data.output_image"></span>
                                    <p class="invalid_text" *ngIf="data.output_image">Invalid Image</p>
                                </ng-template>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                    <div *ngIf="is_images_invalid=='false'">
                        <p class="mt-1">To know more information about your teeth,</p>
                        <p>please click on the button below</p>
                    </div>
                    <div class="text-center mt-2">
                        <button class="btn button_links btn_width_1" (click)="onSubmit(is_images_invalid)">{{is_images_invalid=='true'?'Please Try Again':'Continue'}}</button>
                    </div>
                </div>
                <app-footer></app-footer>
            </div>
        </div>
    </div>
</div> -->

<div class="dynamic_wrapper">
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="dms_content">
            <!-- <div class="processing">
                <img src="assets/images/step2.png" alt="image">
            </div> -->
            <div id="steps">
                <div id="circles" class="circle-steps">
                    <div data-num="1" id="num1"></div>
                    <div data-num="2" id="num2"></div>
                    <div data-num="3" id="num3" class="disabled"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5">
                    <div class="text-center">
                        <h3 class="sub_head mb-4">Your results</h3>
                        <div class="grey_color font_13">
                            <p class="mb-1 font-weight-bold">Hi {{first_name | titlecase}},</p>
                            <div class="font_14">
                                <p>We have analysed all your pictures and here are your results:</p>
                                <!-- <p>You are showing some signs of gum disease.</p>
                                <p>No signs of dental decay.</p> -->
                                <p class="my-3">Scroll through your photos or tap 'Next' to continue.</p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center flex_start select_btn media_button">
                            <button class="btn button_links save_continue_btn btn_width_2 coninue_btn btn_color mx-auto d-flex justify-content-center align-items-center" (click)="onSubmit(is_images_invalid)"><span class="save_continue_btn_span">{{is_images_invalid=='true'?'Please Try Again':'Next'}}</span><span class="icon_arrow icon-right-arrow ml-3 font_12"></span></button>
                        </div>
                        <div class="mt-3 media_button"><span class="accepted_txt">calculated on {{created_date}}</span></div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="slider_sec">
                        <ngx-slick-carousel class="carousel carousel_custom mb-0" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)">
                            <div ngxSlickItem *ngFor="let data of process_images" class="slide position-relative">
                                <p class="accepted_txt mb-2 text-center">{{getTitle(data.title,data.output_image)}}</p>
                                <div class="slicker_images_ohr">
                                    <img *ngIf="data.output_image" [defaultImage]="defaultImage" [lazyLoad]="data.output_image" class="score_one" [errorImage]="defaultImage">
                                    <img *ngIf="!data.output_image" [defaultImage]="defaultImage" [lazyLoad]="data.image" class="score_one" [errorImage]="defaultImage">
                                </div>
                                <div class="d-flex align-items-center justify-content-center height_50" *ngIf="data.is_valid_image==true&&data.output_image; else elseBlock">
                                    <div class="d-flex justify-content-between align-items-center mr-2">
                                        <span class="accepted_txt">Dental cavity</span>
                                        <span class="blue_color custom_box"></span>
                                        <span class="ml-2 icon-info1 custom_style_icon cursor_pointer" (click)="openViewModal(template2,'1')"></span>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span class="accepted_txt">Gum disease</span>
                                        <span class="yellow_color custom_box"></span>
                                        <span class="ml-2 icon-info1 custom_style_icon cursor_pointer" (click)="openViewModal(template2,'2')"></span>
                                    </div>
                                </div>
                                <ng-template #elseBlock class="height_50">
                                    <div class="d-flex align-items-center justify-content-center mt-2">
                                        <span class="icon-cancel invalid_image mr-2" *ngIf="data.output_image"></span>
                                        <span class="invalid_text" *ngIf="data.output_image">Invalid Image</span>
                                    </div>
                                </ng-template>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                    <div class="mb-3 responsive_button text-center"><span class="accepted_txt">calculated on {{created_date}}</span></div>
                    <div class="d-flex justify-content-center flex_start select_btn responsive_button">
                        <button class="btn button_links save_continue_btn btn_width_2 coninue_btn btn_color mx-auto d-flex justify-content-center align-items-center" (click)="onSubmit(is_images_invalid)"><span class="save_continue_btn_span">{{is_images_invalid=='true'?'Please Try Again':'Next'}}</span><span class="icon_arrow icon-right-arrow ml-3 font_12"></span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>

<ng-template #template2>
    <div class="modal_scroll">
        <div class="modal-header d-flex align-items-center justify-content-between">
            <h3 class="text-white" *ngIf="label!='gum'">Dental Decay</h3>
            <h3 class="text-white" *ngIf="label=='gum'">Gum Disease</h3>
            <a class="cancel_icon_color" data-dismiss="modal" aria-label="Close" (click)="closeModal()"> <span class="icon-fill-close1 text-white font_20"></span> </a>
        </div>
        <div class="modal-body  info_sec mt-2">
            <div class="model_section px-3">
                <div class="cancel_button_sec">
                    <div *ngIf="label!='gum'">
                        <h5><b>What is Dental Decay?</b></h5>
                        <p class="pt-1">Tooth decay, which is also called dental cavities or dental caries, is the destruction of the outer surface (enamel,dentin) of a tooth. Decay results from the action of bacteria that live in plaque, which is a sticky, whitish film formed by a protein in saliva (mucin) and sugary substances in the mouth.The decay may be a number of different colors from yellow to black. Symptoms may include pain, sensitivity, visible holes or pits and difficulty with eating.</p>
                    </div>
                    <div *ngIf="label=='gum'">
                        <h5><b>What is Gum Disease?</b></h5>
                        <p class="pt-1">Gum infection that causes inflammation and damages the soft tissue and destroys the bone that supports your teeth.It can appear as gingivitis ,gum or periodontitis. It is largely preventable with appropriate oral hygiene measures.It can appear as-Swollen or puffy gums. Bright red, dusky red or purplish gums. Gums that feel tender when touched. Gums that bleed easily. Gums that pull away from your teeth (recede), making your teeth look longer than normal.</p> 
                    </div>
                    <h5 class="mt-2"><b>How does it look?</b></h5>
                    <div *ngFor="let item of itemsList">
                        <div class="teeth_img mt-3">
                            <img [defaultImage]="defaultImage" [lazyLoad]="item.url" [errorImage]="defaultImage">
                        </div>
                        <p class="mt-1 text-center"><b>{{item.title}}</b></p>
                    </div><br>
                </div>
            </div>
        </div>
    </div>
</ng-template>