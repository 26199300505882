import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ranking-guidelines',
  templateUrl: './ranking-guidelines.component.html',
  styleUrls: ['./ranking-guidelines.component.css']
})
export class RankingGuidelinesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
