import { Component, OnInit, Input, TemplateRef, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Router, ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Services } from '../helpers/Services';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import 'moment-timezone';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { environment } from '../../../src/environments/environment'; 

declare var gtag;
declare const fbq;


@Component({
  selector: 'app-oral-health-score',
  templateUrl: './oral-health-score.component.html',
  styleUrls: ['./oral-health-score.component.css']
})
export class OralHealthScoreComponent implements OnInit {
  modalRef: BsModalRef;
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  input: any;
  first_name: any;
  flag: boolean = false;
  overall_score: any;
  public detaileddata: any;
  scoreData: any;
  result_image: any;
  created_date: any;
  user_email: any;
  user_details: any;
  ohrdata;
  responseData: any;
  // isRatingPopupNotOpened: boolean = true;
  todaysDataTime = formatDate(new Date(), 'yyyyMMddHHMMSS', 'en-US');
  // @Input('parentData') public detaileddata;
  // @Input('parentData2') public created_date;
  defaultImage: string = "assets/images/tooth_loader.svg";
  rating: any = '';
  app_details: any;
  defaultImage1: string = "assets/images/loader_large.gif";
  // staticImage: string = "assets/images/background_2.png";
  staticImage: string = "assets/images/ohr_gb_img.png";
  OHRImage:string = '';
  OHRText:string = '';

  cta_type:any;
  button_text:any;
  button_url:any;

  makePaymentButton:boolean = false ;
  widget_flow_type:any = '';

  constructor(private httpClient: HttpClient, private router: Router, private modalService: BsModalService) {
    this.appservices = new Services;
  }

  @HostListener('window:popstate', ['$event']) onPopState(e: KeyboardEvent) {
    // this.modalRef.hide()
  }
  ngOnInit(): void {
    this.app_details = JSON.parse(JSON.stringify(this.appservices.getSession("app_details", true)));
    //console.log(this.app_details);
    this.overall_score = this.appservices.getSession("overall_score", false);
    this.appservices.serviceMessages('vs-messages', '', '')
    this.first_name = this.appservices.getSession("first_name", false);
    // this.overall_score = this.appservices.getSession("overall_score", false);
    //console.log(this.overall_score + 'is the score')

    this.OHRImage = this.appservices.getSmileyImage(this.overall_score)
     
    this.OHRText = this.appservices.getSmileyText(this.overall_score);

    this.first_name = this.appservices.getSession("first_name", false);
    this.created_date = this.appservices.getSession("created_date", false);

    this.cta_type = this.appservices.getSession("cta_type",false);
    this.button_text = this.appservices.getSession("button_text",false);
    this.button_url = this.appservices.getSession("button_url",false);

    this.widget_flow_type =  this.appservices.getSession("widget_flow_type", false);
    if(this.widget_flow_type==="payment")
    {
      this.makePaymentButton = true;
    }
    // console.log(" this.cta_type : " + this.cta_type + " this.button_text : " + this.button_text + " this.button_url : " + this.button_url );

  }

  redirectOHR()
  {              
    this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_ohr_home_click',true);
    this.router.navigate(['oral-health-score-preference']);
  }

  makePayment()
  {
    this.router.navigate(['payment-successful']);
  }

  reportSend(template1) {
    $('.button_links').html('Please wait...').attr('disabled', true);
    this.apirequest = [];
    this.apirequest["time_zone"] = moment.tz.guess();;
    this.apirequest["oral_unique_id"] = this.appservices.getSession("ohr_unique_key", false);
    this.apirequest["widget_token"] = this.appservices.getSession("token", false);
    this.apirequest["is_payment_requested"] = 'no';
    // this.input = this.appservices.generteJSONFromArray(this.apirequest);
    let report_input = this.appservices.generteJSONFromArray(this.apirequest);
    // console.log(report_input)
    this.appservices.callJSONAPI(this.appservices.OHR_REPORTS, report_input, 'post', this, '').subscribe(
      (data: any) => {
      //  console.log(" data : " + JSON.stringify(data));
        $('.button_links').html('Email me a report').attr('disabled', false);
        if (data.status) {
          this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_ohr_email',true);
          // this.appservices.serviceMessages('vs-messages', data.message, 'success');
          // if (this.isRatingPopupNotOpened)

          
          
          this.openModal1(template1);
          // else
          //   this.router.navigate(['ohr-email-success']);
        } else {
          this.appservices.serviceMessages('vs-messages', data.message, 'danger')
        }
      },
      (error) => {
        this.appservices.showDebugMessage(error);
      }
    );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'custom_notifications_modal vertical_align_center',
      animated: true,
      backdrop: 'static'
    });
  }
  openModal1(template1: TemplateRef<any>) {
    this.rating = '';
    this.modalRef = this.modalService.show(template1, {
      class: 'modal-xs custom_notifications_modal vertical_align_center rating_modal ',
      animated: true,
      backdrop: 'static'
    });
  }

  onClickRating(label, rating) {
    this.appservices.serviceMessages('vs-messages-model', '', '');
    this.rating = rating;
  }

  onSubmit(template1, label, feedback, rating) {
    this.appservices.serviceMessages('vs-messages-model', '', '');
    this.rating = rating;
    if (!this.rating) {
      this.appservices.serviceMessages('vs-messages-model', 'Rating is required', 'danger');
    }
    else if (label == "feedback_section" && $('textarea#feedback').val() == '') {
      this.appservices.serviceMessages('vs-messages-model', 'Message is required', 'danger');
    }
    else if (this.rating < 4 && $('textarea#feedback').val() == '') {
      $(".rating_section").hide();
      $(".feedback_section").removeClass('display_none');
      $(".feedback_section").show();
    }
    else {
      $('.submit_btn').html('Submitting...').attr('disabled', true);
      let user_id = this.appservices.getSession("user_id", false);
      this.apirequest = []
      this.apirequest["user_id"] = user_id;
      this.apirequest["content_id"] = this.todaysDataTime;
      this.apirequest["content_type"] = 1;
      if (this.rating)
        this.apirequest["rating"] = this.rating;
      if (this.rating < 4)
        this.apirequest["feedback"] = $('textarea#feedback').val();

      this.input = this.appservices.generteJSONFromArray(this.apirequest);
      //console.log(this.input);
      this.appservices.callJSONAPI(this.appservices.OHR_DMS_RATING, this.input, 'post', this, '').subscribe(
        (data: any) => {
          // this.appservices.setSession("isRatingPopupNotOpened", false, false);
          //console.log(data);
          $('.submit_btn').html('Submit').attr('disabled', false);
          if (data.status) {

            this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_ohr_result',true);
         

            this.modalRef.hide();
            this.router.navigate(['ohr-email-success']);
            // this.navigate();
          } else {
            this.appservices.serviceMessages('vs-messages-model', data.message, 'danger');
          }
        },
        (error) => {
          this.appservices.showDebugMessage(error);
        }
      );
    }
  }
  navigate() {
    this.modalRef.hide();
    this.router.navigate(['ohr-email-success']);
    // this.appservices.setSession("isRatingPopupNotOpened", false, false);
    // this.router.navigate(["upload-teeth-photos"]);
  }
}
