<div class="dynamic_wrapper">
  <div class="content_main_wrapper">
    <div class="vs-messages"></div>
    <div class="row mx-0 flex_direction">
      <div
        class="col-lg-6 col-md-12 col-sm-12 p-0 background_white position-relative"
      >
        <div class="signup_img">
          <img
            [defaultImage]="defaultImage"
            [lazyLoad]="staticImage"
            [errorImage]="defaultImage"
            alt="Signup-image"
            class="id_img"
          />
        </div>
      </div>

      <!--<div  *ngIf="display_branches_div" class="col-lg-6 col-md-12 col-sm-12 pad_25 display_branches_div" style="min-height: 500px;"   >                                    
                    <h3 class="sub_head mb-3">Select Dental Practice</h3>                    
                    <div class="form-group position-relative min_height">

                        <select name="userSelectedPractice" id="userSelectedPractice" class="form-control" (change)="onSelectPractice($event)" >
                            <option value="">Please select any practice</option>
                            <option *ngFor="let method of practiceBranches" [attr.data-isocode]="method.address" [value]="method.id">{{ method.name }}</option>
                        </select>
                        <br><br>
                        <textarea class="form-control" readonly>{{userSelectedAddress}}</textarea>                        
                        <br><br>

                        <div class="d-flex justify-content-end flex_start">
                            <button [disabled]="isBranchesButtonDisabled" type="button" (click)="moveToContactForm()" class="my-4 btn btn_width_2 button_links coninue_btn btn_color mx-0"><span class="save_continue_btn_span">Next</span><span class="icon_arrow icon-right-arrow ml-3 font_12"></span></button>                             
                        </div>
                        
                    </div>                 
            </div> 

            <div *ngIf="display_contactForm_div"  class="col-lg-6 col-md-12 col-sm-12 pad_25 display_contactForm_div"> -->
      <div class="col-lg-6 col-md-12 col-sm-12 pad_25">
        <form
          autocomplete="off"
          [formGroup]="InputForm"
          class="contact_info_form"
          (ngSubmit)="onSubmit()"
        >
          <!--h5 class="font_w_500 text-left mb-2">Please fill the below details and click on “CONTINUE”</h5-->
          <h3 class="sub_head contact-info-sub-head mb-3">
            Please enter your email address so we can send you your personalised
            report
          </h3>
          <!-- <h3 class="sub_head contact-info-sub-head-tag-line mb-3">On the next screen we'll ask you to take a few selfies to get you started</h3> -->
          <!-- <div class="account-created-msg-div">
                        <span class="font_12"> Smilo.ai will create an account with auto generated password for you and send an email with login details. Please use login details to view/download Oral Health Rating and Design my smile report. </span>
                    </div> -->
          <div class="form-group position-relative min_height">
            <input
              (keyup)="onEnterData($event)"
              type="text"
              formControlName="firstName"
              maxlength="31"
              class="first_name form-control"
              (keyup)="length('first_name')"
              placeholder="First Name"
              [ngClass]="{ 'is-invalid': isSubmitted && ci.firstName.errors }"
              trim="blur"
              value="{{ firstName | titlecase }}"
              appInputRestriction
            />
            <div
              *ngIf="isSubmitted && ci.firstName.errors"
              class="invalid-feedback"
            >
              <div *ngIf="ci.firstName.errors.required" class="text-left">
                First Name is required
              </div>
              <div *ngIf="ci.firstName.errors.minlength" class="text-left">
                First Name should contain at least 3 characters.
              </div>
            </div>
          </div>
          <div class="form-group position-relative min_height">
            <input
              (keyup)="onEnterData($event)"
              type="text"
              formControlName="lastName"
              maxlength="31"
              class="last_name form-control"
              (keyup)="length('last_name')"
              placeholder="Last Name"
              [ngClass]="{ 'is-invalid': isSubmitted && ci.lastName.errors }"
              trim="blur"
              value="{{ lastName | titlecase }}"
              appInputRestriction
            />
            <div
              *ngIf="isSubmitted && ci.lastName.errors"
              class="invalid-feedback"
            >
              <div *ngIf="ci.lastName.errors.required" class="text-left">
                Last Name is required
              </div>
              <div *ngIf="ci.lastName.errors.minlength" class="text-left">
                Last Name should contain at least 3 characters.
              </div>
            </div>
          </div>
          <div class="form-group position-relative">
            <input
              (keyup)="onEnterData($event)"
              maxlength="50"
              type="email"
              formControlName="email"
              class="form-control name_holder"
              placeholder="Email Address"
              [ngClass]="{ 'is-invalid': isSubmitted && ci.email.errors }"
              trim="blur"
            />
            <div
              *ngIf="isSubmitted && ci.email.errors"
              class="invalid-feedback"
            >
              <div *ngIf="ci.email.errors.required" class="text-left">
                Email Address is required
              </div>
              <!-- <div *ngIf="ci.email.errors.email" class="text-left">Email Address is invalid</div> -->
              <div *ngIf="ci.email.errors.pattern" class="text-left">
                Email Address is invalid.
              </div>
            </div>
          </div>
          <div class="form-group position-relative">
            <input
              (keyup)="onEnterData($event)"
              type="tel"
              (keydown.space)="$event.preventDefault()"
              onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              formControlName="phoneNumber"
              maxlength="10"
              (countryChange)="countryChange($event)"
              (intlTelInputObject)="telInputObject($event)"
              placeholder="Mobile Number"
              ng2TelInput
              class="phone-format form-control"
              id="phoneNumber"
              [ngClass]="{ 'is-invalid': isSubmitted && ci.phoneNumber.errors }"
              trim="blur"
            />

            <!-- <input (keyup)="onEnterData($event)"  class="form-control"  type="text" ng2TelInput  
                                (hasError)="signInHasError($event)"
                                (ng2TelOutput)="signInGetNumber($event)"
                                (intlTelInputObject)="telInputObject($event)"
                                (countryChange)="signInOnCountryChange($event)"
                                [value]="InputForm.get('phoneNumber').value" 
                                placeholder="Mobile Number"  formControlName="phoneNumber" required >
                        <div *ngIf="isSubmitted &&!signInValidNumber" class="err-msg"> Invalid number </div> -->
            <div
              *ngIf="isSubmitted && ci.phoneNumber.errors"
              class="invalid-feedback"
            >
              <div *ngIf="ci.phoneNumber.errors.required" class="text-left">
                Mobile Number is required
              </div>
              <div *ngIf="ci.phoneNumber.errors.pattern" class="text-left">Please enter 10 digit Mobile Number.</div> 
              <!-- <div *ngIf="ci.phoneNumber.errors.pattern&&country_name=='au'" class="text-left">
                                Invalid Australia phone number.
                            </div>
                            <div *ngIf="ci.phoneNumber.errors.pattern&&country_name!='au'" class="text-left">
                                Phone Number must be digits only.
                            </div> -->
              <!-- <div PasteRestrictDirective *ngIf="ci.phoneNumber.errors.minlength" class="text-left">Phone Number must be at least 8 digits</div> -->
              <!-- <div *ngIf="ci.phoneNumber.errors.maxlength" class="text-left">Phone Number must be 8 to 15 digits only</div> -->
            </div>
          </div>

          <div
            *ngIf="display_branches_div"
            class="form-group position-relative"
          >
            <span class="practice-location-span">
              Dental Practice near to your location
            </span>
            <select
              name="userSelectedPractice"
              id="userSelectedPractice"
              class="form-control"
              (change)="onSelectPractice($event)"
            >
              <option value="">Please select any branch</option>
              <option value="" [selected]="true">Global Branch</option>
              <option
                *ngFor="let method of practiceBranches"
                [attr.data-isocode]="method.address"
                [value]="method.id"
                [selected]="method.id == preSelectedBranch"
              >
                {{ method.name }}
              </option>
            </select>
          </div>

          <div class="mt-35">
            <!-- <div class="d-flex align-items-center pt-1">
                            <p class="agree_text"><input (change)="onCheckboxChange($event,'agree')" class="pointer_link" id="agree" type="checkbox" name="agree" style='vertical-align:middle;'><span class="grey_color"> I have read and agree to Smilo.ai's </span><a target="_blank" href="https://smilo.ai/patient_consent_form">Consent Form</a>, <a target="_blank" href="{{terms_site}}">Terms and Conditions</a>, and <a target="_blank" href="{{privacy_site}}">Privacy Policy</a></p>
                        </div> -->

            <!-- <div class="checkbox_rounded d-flex align-items-center ml-2">
                            <input id="agree" type="checkbox" name="agree" [checked]="checkboxChecked">
                            <label for="agree"></label>
                            <span class="check_text agree_text ml-2"><span class="grey_color"> I have read and agree to Smilo.ai's </span><a target="_blank" href="{{consent_form}}">Consent Form</a>, <a target="_blank" href="{{terms_site}}">Terms and Conditions</a>, and <a target="_blank" href="{{privacy_site}}">Privacy Policy</a></span>
                        </div> -->

            <div class="d-flex justify-content-end flex_start">
              <button
                [disabled]=""
                type="submit"
                class="my-4 btn btn_width_2 button_links coninue_btn btn_color mx-0"
              >
                <span class="save_continue_btn_span">Next</span
                ><span class="icon_arrow icon-right-arrow ml-3 font_12"></span>
              </button>
            </div>

            <!-- <button [disabled]="isButtonDisabled" type="submit" class="btn button_links btn_width_2 coninue_btn btn_color mx-0 mt-3 justify-content-center align-items-center"><span class="save_continue_btn_span">Next</span><span class="icon_arrow icon-right-arrow ml-3 font_12"></span></button> -->

            <!-- <div class="d-flex align-items-center justify-content-center">
                            <input (change)="onCheckboxChange($event,'agree')" id="agree" type="checkbox" name="agree" class="pointer_link">
                            <label for="agree" class="ml-2 pointer_link agree_label">
                            <a> I agree to the 
                            <a class="border_bottom" target="_blank" href="https://smilo.ai/patient_consent_form">Smilo.ai patient consent</a> information</a>
                            </label>
                        </div>
                        <button type="submit" class="mt-3 btn button_links btn_width_1 coninue_btn btn_color">CONTINUE</button>
                        <p class="mt-3">By clicking on continue, you agree to our <span><a class="border_bottom" target="_blank" href="{{terms_site}}">Terms</a></span> & <span><a class="border_bottom" target="_blank" href="{{privacy_site}}">Privacy</a></span></p> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
