<div class="dynamic_wrapper">
    <div class="vs-messages"></div>
    <div class="dms_wrapper">
        <app-back-to-home [settingsData]="settingsData[0]"></app-back-to-home>
        <div class="dms_content">
            <div class="loading_div analysing_sec" *ngIf="!flag">
                <div class="img_section text-center position-relative">
                    <div class="loader_img_sec mt-4">
                        <img src="assets/images/loader.gif" />
                    </div>
                    <div class="analyzing_text mb-4">
                        <p class="thank_you_head">Please Wait OHR Calculation is in progress</p>
                    </div>
                </div>
            </div>
            <div *ngIf="flag">
                <!-- <div class="processing">
                    <img src="assets/images/step3.png" alt="image">
                </div> -->
                <div id="steps">
                    <div id="circles" class="circle-steps">
                        <div data-num="1" id="num1"></div>
                        <div data-num="2" id="num2"></div>
                        <div data-num="3" id="num3" class="disabled"></div>
                    </div>
                </div>
                <div class="get_in_touch_sec">
                    <h3 class="sub_head mb-4 text-center">Tell us a little more about your Oral Health</h3>
                    <form autocomplete="off" [formGroup]="InputForm" class="contact_info_form" onkeydown="return event.key != 'Enter';">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row align-items-center d_block" *ngFor="let data of responsedata let i = index">
                                    <div class="col-md-12 col-lg-5 my-3 max_width">
                                        <p class="grey_color font_16 MuseoSans-500 ml-1" id="{{data.question_id}}">{{data.question_name}}</p>
                                    </div>
                                    <div class="col-md-12 col-lg-7 my-3 max_width">
                                        <div class=" options_sec">
                                            <div class=" text-center" *ngIf="data.type==1 || data.type==2">
                                                <div class="oral_hygiene_btn{{i}} text-right row ml-1">
                                                    <div *ngFor="let options of data.options let j = index" class="">
                                                        <button class="btn tell-us-about-oral-health custom_btn custom_button{{i}}{{j}} " (click)="onAnswer($event,i,data.type,data.question_id,j)">{{options.option_name}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="data.type==3">
                                            <div class="oral_hygiene_btn{{i}} text-right">
                                                <div>
                                                    <input type="text" autocomplete="off" class="firstletter_capital input_text_field form-control" trim="blur" id="id" #answer_text (focusout)="onAnswer($event,i,data.type,data.question_id)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center my-3 pt-3 d-flex justify-content-center flex_direction">
                            <div>
                                <button class="pointer_link btn btn_width_2 skip_btn" (click)="onContinue('skip')">Skip</button>
                                <button class="btn button_links submit_btn btn_color btn_width_2 finish_btn" [disabled]=isSubmitButtonDisabled (click)="onContinue('continue')">Finish</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>
