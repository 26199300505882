<!-- <div class="d-flex justify-content-between align-items-center px-3" *ngIf="displayIcons">
    <div class="edit_icon text-right font_50"><span class="icon-back icon_edi cursor_pointer" id="icon_back" (click)="onBack()"></span></div>
    <div class="edit_icon text-right font_50"><span class="icon-home icon_edi cursor_pointer" (click)="onBackToHome()"></span></div>
</div> -->


<!--<div class="dms_header position-relative" *ngIf="displayIcons">    
    <h4 class="text-center text-white MuseoSans-500">{{displayHeader}}</h4>
 
 <div> <div class="headerDiv" *ngIf="displaySettings" > <a href="{{button_url}}" class="btn button_links btn_width_2 ctabtn" target="_blank">{{button_text}}</a>  </div> <div class="headerDiv">  <span class="icon-home text-white icon_home headerDiv" (click)="onBackToHome()"></span> </div> </div> 
    
</div> -->


<div class="dms_header position-relative d-none" *ngIf="displayIcons">
    <div class="row align-items-center">
        <div class="col-8">
            <div class="row header-cs">
                <!-- <div class="display_header"> -->
                <!-- <div *ngIf="displaySettings"> -->
                <div class="col-12 order-2 order-md-1 order-lg-1  col-md-6 col-lg-6 d-flex align-items-center justify-content-end justify-content-md-center justify-content-lg-center me-2 mt-3 mt-md-0 mt-lg-0">
                    <a href="{{button_url}}" class="" target="_blank"><button
                            class=" btn btn-primary book_btn">{{button_text}}   </button></a>
                    <a href="{{button_url}}" class="icon-information-a" target="_blank"><span class="text-center"></span> <span
                            class="icon-information mr-3 text-white cta_icon"> </span> </a>
                </div>
                <!-- </div> -->
                <div class="col-12 order-1 order-md-2 order-lg-2 col-md-6 col-lg-6 d-flex align-items-center justify-content-end justify-content-md-center justify-content-lg-center me-2 mt-3 mt-md-0 mt-lg-0">
                    <h4 class="text-center common_back_to_home_header text-white MuseoSans-500">{{displayHeader}}</h4>
                </div>
                <!-- </div> -->
            </div>
        </div>
        <div class="col-4">
            <div class="row">
                <div class="profile-and-home-div col-12 order-3 col-md-12 col-lg-12 d-flex align-items-center justify-content-center mt-lg-0">
                    <div *ngIf="display_home_profile_icon" class="  d-flex align-items-center" [ngClass]="{'unique_class': displaySettings === false}">
                        <div style="margin-right: 1rem !important" class="user_profile d-flex" id="user_profile">
                            <div style="margin-right: 1rem" class="user_data_profile d-flex flex-column justify-content-center">
                                <p class="user_type font_12 d-block" title="{{user_name}}">{{user_name}}</p>
                                <p class="user_email font_12 d-block" title="{{user_email}}">{{user_email}}</p>
                            </div>
                            <div  *ngIf="get_contact_information_later=='yes';then profileWithoutClick else profileWithClick"> </div>

                                <ng-template #profileWithoutClick><div class="user_profile_icon profile_img"> <img class="lazy lazy-complete" src="{{profile_image}}" alt="profile" /> </div> </ng-template>
                                <ng-template #profileWithClick><div class="user_profile_icon profile_img"> <img (click)="user_profile_view()" class="lazy lazy-complete" src="{{profile_image}}" alt="profile" /> </div></ng-template>

                                
                            
                        </div>
                        <div *ngIf="uploadImageBtnSelected=='true';then redirectMainHome else ohrDmsHome"></div>
                        <ng-template #redirectMainHome><span class="icon-home text-white headerDiv" (click)="redirectToHome(move_home_btn)"></span></ng-template>
                        <ng-template #ohrDmsHome><span class="icon-home text-white headerDiv" *ngIf="displayHomeIcon" (click)="onBackToHome(move_home_btn)"></span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="dms_header responsive_dms_header position-relative d-block" *ngIf="displayIcons">
    <div class="h-100">
        <div class="d-flex mt-3 mt-md-0 mt-lg-0 mt-xl-0 justify-content-center align-items-center">
            <h4 class="text-white MuseoSans-500 text-center">{{displayHeader}}</h4>
            <div *ngIf="display_home_profile_icon" class="position-absolute" [ngClass]="displaySettings === true ? 'profile-div-with-cta' : 'profile-div'" >
                <div class="row">
                    <div class="profile-and-home-div col-12 order-3 col-md-12 col-lg-12 d-flex align-items-center justify-content-center mt-lg-0">
                        <div class="  d-flex align-items-center" [ngClass]="{'unique_class': displaySettings === false}">
                            <div (click)="user_profile_view()" style="margin-right: 5px !important" class="user_profile d-flex" id="user_profile">
                                <div style="margin-right: 1rem" class="user_data_profile d-flex flex-column justify-content-center">
                                    <p class="user_type font_12 d-block" title="{{user_name}}">{{user_name}}</p>
                                    <p class="user_email font_12 d-block" title="{{user_email}}">{{user_email}}</p>
                                </div>
                                <div class="user_profile_icon profile_img">
                                    <img class="lazy lazy-complete" src="{{profile_image}}" alt="profile" />
                                </div>
                            </div>
                            <div *ngIf="uploadImageBtnSelected=='true';then redirectMainHome else ohrDmsHome"></div>
                            <ng-template #redirectMainHome><span class="icon-home text-white headerDiv" (click)="redirectToHome(move_home_btn)"></span></ng-template>
                            <ng-template #ohrDmsHome><span class="icon-home text-white headerDiv" *ngIf="displayHomeIcon" (click)="onBackToHome(move_home_btn)"></span>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="position-absolute d-flex align-items-center global_cta des_global_cta" style="left:20px">
                    <div *ngIf="displaySettings">
                        <a href="{{button_url}}" class="btn button_links btn_width_2 ctabtn mr-3" target="_blank">{{button_text}}</a>
                        <a href="{{button_url}}" class="icon-information-a" target="_blank"><span class="text-center"></span> <span class="icon-information mr-3 text-white cta_icon"> </span> </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="position-absolute d-flex align-items-center global_cta responsive_global_cta">
            <div *ngIf="displaySettings">
                <a href="{{button_url}}" class="btn button_links btn_width_2 ctabtn mr-3" target="_blank"><span class="text-center">{{button_text}}</span></a>
                <a href="{{button_url}}" class="icon-information-a" target="_blank"><span class="text-center"></span> <span class="icon-information mr-3 text-white cta_icon"> </span> </a>
            </div>
        </div>
    </div>
</div>

<!-- <div class="dms_header position-relative" *ngIf="displayIcons">
    <div class="d-flex align-items-center justify-content-center h-100">
        <h4 class="text-center text-white MuseoSans-500">{{displayHeader}}</h4>
        <div class="position-absolute d-flex align-items-center global_cta">
            <div *ngIf="displaySettings">
                <a href="{{button_url}}" class="btn button_links btn_width_2 ctabtn mr-3" target="_blank"><span class="text-center">{{button_text}}</span></a>
                <a href="{{button_url}}" class="icon-information-a" target="_blank"><span class="text-center"></span> <span class="icon-information mr-3 text-white cta_icon"> </span> </a>
            </div>
            <span class="icon-home text-white headerDiv" (click)="onBackToHome()"></span>
        </div>
    </div>
</div> -->