import { Component, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, ViewChild, OnInit, TemplateRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialogComponent } from '../global_components/cropper-dialog/cropper-dialog.component';
import { HttpClient } from '@angular/common/http';
// import { Router, ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Services } from '../helpers/Services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
 
import { environment } from "src/environments/environment";

declare var gtag;
declare const fbq;


@Component({
  selector: 'app-upload-smile-photo',
  templateUrl: './upload-smile-photo.component.html',
  styleUrls: ['./upload-smile-photo.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadSmilePhotoComponent implements OnInit {
  modalRef: BsModalRef;
  cropped?: string;
  @ViewChild('fileInput') el: ElementRef;
  flag = false;
  url = '';
  InputForm: FormGroup;
  submitted = false;
  defaultImage = 'assets/images/loader_large.gif';
  // staticImage: string = "assets/images/upload_smile.png";
  staticImage: string = "assets/images/upload-sample_image1.png";
  imageUrl: any;
  editFile: boolean = true;
  folder: any;
  file_name: any;
  todaysDataTime = formatDate(new Date(), 'yyyyMMddHHMMSS', 'en-US');
  todaysDataTime_sec = formatDate(new Date(), 'HHMMSS', 'en-US');
  cropped_url_blob: Blob;
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  showLoading: boolean = true;
  is_image_uploaded = false;
  preview_image_url: any;
  displayHeader: any = '';
  fb_pixel_id:any = ''; 
  gtm_id:any = '';

  constructor(private _dialog: LyDialog, private httpClient: HttpClient, private router: Router,
    private _cd: ChangeDetectorRef, private formBuilder: FormBuilder, private modalService: BsModalService) {
    this.appservices = new Services;
  }
 /* @HostListener('window:popstate', ['$event'])
  onPopState(e: KeyboardEvent) {
    window.location.reload();
   // console.log('Back button pressed');
  }*/

  ngOnInit(): void {

    this.appservices.setSession("bannerTitleText","Design My Smile", false);
    this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_dms_entered',true); 
    this.displayHeader = this.appservices.getSession("bannerTitleText", false);
    // console.log(" Header Name : " + this.displayHeader);
    this.appservices.serviceMessages('vs-messages', '', '');
    this.InputForm = this.formBuilder.group({
      cover_image: ['', [Validators.required]]
    });
  }
  get f() { return this.InputForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.InputForm.invalid) {
      return;
    } else {
      if (this.is_image_uploaded) {
        this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_dms_process',false); 
        this.submitted = false;
        this.appservices.setSession("smile_image_url", this.file_name, false);
        this.appservices.setSession("preview_image_url", this.preview_image_url, false);

        this.fb_pixel_id = this.appservices.getSession("fb_pixel_id", false);
        this.gtm_id      = this.appservices.getSession("gtm_id", false);
 
    

        this.router.navigate(['select-gum-color']);
      }
      else {
        this.appservices.serviceMessages("vs-messages", "Image still in uploading. Please wait...", "danger")
      }

    }
  }
  openCropperDialog(event: Event) {
    $("body").addClass("modal-open");
    this.appservices.serviceMessages("vs-messages", "", "");
    let user_id = this.appservices.getSession("user_id", false);
    let first_name = this.appservices.getSession("first_name", false);
    let last_name = this.appservices.getSession("last_name", false);
    let full_name = this.appservices.getSession("full_name", false);
    this._dialog.open<CropperDialogComponent, Event>(CropperDialogComponent, {
      data: event,
      width: 320,
      disableClose: true,
    }).afterClosed.subscribe((result?: ImgCropperEvent) => {
      $("body").removeClass("modal-open");
      if (result) {
        let imgObj: any = this.appservices.getSession("imgObj", true);
        // console.log('uploaded image' + imgObj.width)
        // console.log('result of s3 image ' + result.height, result.width)
        if (imgObj.width < 700 || imgObj.height < 700) {
          this.flag = false;
          $('.smile_uploaded').hide();
          $(".icon_plus").show();
          this.appservices.serviceMessages("vs-messages", "The cropped image required minimum 700X700px size to process. Please upload high resolution image to process.", "danger")
        }
        else {
          $('.icon-camera').css('display','none');
          $('.icon_upload_text').css('display','none');
          $('.icon_arrow').hide();
          $('.save_continue_btn').removeClass('pointer_events_auto').addClass("disabled pointer_events_none");
          $('.save_continue_btn_span').html('Uploading...');
          $('.upload_inner_section').removeClass('edit_icon_hover').addClass("image_brightness");
          $('#overlay').addClass('overlay');
          $(".upload_preview_span").addClass("spinner-border");
          $('.please_wait_msg').text('Please wait...');
          $('.upload_preview ').removeClass('cursor_pointer');
          $('.smile_uploaded').show();
          this.flag = true;
          // let fileExtention = result.originalDataURL.split(';')[0].split('/')[1];
          // fileExtention = fileExtention == 'jpeg' ? 'jpg' : fileExtention;
          let fileExtention = 'png';
          this.cropped = result.dataURL;
          // console.log(" cropped  : " + this.cropped);
          this.cropped_url_blob = this.appservices.convertDataUrlToBlob(result.dataURL);
          this.folder = 'smile_design/';
          this.file_name = user_id + '/' + this.todaysDataTime + new Date().getTime() + '/' + first_name.toString().replace(/ /g, "_") + '_' + last_name.toString().replace(/ /g, "_") + "_smiley." + fileExtention;
          let greetingPromise = this.appservices.uploadToS3(this.cropped_url_blob, this.file_name, this.folder, fileExtention);
          let thisobject = this;
          greetingPromise.then(function (resolveOutput) {
            // console.log(" uploadToS3  : ");
            thisobject.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_dms_pic_selected',true); 
            thisobject.is_image_uploaded = true;
            $('.icon_arrow').show();
            $(".icon_plus,.icon_upload").hide();
            $('.upload_inner_section').addClass('edit_icon_hover').removeClass("image_brightness");
            $('#overlay').removeClass('overlay');
            $(".upload_preview_span").removeClass("spinner-border");
            $('.please_wait_msg').text('');
            $(".save_continue_btn").removeClass("disabled").addClass('pointer_events_auto');
            $('.save_continue_btn_span').html('Next');
            $('#num2').removeClass('disabled');
            thisobject.preview_image_url = resolveOutput["Location"];
          }, function (rejectOutput) {
            thisobject.is_image_uploaded = false;
          });
          this.InputForm.patchValue({
            file: result.dataURL
          });
          this._cd.markForCheck();
        }
      }
    });
  }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.url = event.target.result as string;
      }
    }
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'custom_notifications_modal vertical_align_center',
      animated: true,
      backdrop: 'static'
    });
  }
}
