import { HttpClient } from "@angular/common/http";
import { Component, OnInit, TemplateRef } from "@angular/core";
import { Subscription, interval } from "rxjs";
import { Services } from "../helpers/Services";
import * as $ from "jquery";
import { Router } from "@angular/router";
import * as Bowser from "bowser";
import { environment } from "src/environments/environment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
declare var gtag;
declare const fbq;
@Component({
  selector: "app-input-user-contact",
  templateUrl: "./input-user-contact.component.html",
  styleUrls: ["./input-user-contact.component.css"],
})
export class InputUserContactComponent implements OnInit {
  InputForm: FormGroup;
  isSubmitted = false;
  firstName: string = "";
  lastName: string = "";
  phoneNumber: any;
  dialCode: any;
  isOtpVerificationProcessExist: boolean = false;
  digitOne: any = "";
  digitTwo: any = "";
  digitThree: any = "";
  digitFour: any = "";
  isInValidOtp: boolean = false;
  isOtpSuccess: boolean = false;
  timer: number = 30;
  isTimerOver: boolean = false;
  isOtpReceive: boolean = true;
  timerSubscription!: Subscription;
  contactFormBtn: boolean = false;
  user_details: any;
  appservices: Services;
  backgroundProcessing: boolean = true;
  contactFormSubmitted: boolean = false;
  showContactForm: boolean = false;
  showLoadingForm: boolean = false;
  apirequest: any[] = [];
  country_name: any = "";
  input: any;
  header_display_msg: any = "";
  guest_user_id: any = "";
  redirecting_url: any = "";
  uploadImageBtnSelected: any = false;
  isButtonDisabled: boolean = true;
  countryCodeNumber: any;
  contactus_country_name: any = "";
  static httpClient: any;
  modalRef: BsModalRef;
  inputFormOtp: FormGroup;
  get_contact_information_later: any = "";
  guest_session_uuid: any = "";
  ohr_flow: any;
  analyzingText: any = "Please wait while we analyse your oral health";
  successText: string = "Successfully Done";
  isDataLoaded: boolean = false;
  constructor(
    private router: Router,
    private modalService: BsModalService,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.appservices = new Services();
  }

  ngOnInit(): void {
    this.appservices.setSession("current_url", "get-user-details", false);
    this.appservices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_personal_details_page_opened",
      true
    );
    let overall_score = this.appservices.getSession("overall_score", false);
    if (overall_score == "") {
      this.router.navigate(["/getting-started"]);
    }
    this.get_contact_information_later = this.appservices.getSession(
      "get_contact_information_later",
      false
    );
    this.guest_session_uuid = this.appservices.getSession(
      "guest_session_uuid",
      false
    );
    this.guest_user_id = this.appservices.getSession("guest_user_id", false);
    this.ohr_flow = this.appservices.getSession("ohr_flow", false);
    if (!this.appservices.getSession("token", false)) {
      this.router.navigate([
        "get-started/" + this.appservices.getSession("token", false),
      ]);
    }
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$";
    this.InputForm = this.formBuilder.group({
      firstName: ["", [Validators.required, Validators.minLength(1)]],
      lastName: ["", [Validators.required, Validators.minLength(1)]],
      email: ["", [Validators.required, Validators.pattern(emailPattern)]],
      // phoneNumber: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      phoneNumber: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?))?[0-9]{10}$")],
      ],
      //termsAndConditions: ['', Validators.requiredTrue]
    });
    this.inputFormOtp = this.formBuilder.group({
      firstDigit: ["", [Validators.required]],
      secondDigit: ["", [Validators.required]],
      thirdDigit: ["", [Validators.required]],
      fourthDigit: ["", [Validators.required]],
    });
    let otpProcess = this.appservices.getSession(
      "user_otp_verification_process",
      false
    );
    if (otpProcess === "true") {
      this.isOtpVerificationProcessExist = false;
    } else {
      this.isOtpVerificationProcessExist = false;
    }

    if (localStorage.getItem("Input_form_data") != "") {
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
    }
    if (this.user_details) {
      this.InputForm.controls["firstName"].setValue(
        this.user_details.firstName
      );
      this.InputForm.controls["lastName"].setValue(this.user_details.lastName);
      this.InputForm.controls["email"].setValue(this.user_details.email);
      this.InputForm.controls["phoneNumber"].setValue(
        this.user_details.phoneNumber
      );
    }
  }

  onBack() {
    this.router.navigate(["./upload-teeth-photos"]);
  }

  onContactFormSubmit() {
    this.isSubmitted = true;
    this.isOtpReceive = false;
    // console.log("submited");
    // if(!this.signInValidNumber) { return false; }

    if (this.InputForm.invalid) {
      return;
    }
    if (
      (this.InputForm.valid && !this.isOtpVerificationProcessExist) ||
      (this.InputForm.valid &&
        this.isOtpVerificationProcessExist &&
        this.isOtpSuccess)
    ) {
      this.isOtpReceive = false;
      // if (this.InputForm.value.phoneNumber.toString().length > 15 || this.InputForm.value.phoneNumber.toString().length < 8) {
      //   this.appservices.serviceMessages('vs-messages', 'Phone Number must be 8 to 15 digits only', 'danger')
      // }
      // else

      // if (!$('#agree').prop("checked")) {
      //   this.appservices.serviceMessages('vs-messages', 'To continue, please accept the consent form, terms of use and privacy policy below.', 'danger')
      // }
      // else {
      // localStorage.setItem('Input_form_data','');
      // console.log(" JSON.stringify(this.InputForm.value) : " + JSON.stringify(this.InputForm.value));
      localStorage.setItem(
        "Input_form_data",
        JSON.stringify(this.InputForm.value)
      );
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
      this.appservices.setUserEmail(this.InputForm.value.email.trim());
      this.contactFormSubmitted = true;
      this.callContactAPI();
      // if (this.backgroundProcessing === false) {
      // } else if (this.backgroundProcessing === true) {
      //   this.showContactForm = false;
      //   this.showLoadingForm = true;
      // }
      // this.router.navigate(['email-confirmation']);
      //  }
    }
  }

  callContactAPI() {
    this.showLoadingForm = true;
    if (true) {
      $(".coninue_btn").html("Please wait...").attr("disabled", true);
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
      this.apirequest = [];
      this.apirequest["first_name"] = this.user_details.firstName;
      this.apirequest["last_name"] = this.user_details.lastName;
      this.apirequest["email"] = this.user_details.email;
      this.apirequest["phone"] = this.user_details.phoneNumber;
      this.apirequest["widget_token"] = this.appservices.getSession(
        "token",
        false
      );
      let dail_code = this.appservices.getSession("dial_code", false)
        ? this.appservices.getSession("dial_code", false).toString()
        : "1";
      this.apirequest["dial_code"] = dail_code;

      this.apirequest["country_name"] = this.country_name;

      this.apirequest["branch_id"] = this.appservices.getSession(
        "user_selected_practice_branch",
        false
      );

      this.input =
        '"user":' + this.appservices.generteJSONFromArray(this.apirequest);
      this.appservices
        .callJSONAPI(
          this.appservices.WIDGET_LOGIN,
          this.input,
          "post",
          this,
          ""
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            // $('.coninue_btn').html('CONTINUE').attr('disabled', false);
            if (data.status) {
              this.appservices.setSession("existing_user", "no", false);
              this.header_display_msg =
                "Smilo.ai will create an account with auto generated password for you and send an email with login details. please use login details to view/download Oral Health Rating and Design my smile report.";
              // console.log(" data.result.existing_user : " + data.result.existing_user);
              if (data.result.existing_user === "true") {
                this.appservices.setSession("existing_user", "yes", false);
              }

              let full_name =
                data.result.user.first_name + data.result.user.last_name;
              this.appservices.setSession("is_logged_in", "true", false);
              this.appservices.setSession(
                "user_access_token",
                data.result.user.user_access_token,
                false
              );
              this.appservices.setSession(
                "first_name",
                data.result.user.first_name,
                false
              );
              this.appservices.setSession(
                "last_name",
                data.result.user.last_name,
                false
              );
              this.appservices.setSession("full_name", full_name, false);
              this.appservices.setSession(
                "email",
                data.result.user.email,
                false
              );
              this.appservices.setSession(
                "user_id",
                data.result.user.user_id,
                false
              );
              // this.appservices.setSession("get_contact_information_later",'', false);

              // this.get_contact_information_later =  this.appservices.getSession("get_contact_information_later",false);
              // console.log(" this.get_contact_information_later : " + this.get_contact_information_later);
              // if(!this.appservices.isEmpty(this.get_contact_information_later))
              // {
              //   console.log("getiing inside");
              //   this.sendSubjectMessage = {first_name:data.result.user.first_name,last_name:data.result.user.first_name,text:this.profile_img}
              //   if(!this.appservices.isEmpty(data.result.user.profile_picture))
              //   {
              //     console.log("getiing inside profile pic");
              //     this.sendSubjectMessage['text'] = environment.S3_BASE_URL+data.result.user.profile_picture;
              //     // this.messageService.sendMessage({text:environment.S3_BASE_URL+this.profile_img_complete_path,first_name:form.value.first_name,last_name:form.value.last_name});
              //     this.appservices.setSession("profile_picture", environment.S3_BASE_URL+data.result.user.profile_picture, false);
              //   }
              //   this.messageService.sendMessage(this.sendSubjectMessage);
              // }

              // console.log(" data.result.user.phone : " + data.result.user.phone);
              // console.log(" data.result.user.dial_code : " + data.result.user.dial_code);
              // console.log(" data.result.user.city : " + data.result.user.city);

              // console.log(" data.result.user.state_id : " + data.result.user.state_id);
              // console.log(" data.result.user.country_id : " + data.result.user.country_id);
              // console.log(" data.result.user.profile_picture : " + data.result.user.profile_picture);

              if (data.result.user.phone)
                this.appservices.setSession(
                  "phoneNumber",
                  data.result.user.phone,
                  false
                );

              if (data.result.user.dial_code)
                this.appservices.setSession(
                  "dial_code",
                  data.result.user.dial_code,
                  false
                );

              if (data.result.user.city)
                this.appservices.setSession(
                  "city",
                  data.result.user.city,
                  false
                );

              if (data.result.user.state_id)
                this.appservices.setSession(
                  "state_id",
                  data.result.user.state_id,
                  false
                );

              if (data.result.user.country_id)
                this.appservices.setSession(
                  "country_id",
                  data.result.user.country_id,
                  false
                );

              // console.log(" data.result.user.dial_country_code : " + data.result.user.dial_country_code);

              if (data.result.user.dial_country_code)
                this.appservices.setSession(
                  "country_name",
                  data.result.user.dial_country_code,
                  false
                );

              this.appservices.setSession(
                "profile_picture",
                data.result.user.profile_picture,
                false
              );

              // console.log(" data.result.city : " + data.result.user.city );
              // console.log(" data.result.state_id : " + data.result.user.state_id );
              // console.log(" data.result.country_id : " + data.result.user.country_id );

              this.appservices.sendAnalyticsData(
                gtag,
                fbq,
                "smilo_event",
                "sa_wg_personal_details_entrered",
                true
              );
              this.appservices.sendPageViewAnalyticsData(
                gtag,
                "",
                this.router.url,
                "OHR – User details page"
              );

              this.apirequest = [];
              this.apirequest["report_id"] = this.appservices.getSession(
                "ohr_unique_key",
                false
              );
              this.apirequest["user_id"] = this.appservices.getSession(
                "user_id",
                false
              );
              this.apirequest["guest_user_id"] = this.guest_user_id;
              this.apirequest["widget_token"] = this.appservices.getSession(
                "token",
                false
              );
              this.apirequest["overall_score"] = this.appservices.getSession(
                "overall_score",
                false
              );
              this.apirequest["selected_branch_id"] =
                this.appservices.getSession("selected_branch_id", false);
              this.apirequest["health_score_id"] = this.appservices.getSession(
                "health_score_id",
                false
              );
              // this.apirequest["widget_token"] = this.appservices.getSession(
              //   "token";
              this.apirequest["timezone"] = "Asia/Calcutta";
              this.apirequest["device_details"] = this.getDeviceInfo();
              // this.input = this.appservices.generteJSONFromArray(this.apirequest);
              let report_input = this.appservices.generteJSONFromArray(
                this.apirequest
              );
              // console.log(this.apirequest);
              this.appservices
                .callJSONAPI(
                  this.appservices.UPDATE_OHR_USER_ID,
                  report_input,
                  "post",
                  this,
                  ""
                )
                .subscribe((data: any) => {
                  console.log(
                    "🚀 ~ file: input-user-contact.component.ts:390 ~ InputUserContactComponent ~ .subscribe ~ data:",
                    data
                  );
                  if (data.status) {
                    this.callMedicalQueSaveAPI();
                  } else {
                    this.router.navigate(["no-internet"]);
                  }
                });

              // this.appservices.callJSONAPI(this.appservices.CALCULATINGSCORES + "?device_details=" + this.getDeviceInfo() + "&oral_unique_id=" + this.appservices.getSession("ohr_unique_key", false) + "&widget_token=" + this.appservices.getSession("token", false) + "&timezone=Asia/Calcutta&selected_branch_id=" + this.appservices.getSession("user_selected_practice_branch", false)+"&payment_transaction=true&get_contact_information_later="+this.get_contact_information_later+"&guest_user_id="+this.guest_user_id+"&user_id="+data.result.user.user_id, "", 'get', this, '127.0.0.1')
              // .subscribe(
              // (data: any) =>
              // {
              //   if (data.status)
              //   {
              //     this.callMedicalQueSaveAPI();
              //   }
              // })

              // this.router.navigate(['medical-questions']);
            } else {
              this.appservices.serviceMessages(
                "vs-messages",
                data.message,
                "danger"
              );
              if (!this.appservices.getSession("token", false)) {
                this.router.navigate([
                  "get-started/" + this.appservices.getSession("token", false),
                ]);
              } else {
                console.log("Token not found");
                this.router.navigate(["no-internet"]);
              }
            }
          },
          (error) => {
            // this.appservices.showDebugMessage(error);
            this.router.navigate(["no-internet"]);
          }
        );
    }
  }

  callMedicalQueSaveAPI() {
    this.apirequest = [];
    this.apirequest["widget_token"] = this.appservices.getSession(
      "token",
      false
    );
    this.apirequest["questions"] = this.appservices.getSession(
      "questions_array",
      true
    );
    let input = this.appservices.generteJSONFromArray(this.apirequest);
    // console.log(input);
    this.appservices
      .callJSONAPI(
        this.appservices.MEDICAL_QUESTIONNAIRE_SAVE,
        input,
        "post",
        this,
        ""
      )
      .subscribe(
        (data: any) => {
          console.log(data);
          if (data.status) {
            // this.appservices.serviceMessages('vs-messages', data.message, 'success');

            this.redirecting_url = this.appservices.getSession(
              "redirecting_url",
              false
            );

            if (!this.appservices.isEmpty(this.redirecting_url)) {
              setTimeout(() => {
                // this.appservices.setSession("get_contact_information_later",'', false);

                this.appservices.setSession(
                  "hasRedirectedFromContactForm",
                  "true",
                  false
                );
                this.isDataLoaded = true;
                setTimeout(() => {
                  this.showLoadingForm = false;
                  this.router.navigate([this.redirecting_url]);
                }, 3000);
                // this.router.navigate([this.redirecting_url]);
              }, 500);
            } else {
              this.uploadImageBtnSelected = this.appservices.getSession(
                "uploadImageBtnSelected",
                false
              );
              //  console.log(" this.uploadImageBtnSelected :" + this.uploadImageBtnSelected);
              if (
                this.uploadImageBtnSelected != undefined &&
                this.uploadImageBtnSelected == "false"
              ) {
                this.router.navigate(["oral-health-score-preference"]);
              } else if (
                this.uploadImageBtnSelected != undefined &&
                this.uploadImageBtnSelected == "true"
              ) {
                this.router.navigate(["practice-images"]);
                // this.router.navigate(['oral-health-score-preference']);
              }
              // this.router.navigate(['oral-health-score-preference']);
            }
          } else {
            this.appservices.serviceMessages(
              "vs-messages",
              data.message,
              "danger"
            );
            this.router.navigate(["no-internet"]);
          }
        },
        (error) => {
          //  this.appservices.showDebugMessage(error);
          this.router.navigate(["no-internet"]);
        }
      );
  }

  public getDeviceInfo() {
    let userAgent = Bowser.parse(window.navigator.userAgent);
    let browser_details =
      userAgent.browser.name +
      " " +
      userAgent.os.name +
      " " +
      userAgent.browser.version;
    return browser_details;
  }

  async onEnterData(event) {
    // this.signInHasError(event);
    let nameType = event.target.getAttribute("formControlName");
    let nameVal = event.target.value;
    if (nameType === "firstName") {
      this.firstName = nameVal;
    } else if (nameType === "lastName") {
      this.lastName = nameVal;
    } else if (nameType === "phoneNumber") {
      this.phoneNumber = nameVal;
      this.isSubmitted = false;
      // await this.signInHasError(event);
    }
    // console.log(" this.signInValidNumber : " + this.signInValidNumber );
    if (this.InputForm.valid) {
      this.isButtonDisabled = false;
    } else this.isButtonDisabled = true;
  }

  nameLength(classname) {
    if ($("." + classname).val().length > 30) {
      $("." + classname).val(
        $("." + classname)
          .val()
          .substr(0, 30)
      );
    }
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  get ci() {
    return this.InputForm.controls;
  }
  countryChange(country: any) {
    this.dialCode = country?.dialCode;
    let a = this.appservices.getSession("contactus_country_name", false);
    if (!a) {
      return;
      this.appservices.setSession("country_name", "IN", false);
      this.appservices.setSession("dial_code", "91", false);
      this.appservices.setSession("contactus_country_name", "IN", false);
    } else {
      this.country_name = country.iso2;
      this.countryCodeNumber = country.dialCode;
      this.appservices.setSession("country_name", this.country_name, false);
      this.appservices.setSession("dial_code", this.countryCodeNumber, false);
      this.appservices.setSession(
        "contactus_country_name",
        this.country_name,
        false
      );
    }
    // this.country_name = country.iso2;
    // this.countryCodeNumber = country.dialCode;
    // this.appservices.setSession("country_name", this.country_name, false);
    // this.appservices.setSession("dial_code", this.countryCodeNumber, false);
    // this.appservices.setSession(
    //   "contactus_country_name",
    //   this.country_name,
    //   false
    // );
    // this.setValidators();
  }

  telInputObject(obj) {
    if (this.appservices.hasSession("contactus_country_name")) {
      this.contactus_country_name = this.appservices.getSession(
        "contactus_country_name",
        false
      );
      obj.setCountry(this.contactus_country_name.toUpperCase());
    } else {
      this.getCountry(obj);
      // obj.setCountry("au");
    }
    // this.setValidators();
  }

  getCountry(obj) {
    this.httpClient
      .get(environment.IPDATA_API + "?api-key=" + environment.IPDATA_KEY)
      .subscribe((res: any) => {
        obj.setCountry(res["country_code"] === "IN" ? "IN" : "IN");
      });
  }

  openModalVerifyOtp(template: TemplateRef<any>) {
    this.isSubmitted = true;
    if (this.InputForm.invalid || this.isOtpSuccess) {
      return;
    }
    if (this.InputForm.valid) {
      let paramArray: any[] = [];
      let paramsJson: any;
      paramArray["mobile_number"] = this.phoneNumber;
      paramsJson = this.appservices.generteJSONFromArray(paramArray);
      console.log(
        "🚀 ~ file: upload-teeth-photos.component.ts:1979 ~ openModalVerifyOtp ~ paramsJson:",
        paramsJson
      );
      this.appservices
        .callJSONAPI(this.appservices.REQUEST_OTP, paramsJson, "post", this, "")
        .subscribe((data: any) => {
          console.log(
            "🚀 ~ file: upload-teeth-photos.component.ts:1986 ~ .subscribe ~ data: ",
            data
          );
          // debugger;
          if (data.status) {
            this.isOtpReceive = true;
            this.modalRef = this.modalService.show(template, {
              class: "custom_notifications_modal vertical_align_center",
              animated: true,
              backdrop: "static",
            });
            this.timer = 30;
            this.startTimer();
          } else {
            this.isOtpReceive = false;
          }
        });
    }
  }
  closeModal(option: string = "default") {
    if (option === "otp") {
      this.isInValidOtp = false;
      this.digitOne = "";
      this.digitTwo = "";
      this.digitThree = "";
      this.digitFour = "";
      this.isTimerOver = false;
      this.timerSubscription.unsubscribe();
    }
    this.modalRef.hide();
  }
  onKeyOtp(event: any, index: number) {
    // debugger;
    if (event.keyCode === 9 || event.keyCode === 8 || event.keyCode === 46) {
      if (event.keyCode === 8) {
        if (index === 0) {
          this.digitOne = "";
        }
        if (index === 1) {
          this.digitTwo = "";
        }
        if (index === 2) {
          this.digitThree = "";
        }
        if (index === 3) {
          this.digitFour = "";
        }
      }
      return true;
    } else if (event.keyCode >= 96 && event.keyCode <= 105) {
      if (index === 0) {
        this.digitOne = event.key;
      }
      if (index === 1) {
        this.digitTwo = event.key;
      }
      if (index === 2) {
        this.digitThree = event.key;
      }
      if (index === 3) {
        this.digitFour = event.key;
      }
      return true;
    } else {
      if (index === 0) {
        this.digitOne = "";
      }
      if (index === 1) {
        this.digitTwo = "";
      }
      if (index === 2) {
        this.digitThree = "";
      }
      if (index === 3) {
        this.digitFour = "";
      }
      return false;
    }
  }
  setFocus(value: any, index: number) {
    setTimeout(() => {
      if (value) {
        const inputElements = document.getElementsByName(`otpNum${index + 1}`);
        if (inputElements && index < 3) {
          inputElements[0]?.focus();
        }
      }
    }, 50);
  }
  onVerifyOtp() {
    if (
      this.digitOne !== "" &&
      this.digitTwo !== "" &&
      this.digitThree !== "" &&
      this.digitFour !== ""
    ) {
      let otpString =
        this.digitOne + this.digitTwo + this.digitThree + this.digitFour;
      const otp = parseInt(otpString);
      let paramArray: any[] = [];
      let paramsJson: any;
      paramArray["mobile_number"] = this.phoneNumber;
      paramArray["otp"] = otp;
      paramsJson = this.appservices.generteJSONFromArray(paramArray);
      this.isInValidOtp = false;
      this.isOtpSuccess = true;
      this.closeModal("otp");
      this.appservices
        .callJSONAPI(this.appservices.VERIFY_OTP, paramsJson, "post", this, "")
        .subscribe((data: any) => {
          // debugger;
          if (data.status) {
          }
        });
    } else {
      this.isInValidOtp = true;
    }
  }
  startTimer() {
    this.timerSubscription = interval(1000).subscribe(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.timerSubscription.unsubscribe();
        this.isTimerOver = true;
        // Handle timer expiration here (e.g., show a message)
      }
    });
  }

  onResendCode() {
    this.isTimerOver = false;
    this.timer = 30;
    this.timerSubscription.unsubscribe();
    this.startTimer();
  }
  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
