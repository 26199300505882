<div>
  <div class="next-card-header">
    <div
      class="next-card-top d-flex align-items-center justify-content-between"
    >
      <div class="card-content">
        <h4>Get Special Offers</h4>
        <!-- <h4>Get Product Discounts</h4> -->
        <p>Get discounts on your favourite Sensodyne oral products.</p>
        <!-- <p>Get a discount on dental products & enhance your dental routine.</p> -->
        <button
          class="btn btn-primary btn-rounded-corner btn-icon-right btn-xs mt-2"
          (click)="onGetNow()"
        >
          Get Now <i class="icon_arrow icon-right-arrow"></i>
        </button>
      </div>
      <div class="card-image">
        <img
          src="assets/images/product-discount.png"
          class="img-fluid d-none d-sm-block"
          alt=""
        />
        <img
          src="assets/images/product-discount-mobile.png"
          class="img-fluid d-block d-sm-none"
          alt=""
        />
      </div>
    </div>
    <div class="next-card-bottom">
      <a
        href="javascript:void(0)"
        (click)="isExpand = !isExpand"
        class="d-flex align-items-center justify-content-center"
        ><b class="mr-1">Learn More</b>About Getting Product Discounts
        <i
          class="ml-2"
          [ngClass]="!isExpand ? 'sh-angle-down' : 'sh-angle-up'"
        ></i
      ></a>
    </div>
  </div>
  <div class="next-card-body" *ngIf="isExpand">
    <h4>All Benefits</h4>
    <div class="card-detail-list d-flex flex-column">
      <div
        class="card-detail-item d-flex align-items-center justify-content-between"
      >
        <div class="content">
          <h5 class="mb-2">Exclusive Discounts</h5>
          <p>
            Enjoy special offers on dental care products for better savings and
            a healthier smile.
          </p>
        </div>
        <div class="icon d-flex align-items-center justify-content-center">
          <i class="sh-box"></i>
        </div>
      </div>
      <div
        class="card-detail-item d-flex align-items-center justify-content-between"
      >
        <div class="content">
          <h5 class="mb-2">Oral Care Support</h5>
          <p>
            Discover products that support your oral health and enhance your
            at-home dental routine.
          </p>
        </div>
        <div class="icon d-flex align-items-center justify-content-center">
          <i class="sh-tooth"></i>
        </div>
      </div>
      <div
        class="card-detail-item d-flex align-items-center justify-content-between"
      >
        <div class="content">
          <h5 class="mb-2">Personalized Recommendations</h5>
          <p>Find products tailored to your oral health needs and concerns.</p>
        </div>
        <div class="icon d-flex align-items-center justify-content-center">
          <i class="sh-shopping-basket-heart"></i>
        </div>
      </div>
    </div>
    <button
      class="btn btn-primary btn-rounded-corner btn-icon-right btn-lg mx-auto mt-3"
      (click)="onGetNow()"
    >
      Get Coupon <i class="icon_arrow icon-right-arrow"></i>
    </button>
  </div>
</div>
