import { FormatTimePipe } from "./helpers/timer.pipe";
import { BrowserModule, Title } from "@angular/platform-browser";
// import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { GetStartedComponent } from "./get-started/get-started.component";
import { ContactInformationComponent } from "./contact-information/contact-information.component";
import { EmailConfirmationComponent } from "./email-confirmation/email-confirmation.component";
import { OralHygieneComponent } from "./oral-hygiene/oral-hygiene.component";
import { ViewReportComponent } from "./view-report/view-report.component";
import { AnalyzingYourImagesComponent } from "./analyzing-your-images/analyzing-your-images.component";
import { OralHealthScoreComponent } from "./oral-health-score/oral-health-score.component";
import { AnalysedResultsComponent } from "./analysed-results/analysed-results.component";
import { RankingGuidelinesComponent } from "./ranking-guidelines/ranking-guidelines.component";
import { SmileDesignPreferenceComponent } from "./smile-design-preference/smile-design-preference.component";
import { UploadSmilePhotoComponent } from "./upload-smile-photo/upload-smile-photo.component";
import { SmilePhotoPreviewComponent } from "./smile-photo-preview/smile-photo-preview.component";
import { SelectGumColorComponent } from "./select-gum-color/select-gum-color.component";
import { SmileBeforeAfterPictureComponent } from "./smile-before-after-picture/smile-before-after-picture.component";
import { OralHealthScorePreferenceComponent } from "./oral-health-score-preference/oral-health-score-preference.component";
import { UploadTeethPhotosComponent } from "./upload-teeth-photos/upload-teeth-photos.component";
import { AppRoutingModule } from "./app-routing.module";
import { VideosInstructionsComponent } from "./videos-instructions/videos-instructions.component";
import { LazyLoadImageModule } from "ng-lazyload-image"; //
import { InvalidAccessComponent } from "./invalid-access/invalid-access.component";
import { FooterComponent } from "./footer/footer.component";
import { CropperDialogComponent } from "./global_components/cropper-dialog/cropper-dialog.component";
import { PracticeImageComponent } from "./practice-images/practice-images.component";
import { PatientImageUploadedComponent } from "./patient-image-uploaded/patient-image-uploaded.component";
import { MomentModule } from "ngx-moment";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import * as moment from 'moment-timezone';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { NgxTrimDirectiveModule } from "ngx-trim-directive";
import { Ng2TelInputModule } from "ng2-tel-input";
// import { SentryErrorHandler } from './error.service';

import { InputRestrictionDirective } from "./restrict-input-char";

import { ErrorInterceptor } from "./core/interceptors/error.interceptor";

import { HttpCancelService } from "./helpers/httpcancel.service";

// Gestures
// Gestures
import { HAMMER_GESTURE_CONFIG, HammerModule } from "@angular/platform-browser";

// /* Import Alyle UI /
import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
  LY_THEME_GLOBAL_VARIABLES,
  LyHammerGestureConfig,
  LyThemeModule,
} from "@alyle/ui";
// /* Import themes /
import { MinimaLight, MinimaDark } from "@alyle/ui/themes/minima";
import { color } from "@alyle/ui/color";
export class GlobalVariables {
  testVal = color(0x00bcd4);
  Quepal = {
    default: `linear-gradient(135deg,#11998e 0%,#38ef7d 100%)`,
    contrast: color(0xffffff),
    shadow: color(0x11998e),
  };
  SublimeLight = {
    default: `linear-gradient(135deg,#FC5C7D 0%,#6A82FB 100%)`,
    contrast: color(0xffffff),
    shadow: color(0xb36fbc),
  };
  Amber = {
    default: color(0xffc107),
    contrast: color(0, 0, 0, 0.87),
  };
}
// /* Import the component modules /
import { LyButtonModule } from "@alyle/ui/button";
// import { LyToolbarModule } from '@alyle/ui/toolbar';
import { LyImageCropperModule } from "@alyle/ui/image-cropper";
import { LySliderModule } from "@alyle/ui/slider";
import { LyIconModule } from "@alyle/ui/icon";
import { LyDialogModule } from "@alyle/ui/dialog";

import { EmailSuccessComponent } from "./email-success/email-success.component";
import { ProcessOhrImagesComponent } from "./process-ohr-images/process-ohr-images.component";
import { MedicalQuestionsComponent } from "./medical-questions/medical-questions.component";
// import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { BackToHomeComponent } from "./back-to-home/back-to-home.component";
import { SignoutComponent } from "./signout/signout.component";

// import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { NgModule, ErrorHandler } from "@angular/core";

import { PasteRestrictDirective } from "./paste-restrict.directive";
import { OhrEmailSuccessComponent } from "./ohr-email-success/ohr-email-success.component";
import { ConnectionServiceModule } from "ng-connection-service";
import { PaymentSuccessfulComponent } from "./payment-successful/payment-successful.component";
import { PaymentOralHealthScoreComponent } from "./payment-oral-health-score/payment-oral-health-score.component";
import { PaymentAccessReportComponent } from "./payment-access-report/payment-access-report.component";
import { PaymentProcessComponent } from "./payment-process/payment-process/payment-process.component";

import { PaymentOralHealthScoreCompletedComponent } from "./payment-oral-health-score-completed/payment-oral-health-score-completed.component";
import { MakeStripePaymentComponent } from "./make-stripe-payment/make-stripe-payment.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { LoginComponent } from "./login/login.component";
import { PurposeOfVisitComponent } from "./purpose-of-visit/purpose-of-visit.component";
import { SelectServiceComponent } from "./select-service/select-service.component";
import { NoInternetComponent } from "./no-internet/no-internet.component";
import { GettingStartPageWidgetComponent } from "./getting-start-page-widget/getting-start-page-widget.component";
import { ConsentAgreementComponent } from "./consent-agreement/consent-agreement.component";
import { InputUserContactComponent } from "./input-user-contact/input-user-contact.component";
import { PaymentSuccessComponent } from "./payment-process/payment-success/payment-success.component";
import { ViewProcessedReportComponent } from "./helpers/common-components/view-processed-report/view-processed-report.component";
import { DownloadReportOptionsComponent } from "./helpers/common-components/download-report-options/download-report-options.component";
import { OtpVerificationPopupComponent } from "./helpers/common-components/otp-verification-popup/otp-verification-popup.component";
import { ReportSharedPopupComponent } from "./helpers/common-components/report-shared-popup/report-shared-popup.component";
import { SharedModule } from "./shared/shared.module";
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { OnlyNumbersDirective } from './helpers/directives/only-numbers.directive';
import { DownloadReportCardComponent } from './helpers/common-components/download-report-card/download-report-card.component';
import { WhatsappChatDentistCardComponent } from './helpers/common-components/whatsapp-chat-dentist-card/whatsapp-chat-dentist-card.component';
import { FiveImageFlowNavigationCardComponent } from './helpers/common-components/five-image-flow-navigation-card/five-image-flow-navigation-card.component';
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    PasteRestrictDirective,
    AppComponent,
    GetStartedComponent,
    ContactInformationComponent,
    EmailConfirmationComponent,
    OralHygieneComponent,
    ViewReportComponent,
    AnalyzingYourImagesComponent,
    OralHealthScoreComponent,
    AnalysedResultsComponent,
    RankingGuidelinesComponent,
    SmileDesignPreferenceComponent,
    UploadSmilePhotoComponent,
    SmilePhotoPreviewComponent,
    SelectGumColorComponent,
    SmileBeforeAfterPictureComponent,
    OralHealthScorePreferenceComponent,
    UploadTeethPhotosComponent,
    VideosInstructionsComponent,
    InvalidAccessComponent,
    FooterComponent,
    CropperDialogComponent,
    PracticeImageComponent,
    EmailSuccessComponent,
    ProcessOhrImagesComponent,
    MedicalQuestionsComponent,
    BackToHomeComponent,
    SignoutComponent,
    OhrEmailSuccessComponent,
    PatientImageUploadedComponent,
    PaymentSuccessfulComponent,
    PaymentOralHealthScoreComponent,
    PaymentAccessReportComponent,
    PaymentProcessComponent,
    PaymentSuccessComponent,
    PaymentOralHealthScoreCompletedComponent,
    MakeStripePaymentComponent,
    UserProfileComponent,
    LoginComponent,
    PurposeOfVisitComponent,
    SelectServiceComponent,
    InputRestrictionDirective,
    NoInternetComponent,
    FormatTimePipe,
    GettingStartPageWidgetComponent,
    ConsentAgreementComponent,
    InputUserContactComponent,
    ViewProcessedReportComponent,
    DownloadReportOptionsComponent,
    OtpVerificationPopupComponent,
    ReportSharedPopupComponent,
    OnlyNumbersDirective,
    DownloadReportCardComponent,
    WhatsappChatDentistCardComponent,
    FiveImageFlowNavigationCardComponent,

    // LazyLoadImageModule
  ],
  imports: [
    ConnectionServiceModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SlickCarouselModule,
    LazyLoadImageModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    LyDialogModule,
    // LyToolbarModule,
    HammerModule,
    MomentModule,
    NgxTrimDirectiveModule,
    Ng2TelInputModule,
    ModalModule.forRoot(),
    SharedModule,
    // Gestures,
    LottieModule.forRoot({ player: playerFactory }),
  ],

  /** Add themes */
  providers: [
    [LyTheme2],
    [Title],
    [StyleRenderer],
    { provide: LY_THEME_NAME, useValue: "minima-light" },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    { provide: LY_THEME, useClass: MinimaDark, multi: true },
    { provide: LY_THEME_GLOBAL_VARIABLES, useClass: GlobalVariables },
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    HttpCancelService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .then(success => console.log('Bootstrap success'))
//   .catch(err => console.error(err));
