import { HttpClient } from "@angular/common/http";
import { Component, OnInit, HostListener } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { HelperServiceService } from "../../services/helper-service.service";
import { Subscription, interval } from "rxjs";
import { Services } from "../../Services";
import { Router } from "@angular/router";
declare var gtag;
declare const fbq;
@Component({
  selector: "app-otp-verification-popup",
  templateUrl: "./otp-verification-popup.component.html",
  styleUrls: ["./otp-verification-popup.component.css"],
})
export class OtpVerificationPopupComponent implements OnInit {
  timer: number = 30;
  timerSubscription!: Subscription;
  isTimerOver: boolean = false;
  appServices: Services;
  user_details: any = {};
  digitOne: any = "";
  digitTwo: any = "";
  digitThree: any = "";
  digitFour: any = "";
  isCorrectOtp: boolean = false;
  errorMessage: string = "";
  oral_unique_id: any = "";
  isInProcess: boolean = false;
  private otpDigit2Element: HTMLInputElement;
  private otpDigit3Element: HTMLInputElement;
  private otpDigit4Element: HTMLInputElement;
  otp = ["", "", "", ""];
  constructor(
    private bsModalRef: BsModalRef,
    private helperServiceService: HelperServiceService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.appServices = new Services();
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(e: KeyboardEvent) {
    this.helperServiceService.downloadOptionRequest.next(0);
    this.bsModalRef.hide();
  }

  ngOnInit(): void {
    let data = localStorage.getItem("Input_form_data");
    if (data) {
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
    }
    this.oral_unique_id = this.appServices.getSession("ohr_unique_key", false);

    if (this.user_details) {
      this.sendOtp();
    }
  }

  ngAfterViewInit() {
    this.otpDigit2Element = <HTMLInputElement>(
      document.getElementsByClassName("otp-input")[1].querySelector("input")
    );
    this.otpDigit3Element = <HTMLInputElement>(
      document.getElementsByClassName("otp-input")[2].querySelector("input")
    );
    this.otpDigit4Element = <HTMLInputElement>(
      document.getElementsByClassName("otp-input")[3].querySelector("input")
    );
  }

  onInput(event: any, index: number) {
    const inputValue = (<HTMLInputElement>event.target).value;
    if (inputValue.length === 1) {
      this.otp[index] = inputValue;
      this.navigateToNextInput(index);
    } else {
      this.otp[index] = "";
    }
  }
  navigateToNextInput(index: number) {
    if (index === 0) {
      if (this.otp[0] != "") {
        this.otpDigit2Element.focus();
      }
    } else if (index === 1) {
      if (this.otp[1] != "") {
        this.otpDigit3Element.focus();
      }
    } else if (index === 2) {
      if (this.otp[2] != "") {
        this.otpDigit4Element.focus();
      }
    } else if (index === 3) {
      if (this.otp[3] != "") {
      }
    }
  }

  onOtpInputChange(index: number) {
    if (index === 1) {
      if (this.digitOne.length === 1) {
        this.otpDigit2Element.focus();
      }
    } else if (index === 2) {
      if (this.digitTwo.length === 1) {
        this.otpDigit3Element.focus();
      }
    } else if (index === 3) {
      if (this.digitThree.length === 1) {
        this.otpDigit4Element.focus();
      }
    } else if (index === 4) {
      if (this.digitFour.length === 1) {
      }
    }
  }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData("text");
    if (pastedText.length === 4) {
      this.otp[0] = pastedText.charAt(0);
      this.otp[1] = pastedText.charAt(1);
      this.otp[2] = pastedText.charAt(2);
      this.otp[3] = pastedText.charAt(3);
      setTimeout(() => {
        this.otpDigit4Element.focus();
      }, 0);
    }
  }

  closeModal() {
    this.helperServiceService.downloadOptionRequest.next(0);
    this.bsModalRef.hide();
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  sendOtp() {
    this.isInProcess = true;
    let paramArray: any[] = [];
    let paramsJson: any;
    paramArray["mobile_number"] = this.user_details.phoneNumber;
    paramsJson = this.appServices.generteJSONFromArray(paramArray);
    console.log(
      "🚀 ~ file: otp-verification-popup.component.ts:60 ~ OtpVerificationPopupComponent ~ sendOtp ~ paramsJson:",
      paramsJson
    );

    this.appServices
      .callJSONAPI(this.appServices.REQUEST_OTP, paramsJson, "post", this, "")
      .subscribe((data: any) => {
        this.isInProcess = false;
        console.log(
          "🚀 ~ file: otp-verification-popup.component.ts:65 ~ OtpVerificationPopupComponent ~ .subscribe ~ data:",
          data
        );
        this.isCorrectOtp = false;
        this.errorMessage = "";
        if (data.status) {
          this.appServices.sendAnalyticsData(
            gtag,
            fbq,
            "smilo_event",
            "sa_wg_payment_success_page_otp_sent_successfully",
            true
          );
          this.timer = 30;
          this.startTimer();
        } else {
          this.appServices.sendAnalyticsData(
            gtag,
            fbq,
            "smilo_event",
            "sa_wg_payment_success_page_otp_sent_failed",
            true
          );
          this.isCorrectOtp = true;
          this.errorMessage = "Something went wrong";
        }
      });
  }

  onOtpSubmit() {
    if (
      this.otp[0].length != 0 &&
      this.otp[1].length != 0 &&
      this.otp[2].length != 0 &&
      this.otp[3].length != 0
    ) {
      this.isInProcess = true;
      let otpString = this.otp[0] + this.otp[1] + this.otp[2] + this.otp[3];
      const otp = parseInt(otpString);
      let paramArray: any[] = [];
      let paramsJson: any;
      paramArray["mobile_number"] = this.user_details.phoneNumber;
      paramArray["first_name"] = this.user_details.firstName;
      paramArray["last_name"] = this.user_details.lastName;
      paramArray["otp"] = otp;
      paramArray["oral_unique_id"] = this.oral_unique_id;
      paramArray["practice_id"] = this.appServices.getSession(
        "practice_id",
        false
      );
      paramArray["isSms"] =
        this.appServices.getSession("selected_download_option", true) == "2";
      paramsJson = this.appServices.generteJSONFromArray(paramArray);
      console.log(
        "🚀 ~ file: otp-verification-popup.component.ts:88 ~ OtpVerificationPopupComponent ~ onOtpSubmit ~ paramsJson:",
        paramsJson
      );
      this.appServices
        .callJSONAPI(this.appServices.VERIFY_OTP, paramsJson, "post", this, "")
        .subscribe((data: any) => {
          console.log(
            "🚀 ~ file: otp-verification-popup.component.ts:134 ~ OtpVerificationPopupComponent ~ .subscribe ~ data:",
            data
          );
          this.isInProcess = false;
          if (data.status) {
            this.appServices.sendAnalyticsData(
              gtag,
              fbq,
              "smilo_event",
              "sa_wg_payment_success_page_otp_verification_successful",
              true
            );
            this.isCorrectOtp = false;
            this.errorMessage = "";
            this.bsModalRef.hide();
            this.timerSubscription.unsubscribe();
            setTimeout(() => {
              this.helperServiceService.downloadOptionRequest.next(4);
            }, 500);
          } else {
            this.appServices.sendAnalyticsData(
              gtag,
              fbq,
              "smilo_event",
              "sa_wg_payment_success_page_otp_verification_failed",
              true
            );
            this.isCorrectOtp = true;
            this.errorMessage = "Please enter valid OTP";
          }
        });
    } else {
      this.isCorrectOtp = true;
      this.errorMessage = "Please enter valid OTP";
    }
  }

  onKeyOtp(event: any, index: number) {
    if (event.keyCode === 9 || event.keyCode === 8 || event.keyCode === 46) {
      if (event.keyCode === 8) {
        if (index === 0) {
          this.digitOne = "";
        }
        if (index === 1) {
          this.digitTwo = "";
        }
        if (index === 2) {
          this.digitThree = "";
        }
        if (index === 3) {
          this.digitFour = "";
        }
      }
      return true;
    } else if (
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105)
    ) {
      if (index === 0) {
        this.digitOne = event.key;
      }
      if (index === 1) {
        this.digitTwo = event.key;
      }
      if (index === 2) {
        this.digitThree = event.key;
      }
      if (index === 3) {
        this.digitFour = event.key;
      }
      return true;
    } else {
      if (index === 0) {
        this.digitOne = "";
      }
      if (index === 1) {
        this.digitTwo = "";
      }
      if (index === 2) {
        this.digitThree = "";
      }
      if (index === 3) {
        this.digitFour = "";
      }
      return false;
    }
  }

  startTimer() {
    this.timerSubscription = interval(1000).subscribe(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.timerSubscription.unsubscribe();
        this.isTimerOver = true;
      }
    });
  }

  onResendCode() {
    if (!this.isTimerOver) {
      this.isCorrectOtp = true;
      this.errorMessage = "Please wait";
    } else {
      this.appServices.sendAnalyticsData(
        gtag,
        fbq,
        "smilo_event",
        "sa_wg_payment_success_page_otp_resend_requested",
        true
      );
      this.isCorrectOtp = false;
      this.errorMessage = "";
      this.isTimerOver = false;
      this.timerSubscription.unsubscribe();
      this.sendOtp();
    }
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
