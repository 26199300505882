import { Component, OnInit, Input, TemplateRef, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
// import * as $ from 'jquery';
import { Services } from '../helpers/Services';
import { formatDate } from '@angular/common';
// import * as moment from 'moment';
// import 'moment-timezone';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { environment } from '../../../src/environments/environment'; 

declare var gtag;
declare const fbq;


@Component({
  selector: 'app-patient-image-uploaded',
  templateUrl: './patient-image-uploaded.component.html',
  styleUrls: ['./patient-image-uploaded.component.css']
})
export class PatientImageUploadedComponent implements OnInit {
  modalRef: BsModalRef;
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  input: any;
  first_name: any;
  flag: boolean = false;
  overall_score: any;
  public detaileddata: any;
  scoreData: any;
  result_image: any;
  created_date: any;
  user_email: any;
  user_details: any;
  ohrdata;
  responseData: any;
  // isRatingPopupNotOpened: boolean = true;
  todaysDataTime = formatDate(new Date(), 'yyyyMMddHHMMSS', 'en-US');
  // @Input('parentData') public detaileddata;
  // @Input('parentData2') public created_date;
  defaultImage: string = "assets/images/tooth_loader.svg";
  rating: any = '';
  app_details: any;
  defaultImage1: string = "assets/images/loader_large.gif";
  // staticImage: string = "assets/images/background_2.png";
  staticImage: string = "assets/images/ohr_gb_img.png";
  OHRImage:string = '';
  OHRText:string = '';

  cta_type:any;
  button_text:any;
  button_url:any;

  displaySettings:boolean = false;

  constructor(private httpClient: HttpClient, private router: Router, private modalService: BsModalService) {
    this.appservices = new Services;
  }

  @HostListener('window:popstate', ['$event']) onPopState(e: KeyboardEvent) {
    // this.modalRef.hide()
  }
  ngOnInit(): void {
    this.app_details = JSON.parse(JSON.stringify(this.appservices.getSession("app_details", true)));
    this.appservices.serviceMessages('vs-messages', '', '')
    
    this.cta_type = this.appservices.getSession("cta_type",false);
    this.button_text = this.appservices.getSession("button_text",false);
    this.button_url = this.appservices.getSession("button_url",false);
 
    if((this.cta_type!=undefined&&this.cta_type!='')&&(this.button_text!=undefined&&this.button_text!='')&&(this.button_url!=undefined&&this.button_url!=''))
    {
      this.displaySettings = true;
    }

      
  }

  redirectPatientUpload()
  {              
    // this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_ohr_home_click',true);
    this.router.navigate(['practice-images']);
  }
 
}
