import { Component, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, ViewChild, OnInit, TemplateRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Services } from '../helpers/Services';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
// import { exit } from 'process';
// import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';
import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { CropperDialogComponent } from '../global_components/cropper-dialog/cropper-dialog.component';
import * as $ from 'jquery';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
declare const loadScript: any;

import { environment } from '../../../src/environments/environment'; 

declare var gtag;
declare const fbq;

@Component({
  selector: 'app-practice-images',
  templateUrl: './practice-images.component.html',
  styleUrls: ['./practice-images.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PracticeImageComponent implements OnInit {
  youtube_1: any;
  youtube_2: any;
  youtube_3: any;
  youtube_4: any;
  youtube_5: any;
  modalRef: BsModalRef;
  cropped?: string;
  @ViewChild('fileInput') el: ElementRef;
  InputForm: FormGroup;
  submitted = false;
  defaultImage = 'assets/images/loader_large.gif';
  imageUrl: any;
  editFile: boolean = true;
  folder: any;
  todaysDataTime;
  cropped_url_blob: Blob;
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  ohrdata: any = [];
  // ohrStoredImages: any = [];
  extra_images: any = [];
  imageError: any;
  length: any = 0;
  urls = new Array<string>();
  unique_key: any;
  today = new Date();
  responseData: any;
  imagesInfo: any;
  count: any;
  object: object = {};
  images = [];
  flag: boolean = false;
  isfrontImage: any;
  input: any;
  displayImage: any;
  displayTitle: any;
  count_value: number = 0;
  isButtonDisabled: boolean = true;
  
  displayHeader: any = 'Upload Images';


  // src:any;
  constructor(private _dialog: LyDialog, private httpClient: HttpClient, private router: Router,
    private _cd: ChangeDetectorRef, private formBuilder: FormBuilder, private modalService: BsModalService, private sanitizer: DomSanitizer) {
    this.appservices = new Services;
  }

 /* @HostListener('window:popstate', ['$event'])
  onPopState(e: KeyboardEvent) {
    // this.modalRef.hide();
    window.location.reload();
    //console.log('Back button pressed');
  } */

  ngOnInit(): void {
    // console.log("pracrtice images");
   //  this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_ohr_entered',true); 
    // this.displayHeader = this.appservices.getSession("bannerTitleText", false);
    this.appservices.setSession("bannerTitleText", this.displayHeader ,false);
    // console.log(" Header Name : " + this.displayHeader);
    // let ohrStoredImages;
    $('.sec_inner_content1').show();
    $('.analysing_sec').hide();
    this.todaysDataTime = formatDate(new Date(), 'yyyyMMddHHMMSS', 'en-US');
    this.InputForm = this.formBuilder.group({
      file0: ['', [Validators.required]],
      file1: ['', [Validators.required]],
      file2: ['', [Validators.required]],
      file3: ['', [Validators.required]],
      file4: ['', [Validators.required]],
      file5: ['', [Validators.required]]
    });
    this.extra_images = [{ "image": '', "title": "Any additional images" }]
    this.imagesInfo = [
      { "url": 'assets/images/teeth_front.png', "title": "Teeth Front View" },
      { "url": 'assets/images/teeth_right.png', "title": "Teeth Right View" },
      { "url": 'assets/images/teeth_left.png', "title": "Teeth Left View" },
      { "url": 'assets/images/teeth_upper.png', "title": "Teeth Upper Jaw" },
      { "url": 'assets/images/teeth_lower.png', "title": "Teeth Lower Jaw" },
      { "url": 'assets/images/teeth_ref.png', "title": "Any additional images" }
    ];
    this.responseData = [
      { "url": 'assets/images/teeth_1.png', "title": "Upload front view", "base64": '', "file_name": '', "content": "Take a selfie of your teeth and mouth using the front camera." },
      { "url": 'assets/images/teeth_right.png', "title": "Upload right view", "base64": '', "file_name": '', "content": "Take a selfie of your teeth and mouth using the front camera." },
      { "url": 'assets/images/teeth_3.png', "title": "Upload left view", "base64": '', "file_name": '', "content": "Take a selfie of your teeth and mouth using the front camera." },
      { "url": 'assets/images/teeth_4.png', "title": "Upload upper jaw", "base64": '', "file_name": '', "content": "Take a selfie of your teeth and mouth using the front camera." },
      { "url": 'assets/images/teeth_5.png', "title": "Upload lower jaw", "base64": '', "file_name": '', "content": "Take a selfie of your teeth and mouth using the front camera." },
      { "url": 'assets/images/teeth_6.png', "title": "Additional images", "base64": '', "file_name": '', "content": "Add any other images you like." }
    ];

    setTimeout(function () {
      $('.alert_close').remove();
    }, 30000);

    this.youtube_1 = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/VCH8zdrlfyU/?enablejsapi=1&version=3&wmode=transparent");
    this.youtube_2 = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/bIKvTz4ix38/?enablejsapi=1&version=3&wmode=transparent");
    this.youtube_3 = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/nBx7CQCO7LM/?enablejsapi=1&version=3&wmode=transparent");
    this.youtube_4 = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/OGTQLTImHk0/?enablejsapi=1&version=3&wmode=transparent");
    this.youtube_5 = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/cLEklsoh4ic/?enablejsapi=1&version=3&wmode=transparent");

    // if (this.appservices.hasSession("ohrStoredImages")) {
    //   this.ohrdata = this.appservices.getSession("ohr_data", true);
    //   this.ohrStoredImages = this.appservices.getSession("ohrStoredImages", true);
    //   ohrStoredImages = this.appservices.getSession("ohrStoredImages", true);
    //   // let extra_image = this.appservices.getSession("extra_images", true);
    //   for (let i = 0; i < ohrStoredImages.length; i++) {
    //     this.responseData[ohrStoredImages[i].id].base64 = ohrStoredImages[i].base64;
    //   }
    // }
  }
  get f() { return this.InputForm.controls; }
  displayInputBox(id) {
    if (id == 5) {
      $(".input_box").removeClass('d-none').addClass('d-flex');
      $(".form_control").val($("#lblName5").html());
      $(".submit_btn").addClass('pt-4');
      $("#lblName5").hide();
    }
  }
  CopyToLabel(id) {
    var txtName = $("#txtName");
    var lblName = $("#lblName5");
    if (!this.allLetter(txtName.val())) {
      window.scrollTo(0, 0);
      $(window).scrollTop(0);
      this.appservices.serviceMessages('vs-messages', 'Label must contains alphabets and spaces only', 'danger')
    }
    else if (txtName.val().trim().length < 5) {
      window.scrollTo(0, 0);
      $(window).scrollTop(0);
      this.appservices.serviceMessages('vs-messages', 'Label must be minimum 5 characters', 'danger')
    }
    else {
      this.appservices.serviceMessages('vs-messages', '', '')
      lblName.html(txtName.val()).show();
      $(".input_box").removeClass('d-flex').addClass('d-none');
      this.pushImageDataInToArray(5, lblName.html(), '')
    }
  }
  onSubmit() {
    let message = 'Please upload at least one image (Front view, Left view, Right view, Upper jaw or Lower Jaw) to continue';
    this.count = $('.base64Image').length;
    this.isfrontImage = $('#uploadImage0').length;
    if (this.count < 1) {
      window.scrollTo(0, 0);
      $(window).scrollTop(0);
      this.appservices.serviceMessages('vs-messages', message, 'danger')
    }
    if (this.count >= 1) {
      /* if (this.isfrontImage == 0) {
        window.scrollTo(0, 0);
        $(window).scrollTop(0);
        this.appservices.serviceMessages('vs-messages', 'Teeth Front View Image is required', 'danger')
      } */
      // else {
        this.isButtonDisabled = false;
        $('.sec_inner_content1').hide();
        $('.analysing_sec').show();
        this.appservices.setSession("patient_upload_extra_images", this.extra_images, true);
        this.appservices.setSession("patient_upload_images_data", this.ohrdata, true);
        // this.appservices.setSession("ohrStoredImages", this.ohrStoredImages, true);
        this.appservices.setSession("patient_images_unique_key", this.todaysDataTime, false);
        this.processUploadImages();
     // }
    }
  }
  allLetter(inputtxt) {
    var letters = /^[a-zA-Z ]*$/;
    if (inputtxt.match(letters)) {
      return true;
    }
    else {
      return false;
    }
  }

  processUploadImages() {
    // this.appservices.sendPageViewAnalyticsData(gtag, '', this.router.url, 'OHR – Processing input images');    
    let extra_image = this.appservices.getSession("patient_upload_extra_images", true);
    if (extra_image[0].image)
      this.apirequest["extra_images"] = this.extra_images;
    this.apirequest["images"] = this.appservices.getSession("patient_upload_images_data", true);
    this.apirequest["oral_unique_id"] = this.appservices.getSession("patient_images_unique_key", false);
    this.apirequest["selected_practice_branch"] = this.appservices.getSession("user_selected_practice_branch",false); 
    this.apirequest["token"] = this.appservices.getSession("token", false);
    this.input = this.appservices.generteJSONFromArray(this.apirequest);
     // console.log(this.input); return
    this.appservices.callJSONAPI(this.appservices.PATIENT_UPLOAD_IMAGE, this.input, 'post', this, '').subscribe(
      (data: any) => {
        //console.log(" message : " + data.message);
        if (data.status) {
          this.router.navigate(['patient-image-uploaded']);          
        }
      },
      (error) => {
        this.appservices.showDebugMessage(error);
      }
    );
  }

  onInstructions() {
    let htmlElement: HTMLIFrameElement;
    let src;
    let elements = document.getElementsByClassName("yt_players");
    for (let i = 0; i <= elements.length; i++) {
      htmlElement = <HTMLIFrameElement>elements[0];
      src = htmlElement.getAttribute("src");
      htmlElement.setAttribute("src", src);
    }
  }
  openCropperDialog(event: Event, rowid, title) {
    $("body").addClass("modal-open");
    this.appservices.serviceMessages('vs-messages', '', '');
    let user_id = this.appservices.getSession("user_id", false);
    this._dialog.open<CropperDialogComponent, Event>(CropperDialogComponent, {
      data: event,
      width: 320,
      disableClose: true
    }).afterClosed.subscribe((result?: ImgCropperEvent) => {

      $("body").removeClass("modal-open");
      if (result) {
        this.count_value = this.count_value + 1;
        $('.please_wait_msg' + rowid).html('Please wait...');
        $('.continue_btn').html('Please wait...').attr('disabled', true);
        $('#overlay1' + rowid).addClass('overlay');
        $('.avatar_preview' + rowid).removeClass('edit_icon_hover');
        $('.upload_preview_span' + rowid).addClass("spinner-border");
        $('.upload_inner_section' + rowid).addClass("image_brightness");
        $('.image_upload' + rowid).removeClass('cursor_pointer').hide();
        this.flag = true;
        this.responseData[rowid].base64 = result.dataURL;
        this.cropped_url_blob = this.appservices.convertDataUrlToBlob(result.dataURL);
        let fileExt = result.dataURL.split(';')[0].split('/')[1];
        this.folder = 'patient_upload_images/';
        this.responseData[rowid].file_name = user_id + '/' + this.todaysDataTime + '/image_' + (rowid == 5 ? 7 : rowid + 1) + "." + fileExt;
        let thisobject = this;
        let greetingPromise = this.appservices.uploadToS3(this.cropped_url_blob, this.responseData[rowid].file_name, this.folder, fileExt);
        greetingPromise.then(function (resolveOutput) {
          // console.log(" resolveOutput : " + JSON.stringify(resolveOutput));
          thisobject.pushImageDataInToArray(rowid, $("#lblName5").html(), resolveOutput["Location"]);
          thisobject.count_value = thisobject.count_value - 1;
          $('.avatar_preview' + rowid).addClass('edit_icon_hover');
          $('#overlay1' + rowid).removeClass('overlay');
          $('.upload_preview_span' + rowid).removeClass("spinner-border");
          $('.upload_inner_section' + rowid).removeClass("image_brightness");
          $('.please_wait_msg' + rowid).html('');
          // console.log(" this.ohrdata.length : " + this.ohrdata.length);
          // console.log(" this.ohrdata.length : " + thisobject.getOHRDataLength());
          if(thisobject.getOHRDataLength() >= 1)
          {
            $('#num2').removeClass('disabled');
            $('.upload-teeth-continue-btn').removeAttr('disabled');
          }       
          if (thisobject.count_value == 0)
            // $('#circles > div').removeClass('disabled');
            $('.continue_btn').html('Continue').attr('disabled', false);
        }, function (rejectOutput) {
        });
        this.InputForm.patchValue({
          file: result.dataURL
        });
        this._cd.markForCheck();
      }
    });
  }

  getOHRDataLength()
  {
    return this.ohrdata.length;
  }

  get myForm() {
    return this.InputForm.get('file');
  }
  pushImageDataInToArray(rowid, title, s3Location) {
    if (rowid != 5) {
      if (this.ohrdata.length == 0) {
        this.ohrdata.push({ "image": this.responseData[rowid].file_name, "title": this.responseData[rowid].title });
        // this.ohrStoredImages.push({ "image": this.responseData[rowid].file_name, "base64": s3Location, "title": this.responseData[rowid].title, id: rowid });
      }
      else {
        let found = false;
        this.ohrdata.forEach(element => {
          if (element.image == this.responseData[rowid].file_name) {
            found = true;
          }
        });
        if (found == false) {
          // let obj = { "image": this.responseData[rowid].file_name, "title": this.responseData[rowid].title };
          // this.ohrdata.splice(rowid, 1, obj)
          this.ohrdata.push({ "image": this.responseData[rowid].file_name, "title": this.responseData[rowid].title });
          // this.ohrStoredImages.push({ "image": this.responseData[rowid].file_name, "base64": s3Location, "title": this.responseData[rowid].title, id: rowid });
          // console.log(this.ohrdata);
        }
      }
    }
    else {
      this.extra_images = [];
      this.extra_images.push({ "image": this.responseData[rowid].file_name, "title": title });
    }
  }
  closeModal() {
    this.modalRef.hide();
  }
  openModal(template: TemplateRef<any>) {
    // e.stopPropagation();
    loadScript();
    this.modalRef = this.modalService.show(template, {
      class: 'custom_notifications_modal vertical_align_center',
      animated: true,
      backdrop: 'static'
    });
  }
  openViewModal(template: TemplateRef<any>, id) {
    this.displayImage = this.imagesInfo[id].url;
    this.displayTitle = id != 5 ? this.imagesInfo[id].title : $("#lblName5").html();
    this.modalRef = this.modalService.show(template, {
      class: 'custom_notifications_modal vertical_align_center',
      animated: true,
      backdrop: 'static'
    });
  }

}
