import { Component, OnInit } from "@angular/core";
import { Services } from "../../Services";
import { environment } from "src/environments/environment";
declare var gtag;
declare const fbq;
@Component({
  selector: "app-five-image-flow-navigation-card",
  templateUrl: "./five-image-flow-navigation-card.component.html",
})
export class FiveImageFlowNavigationCardComponent implements OnInit {
  isExpand: boolean = false;
  appServices: Services;
  isDaburFlow: boolean = false;

  constructor() {
    this.appServices = new Services();
  }

  ngOnInit(): void {
    this.isDaburFlow =
      this.appServices.getSession("isDaburFlow", false) === "true";
  }
  OnFiveImageFlow() {
    this.appServices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_five_image_flow_clicked",
      true
    );

    window.open(environment.FIVE_IMAGE_WIDGET, "_self");
    return false;
  }
}
