<div class="loader-section" *ngIf="showLoadingForm">
  <div class="loader_wrapper">
    <div class="loading_div">
      <div class="circle-chekmark analyze_loader upload-image_loader">
        <div class="loding-circle" *ngIf="!isDataLoaded">
          <div class="circle-chekmark-border"></div>
          <svg width="200" height="200" class="circle-svg">
            <circle
              fill="none"
              stroke="#f5821e"
              stroke-width="18"
              cx="100"
              cy="100"
              r="90"
              class="circle"
              stroke-linecap="round"
              transform="rotate(-90 100 100) "
            />
          </svg>
        </div>
        <div class="loading-arrow" *ngIf="!isDataLoaded">
          <i class="sh-arrow-up"></i>
        </div>
        <div class="zoomout-circle_tick" *ngIf="isDataLoaded">
          <div class="circle-chekmark-dark"></div>
          <svg width="400" height="400" class="tick-svg">
            <polyline
              fill="none"
              stroke="#ffffff"
              stroke-width="50"
              points="88,214 173,284 304,138"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="tick"
            />
          </svg>
        </div>
      </div>
      <div class="art_intelli_para_sec">
        <p
          class="text-center"
          [ngClass]="{ 'text-green': isDataLoaded === true }"
        >
          {{ !isDataLoaded ? analyzingText : successText }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="section_wrapper pt-0 position-relative" *ngIf="!showLoadingForm">
  <div class="section-banner_wrapper dentist-appointment position-relative">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <a href="javascript:void(0)" class="back-btn" (click)="onBack()">
            <i class="sh-chevron-left"></i> <span>back</span>
          </a>
          <div
            class="banner_wrap d-flex flex-column-reverse flex-lg-row align-items-center justify-content-between justify-content-lg-center"
          >
            <div class="banner-content">
              <h1>
                Payment<br />
                Successful
              </h1>
              <h3>Your payment has been successfully processed!</h3>
              <p>
                You can now access your tailored
                {{ imageSelectionCount == 1 ? "smile check" : "oral health" }}
                report and<br />
                discover valuable insights about your dental well-being.
              </p>
              <div
                class="button-group d-flex align-items-center justify-content-center justify-content-lg-end mt-4 pt-lg-2"
              >
                <button
                  type="button"
                  class="btn btn-outline-light btn-rounded-corner"
                  (click)="openModalDownloadReport()"
                >
                  Download Report
                </button>
                <button
                  type="button"
                  class="btn btn-primary btn-rounded-corner"
                  (click)="openModalViewReport()"
                >
                  View Report
                </button>
              </div>
            </div>
            <div class="banner-image">
              <img
                src="assets/images/appointment-sent.png"
                class="img-fluid d-none d-lg-block"
                alt=""
              />
              <img
                src="assets/images/appointment-sent-mobile.png"
                class="img-fluid d-block d-lg-none"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="wrapper-container">
          <div class="tile">
            <div class="section-heading text-center">
              <h3 class="mb-1">What's Next?</h3>
              <p>Discover our services to improve your Oral Health</p>
              <!-- <p>Discover our other services to enhance your experience</p> -->
            </div>
            <div class="next_wrapper">
              <app-appointment-card
                *ngIf="isRequestForAppointmentCard"
                class="next-card"
                (bookNowClick)="onBookNow()"
              ></app-appointment-card>
              <app-product-discount-card
                *ngIf="isProductDiscountCard"
                class="next-card"
                (productDiscountClick)="onProductDiscountClick()"
              ></app-product-discount-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Oral Health Report -->
<!-- <div
  class="modal report-modal"
  id="viewReport"
  tabindex="-1"
  aria-labelledby="viewReportLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="sh-cancel"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="report-modal-wrapper">
          <div class="report-modal-header text-center">
            <h2 class="mb-1">Your Oral Health Report</h2>
            <p>
              Here's your comprehensive report. A copy of the<br />
              report has also been sent to your registered email.
            </p>
          </div>
          <div class="report-modal-body">
            <ngx-slick-carousel
              class="report_carousel"
              #slickModal="slick-carousel"
              [config]="slideConfig"
              (init)="slickInit($event)"
            >
              <div ngxSlickItem class="slide" style="width: 100%">
                <div
                  class="report-slider d-flex flex-wrap flex-lg-nowrap align-items-center"
                >
                  <div class="report-slider-image slider-for">
                    <div class="report-slider-item">
                      <img
                        src="assets/images/report-image-1.png"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="report-slider-content slider-nav">
                    <div class="report-slider-item text-center">
                      <ul
                        class="issue-list d-flex align-items-center justify-content-center mb-4 mb-lg-3"
                      >
                        <li class="d-flex align-items-center">
                          <img
                            src="assets/images/dental-decay.svg"
                            class="img-fluid"
                            alt=""
                          />
                          Dental Decay
                        </li>
                        <li class="d-flex align-items-center">
                          <img
                            src="assets/images/gum-disease.svg"
                            class="img-fluid"
                            alt=""
                          />
                          Gum Disease
                        </li>
                      </ul>
                      <div class="no-of-slide mb-1">1 OF 6</div>
                      <h3 class="mb-1 mb-lg-3">Front View</h3>
                      <p>Your uploaded image indicates</p>
                      <p><b>potential dental decay concerns.</b></p>
                    </div>
                  </div>
                </div>
              </div>
              <div ngxSlickItem class="slide" style="width: 100%">
                <div
                  class="report-slider d-flex flex-wrap flex-lg-nowrap align-items-center"
                >
                  <div class="report-slider-image slider-for">
                    <div class="report-slider-item">
                      <img
                        src="assets/images/report-image-2.png"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="report-slider-content slider-nav">
                    <div class="report-slider-item text-center">
                      <ul
                        class="issue-list d-flex align-items-center justify-content-center mb-4 mb-lg-3"
                      >
                        <li class="d-flex align-items-center">
                          <img
                            src="assets/images/dental-decay.svg"
                            class="img-fluid"
                            alt=""
                          />
                          Dental Decay
                        </li>
                        <li class="d-flex align-items-center">
                          <img
                            src="assets/images/gum-disease.svg"
                            class="img-fluid"
                            alt=""
                          />
                          Gum Disease
                        </li>
                      </ul>
                      <div class="no-of-slide mb-1">2 OF 6</div>
                      <h3 class="mb-1 mb-lg-3">Left View</h3>
                      <p>Your uploaded image indicates</p>
                      <p><b>potential gum disease concerns.</b></p>
                    </div>
                  </div>
                </div>
              </div>
              <div ngxSlickItem class="slide" style="width: 100%">
                <div
                  class="report-slider d-flex flex-wrap flex-lg-nowrap align-items-center"
                >
                  <div class="report-slider-image slider-for">
                    <div class="report-slider-item">
                      <img
                        src="assets/images/report-image-1.png"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="report-slider-content slider-nav">
                    <div class="report-slider-item text-center">
                      <ul
                        class="issue-list d-flex align-items-center justify-content-center mb-4 mb-lg-3"
                      >
                        <li class="d-flex align-items-center">
                          <img
                            src="assets/images/dental-decay.svg"
                            class="img-fluid"
                            alt=""
                          />
                          Dental Decay
                        </li>
                        <li class="d-flex align-items-center">
                          <img
                            src="assets/images/gum-disease.svg"
                            class="img-fluid"
                            alt=""
                          />
                          Gum Disease
                        </li>
                      </ul>
                      <div class="no-of-slide mb-1">3 OF 6</div>
                      <h3 class="mb-1 mb-lg-3">Right View</h3>
                      <p>Your uploaded image indicates</p>
                      <p><b>potential dental decay concerns.</b></p>
                    </div>
                  </div>
                </div>
              </div>
              <div ngxSlickItem class="slide" style="width: 100%">
                <div
                  class="report-slider d-flex flex-wrap flex-lg-nowrap align-items-center"
                >
                  <div class="report-slider-image slider-for">
                    <div class="report-slider-item">
                      <img
                        src="assets/images/report-image-2.png"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="report-slider-content slider-nav">
                    <div class="report-slider-item text-center">
                      <ul
                        class="issue-list d-flex align-items-center justify-content-center mb-4 mb-lg-3"
                      >
                        <li class="d-flex align-items-center">
                          <img
                            src="assets/images/dental-decay.svg"
                            class="img-fluid"
                            alt=""
                          />
                          Dental Decay
                        </li>
                        <li class="d-flex align-items-center">
                          <img
                            src="assets/images/gum-disease.svg"
                            class="img-fluid"
                            alt=""
                          />
                          Gum Disease
                        </li>
                      </ul>
                      <div class="no-of-slide mb-1">4 OF 6</div>
                      <h3 class="mb-1 mb-lg-3">Upper Jaw</h3>
                      <p>Your uploaded image indicates</p>
                      <p><b>potential gum disease concerns.</b></p>
                    </div>
                  </div>
                </div>
              </div>
              <div ngxSlickItem class="slide" style="width: 100%">
                <div
                  class="report-slider d-flex flex-wrap flex-lg-nowrap align-items-center"
                >
                  <div class="report-slider-image slider-for">
                    <div class="report-slider-item">
                      <img
                        src="assets/images/report-image-1.png"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="report-slider-content slider-nav">
                    <div class="report-slider-item text-center">
                      <ul
                        class="issue-list d-flex align-items-center justify-content-center mb-4 mb-lg-3"
                      >
                        <li class="d-flex align-items-center">
                          <img
                            src="assets/images/dental-decay.svg"
                            class="img-fluid"
                            alt=""
                          />
                          Dental Decay
                        </li>
                        <li class="d-flex align-items-center">
                          <img
                            src="assets/images/gum-disease.svg"
                            class="img-fluid"
                            alt=""
                          />
                          Gum Disease
                        </li>
                      </ul>
                      <div class="no-of-slide mb-1">5 OF 6</div>
                      <h3 class="mb-1 mb-lg-3">Lower Jaw</h3>
                      <p>Your uploaded image indicates</p>
                      <p><b>potential dental decay concerns.</b></p>
                    </div>
                  </div>
                </div>
              </div>
              <div ngxSlickItem class="slide" style="width: 100%">
                <div
                  class="report-slider d-flex flex-wrap flex-lg-nowrap align-items-center"
                >
                  <div class="report-slider-image slider-for">
                    <div class="report-slider-item">
                      <img
                        src="assets/images/report-image-2.png"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="report-slider-content slider-nav">
                    <div class="report-slider-item text-center">
                      <ul
                        class="issue-list d-flex align-items-center justify-content-center mb-4 mb-lg-3"
                      >
                        <li class="d-flex align-items-center">
                          <img
                            src="assets/images/dental-decay.svg"
                            class="img-fluid"
                            alt=""
                          />
                          Dental Decay
                        </li>
                        <li class="d-flex align-items-center">
                          <img
                            src="assets/images/gum-disease.svg"
                            class="img-fluid"
                            alt=""
                          />
                          Gum Disease
                        </li>
                      </ul>
                      <div class="no-of-slide mb-1">6 OF 6</div>
                      <h3 class="mb-1 mb-lg-3">Additional images</h3>
                      <p>Your uploaded image indicates</p>
                      <p><b>potential gum disease concerns.</b></p>
                    </div>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>

            <div
              class="button-group d-flex align-items-center justify-content-center"
            >
              <button type="button" class="btn btn-outline btn-rounded-corner">
                <i class="sh-phone pr-2"></i> Contact Us
              </button>
              <button
                type="button"
                class="btn btn-primary btn-rounded-corner"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i class="sh-rotate-left pr-2"></i> Start Again
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Download Report Modal -->
<!-- <div
  class="modal report-modal"
  id="downloadReport"
  tabindex="-1"
  aria-labelledby="downloadReportLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="sh-cancel"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="report-modal-wrapper">
          <div class="report-modal-header text-center">
            <h2 class="mb-1">Download Your Report</h2>
            <p>
              Select how you would like to get a copy of your<br />
              report and we'll do the rest
            </p>
          </div>
          <div class="tile tile-small">
            <div class="tile-header mb-2">
              <h3 class="d-flex align-items-center">
                <i class="sh-folder-download"></i> Download Options
              </h3>
            </div>
            <div class="tile-body mt-5">
              <div class="download-options">
                <form>
                  <div
                    class="radio-button-group termsAndConditionsDiv d-flex flex-column"
                  >
                    <div class="form-check">
                      <input
                        class="form-check-input form-control"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        class="form-check-label d-flex align-items-center"
                        for="flexRadioDefault1"
                      >
                        <i class="sh-mail"></i>
                        <span>Email ID</span>
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input form-control"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked
                      />
                      <label
                        class="form-check-label d-flex align-items-center"
                        for="flexRadioDefault2"
                      >
                        <i class="sh-phone"></i>
                        <span>Phone Number</span>
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input form-control"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault3"
                      />
                      <label
                        class="form-check-label d-flex align-items-center"
                        for="flexRadioDefault3"
                      >
                        <i class="sh-whatsapp"></i>
                        <span>WhatsApp</span>
                      </label>
                    </div>
                  </div>
                  <div class="button-group text-center mt-2 mt-sm-3">
                    <button
                      type="submit"
                      class="btn btn-primary btn-rounded-corner btn-lg"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#otpVerification"
                    >
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- OTP Verification Modal -->
<!-- <div
  class="modal report-modal report-shared otp-modal"
  id="otpVerification"
  tabindex="-1"
  aria-labelledby="otpVerificationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="sh-cancel"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="report-modal-wrapper">
          <div class="report-modal-header text-center">
            <h2 class="mb-1 mt-1 pt-lg-2">OTP Verification</h2>
            <p class="mb-3 pb-lg-1">
              Successfully sent the OTP to your<br />
              Registered Mobile Number
            </p>
            <form class="otp-form">
              <div
                class="otp-form-group d-flex justify-content-center mt-3 mb-4 py-1"
              >
                <div class="form-group mb-0">
                  <input
                    type="text"
                    maxlength="1"
                    class="form-control"
                    name="otpNum0"
                  />
                </div>
                <div class="form-group mb-0">
                  <input
                    type="text"
                    maxlength="1"
                    class="form-control"
                    name="otpNum1"
                  />
                </div>
                <div class="form-group mb-0">
                  <input
                    type="text"
                    maxlength="1"
                    class="form-control"
                    name="otpNum2"
                  />
                </div>
                <div class="form-group mb-0">
                  <input
                    type="text"
                    maxlength="1"
                    class="form-control"
                    name="otpNum3"
                  />
                </div>
              </div>
              <p>
                <a href="#"><i class="sh-resend"></i> Resend OTP</a> in 01:50
              </p>
            </form>
            <div
              class="button-group text-center mt-4 pt-1 pt-sm-3 mb-4 mb-sm-0"
            >
              <button
                type="submit"
                class="btn btn-primary btn-rounded-corner btn-lg"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#reportShared"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Report Shared Modal -->
<!-- <div
  class="modal report-modal report-shared"
  id="reportShared"
  tabindex="-1"
  aria-labelledby="reportSharedLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="sh-cancel"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="report-modal-wrapper">
          <div class="report-modal-header text-center">
            <div class="circle-chekmark">
              <div class="circle-chekmark-light"></div>
              <div class="circle-chekmark-light1"></div>
              <div class="zoomout-circle_tick">
                <div class="circle-chekmark-dark"></div>
                <svg width="400" height="400" class="tick-svg">
                  <polyline
                    fill="none"
                    stroke="#ffffff"
                    stroke-width="36"
                    points="88,214 173,284 304,138"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="tick"
                  />
                </svg>
              </div>
            </div>
            <h2 class="mb-1 mt-1 pt-lg-2">Report Shared</h2>
            <p class="mb-3 pb-lg-1">
              Your report has been successfully shared via your chosen<br />
              method. For assistance, please contact our support team.
            </p>
            <p>
              <a href="#"><i class="sh-phone"></i> Contact Us</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Accept request block -->
<!-- <div class="section_wrapper select-reason_wrapper position-relative">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <a href="javascript:void(0)" class="back-btn">
          <i class="sh-chevron-left"></i> <span>back</span>
        </a>
        <div class="wrapper-container request-wrapper_container">
          <div class="request-wrapper">
            <div class="request-block">
              <div class="block-content">
                <img
                  src="assets/images/site_logo.png"
                  class="img-fluid"
                  alt="smilo.ai"
                />
                <p class="mt-4 mt-sm-5">
                  Please accept the request from<br />
                  Razorpay's VPA on your UPI app
                </p>
              </div>
              <div class="block-button">
                <button
                  type="button"
                  class="btn btn-primary btn-lg btn-rounded-corner mt-4"
                >
                  Cancel Payment
                </button>
              </div>
            </div>
            <div
              class="request-wrapper-image d-flex align-items-center justify-content-center mt-4 pt-2"
            >
              <img
                src="assets/images/razor_pay.svg"
                class="img-fluid"
                alt="smilo.ai"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- OTP Verification -->
<!-- <div class="section_wrapper select-reason_wrapper position-relative">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <a href="javascript:void(0)" class="back-btn">
          <i class="sh-chevron-left"></i> <span>back</span>
        </a>
        <div class="wrapper-container request-wrapper_container">
          <div class="request-wrapper">
            <div class="request-block">
              <div class="block-content">
                <img
                  src="assets/images/site_logo.png"
                  class="img-fluid"
                  alt="smilo.ai"
                />
                <h2 class="mt-3">OTP Verification</h2>
                <p class="mt-3">
                  Successfully sent the OTP to your<br />
                  Registered Mobile Number
                </p>
                <form class="otp-form">
                  <div
                    class="otp-form-group d-flex justify-content-center mt-3 mb-4 py-1"
                  >
                    <div class="form-group mb-0">
                      <input
                        type="text"
                        maxlength="1"
                        class="form-control"
                        name="otpNum0"
                      />
                    </div>
                    <div class="form-group mb-0">
                      <input
                        type="text"
                        maxlength="1"
                        class="form-control"
                        name="otpNum1"
                      />
                    </div>
                    <div class="form-group mb-0">
                      <input
                        type="text"
                        maxlength="1"
                        class="form-control"
                        name="otpNum2"
                      />
                    </div>
                    <div class="form-group mb-0">
                      <input
                        type="text"
                        maxlength="1"
                        class="form-control"
                        name="otpNum3"
                      />
                    </div>
                  </div>
                  <p>
                    <a href="#"><i class="sh-resend"></i> Resend OTP</a> in
                    01:50
                  </p>
                </form>
              </div>
              <div class="block-button">
                <button
                  type="button"
                  class="btn btn-primary btn-lg btn-rounded-corner mt-4"
                >
                  Submit
                </button>
              </div>
            </div>
            <div
              class="request-wrapper-image d-flex align-items-center justify-content-between mt-4 pt-2"
            >
              <img
                src="assets/images/hdfc.svg"
                class="img-fluid"
                alt="smilo.ai"
              />
              <img
                src="assets/images/visa-large.svg"
                class="img-fluid"
                alt="smilo.ai"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
