import { Subscription } from "rxjs";
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Services } from "src/app/helpers/Services";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Location } from "@angular/common";
import { Utilities } from "../../helpers/utilities";
import { RazorpayService } from "../../razorpay.service";
import { environment } from "src/environments/environment";

declare var Razorpay: any;
declare var gtag;
declare const fbq;
@Component({
  selector: "app-payment-process",
  templateUrl: "./payment-process.component.html",
  styleUrls: ["./payment-process.component.css"],
})
export class PaymentProcessComponent implements OnInit {
  appservices: Services;
  apputilities: Utilities;
  static httpClient: any;
  customerData: any = {};
  apirequest: any = [];
  overall_score: any = "";
  first_name: any;
  last_name: any;
  email: any;
  phone_number: any;
  customerId: any;
  customer: any = [];
  payment_type: any = "";
  practice_id: any = "";
  user_access_token: any = "";
  user_id: any = "";
  report_id: any = "";
  payment_amount: any = "";
  widget_token: any = "";
  paymentId: any = "";
  txt: any = "";
  api_key: any = " ";
  isPaymentSuccessful: boolean = false;
  isExpand: boolean = false;
  isExpand2: boolean = false;
  user_details: any;
  showLoadingForm: boolean = false;
  isDataLoaded: boolean = false;
  analyzingText: any = "Please wait";
  successText: string = "Successfully Done";
  private _subscriptions = new Subscription();
  @ViewChild("form") form: ElementRef;
  encRequest: String;
  accessCode: String;
  ccavenueRedirectionUrl: string = "";
  imageSelectionCount: any = "5";
  constructor(
    private httpClient: HttpClient,
    private razorpayService: RazorpayService,
    private router: Router,
    private _cd: ChangeDetectorRef,
    private _location: Location
  ) {
    this.appservices = new Services();
  }

  ngOnInit(): void {
    this.imageSelectionCount = this.appservices.getSession(
      "images_selection_count",
      false
    );
    this.accessCode = environment.CCAVENUE_ACCESS_CODE;
    this.ccavenueRedirectionUrl = environment.CCAVENUE_REDIRECTION_URL;
    this.isPaymentSuccessful =
      this.appservices.getSession("payment_successful", false) === "true";
    if (this.isPaymentSuccessful) {
      let sessionId = this.appservices.getSession("session_id", false);
      this.router.navigate([
        "payment-process/payment-success?payment_transaction_id=" + sessionId,
      ]);
    }
    if (!this.appservices.getSession("token", false)) {
      this.router.navigate([
        "get-started/" + this.appservices.getSession("token", false),
      ]);
    } else {
      this.overall_score = this.appservices.getSession("overall_score", false);
      if (this.overall_score == "") {
        this.router.navigate([
          "get-started/" + this.appservices.getSession("token", false),
        ]);
      }
      this.payment_type = this.appservices.getSession("payment_type", false);
      this.appservices.sendAnalyticsData(
        gtag,
        fbq,
        "smilo_event",
        "sa_wg_payment_payment_loading",
        true
      );
      this.report_id = this.appservices.getSession("ohr_unique_key", false);
      this.practice_id = this.appservices.getSession("practice_id", false);
      this.user_id = this.appservices.getSession("user_id", false);
      this.user_access_token = this.appservices.getSession(
        "user_access_token",
        false
      );
      this.payment_amount = this.appservices.getSession(
        "payment_amount",
        false
      );

      if (localStorage.getItem("Input_form_data") != "") {
        this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
      }
      if (this.user_details) {
        this.first_name = this.user_details.firstName;
        this.last_name = this.user_details.lastName;
        this.email = this.user_details.email;
        this.phone_number = this.user_details.phoneNumber;
      }
      //this.api_key = "poyT5nRzmLF6OEjKcr5mehZd";
    }
  }
  onBack() {
    // this._location.back();
    this.router.navigate(["payment-access-report"]);
  }

  makePayment() {
    this.appservices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_clicked",
      true
    );
    let thisObject = this;
    if (thisObject.payment_type === "stripe") {
      let CardOptions = {
        key: environment.RAZOR_PAY_KEY, // Enter the Key ID generated from the Dashboard
        amount: Number(this.payment_amount) * 100,
        currency: "INR",
        description: "Detailed Oral Health Report",
        image: "assets/images/favicon.png",
        // callback_url: "payment-process/payment-success/",
        prefill: {
          email: thisObject.email,
          contact: thisObject.phone_number,
        },

        handler: function (response) {
          thisObject.showLoadingForm = true;
          thisObject._cd.detectChanges();
          if (response.razorpay_payment_id) {
            thisObject.appservices.sendAnalyticsData(
              gtag,
              fbq,
              "smilo_event",
              "sa_wg_payment_succeed",
              true
            );
            thisObject.paymentId = response.razorpay_payment_id;
            thisObject.apirequest = [];
            thisObject.apirequest["practice_id"] = thisObject.practice_id;
            thisObject.apirequest["report_id"] = thisObject.report_id;
            thisObject.apirequest["report_type"] = "ohr";
            thisObject.apirequest["payment_id"] = response.razorpay_payment_id;
            thisObject.apirequest["user_id"] = thisObject.user_id;
            thisObject.apirequest["price"] = thisObject.payment_amount;
            let report_input = thisObject.appservices?.generteJSONFromArray(
              thisObject.apirequest
            );
            console.log(report_input);
            thisObject._subscriptions.add(
              thisObject.appservices
                .callJSONAPI(
                  thisObject.appservices.RAZORPAY_PAYMENT_PROCESS,
                  report_input,
                  "post",
                  thisObject,
                  ""
                )
                .subscribe((data: any) => {
                  thisObject.isDataLoaded = true;
                  thisObject._cd.detectChanges();
                  if (data.status == true) {
                    thisObject.appservices.setSession(
                      "payment_successful",
                      "true",
                      false
                    );
                    thisObject.isPaymentSuccessful = true;
                    setTimeout(() => {
                      window.location.href = "/payment-process/payment-success";
                    }, 1500);
                  } else {
                    thisObject.showLoadingForm = false;
                    thisObject.isDataLoaded = false;
                    thisObject.isPaymentSuccessful = false;
                  }
                })
            );
          }
        },
      };
      let rzp1 = new Razorpay(CardOptions);
      rzp1.open(CardOptions);
      rzp1.on("payment.failed", function (response) {
        thisObject.appservices.setSession("payment_successful", "false", false);
        this.appservices.setSession("payment_id", "false", false);
        thisObject.appservices.sendAnalyticsData(
          gtag,
          fbq,
          "smilo_event",
          "sa_wg_payment_failed",
          true
        );
      });

      if (this.paymentId != null) {
        this.appservices.setSession("payment_id", this.paymentId, false);
      }
    }
  }

  makePaymentWithStripe() {
    this.appservices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_button_make_payment",
      true
    );

    if (this.payment_type === "stripe") {
      this.router.navigate(["make-stripe-payment"]);
    } else {
      this.appservices.setSession("payment_successful", "true", false);
      this.router.navigate(["/payment-process/payment-success"]);
    }
  }

  makeCCAVENUEPay() {
    this.showLoadingForm = true;
    this.encRequest = "";
    let apiRequest: any[] = [];
    apiRequest["practice_id"] = this.practice_id;
    apiRequest["report_id"] = this.report_id;
    apiRequest["amount"] = this.payment_amount;
    apiRequest["report_type"] = "ohr";
    apiRequest["user_id"] = this.user_id;
    let input = this.appservices.generteJSONFromArray(apiRequest);
    this.appservices
      .callJSONAPI(
        this.appservices.PAYMENT_PROCESS_CCAVENUE,
        input,
        "post",
        this,
        ""
      )
      .subscribe((data: any) => {
        if (data.status) {
          this.encRequest = data.result;
          this.appservices.sendAnalyticsData(
            gtag,
            fbq,
            "smilo_event",
            "sa_wg_payment_button_make_payment",
            true
          );
          // this.showLoadingForm = false;
          setTimeout((_) => this.form.nativeElement.submit());
        } else {
          this.showLoadingForm = false;
        }
      });
  }

  onDownloadSampleReport() {
    this.appservices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_sample_report_download_clicked",
      true
    );
    if (this.imageSelectionCount == 1) {
      const fileUrl = "../../../assets/images/SmileCheck-SampleReport.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "SmileCheck-SampleReport.pdf";
      link.click();
    } else {
      const fileUrl = "../../../assets/images/OHR-SampleReportNew.pdf";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "OHR-SampleReport.pdf";
      link.click();
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
