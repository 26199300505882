<div class="model-body">
    <div class="model_section p-2">
        <div class="cancel_button_sec text-right">
            <a class="close close_button" data-dismiss="modal" href="#"> <span class="icon-fill-close1"></span> </a>
        </div>
        <div class="model_content_section p-2">
            <div class="head_img_sec">
                <div class="d-flex">
                    <div class="img_poto_smile">
                        <img src="assets/images/sample.png" alt="before_look">
                    </div>
                    <h4 class="title_poto font-weight-bold align-self-center font_16" style="width:30%">Preview of Sample Picture</h4>
                </div>
                <div class="head_pera text-left"> <P class="text_inner font-weight-bold py-3">Instructions to follow:</P> </div>
                <div class="inner_points text-left px-3">
                    <ul class="instruction_points ">
                        <li class="mb-3">
                            Take a close-up shot of your face.
                        </li>
                        <li class="mb-3">
                            Give us a big smile and show your teeth.
                        </li>
                        <li class="mb-3">
                            Use a high quality smart phone camera.
                        </li>
                        <li class="mb-3">
                            Make sure the environment has good lighting.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>