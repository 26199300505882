import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
// import { Router, ActivatedRoute } from '@angular/router';
import { Services } from "../helpers/Services";
import * as $ from "jquery";
import { environment } from "../../../src/environments/environment";

import { MessageService } from "../profile-pic-service.service";

declare var gtag;
declare const fbq;

@Component({
  selector: "app-contact-information",
  templateUrl: "./contact-information.component.html",
  styleUrls: ["./contact-information.component.css"],
})
export class ContactInformationComponent implements OnInit {
  isSubmitted = false;
  InputForm: FormGroup;
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  input: any;
  user: any;
  user_details: any;
  countryCodeNumber: any;
  location: any;
  country_name: any = "";
  isDigitsOnly: any;
  reg: any;
  privacy_site: string = environment.PRIVACY;
  terms_site: string = environment.TERMS;
  consent_form: string = environment.CONSENT_FORM;
  defaultImage: string = "assets/images/loader_large.gif";
  staticImage: string = "assets/images/sign-up.png";
  isButtonDisabled: boolean = true;
  practiceBranches: any = "";
  userSelectedAddress: string = "";
  isBranchesButtonDisabled: boolean = true;

  display_branches_div: boolean = false;
  display_contactForm_div: boolean = true;
  checkboxChecked: boolean = false;

  firstName: string = "";
  lastName: string = "";

  preSelectedBranch: any = "";

  uploadImageBtnSelected: any = false;

  header_display_msg: any = "";

  signInValidNumber: any = "";
  phoneSignIn: any = "";
  signInPhoneNumber: any = "";
  signInSelectedCountryCode: any = "";
  signInSelectedCountry: any = "";

  contactus_country_name: any = "";
  redirecting_url: any = "";
  get_contact_information_later: any = "";
  sendSubjectMessage: any = "";
  profile_img: any = "assets/images/default-profile.png";
  isUserFromIndia: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private router: Router,
    private messageService: MessageService
  ) {
    this.appservices = new Services();
  }

  getLocationAddress() {
    let locationDetails;
    this.httpClient
      .get(environment.IPDATA_API + "?api-key=" + environment.IPDATA_KEY)
      .subscribe((res: any) => {
      
        locationDetails = {
          // calling_code: res["calling_code"],
          // city: res["city"],
          // country_code: res["country_code"],
          // country_name: res["country_name"],
          // ip: res["ip"],
          // state: res["state"],
          // time_zone: res["time_zone"]["name"],
          // time_zone_offset: res["time_zone"]["offset"],
    
          calling_code: "91",
          city: "Kolkata",
          country_code: "IN",
          country_name: "India",
          ip: "103.75.160.75",
          state: "undefined",
          time_zone: "Asia/Kolkata",
          time_zone_offset: "+0530",
          
        };
        this.isUserFromIndia = res["country_code"] === "IN";
        // console.log(
        //   "🚀 ~ file: contact-information.component.ts:94 ~ ContactInformationComponent ~ .subscribe ~ this.isUserFromIndia:",
        //   this.isUserFromIndia
        // );
        if (this.isUserFromIndia) {
          this.appservices.setSession("country_name", "IN", false);
          this.appservices.setSession("contactus_country_name", "IN", false);
        } else {
          // this.appservices.setSession("country_name", "au", false);
          // this.appservices.setSession("contactus_country_name", "au", false);
          this.appservices.setSession("country_name", "IN", false);
          this.appservices.setSession("contactus_country_name", "IN", false);
        }
        this.appservices.setSession("location_details", locationDetails, true);
        // console.log(
        //   "🚀 ~ file: contact-information.component.ts:84 ~ ContactInformationComponent ~ .subscribe ~ locationDetails:",
        //   locationDetails,
        //   "Country: ",
        //   this.appservices.getSession("contactus_country_name", false)
        // );
      });
  }
  ngOnInit(): void {
    this.appservices.setSession("bannerTitleText", "Select Preferences", false);
    this.preSelectedBranch = this.appservices.getSession(
      "user_selected_practice_branch",
      false
    );
    // console.log("  branches_list : " + (this.appservices.getSession("branches_list",false)));
    this.practiceBranches = this.appservices.getSession("branches_list", false);
    if (this.practiceBranches != undefined && this.practiceBranches != "") {
      this.practiceBranches = JSON.parse(this.practiceBranches);
      // console.log("this.practiceBranches : " + this.practiceBranches);
      //  this.appservices.setSession("has_branches",'yes', false);
      if (this.practiceBranches.length > 0) {
        this.display_branches_div = true;
        this.display_contactForm_div = false;
      }
      // this.appservices.setSession("branches_list",this.practiceBranches, false);
    }

    this.reg =
      "/^({0,1}((0|+61)(2|4|3|7|8)){0,1}){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/";
    window.scrollTo(0, 0);
    $(window).scrollTop(0);
    if (!this.appservices.getSession("token", false)) {
      this.router.navigate([
        "get-started/" + this.appservices.getSession("token", false),
      ]);
    }
    this.getLocationAddress();
    this.callAppDetailsAPI();
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$";
    this.location = this.appservices.getSession("location_details", true);
    // console.log(" localStorage.getItem('Input_form_data') : " + localStorage.getItem('Input_form_data'));
    if (localStorage.getItem("Input_form_data") != "")
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
    this.InputForm = this.formBuilder.group({
      firstName: ["", [Validators.required, Validators.minLength(1)]],
      lastName: ["", [Validators.required, Validators.minLength(1)]],
      email: ["", [Validators.required, Validators.pattern(emailPattern)]],
      // phoneNumber: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      phoneNumber: ["", [Validators.required, Validators.pattern("^((\\+91-?))?[0-9]{10}$")]],
    });
    if (this.user_details) {
      this.InputForm.controls["firstName"].setValue(
        this.user_details.firstName
      );
      this.InputForm.controls["lastName"].setValue(this.user_details.lastName);
      this.InputForm.controls["email"].setValue(this.user_details.email);
      this.InputForm.controls["phoneNumber"].setValue(
        this.user_details.phoneNumber
      );
      $("#agree").prop("checked", true);
      this.checkboxChecked = true;
      this.isButtonDisabled = false;
    }

    // if (!this.appservices.hasSession("country_name")) {
    //   debugger;
    //   this.appservices.setSession("country_name", "IN", false);
    //   this.appservices.setSession("contactus_country_name", "IN", false);
    // }
    // this.setSessionData();
    // debugger;
    // if (!this.appservices.hasSession("contactus_country_name")) {
    //   if (this.isUserFromIndia) {
    //     this.appservices.setSession("contactus_country_name", "in", false);
    //   } else {
    //     this.appservices.setSession("contactus_country_name", "in", false);
    //   }
    // }
  }
  async onEnterData(event) {
    // this.signInHasError(event);
    let nameType = event.target.getAttribute("formControlName");
    let nameVal = event.target.value;
    if (nameType === "firstName") {
      this.firstName = nameVal;
    } else if (nameType === "lastName") {
      this.lastName = nameVal;
    } else if (nameType === "phoneNumber") {
      this.isSubmitted = false;
      // await this.signInHasError(event);
    }
    // console.log(" this.signInValidNumber : " + this.signInValidNumber );
    if (this.InputForm.valid) {
      this.isButtonDisabled = false;
    } else this.isButtonDisabled = true;
  }
  onCheckboxChange(e, type) {
    this.appservices.serviceMessages("vs-messages", "", "");
  }
  onSubmit() {
    this.isSubmitted = true;
    console.log("submited");
    // if(!this.signInValidNumber) { return false; }

    if (this.InputForm.invalid) {
      return;
    }
    if (this.InputForm.valid) {
      // if (this.InputForm.value.phoneNumber.toString().length > 15 || this.InputForm.value.phoneNumber.toString().length < 8) {
      //   this.appservices.serviceMessages('vs-messages', 'Phone Number must be 8 to 15 digits only', 'danger')
      // }
      // else

      // if (!$('#agree').prop("checked")) {
      //   this.appservices.serviceMessages('vs-messages', 'To continue, please accept the consent form, terms of use and privacy policy below.', 'danger')
      // }
      // else {

      // localStorage.setItem('Input_form_data','');
      // console.log(" JSON.stringify(this.InputForm.value) : " + JSON.stringify(this.InputForm.value));
      localStorage.setItem(
        "Input_form_data",
        JSON.stringify(this.InputForm.value)
      );
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
      this.appservices.setUserEmail(this.InputForm.value.email.trim());
      this.callContactAPI();
      // this.router.navigate(['email-confirmation']);

      // }
    }
  }
  callContactAPI() {
    if (true) {
      $(".coninue_btn").html("Please wait...").attr("disabled", true);
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
      this.apirequest = [];
      this.apirequest["first_name"] = this.user_details.firstName;
      this.apirequest["last_name"] = this.user_details.lastName;
      this.apirequest["email"] = this.user_details.email;
      this.apirequest["phone"] = this.user_details.phoneNumber;
      this.apirequest["widget_token"] = this.appservices.getSession(
        "token",
        false
      );
      let dail_code = this.appservices.getSession("dial_code", false)
        ? this.appservices.getSession("dial_code", false).toString()
        : "1";
      this.apirequest["dial_code"] = dail_code;

      this.apirequest["country_name"] = this.country_name;

      this.apirequest["branch_id"] = this.appservices.getSession(
        "user_selected_practice_branch",
        false
      );

      this.input =
        '"user":' + this.appservices.generteJSONFromArray(this.apirequest);
      this.appservices
        .callJSONAPI(
          this.appservices.WIDGET_LOGIN,
          this.input,
          "post",
          this,
          ""
        )
        .subscribe(
          (data: any) => {
            // console.log(data);
            // $('.coninue_btn').html('CONTINUE').attr('disabled', false);
            if (data.status) {
              this.appservices.setSession("existing_user", "no", false);
              this.header_display_msg =
                "Smilo.ai will create an account with auto generated password for you and send an email with login details. please use login details to view/download Oral Health Rating and Design my smile report.";
              // console.log(" data.result.existing_user : " + data.result.existing_user);
              if (data.result.existing_user === "true") {
                this.appservices.setSession("existing_user", "yes", false);
              }

              let full_name =
                data.result.user.first_name + data.result.user.last_name;
              this.appservices.setSession("is_logged_in", "true", false);
              this.appservices.setSession(
                "user_access_token",
                data.result.user.user_access_token,
                false
              );
              this.appservices.setSession(
                "first_name",
                data.result.user.first_name,
                false
              );
              this.appservices.setSession(
                "last_name",
                data.result.user.last_name,
                false
              );
              this.appservices.setSession("full_name", full_name, false);
              this.appservices.setSession(
                "email",
                data.result.user.email,
                false
              );
              this.appservices.setSession(
                "user_id",
                data.result.user.user_id,
                false
              );
              this.appservices.setSession(
                "get_contact_information_later",
                "",
                false
              );

              // this.get_contact_information_later =  this.appservices.getSession("get_contact_information_later",false);
              // console.log(" this.get_contact_information_later : " + this.get_contact_information_later);
              // if(!this.appservices.isEmpty(this.get_contact_information_later))
              // {
              //   console.log("getiing inside");
              //   this.sendSubjectMessage = {first_name:data.result.user.first_name,last_name:data.result.user.first_name,text:this.profile_img}
              //   if(!this.appservices.isEmpty(data.result.user.profile_picture))
              //   {
              //     console.log("getiing inside profile pic");
              //     this.sendSubjectMessage['text'] = environment.S3_BASE_URL+data.result.user.profile_picture;
              //     // this.messageService.sendMessage({text:environment.S3_BASE_URL+this.profile_img_complete_path,first_name:form.value.first_name,last_name:form.value.last_name});
              //     this.appservices.setSession("profile_picture", environment.S3_BASE_URL+data.result.user.profile_picture, false);
              //   }
              //   this.messageService.sendMessage(this.sendSubjectMessage);
              // }

              // console.log(" data.result.user.phone : " + data.result.user.phone);
              // console.log(" data.result.user.dial_code : " + data.result.user.dial_code);
              // console.log(" data.result.user.city : " + data.result.user.city);

              // console.log(" data.result.user.state_id : " + data.result.user.state_id);
              // console.log(" data.result.user.country_id : " + data.result.user.country_id);
              // console.log(" data.result.user.profile_picture : " + data.result.user.profile_picture);

              if (data.result.user.phone)
                this.appservices.setSession(
                  "phoneNumber",
                  data.result.user.phone,
                  false
                );

              if (data.result.user.dial_code)
                this.appservices.setSession(
                  "dial_code",
                  data.result.user.dial_code,
                  false
                );

              if (data.result.user.city)
                this.appservices.setSession(
                  "city",
                  data.result.user.city,
                  false
                );

              if (data.result.user.state_id)
                this.appservices.setSession(
                  "state_id",
                  data.result.user.state_id,
                  false
                );

              if (data.result.user.country_id)
                this.appservices.setSession(
                  "country_id",
                  data.result.user.country_id,
                  false
                );

              // console.log(" data.result.user.dial_country_code : " + data.result.user.dial_country_code);

              if (data.result.user.dial_country_code)
                this.appservices.setSession(
                  "country_name",
                  data.result.user.dial_country_code,
                  false
                );

              this.appservices.setSession(
                "profile_picture",
                data.result.user.profile_picture,
                false
              );

              // console.log(" data.result.city : " + data.result.user.city );
              // console.log(" data.result.state_id : " + data.result.user.state_id );
              // console.log(" data.result.country_id : " + data.result.user.country_id );

              this.appservices.sendAnalyticsData(
                gtag,
                fbq,
                "smilo_event",
                "sa_wg_personal_details_entrered",
                true
              );

              this.callMedicalQueSaveAPI();
              // this.router.navigate(['medical-questions']);
            } else {
              this.appservices.serviceMessages(
                "vs-messages",
                data.message,
                "danger"
              );
              if (!this.appservices.getSession("token", false)) {
                this.router.navigate([
                  "get-started/" + this.appservices.getSession("token", false),
                ]);
              }
            }
          },
          (error) => {
            this.appservices.showDebugMessage(error);
          }
        );
    }
  }
  callMedicalQueSaveAPI() {
    this.apirequest = [];
    this.apirequest["widget_token"] = this.appservices.getSession(
      "token",
      false
    );
    this.apirequest["questions"] = this.appservices.getSession(
      "questions_array",
      true
    );
    let input = this.appservices.generteJSONFromArray(this.apirequest);
    // console.log(input);
    this.appservices
      .callJSONAPI(
        this.appservices.MEDICAL_QUESTIONNAIRE_SAVE,
        input,
        "post",
        this,
        ""
      )
      .subscribe(
        (data: any) => {
          // console.log(data);
          if (data.status) {
            // this.appservices.serviceMessages('vs-messages', data.message, 'success');

            this.redirecting_url = this.appservices.getSession(
              "redirecting_url",
              false
            );

            if (!this.appservices.isEmpty(this.redirecting_url)) {
              setTimeout(() => {
                this.appservices.setSession(
                  "hasRedirectedFromContactForm",
                  "true",
                  false
                );
                this.router.navigate([this.redirecting_url]);
              }, 500);
            } else {
              this.uploadImageBtnSelected = this.appservices.getSession(
                "uploadImageBtnSelected",
                false
              );
              //  console.log(" this.uploadImageBtnSelected :" + this.uploadImageBtnSelected);
              if (
                this.uploadImageBtnSelected != undefined &&
                this.uploadImageBtnSelected == "false"
              ) {
                this.router.navigate(["oral-health-score-preference"]);
              } else if (
                this.uploadImageBtnSelected != undefined &&
                this.uploadImageBtnSelected == "true"
              ) {
                this.router.navigate(["practice-images"]);
                // this.router.navigate(['oral-health-score-preference']);
              }
              // this.router.navigate(['oral-health-score-preference']);
            }
          } else {
            this.appservices.serviceMessages(
              "vs-messages",
              data.message,
              "danger"
            );
          }
        },
        (error) => {
          this.appservices.showDebugMessage(error);
        }
      );
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  get ci() {
    return this.InputForm.controls;
  }

  length(classname) {
    if ($("." + classname).val().length > 30) {
      $("." + classname).val(
        $("." + classname)
          .val()
          .substr(0, 30)
      );
    }
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  countryChange(country: any) {
    let a = this.appservices.getSession("contactus_country_name", false);
    if (!a) {
      return;
      this.appservices.setSession("country_name", "IN", false);
      this.appservices.setSession("dial_code", "91", false);
      this.appservices.setSession("contactus_country_name", "IN", false);
    } else {
      this.country_name = country.iso2;
      this.countryCodeNumber = country.dialCode;
      this.appservices.setSession("country_name", this.country_name, false);
      this.appservices.setSession("dial_code", this.countryCodeNumber, false);
      this.appservices.setSession(
        "contactus_country_name",
        this.country_name,
        false
      );
    }
    // this.setValidators();
  }
  telInputObject(obj) {
    if (this.appservices.hasSession("contactus_country_name")) {
      this.contactus_country_name = this.appservices.getSession(
        "contactus_country_name",
        false
      );
      obj.setCountry(this.contactus_country_name.toUpperCase());
    } else {
      this.getCountry(obj);

      // return;
      // if (this.isUserFromIndia) {
      // } else {
      //   obj.setCountry("au");
      // }
    }
    // this.setValidators();
  }
  setValidators() {
    if (this.country_name == "au") {
      this.InputForm.get("phoneNumber").setValidators([
        Validators.required,
        Validators.pattern(
          /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/
        ),
      ]);
      this.InputForm.get("phoneNumber").updateValueAndValidity();
    } else {
      this.InputForm.get("phoneNumber").setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
      ]);
      this.InputForm.get("phoneNumber").updateValueAndValidity();
    }
  }
  callAppDetailsAPI() {
    this.appservices
      .callJSONAPI(
        this.appservices.APPDETAILS + "?",
        "",
        "get",
        this,
        "127.0.0.1"
      )
      .subscribe(
        (data: any) => {
          //console.log(data);
          if (data.status) {
            this.appservices.setSession(
              "app_details",
              data.result.app_details,
              true
            );
            this.appservices.setSession(
              "smile_design",
              data.result.app_details.smile_design,
              true
            );
            this.appservices.setSession(
              "gum_types",
              data.result.app_details.smile_design.gum_types,
              true
            );
            this.appservices.setSession(
              "teeth_shade",
              data.result.app_details.smile_design.teeth_shade,
              true
            );
            this.appservices.setSession(
              "teeth_template",
              data.result.app_details.smile_design.teeth_template,
              true
            );
          } else {
          }
        },
        (error) => {
          this.appservices.showDebugMessage(error);
        }
      );
  }

  onSelectPractice(selectedPractice) {
    // console.log(" selectedPractice.target.value : " + selectedPractice.target.value);
    if (selectedPractice.target.value != "") {
      this.isBranchesButtonDisabled = false;
      this.appservices.setSession(
        "user_selected_practice_branch",
        selectedPractice.target.value,
        false
      );
      let selectedIndex: number = selectedPractice.target["selectedIndex"];
      this.userSelectedAddress =
        selectedPractice.target.options[selectedIndex].getAttribute(
          "data-isocode"
        );
    } else {
      this.userSelectedAddress = "";
      this.isBranchesButtonDisabled = true;
    }
  }

  moveToContactForm() {
    this.display_branches_div = false;
    this.display_contactForm_div = true;
  }

  hasError1(event: any): void {
    if (!event && this.InputForm.value.cellnumber1) {
      this.InputForm.get("cellnumber1").setErrors(["invalid_cell_phone", true]);
    }
  }

  signInHasError(status: any) {
    // console.log(" Sign in has error called ");
    if (status == null) {
      this.signInValidNumber = false;
      this.phoneSignIn.setValue(null);
    } else {
      this.signInValidNumber = status;
    }
  }

  signInGetNumber(n: string) {
    this.signInPhoneNumber = n;
  }

  signInOnCountryChange(c: any) {
    // console.log(" Onchange clicked  ");
    this.signInSelectedCountryCode = "+" + c.dialCode;
    let up: string = c.iso2;
    this.signInSelectedCountry = up.toUpperCase();

    this.country_name = c.iso2;
    this.countryCodeNumber = c.dialCode;
    // console.log(this.country_name );
    // console.log(this.countryCodeNumber );
    this.appservices.setSession("country_name", this.country_name, false);
    this.appservices.setSession("dial_code", this.countryCodeNumber, false);
    this.isSubmitted = false;
    // this.setValidators();
  }

  getCountry(obj) {
    this.httpClient
      .get(environment.IPDATA_API + "?api-key=" + environment.IPDATA_KEY)
      .subscribe((res: any) => {
        obj.setCountry(res["country_code"] === "IN" ? "IN" : "IN");
      });
  }
}
