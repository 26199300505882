<div ly-dialog-content [lyPx]="0" class="cropper-wrapper">
  <div class="cropper-header">
    <h4>Adjust Your Picture</h4>
    <p>Please crop the mouth and teeth portion in the picture and submit</p>
  </div>

  <div class="cropper-body">
    <div
      *ngIf="cropper.isLoaded"
      class="d-flex align-items-center croppericon_action"
    >
      <!-- <button (click)="cropper.zoomIn()" ly-button appearance="icon"><ly-icon>zoom_in</ly-icon></button>
            <button (click)="cropper.zoomOut()" ly-button appearance="icon"><ly-icon>zoom_out</ly-icon></button> -->
      <button (click)="cropper.center()" ly-button appearance="icon">
        <i class="sh-image-center"></i>
        <!-- <ly-icon>filter_center_focus</ly-icon> -->
      </button>
      <button (click)="cropper.rotate(-90)" ly-button appearance="icon">
        <i class="sh-refresh"></i>
        <!-- <ly-icon>rotate_90_degrees_ccw</ly-icon> -->
      </button>
      <!-- <button (click)="cropper.fit()" ly-button>Fit</button>
            <button (click)="cropper.fitToScreen()" ly-button>Fit to screen</button>
            <button (click)="cropper.setScale(1)" ly-button>1:1</button> -->
    </div>

    <ly-img-cropper
      [withClass]="classes.cropper"
      [config]="myConfig"
      [(scale)]="scale"
      (cropped)="onCropped($event)"
      (loaded)="onLoaded($event)"
      (error)="onError($event)"
      (ready)="onReady($event)"
    >
      <span>Drag and drop image</span>
    </ly-img-cropper>

    <div *ngIf="cropper.isLoaded" [className]="classes.sliderContainer">
      <div [class]="classes.slider">
        <ly-slider
          [color]="'primary:contrast'"
          [thumbVisible]="false"
          [min]="cropper.minScale"
          [max]="1"
          [(ngModel)]="scale"
          (input)="scale = $event.value"
          step="0.000001"
        ></ly-slider>
      </div>
      <div class="croppericon_action2">
        <button
          (click)="cropper.zoomIn()"
          ly-button
          appearance="icon"
          class="zoom_in"
        >
          <i class="sh-search-plus"></i>
          <!-- <ly-icon>zoom_in</ly-icon> -->
        </button>
        <button
          (click)="cropper.zoomOut()"
          ly-button
          appearance="icon"
          class="zoom_out"
        >
          <i class="sh-search-minus"></i>
          <!-- <ly-icon>zoom_out</ly-icon> -->
        </button>
      </div>
    </div>
    <!-- Error Message -->
    <!-- <div class="text-center success-msg dialogue_box_msg ng-star-inserted"
            *ngIf="detectionClass == 'Rated successfully' ">
            <p>The detected teeth portion in the picture is small. Please submit picture as per the sample.</p> -->
    <!-- <div class="text-center warning-msg dialogue_box_msg ng-star-inserted" ng-reflect-ng-class="warning-msg dialogue_box_msg">
                <p>Sorry! We could not able to identify face / tooth part, please move the cropping part manually.</p></div> -->
    <!-- </div> -->

    <!-- <div class="text-center success-msg dialogue_box_msg ng-star-inserted"
            *ngIf="detectionClass == 'No Face detected' ">
            <p>Please crop the mouth and teeth portion in the picture and submit.</p>
        </div> -->
    <!-- Error Message -->
  </div>
  <div
    container
    *ngIf="cropper.isLoaded"
    [lyJustifyContent]="'flex-end'"
    class="cropper-footer"
    ly-dialog-actions
  >
    <button
      class="btn btn-outline btn-rounded-corner"
      ly-button
      (click)="onCancel()"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary btn-rounded-corner"
      ly-button
      (click)="dialogRef.close(cropper.crop())"
    >
      Done
    </button>
  </div>
</div>
