<div class="dynamic_wrapper">
    <div class="vs-messages"></div>
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="profile-update-msg text-center success-msg" [ngClass]="profileUpdatedSuccessfully">Profile has been updated successfully</div>
        <div class="profile-update-msg text-center err-msg" [ngClass]="profileUpdatedError">{{profileUpdatedErrorMsg}}</div>
        <div class="profile-update-msg text-center success-msg" [ngClass]="deleted_msg_class">Your Account has been deleted successfully</div>
        <div class="profile-update-msg text-center success-msg" [ngClass]="passwordUpdatedSuccessfully">Your password has been updated successfully</div>
        <div class="profile-update-msg text-center err-msg" [ngClass]="passwordUpdatedError">{{passwordUpdatedErrorMsg}}</div>
        <div class="dms_content">            
            <div class="sec_inner_content1 p-0">
                <!-- <div class="tab">
                    <button class="tablinks" [ngClass]=" profile_status ? 'active' : ''" (click)="selectedTab('profile')">My Profile</button>
                    <button class="tablinks" [ngClass]=" change_password_status ? 'active' : ''" (click)="selectedTab('change_password')">Change Password</button>
                  </div>
                  
                  <div id="London" class="tabcontent">
                    <h3>London</h3>
                    <p>London is the capital city of England.</p>
                  </div>
                  
                  <div id="Paris" class="tabcontent">
                    <h3>Paris</h3>
                    <p>Paris is the capital of France.</p> 
                  </div> 
            </div> --> 

            <div class="body_content">
                <div class="profile_content_sec">
                  <ul class="nav nav-tabs app_content_sidebar_menu myprofile_tabs clearfix">
                    <li class="tablinks active" (click)="tabChange('profile')" id="profile_content_sec_li">
                      <a href="#myprofile" #myProfile data-toggle="tab" class="active">My profile</a>
                    </li>
                    <li class="tablinks" (click)="tabChange('password')">
                      <a id="change_pwd_link" href="#changepassword" data-toggle="tab">Change password</a>
                    </li>
                  </ul>
                  <div id="profile_validation_msgs" class="status_msg visibility_hidden"></div>
                  <div>
                    <div class="tab-content tab_content_sec clearfix">
                      <div class="my_profile tab-pane active" id="myprofile">
                        <form autocomplete="off" [formGroup]="updateProfileForm" (ngSubmit)="onSubmit(updateProfileForm,'updateProfileForm')" class="contact_form" action="" enctype="multipart/form-data" method="post" id="update_profile_form" name="update_profile_form">
                          <div class="row">
                            <div class="col-md-2 my_profile_pic">
                              <div class="station_photo clearfix">
                                <div class="photo_list square">
                                  <div class="pic_sec photo">
                                    <div class="thumbnail_sec">
                                      <div id="overlay1"></div>
                                      <span class="upload_preview_span"></span>
                                      <p class="please_wait_msg"></p>
                                      <!-- <input type="file" formControlName="file{{i}}" accept="image/*" class="file" (change)="openCropperDialog($event,i,data.name)" id="image_upload{{i}}" hidden /> -->
                                      <input type="file" accept="image/*"  class="file" (change)="openCropperDialog($event)" name="profile_pic" id="profile_pic" />
                                    </div>
                                  </div>
                                  <div id="logo_upload_img" class="square mb-4">
                                    <img class="lazy profile_image br_50" name="profile_image" src="{{profile_img}}" /> 
                                  </div>
                                  <div class="default_btn upload_new_bg text-center cursor_pointer" id="upload_new_profile">
                                    <label class="file d-block cursor_pointer" for="profile_pic">Upload New</label>
                                  </div>
                                </div>                               
                              </div>
                            </div>
                            <div class="col-md-10 px-5 pb-5 profile_form">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="first_name" class="margin_bottom_5 label_input">First Name <span class="err-msg">*</span> </label>
                                    <div class="input-field">
                                      <input id="first_name" formControlName="first_name"  (keyup)="onUpdateProfileEnterData($event)" (focusout)="validateInput($event)"  name="first_name" type="text" class="form-control shadow-none" value="{{firstName}}" maxlength="15" placeholder="Enter First Name" />
                                    </div>
                                    <div class="err-msg" *ngIf="isSubmitted===true&& updateProfileForm.get('first_name').hasError('required')">
                                      Please enter the First Name
                                    </div>
                                    <div class="err-msg" *ngIf="isSubmitted===true&& updateProfileForm.get('first_name').hasError('minlength')">
                                        First Name should be Min 3 characters
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="last_name" class="margin_bottom_5 label_input">Last Name <span class="err-msg">*</span> </label>
                                    <div class="input-field">
                                      <input id="last_name" formControlName="last_name"  (keyup)="onUpdateProfileEnterData($event)"  (focusout)="validateInput($event)"  name="last_name" type="text" class="form-control shadow-none" value="{{lastName}}" maxlength="15" placeholder="Enter Last Name" />
                                    </div>
                                    <div class="err-msg" *ngIf="isSubmitted===true&& updateProfileForm.get('last_name').hasError('required')">
                                        Last Name should be Min 1 character(s)
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="email" class="margin_bottom_5 label_input">Email address</label>
                                    <div class="input-field">
                                      <input id="email" formControlName="email"  (keyup)="onUpdateProfileEnterData($event)"  name="email" type="text" readonly class="form-control shadow-none" value="{{email}}" maxlength="50" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="phone" class="margin_bottom_5 label_input">Phone number (Optional)</label>
                                    <div class="input-field">
                                      <!-- <input id="phone" name="phone" type="tel" class="form-control shadow-none" value="" minlength="8" maxlength="15" placeholder="Enter Phone Number" /> -->
                                      <!-- <input  (keyup)="onUpdateProfileEnterData($event)" type="tel" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" formControlName="phoneNumber" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                      maxlength="15" (countryChange)="countryChange($event)" (intlTelInputObject)="telInputObject($event)" placeholder="Mobile number" ng2TelInput class="phone-format form-control" id="phoneNumber" 
                                      trim="blur" value="{{phoneNumber}}">
                                      <div class="err-msg" *ngIf="updateProfileForm.controls['phoneNumber'].touched && updateProfileForm.get('phoneNumber').hasError('required')">
                                        Please enter the Phone Number
                                      </div>
                                      
                                      
                                      <div class="err-msg" *ngIf="ci.phoneNumber.errors.pattern&&country_name=='au'" class="text-left">
                                        Invalid Australia phone number.
                                      </div> -->

                                      <!-- <input name="phone" (keyup)="onEnterData($event)" type="tel" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" formControlName="phoneNumber"
                                      maxlength="15" (countryChange)="countryChange($event)" (intlTelInputObject)="telInputObject($event)" placeholder="Mobile number" ng2TelInput class="phone-format form-control" id="phoneNumber" [ngClass]="{ 'is-invalid': isSubmitted && ci.phoneNumber.errors }"
                                      trim="blur"> -->

                                      <input (keyup)="onEnterData($event)" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" (keydown.space)="$event.preventDefault();"   (focusout)="validateInput($event)"   class="form-control"  type="text" ng2TelInput  
                                      (hasError)="signInHasError($event)"
                                      (ng2TelOutput)="signInGetNumber($event)"
                                      (intlTelInputObject)="telInputObject($event)"
                                      (countryChange)="signInOnCountryChange($event)"
                                      [value]="updateProfileForm.get('phoneNumber').value" 
                                      placeholder="Mobile Number"  formControlName="phoneNumber" >
                                       
                                      <!-- <div *ngIf="isSubmitted &&!signInValidNumber&&!ci.phoneNumber.errors?.required" class="err-msg"> Invalid number </div>
                                      <div *ngIf="isSubmitted && ci.phoneNumber.errors">
                                          <div *ngIf="ci.phoneNumber.errors?.required" class="err-msg">Mobile number is required</div>
                                       </div> -->


                                  <!-- <div *ngIf="isSubmitted===true && ci.phoneNumber.errors" >
                                      <div *ngIf="ci.phoneNumber.errors.required" class="err-msg">Required</div>
                                      <div *ngIf="ci.phoneNumber.errors.pattern&&country_name=='au'" class="err-msg">
                                          Invalid Australia phone number.
                                      </div>
                                      <div *ngIf="ci.phoneNumber.errors.pattern&&country_name!='au'" class="err-msg">
                                          Please enter a valid Phone Number.
                                    </div>
                                    <div PasteRestrictDirective *ngIf="ci.phoneNumber.errors.minlength" class="err-msg">Phone Number must be at least 8 digits</div>
                                    <div *ngIf="ci.phoneNumber.errors.maxlength" class="err-msg">Phone Number must be 8 to 15 digits only</div>
                                       <div PasteRestrictDirective *ngIf="ci.phoneNumber.errors.minlength" class="text-left">Phone Number must be at least 8 digits</div> 
                                      <div *ngIf="ci.phoneNumber.errors.maxlength" class="text-left">Phone Number must be 8 to 15 digits only</div>
                                  </div> -->
                                       
                                  <!-- <div *ngIf="isSubmitted && ci.phoneNumber.errors" class="invalid-feedback">
                                      <div *ngIf="ci.phoneNumber.errors.required" class="text-left">Required</div>
                                      <div *ngIf="ci.phoneNumber.errors.pattern&&country_name=='au'" class="text-left">
                                          Invalid Australia phone number.
                                      </div>
                                      <div *ngIf="ci.phoneNumber.errors.pattern&&country_name!='au'" class="text-left">
                                          Phone Number must be digits only.
                                      </div> -->
                                       <!-- <div PasteRestrictDirective *ngIf="ci.phoneNumber.errors.minlength" class="text-left">Phone Number must be at least 8 digits</div> -->
                                      <!-- <div *ngIf="ci.phoneNumber.errors.maxlength" class="text-left">Phone Number must be 8 to 15 digits only</div> -->
                                  <!-- </div> -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group common_selectfield">
                                    <label for="country" class="margin_bottom_5 label_input">Country <span class="err-msg">*</span></label>
                                    <div class="input-field position-relative">
                                      <select formControlName="userSelectedCountry" (change)="onUpdateProfileEnterData($event)" name="userSelectedCountry" id="userSelectedCountry" class="form-control" (change)="onSelectCountry($event)" >
                                        <option value="" [selected]='true'>Please select any country</option>
                                        <option *ngFor="let country of countryList"  [selected]="country.country_id == user_selected_country"  [value]="country.country_id">{{ country.country_name }}</option>
                                      </select>
                                      <!-- *ngFor="let a of fakeArray; let index = index">Something {{ index } -->
                                    </div>
                                    <div class="err-msg" *ngIf="isSubmitted===true&&updateProfileForm.get('userSelectedCountry').hasError('required')">
                                      Please choose a country
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group common_selectfield">
                                    <label for="state" class="margin_bottom_5 label_input">State <span class="err-msg">*</span> </label>
                                    <div class="input-field position-relative">
                                      <select formControlName="userSelectedState" (change)="onUpdateProfileEnterData($event)" name="userSelectedState" id="userSelectedState" class="form-control" >
                                        <option value="" [selected]='true'>Please select any state</option>
                                        <option *ngFor="let state of stateList" [selected]="state.state_id == user_selected_state"  [value]="state.state_id">{{ state.state_name }}</option>
                                      </select>
                                    </div>
                                    <div class="err-msg" *ngIf="isSubmitted===true&&updateProfileForm.get('userSelectedState').hasError('required')">
                                      Please choose a state
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="city" class="margin_bottom_5 label_input">City <span class="err-msg">*</span></label>
                                    <div class="input-field">
                                      <!-- (keydown.space)="$event.preventDefault();" -->
                                      <input placeholder="Enter City" id="city" formControlName="city" (keyup)="onUpdateProfileEnterData($event)" name="city" type="text" class="form-control shadow-none" value="{{selectedCity | titlecase}}"  maxlength="20" appInputRestriction />
                                    </div>
                                    <div class="err-msg" *ngIf="isSubmitted===true&& updateProfileForm.get('city').hasError('required')">
                                      Please enter the city
                                    </div>
                                    <div class="err-msg" *ngIf="isSubmitted===true&& updateProfileForm.get('city').hasError('minlength')">
                                        City should be Min 3 characters
                                    </div>
                                    <div class="err-msg" *ngIf="isSubmitted===true&& updateProfileForm.get('city').hasError('maxlength')">
                                        City can be Max 20 characters
                                    </div>
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="row">
                            <div class="col-md-12 text-center success-msg" [ngClass]="profileUpdatedSuccessfully">
                              Profile has been updated successfully
                            </div>
                            <div class="col-md-12 text-center err-msg" [ngClass]="profileUpdatedError">
                              {{profileUpdatedErrorMsg}}
                            </div>
                          </div>  
                          <div class="row">
                            <div class="col-md-12 text-center success-msg" [ngClass]="deleted_msg_class">
                              Your Account has been deleted successfully
                            </div>
                          </div>   -->

                          <!-- <div class="button_sec">
                            <ul class="button-sec-ul d-flex align-items-center justify-content-between">
                                <li>
                                    <a href="javascript:;" (click)="deleteUserAlert()" class="del_my_acnt"> Delete My Account </a>
                                </li>                                 
                                <li class="default_btn default_btn_bg p-0">                                     
                                    <a href="javascript:;" (click)="onBackToHome()" class="d-block"><input id="go_home_btn" type="button" class="btn button_links btn_width_2 continue_btn" value="Home" /></a>
                                </li>
                                <li class="default_btn default_btn_bg p-0">                                     
                                    <a href="javascript:;" class="d-block"><input id="save_profile_btn" [disabled]="isUpdateProfileButtonDisabled" type="submit" class="btn button_links btn_width_2 continue_btn" value="Save" /></a>
                                </li>
                            </ul>
                        </div> -->

                        <div class="button_sec">
                            <div class="button-sec-ul d-flex align-items-center justify-content-between">
                                <div class="delete_btn">
                                    <a href="javascript:;" (click)="deleteUserAlert()" class="del_my_acnt"> Delete My Account </a>
                                </div>
                                <div class="d-flex align-items-center responsive_btns">
                                    <!-- <div class="default_btn default_btn_bg p-0 mr-3 ">
                                        <a href="javascript:;" (click)="onBackToHome()" class="d-block"><input id="go_home_btn" type="button" class="btn button_links btn_width_2" value="Back" /></a>
                                    </div> -->
                                    <div class="default_btn default_btn_bg p-0">
                                        <a href="javascript:;" class="d-block"><input id="save_profile_btn" [disabled]="isUpdateProfileButtonDisabled" type="submit" class="btn button_links btn_width_2 continue_btn" value="Save" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        </form>
                      </div>
                      <div class="change_pwd tab-pane" id="changepassword">
                        <div class="change-password-text">
                            <p class="font_12">You can change your password by entering your current password and a new password in the relevant boxes.</p>
                        </div>
                        <form autocomplete="off"  [formGroup]="changePasswordForm" (ngSubmit)="onSubmit(changePasswordForm,'changePasswordForm')" class="change_password_form mt-3" action="" method="post" id="change_password_form" name="change_password_form">
                          <input type="hidden" id="" name="" value="" />
                          <div class="row">
                            <div class="col-md-5">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="current_password" class="margin_bottom_5 label_input">Current Password <span class="err-msg">*</span></label>
                                    <div class="input-field">
                                      <input id="current_password" (keydown.space)="$event.preventDefault();" formControlName="current_password" value="" placeholder="Enter your Current Password" name="current_password" type="password" class="form-control shadow-none" (keyup)="onEnterData($event)" formControlName="current_password" maxlength="20" />
                                    </div>  
                                    <!-- <div  *ngIf="current_password.invalid && (current_password.dirty || current_password.touched)" class="text-left">Please Enter the current password</div> -->
                                    <div class="err-msg" *ngIf="isPasswordFormSubmitted===true&& changePasswordForm.get('current_password').hasError('required')">
                                      Please enter the Current Password
                                    </div>
                                    <div class="err-msg" *ngIf="isPasswordFormSubmitted===true && changePasswordForm.get('current_password').hasError('minlength')">
                                      Current Password should be Min 6 characters
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label for="new_password" class="margin_bottom_5 label_input">New Password <span class="err-msg">*</span></label>
                                    <div class="input-field">
                                      <input id="new_password" (keydown.space)="$event.preventDefault();" formControlName="new_password"  value="********" placeholder="Enter your New Password" name="new_password" type="password" class="form-control shadow-none" (keyup)="onEnterData($event)" formControlName="new_password" maxlength="20" />
                                    </div>
                                    <!-- <div  *ngIf="new_password.invalid && (new_password.dirty || new_password.touched)" class="text-left">Please Enter the new password</div> -->
                                    <div class="err-msg" *ngIf="isPasswordFormSubmitted===true && changePasswordForm.get('new_password').hasError('required')">
                                      Please enter the New Password
                                    </div>
                                    <div class="err-msg" *ngIf="changePasswordForm.get('new_password').hasError('minlength')">
                                      New Password should be Min 6 characters
                                    </div>
                                    <div class="err-msg" *ngIf="!changePasswordForm.get('new_password').hasError('minlength')&&!changePasswordForm.get('new_password').hasError('required') && changePasswordForm.get('new_password').hasError('passwordValidation')">
                                        Password should be combination of lower, upper, number, special chars
                                    </div>

                                    <div class="err-msg" *ngIf="!changePasswordForm.get('new_password').hasError('minlength')&&!changePasswordForm.get('new_password').hasError('required') && !changePasswordForm.get('new_password').hasError('passwordValidation')  && changePasswordForm.get('new_password').hasError('currentPasswordValidation')">
                                        New Password and Current Password should not be same
                                    </div>
                                    
                                    

                                    <!-- <div class="err-msg" *ngFor="let err of getErrorList(changePasswordForm.controls.new_password.errors)">
                                        <div>{{err}}</div>
                                      </div> -->
                                    
                                  </div>
                                  <div class="form-group">
                                    <label for="confirm_password" class="margin_bottom_5 label_input">Confirm Password <span class="err-msg">*</span></label>
                                    <div class="input-field">
                                      <input id="confirm_password"  (keydown.space)="$event.preventDefault();"   formControlName="confirm_password" value="********" placeholder="Enter your Confirm Password" name="confirm_password" type="password" class="form-control shadow-none" (keyup)="onEnterData($event)" formControlName="confirm_password" maxlength="20" />
                                    </div> 
                                    <!-- <div  *ngIf="confirm_password.invalid && (confirm_password.dirty || confirm_password.touched)" class="text-left">Please Enter the confirm password</div> -->
                                    <div class="err-msg" *ngIf="isPasswordFormSubmitted===true && changePasswordForm.get('confirm_password').hasError('required')">
                                      Please enter the Confirm Password
                                    </div>
                                    <div class="err-msg" *ngIf="!changePasswordForm.get('confirm_password').hasError('minlength') && (!changePasswordForm.get('confirm_password').hasError('required') && changePasswordForm.get('confirm_password').hasError('ConfirmPassword'))">
                                      New Password and Confirm Password are not matching
                                    </div>
                                    <div class="err-msg" *ngIf="changePasswordForm.get('confirm_password').hasError('minlength')">
                                        Confirm Password should be Min 6 characters
                                      </div> 
                                  </div>
                                  <div>

                                    <!-- <div class="row">
                                      <div class="col-md-12 text-center success-msg" [ngClass]="passwordUpdatedSuccessfully">
                                        Your password has been updated successfully
                                      </div>

                                      <div class="col-md-12 text-center err-msg" [ngClass]="passwordUpdatedError">
                                        {{passwordUpdatedErrorMsg}}
                                      </div>

                                    </div>     -->

                                    <!-- <ul class="button-sec-ul">
                                        <li class="default_btn default_btn_bg p-0">                                     
                                            <a href="javascript:;" (click)="onBackToHome()" class="d-block"><input id="go_home_btn" type="button" class="btn button_links btn_width_2 continue_btn" value="Home" /></a>
                                        </li>
                                      <li class="default_btn default_btn_bg p-0">
                                        <a href="javascript:;" class="d-block"><input id="save_change_pwd_btn" type="submit" class="btn button_links btn_width_2 continue_btn mt-4" value="Change password" [disabled]="isButtonDisabled" /></a>
                                      </li>
                                    </ul> -->

                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                                <div class="button-sec-ul d-flex justify-content-between align-items-center mt-3">
                                    <!-- <div class="default_btn default_btn_bg p-0 mb-3">
                                        <a href="javascript:;" (click)="onBackToHome()" class="d-block"><input id="go_home_btn" type="button" class="btn button_links btn_width_2" value="Back" /></a>
                                    </div> -->
                                    <div class="default_btn default_btn_bg p-0 mb-3">
                                        <a href="javascript:;" class="d-block"><input id="save_change_pwd_btn" type="submit" class="btn button_links btn_width_2 continue_btn" value="Change password" [disabled]="isButtonDisabled" /></a>
                                    </div>
                                </div>
                            </div>

                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

        </div>
    </div>
</div>