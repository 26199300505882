import { Component, OnInit, Input, TemplateRef, HostListener } from '@angular/core';
import { Services } from '../helpers/Services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import * as $ from "jquery";

import * as moment from 'moment';
import 'moment-timezone';


import { environment } from '../../../src/environments/environment';
import { MessageService } from '../profile-pic-service.service';

declare var gtag;
declare const fbq;


@Component({
  selector: 'app-payment-oral-health-score-completed',
  templateUrl: './payment-oral-health-score-completed.component.html',
  styleUrls: ['./payment-oral-health-score-completed.component.css']
})
export class PaymentOralHealthScoreCompletedComponent implements OnInit {
  
  modalRef: BsModalRef;
  defaultImage = 'assets/images/tooth_loader.gif';
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  responsedata: any;
  input: any;
  process_images: any;
  first_name: any;
  created_date: any;
  is_images_invalid: any;
  flag: boolean = false;
  dentalInfo: any;
  gumlInfo: any;
  itemsList: any;
  label: any;
  fb_pixel_id:any = '';
  gtm_id:any = '';
  widget_flow_type:any = '';
  dentalImages: any;  
  payment_successful:any = '';
  payment_type:any = '';
  cta_type:any = '';
  button_text:any = '';
  button_url:any = '';
  displaySettings:any = '';
  session_id:any = '';
  report_id:any = '';  
  user_details: any = '';
  user_email: any = '';
  displayHeader: any = 'Oral Health Rating';  
  get_contact_information_later:any = '';   
  guest_user_id:any = '';
  sendSubjectMessage:any = '';
  profile_img:any = 'assets/images/default-profile.png';
  inputFormData:{} = {};
  startAgainBtntext:string = 'Please wait';
  startAgainBtn:string = 'disabled';
  hasRedirectedFromContactForm:any = '';
  redirectToDMSDiv:boolean = false;
  ohr_flow:any;


  constructor(private httpClient: HttpClient, private router: Router,private location: LocationStrategy, private modalService: BsModalService,private route: ActivatedRoute,private messageService: MessageService) {
    this.appservices = new Services;
    // preventing back button in browser implemented by "Samba Siva"  
    history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });  
  }

  @HostListener('window:popstate', ['$event']) onPopState(e: KeyboardEvent) {
    // this.modalRef.hide()
  }

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn icon-right-arrow next-slide blue_color font_30'></div>",
    "prevArrow": "<div class='nav-btn icon-left-arrow prev-slide blue_color font_30'></div>",
    "infinite": false,
  };

  slickInit(e) {
    // console.log('slick initialized');
  }

  async ngOnInit(){

    this.hasRedirectedFromContactForm  = this.appservices.getSession("hasRedirectedFromContactForm", false);                 
    if(this.hasRedirectedFromContactForm=='true')
    {
      this.appservices.setSession("payment_successful", 'true', false);     
    }

    this.ohr_flow = this.appservices.getSession("ohr_flow", false);

    if(this.ohr_flow=='2')
    {
      this.redirectToDMSDiv = true;
    }
    

    this.get_contact_information_later = this.appservices.getSession("get_contact_information_later", false);                 
    this.guest_user_id                 = this.appservices.getSession("guest_user_id", false); 
    this.appservices.setSession("bannerTitleText",this.displayHeader,false);
    this.widget_flow_type = this.appservices.getSession("widget_flow_type", false);
    this.payment_successful = this.appservices.getSession("payment_successful", false);
    this.payment_type          = this.appservices.getSession("payment_type", false);
   // console.log(" this.payment_successful : " + this.payment_successful);

   this.cta_type = this.appservices.getSession("cta_type",false);
   this.button_text = this.appservices.getSession("button_text",false);
   this.button_url = this.appservices.getSession("button_url",false);

   if((this.cta_type!=undefined&&this.cta_type!='')&&(this.button_text!=undefined&&this.button_text!='')&&(this.button_url!=undefined&&this.button_url!=''))
   {
     this.displaySettings = true;
   }

   if(localStorage.getItem('Input_form_data')!='')
   {
     this.user_details = JSON.parse(localStorage.getItem('Input_form_data'));
     this.user_email = this.user_details.email;
     // console.log(" this.user_details : " + this.user_details.email);
   }


   await this.route.queryParams
   .subscribe(params => {
     // console.log(" params.session_id : " + params.session_id); 
     this.session_id = params.session_id;
    })

    this.report_id = this.appservices.getSession("ohr_unique_key", false);

    if(this.appservices.isEmpty(this.widget_flow_type)||this.appservices.isEmpty(this.report_id))
    {
      this.router.navigate(['get-started/'+this.appservices.getSession("token", false)]);
    }

    if(!this.appservices.isEmpty(this.session_id))
    {
      this.apirequest["payment_amount"]      = this.appservices.getSession("payment_amount", false);
      this.apirequest["report_id"]           = this.report_id;
      this.apirequest["report_type"]         = "ohr";
      this.apirequest["selected_branch_id"]  = this.appservices.getSession("selected_branch_id", false);
      this.apirequest["widget_token"]        = this.appservices.getSession("token", false);
      this.apirequest["session_id"]          = this.session_id;
      this.apirequest["get_contact_information_later"] = this.get_contact_information_later;       
      this.apirequest["guest_user_id"]                 = this.guest_user_id; 


      // this.input = this.appservices.generteJSONFromArray(this.apirequest);
      let report_input = this.appservices.generteJSONFromArray(this.apirequest);
      // console.log(this.apirequest);
      this.appservices.callJSONAPI(this.appservices.GET_STRIPE_PAYMENT_DETAILS, report_input, 'post', this, '').subscribe(
      async (data: any) => {
        //  console.log(" data.status_code : " + data.status_code);
        //  console.log(" data.message : " + data.message);     
        //  console.log(" this.get_contact_information_later : " + this.get_contact_information_later);
        //  console.log(" data.result.user_id : " + data.result.user_id);
        if(data.status_code!='1')
        {
        //  console.log('sa_wg_payment_fail'); 
          this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_payment_fail',true);           
          this.router.navigate(['get-started/'+this.appservices.getSession("token", false)]);
        }
        else 
        {
          //  console.log('sa_wg_payment_success'); 

          if(this.get_contact_information_later=='yes' && (!this.appservices.isEmpty(data.result.user_id))    )
          {
            let full_name = '';                
            // console.log(" data.result.user_access_token : " + data.result.user_access_token);

            // let full_name = '';                
            //  if(!this.appservices.isEmpty(data.result.user_access_token)){   
            //   this.appservices.setSession("user_access_token", data.result.user_access_token, false);   
            //   this.appservices.setSession("is_logged_in", "true", false);
            //   this.appservices.setSession("isUserLoggedIn","false",false);
            // }

            // if(!this.appservices.isEmpty(data.result.first_name)){   
            //   this.firstName = data.result.first_name;  
            // }

            // if(!this.appservices.isEmpty(data.result.last_name))
            // {
            //   this.lastName = data.result.last_name; 
            // }

            // if(!this.appservices.isEmpty(data.result.existing_user)&&data.result.existing_user!='true'&&this.appservices.isEmpty(this.lastName))
            // {
            //   var nameSplit = (data.result.first_name).split(' ');
            //   if(!this.appservices.isEmpty(nameSplit[1]))
            //   {
            //     this.firstName = nameSplit[0];
            //     this.lastName  = nameSplit[1];
            //   }
            // }
            // full_name = this.firstName +" "+ this.lastName;
            // this.appservices.setSession("first_name", this.firstName, false);   
            // this.appservices.setSession("last_name", this.lastName, false);   
            // this.appservices.setSession("full_name", full_name, false);


            if(!this.appservices.isEmpty(data.result.user_access_token)){   
              this.appservices.setSession("user_access_token", data.result.user_access_token, false);   
              this.appservices.setSession("is_logged_in", "true", false);
              this.appservices.setSession("isUserLoggedIn","false",false);
            }

            // console.log(" data.result.first_name : " + data.result.first_name);
            if(!this.appservices.isEmpty(data.result.first_name)){   
              this.appservices.setSession("first_name", data.result.first_name, false);   
            }

            // console.log(" data.result.last_name : " + data.result.last_name);
            if(!this.appservices.isEmpty(data.result.last_name)){   
              this.appservices.setSession("last_name", data.result.last_name, false);   
              full_name = data.result.first_name + data.result.last_name;
              this.appservices.setSession("full_name", full_name, false);
            }
            // console.log(" data.result.email : " + data.result.email);
            if(!this.appservices.isEmpty(data.result.email)){   
              this.appservices.setSession("email", data.result.email, false);   
            }
            // console.log(" data.result.user_id : " + data.result.user_id);
            if(!this.appservices.isEmpty(data.result.user_id)){   
              this.appservices.setSession("user_id", data.result.user_id, false);            
            }
            if(!this.appservices.isEmpty(data.result.phone)){   
              this.appservices.setSession("phoneNumber", data.result.phone, false);            
            }
            if(!this.appservices.isEmpty(data.result.dial_code)){   
              this.appservices.setSession("dial_code", data.result.dial_code, false);
            }
            if(!this.appservices.isEmpty(data.result.profile_picture)){   
              this.appservices.setSession("profile_picture", data.result.profile_picture, false);   
            }
            if(!this.appservices.isEmpty(data.result.city)){   
              this.appservices.setSession("city", data.result.city, false);
            }
            if(!this.appservices.isEmpty(data.result.state_id)){   
              this.appservices.setSession("state_id", data.result.state_id, false);
            }
            if(!this.appservices.isEmpty(data.result.country_id)){   
              this.appservices.setSession("country_id", data.result.country_id, false);
            }
          // console.log(" data.result.user.dial_country_code : " + data.result.user.dial_country_code);
            if(!this.appservices.isEmpty(data.result.dial_country_code)){   
              this.appservices.setSession("country_name",data.result.dial_country_code, false);
            }

            this.inputFormData = {"firstName":data.result.first_name,"lastName":data.result.last_name,"email":data.result.email,"phoneNumber":data.result.phone};
            localStorage.setItem('Input_form_data', JSON.stringify(this.inputFormData)); 

            this.sendSubjectMessage = {first_name:data.result.first_name,last_name:data.result.last_name,text:this.profile_img,email:data.result.email}
            if(data.result.profile_picture!='') 
            {
              this.sendSubjectMessage['text'] = data.result.profile_picture;
              // this.messageService.sendMessage({text:environment.S3_BASE_URL+this.profile_img_complete_path,first_name:form.value.first_name,last_name:form.value.last_name});          
            }
            this.messageService.sendMessage(this.sendSubjectMessage);

            this.apirequest = [];
            this.apirequest["report_id"]           = this.report_id;
            this.apirequest["user_id"]             = data.result.user_id;       
            this.apirequest["guest_user_id"]       = this.guest_user_id; 
            this.apirequest["widget_token"]        = this.appservices.getSession("token", false);
            this.apirequest["overall_score"]       = this.appservices.getSession("overall_score", false);     
            this.apirequest["selected_branch_id"]  = this.appservices.getSession("selected_branch_id", false);            
            this.apirequest["health_score_id"]     = this.appservices.getSession("health_score_id", false);
            this.apirequest["timezone"]            = 'Asia/Calcutta';
            // this.input = this.appservices.generteJSONFromArray(this.apirequest);
            let report_input = this.appservices.generteJSONFromArray(this.apirequest);
            // console.log(this.apirequest);
            // await  this.appservices.callJSONAPI(this.appservices.UPDATE_OHR_USER_ID, report_input, 'post', this, '').subscribe(
            // async (data: any) => {              
            //   await this.reportSend();
            //   this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_payment_success',true);           
              
            // })
            await this.reportSend();
            this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_payment_success',true);           

          }  
          else 
          {
            await this.reportSend();
          }
        }        
      })
    }

    else 
        {
          //  console.log('sa_wg_payment_success'); 
        //  console.log(" get_contact_information_later : " + this.get_contact_information_later  );
        //  console.log(" user_id : " + this.appservices.getSession("user_id", false)  );
          if((!this.appservices.isEmpty(this.appservices.getSession("user_id", false))))
          {
          
            this.apirequest = [];
            this.apirequest["report_id"]           = this.report_id;
            this.apirequest["user_id"]             = this.appservices.getSession("user_id", false);       
            this.apirequest["guest_user_id"]       = this.guest_user_id; 
            this.apirequest["widget_token"]        = this.appservices.getSession("token", false);
            this.apirequest["overall_score"]       = this.appservices.getSession("overall_score", false);     
            this.apirequest["selected_branch_id"]  = this.appservices.getSession("selected_branch_id", false);            
            this.apirequest["health_score_id"]     = this.appservices.getSession("health_score_id", false);
            this.apirequest["timezone"]            = 'Asia/Calcutta';
            // this.input = this.appservices.generteJSONFromArray(this.apirequest);
            let report_input = this.appservices.generteJSONFromArray(this.apirequest);
            // console.log(this.apirequest);
            // await  this.appservices.callJSONAPI(this.appservices.UPDATE_OHR_USER_ID, report_input, 'post', this, '').subscribe(
            // async (data: any) => {              
            //   await this.reportSend();
            //   this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_payment_success',true);           
              
            // })

            await this.reportSend();
            this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_payment_success',true);           


          }  
        }



    this.fb_pixel_id = this.appservices.getSession("fb_pixel_id",false);
    this.gtm_id = this.appservices.getSession("gtm_id",false);

    window.scrollTo(0,0);
    $(window).scrollTop(0);
    this.dentalInfo = [
      { "url": 'assets/images/decay1.png', "title": "Healthy Teeth" },
      { "url": 'assets/images/decay2.png', "title": "Mild Decay" },
      { "url": 'assets/images/decay3.png', "title": "Moderate Decay" },
      { "url": 'assets/images/decay4.png', "title": "Severe Decay" }
    ]
    this.gumlInfo = [
      { "url": 'assets/images/decay1.png', "title": "Healthy Teeth" },
      { "url": 'assets/images/gum2.png', "title": "Mild Gum" },
      { "url": 'assets/images/gum3.png', "title": "Moderate Gum" },
      { "url": 'assets/images/gum4.png', "title": "Severe Gum" }]

    this.first_name = this.appservices.getSession("first_name", false);
    this.created_date = this.appservices.getSession("created_date", false);
    this.is_images_invalid = this.appservices.getSession("is_all_images_invalid", false);
    // console.log(this.is_images_invalid)
    // console.log(this.created_date)
    this.process_images = JSON.parse(JSON.stringify(this.appservices.getSession("process_images", true)));
    // console.log(this.process_images);

    this.created_date = this.appservices.getSession("created_date", false);
    this.dentalImages = [
      { "url": 'assets/images/decay1.png', "title": "Healthy Teeth" },
      { "url": 'assets/images/decay2.png', "title": "Mild Decay" },
      { "url": 'assets/images/decay3.png', "title": "Moderate Decay" },
      { "url": 'assets/images/decay4.png', "title": "Severe Decay" }
    ]
    // console.log(this.dentalImages[0]['url'])
    
  }

  getTitle(title, output_image) {     
    if (output_image) {
      return title;
    }
    else {
      // var output = title.replace("- Oral Prediction", " ");
      var output = "Additional Image - Oral Prediction";
      return output;
    }
  }
  onSubmit(is_images_invalid) {
    if (this.is_images_invalid == 'true') {
      this.clearUserSession();
      // console.log('sa_wg_payment_start_again');
      this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_payment_start_again',true);     
      // this.router.navigate(['oral-health-score-preference']);
      this.router.navigate(['purpose-of-visit']);
    }
    else { 
      this.widget_flow_type =  this.appservices.getSession("widget_flow_type", false);
      if(this.widget_flow_type==="payment")
      {
        this.clearUserSession();
        // console.log('sa_wg_payment_start_again');
        this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_payment_start_again',true);     
        // this.router.navigate(['oral-health-score-preference']);
        this.router.navigate(['purpose-of-visit']);
      }
      else 
      {
        // this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_ohr_process',false);
        // this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_ohr_process',true);
        this.router.navigate(['oral-hygiene']);
      }
    }
  }

  
  openViewModal(template: TemplateRef<any>, id) {
    this.itemsList = id == '1' ? this.dentalInfo : this.gumlInfo;
    this.label = id == '1' ? 'dental_decay' : 'gum';
    this.modalRef = this.modalService.show(template, {
      class: 'custom_notifications_modal vertical_align_center',
      animated: true,
      backdrop: 'static'
    });
  }
  closeModal() {
    this.modalRef.hide();
  }


  async reportSend() {
    // $('.button_links').html('Please wait...').attr('disabled', true);
    this.apirequest = [];
    this.apirequest["time_zone"] = moment.tz.guess();;
    this.apirequest["oral_unique_id"] = this.appservices.getSession("ohr_unique_key", false);
    this.apirequest["widget_token"] = this.appservices.getSession("token", false);
    this.apirequest["is_payment_requested"] = 'no';
    // console.log(" this.appservices.getSession(widget_flow_type, false)  " + this.appservices.getSession("widget_flow_type", false) );
    if(this.appservices.getSession("widget_flow_type", false) == 'payment' && this.appservices.getSession("payment_gateway", false) != '0'  )
    {
      this.apirequest["is_payment_requested"] = 'yes';
    }    
    // this.input = this.appservices.generteJSONFromArray(this.apirequest);
    let report_input = this.appservices.generteJSONFromArray(this.apirequest);
    // console.log(this.apirequest);
    this.appservices.callJSONAPI(this.appservices.OHR_REPORTS, report_input, 'post', this, '').subscribe(
      (data: any) => {
        // console.log(" data " + data);
        //v$('.button_links').html('Email me a report').attr('disabled', false);
        if (data.status) {
          // this.appservices.serviceMessages('vs-messages', data.message, 'success');
          // if (this.isRatingPopupNotOpened)
          if(window.location.host.toLowerCase() == "get.smilo.ai"){
            gtag('event', 'conversion', {'send_to': 'AW-10941358920/AgABCJ6M3tEDEMjGn-Eo'});
          }
          if(environment.GTM_ID!='')
          {              
              let postMessageParams:any = {event:"select_content",content_type: "sa_wg_ohr_email",item_id:'true',send_to: environment.GTM_ID};                    
              // console.log(" postMessageParams : " + JSON.stringify(postMessageParams ));
              window.parent.postMessage({ action: 'iframe-message', params:postMessageParams}, '*');                
           // gtag('config',environment.GTM_ID);              
           // this.appservices.gtmEvents('event','select_content',{content_type: "sa_wg_ohr_email",item_id:'true',send_to: environment.GTM_ID});         
          }
          if(environment.FBPIXEL_ID!='')
          { 
            fbq('trackSingleCustom', environment.FBPIXEL_ID , 'select_content',{sa_wg_ohr_email:'true'} );
          }  

          this.appservices.setSession("payment_successful",'', false);
          this.appservices.setSession("ohr_unique_key",'', false);
         // this.appservices.setSession("get_contact_information_later",'', false);
          // this.appservices.setSession("guest_user_id",'', false);
          this.appservices.setSession("redirecting_url",'', false);
          this.startAgainBtntext = 'Start Again';
          this.startAgainBtn = '';

          this.flag = true;

          // else
          //   this.router.navigate(['ohr-email-success']);
        } else {
          this.appservices.serviceMessages('vs-messages', data.message, 'danger')
        }
      },
      (error) => {
        this.appservices.showDebugMessage(error);
      }
    );
  }

  redirectToDMS()
  {
    this.router.navigate(['upload-smile-photo']);
  }


  clearUserSession()
  {
    this.appservices.setSession("is_logged_in", "false", false);
    this.appservices.setSession("isUserLoggedIn","false",false);
    this.appservices.setSession("first_name", '', false); 
    this.appservices.setSession("last_name", '', false); 
    this.appservices.setSession("email", '', false); 
    this.appservices.setSession("user_id", '', false); 
    this.appservices.setSession("phoneNumber", '', false); 
    this.appservices.setSession("dial_code", '', false);
    this.appservices.setSession("profile_picture", '', false);   
    this.appservices.setSession("city",  '', false);
    this.appservices.setSession("state_id",  '', false);
    this.appservices.setSession("country_id",  '', false);
    this.appservices.setSession("country_name", '', false);
    localStorage.setItem('Input_form_data',''); 
  }

}
