<div class="dynamic_wrapper">
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="dms_content">
            <!-- <div class="processing">
                <img src="assets/images/step3.png" alt="image">
            </div> -->
            <div id="steps">
                <div id="circles" class="circle-steps">
                    <div data-num="1" id="num1"></div>
                    <div data-num="2" id="num2"></div>
                    <div data-num="3" id="num3" class="disabled"></div>
                </div>
            </div>
            <div *ngIf="flag">
                <div class="img_section text-center position-relative">
                    <div class="user_img_sec">
                        <img [src]="preview_image">
                    </div>
                    <div class="analyzing_text">
                        <p class="text-danger error_div">{{errorMessage}}</p>
                    </div>
                    <div class="text-center col-sm-12 my-3">
                        <button class="btn button_links btn_width_1 coninue_btn btn_color"  [routerLink]="['/upload-smile-photo']">Take a New Picture</button>
                    </div>
                </div>
            </div>
            <div class="sec_inner_content p-0">
                <div class="row displayblock">
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <div class="text-center">
                            <h3 class="sub_head mb-4">Customise Your look</h3>
                            <div class="grey_color font_14 pb-3 media_text">
                                <p>Select a colour that matches your gums then choose a teeth style you like.</p>
                                <p>Finish up by selecting the intended teeth shade.</p>
                                <p>Get ready for your new smile.</p>
                            </div>
                            <div class="grey_color font_14 py-3 responsive_text">
                                <p>Select a colour that matches your gums then choose a teeth style you like.Finish up by selecting the intended teeth shade.Get ready for your new smile.</p>
                            </div>
                            <div class="select_btn apply_btn text-center media_button">
                                <button [disabled]="isButtonDisabled" type="submit" (click)="onPreview()" class="btn button_links btn_width_2 coninue_btn btn_color mx-0 mt-3 justify-content-center align-items-center"><span class="save_continue_btn_span">Next</span><span class="icon_arrow icon-right-arrow ml-3 font_12"></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 pointer_events">
                        <div class="row align-items-center flex_direction">
                            <div class="col-md-6 col-sm-12 responsive_sections">
                                <div class="preview_text_color_select my-3">
                                    <p class="grey_color mb-2">Choose your gum color</p>
                                    <div class="d-flex align-items-center gum_div">
                                        <div *ngFor="let data of gum_types let i = index" class="mr-3">
                                            <div class="gum_hover gum_img_loop pointer_link" [ngClass]="{'gum_border_grey':shade_id===data.shade_number}" (click)="onShade(data.shade_number,data.shade_image)">
                                                <!-- <img [src]="data.shade_image" class="score_one"> -->
                                                <img [defaultImage]="defaultImage" [lazyLoad]="data.shade_image" [errorImage]="defaultImage" alt="score_one">  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="preview_text_color_select my-3">
                                    <p class="grey_color mb-2">Select your teeth style
                                    </p>
                                </div>
                                <div class="teeth_div">
                                    <ngx-slick-carousel class="carousel m-0 gum_carousel" #slickModal="slick-carousel"
                                        [config]="slideConfig" (init)="slickInit($event)">
                                        <div ngxSlickItem *ngFor="let data of teeth_template" class="slide">
                                            <div class="teeth_img teeth_hover pointer_link" [ngClass]="{'teeth_border_grey':template_id===data.template_number}" (click)="onTemplate(data.template_number,data.template_image)">
                                                <!-- <img [src]="data.template_image" class="score_one"> -->
                                                <img [defaultImage]="defaultImage" [lazyLoad]="data.template_image" [errorImage]="defaultImage" alt="score_one">  
                                            </div>
                                        </div>
                                    </ngx-slick-carousel>
                                </div>
                                <div class="preview_text_color_select my-3">
                                    <p class="grey_color mb-2">Preferred teeth shade</p>
                                </div>
                                <div class="teeth_div">
                                    <!-- <ngx-slick-carousel class="carousel m-0 teeth_shade_width" #slickModal="slick-carousel"
                                        [config]="slideConfig" (init)="slickInit($event)">
                                        <div ngxSlickItem *ngFor="let data of teeth_shade" class="slide">
                                            <div class="shade_hover shade_img pointer_link" (click)="onTeethShade(data.number,data.image)">
                                                <img [src]="data.image" class="score_one">
                                            </div>
                                        </div>
                                    </ngx-slick-carousel> -->
                                </div>
                                <div class="d-flex align-items-center gum_div">
                                    <div *ngFor="let data of teeth_shade let i = index" class="mr-3">
                                        <div class="shade_hover shade_image pointer_link" [ngClass]="{'teeth_border_grey':template_id===data.number}" title="{{data.name}}" (click)="onTeethShade(data.number,data.image)">
                                            <!-- <img [src]="data.image" class="score_one"> -->
                                            <img (load)="updateImgAfterLoad()" class="img_teeth_shade_{{data.number}}" [defaultImage]="defaultImage" [lazyLoad]="data.image" [errorImage]="defaultImage" alt="{{data.name}}">                                              
                                            <span class="teethShadeName" title="{{data.name}}"> {{data.name}} </span>                                             
                                        </div>                                        
                                    </div>
                                </div>
                                
                                <div class="my-4 before_after_btn">
                                    <a (click)="navigate()" *ngIf="smile_preview" class="pointer_link start_btn smg_before_after_btn">See your Before and After</a>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 responsive_sections" [ngClass]="{'padding_bottom': smile_preview}">
                                <div class="img_preview dms-image-preview">
                                    <img [defaultImage]="defaultImage" [lazyLoad]="preview_image" [errorImage]="defaultImage" alt="preview_look" class="loader_image">                                            
                                </div>
                            </div>
                        </div>
                        <div class="select_btn apply_btn text-center responsive_button">
                            <button [disabled]="isButtonDisabled" type="submit" (click)="onPreview()" class="btn button_links btn_width_2 coninue_btn btn_color mx-0 mt-3 justify-content-center align-items-center"><span class="save_continue_btn_span">Next</span><span class="icon_arrow icon-right-arrow ml-3 font_12"></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>
