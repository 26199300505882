import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// import { Router, ActivatedRoute } from '@angular/router';
import { Services } from '../helpers/Services';

import { environment } from '../../../src/environments/environment';

 
declare var gtag


@Component({
  selector: 'app-oral-health-score-preference',
  templateUrl: './oral-health-score-preference.component.html',
  styleUrls: ['./oral-health-score-preference.component.css']
})
export class OralHealthScorePreferenceComponent implements OnInit {
  isSubmitted = false;
  testForm: FormGroup;
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  testValue: any;
  bannerTitleText: any = 'Select Preferences';
  user_details: any = '';
  user_email: any = '';
  defaultImage: string = "assets/images/loader_large.gif";
  // staticImage1: string = "assets/images/ohr.png";
  // staticImage2: string = "assets/images/design_my_smile.png";
  staticImage1: string = "assets/images/ohr.png";
  staticImage2: string = "assets/images/dms.png";

  header_display_msg: any = '';

  existing_user: any = '';

  bypass_select_service:any;
  widget_service_type:any;

  // staticImage1: string = "assets/images/OHR1.png";
  // staticImage2: string = "assets/images/DMS1.png";
  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient, private router: Router) {
    this.appservices = new Services;
  }
  ngOnInit(): void {
    if (!this.appservices.getSession("token", false)) {
      this.router.navigate(['get-started']);
    }
    this.testForm = this.formBuilder.group({
      test: ['', [Validators.required]]
    });

    if(localStorage.getItem('Input_form_data')!='')
    {
      this.user_details = JSON.parse(localStorage.getItem('Input_form_data'));
      this.user_email = this.user_details.email;
      // this.header_display_msg = 'Smilo.ai successfully created an account for you and send an email to <a  href="mailto:'+this.user_email+'">'+this.user_email+'</a> . please use login details to view/download Oral Health Rating and Design my smile report.';
      // console.log(" this.user_details : " + this.user_details.email);
    }

    // console.log(" this.appservices.getSession(existing_user,false) : " + this.appservices.getSession("existing_user",false));
    
    if(this.appservices.getSession("existing_user",false)==='yes')
    {
      this.existing_user = 'yes';
      // this.header_display_msg = 'Welcome back! You have successfully Logged In.';
    }
    if(this.appservices.getSession("existing_user",false)==='no')
    {
      this.existing_user = 'no';
    }

    this.appservices.setSession("existing_user",'', false);
    
    this.appservices.setSession("bannerTitleText", this.bannerTitleText, false);


    // this.bypass_select_service = this.appservices.getSession("bypass_select_service",false);
    // this.widget_service_type   = this.appservices.getSession("widget_button_type", false);
    // if( (this.widget_service_type!="2")||(this.bypass_select_service!='0')) 
    // {
    //   this.widget_service_type = this.appservices.getSession("widget_button_type", false);
    //   if(this.widget_service_type=="0")
    //   {
    //     this.appservices.setSession("uploadImageBtnSelected",false ,false);  
    //     this.router.navigate(['purpose-of-visit']);
    //   }
    //   else if(this.widget_service_type=="1")
    //   {
    //     this.appservices.setSession("uploadImageBtnSelected",true ,false); 
    //     this.router.navigate(['contact-information']);
    //   }
    // }


  }
  get myForm() {
    return this.testForm.get('test');
  }

  selectTestType(e, type)
  {      
    this.testValue = type;
    this.bannerTitleText = this.testValue == 'DMS'?'Design My Smile':'Oral Health Rating';
    this.appservices.setSession("bannerTitleText", this.bannerTitleText, false);
    if (this.testValue == 'DMS') {
      this.router.navigate(['upload-smile-photo']);
    }
    else {
      this.router.navigate(['upload-teeth-photos']);
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.testForm.valid) {
      return false;
    } else {
      this.testValue = this.testForm.get('test').value;
      this.bannerTitleText = this.testValue == 'smile_design'?'Design My Smile':'Oral Health Rating';
      this.appservices.setSession("bannerTitleText", this.bannerTitleText, false);
      // console.log(this.appservices.getSession("bannerTitleText", true))
      if (this.testValue == 'smile_design') {
        this.router.navigate(['upload-smile-photo']);
      }
      else {
        this.router.navigate(['upload-teeth-photos']);
      }
    }
  }

}
