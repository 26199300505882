<!-- <p>payment-process works!</p> -->

<div class="section_wrapper pt-0 position-relative" *ngIf="!showLoadingForm">
  <div class="section-banner_wrapper position-relative">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <a href="javascript:void(0)" class="back-btn" (click)="onBack()">
            <i class="sh-chevron-left"></i> <span>back</span>
          </a>
          <div class="wrapper-container">
            <div
              class="banner_wrap d-flex align-items-center justify-content-between justify-content-lg-center"
            >
              <div class="banner-content">
                <h1>
                  Buy
                  {{
                    imageSelectionCount == 1
                      ? "Your Smile Check"
                      : "An Oral Health"
                  }}
                  <br />
                  Report
                </h1>
              </div>
              <div class="banner-image">
                <img
                  src="assets/images/health-report-banner.png"
                  class="img-fluid d-none d-sm-block"
                  alt=""
                />
                <img
                  src="assets/images/rag-report-mobile.png"
                  class="img-fluid d-block d-sm-none"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-heading mb-3 mb-lg-4 pb-1 pb-lg-2 text-lg-center">
          <h1 class="mb-1">Review & Pay</h1>
          <p>Review & confirm your details before proceeding to payment</p>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tile tile-small">
          <div class="tile-header mb-2">
            <h3 class="d-flex align-items-center">
              <i class="sh-user-square"></i> Patient Details
            </h3>
          </div>
          <div class="tile-body">
            <div class="row">
              <div class="col-6">
                <div class="patient-info">
                  <span>First Name</span>
                  <p class="word_breakall">{{ first_name }}</p>
                </div>
              </div>
              <div class="col-6">
                <div class="patient-info">
                  <span>Last Name</span>
                  <p class="word_breakall">{{ last_name }}</p>
                </div>
              </div>
              <div class="col-6">
                <div class="patient-info">
                  <span>Email ID</span>
                  <p class="word_breakall">{{ email }}</p>
                </div>
              </div>
              <div class="col-6">
                <div class="patient-info">
                  <span>Phone Number</span>
                  <p class="word_breakall">{{ phone_number }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tile tile-small">
          <div class="tile-header mb-3 mb-lg-4 pb-lg-1">
            <h3 class="d-flex align-items-center">
              <i class="sh-credit-card-fill"></i> Sample Report
            </h3>
          </div>
          <div class="tile-body">
            <div class="payment-options-list d-flex flex-column">
              <div class="payment-option">
                <div class="payment-body">
                  <form>
                    <div class="text-center mt-2 mt-sm-3 mb-3 mb-sm-4 pb-sm-2">
                      <button
                        id="sample"
                        type="button"
                        (click)="onDownloadSampleReport()"
                        class="btn btn-primary btn-rounded-corner btn-sm mx-auto w-auto"
                      >
                        Download Sample Report
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="tile tile-small">
          <div class="tile-header mb-3 mb-lg-4 pb-lg-1">
            <h3 class="d-flex align-items-center">
              <i class="sh-tooth"></i> Flagged Risks
            </h3>
          </div>
          <div class="tile-body">
            <div class="reportscore-rt d-flex flex-row">
              <!-- rating card 1 -->
        <!-- <div class="rating-card poor-card">
                                <div class="ratingteeth">
                                    <i class="sh-tooth-decay"></i>
                                </div>
                                <div class="ratinginfo">
                                    <h6>DENTAL DECAY</h6>
                                    <p>Low possibility of dental decay</p>
                                </div>
                            </div> -->
        <!-- <div
                class="rating-card"
                [ngClass]="
                  overall_score <= 4
                    ? 'poor-card'
                    : overall_score >= 5 && overall_score <= 7
                    ? 'poor-card'
                    : overall_score >= 8 && this.overall_score <= 10
                    ? 'good-card'
                    : ''
                "
              >
                <div class="ratingteeth">
                  <i class="sh-tooth-decay"></i>
                </div>

                <div class="ratinginfo">
                  <h6>DENTAL DECAY</h6>

                  <p>
                    {{
                      overall_score >= 8 && overall_score <= 10
                        ? "Low possibility"
                        : "Possibility"
                    }}

                    of dental decay
                  </p>
                </div>
              </div> -->
        <!-- rating card 2 -->
        <!-- <div class="rating-card severe-card">
                <div class="ratingteeth">
                  <i class="sh-tooth-gum"></i>
                </div>
                <div class="ratinginfo">
                  <h6>GUM ISSUES</h6>
                  <p>Low possibility of gum health issues</p>
                </div>
              </div> -->

        <!-- <div
                class="rating-card"
                [ngClass]="overall_score <= 4 ? 'severe-card' : 'good-card'"
              >
                <div class="ratingteeth">
                  <i class="sh-tooth-gum"></i>
                </div>

                <div class="ratinginfo">
                  <h6>GUM ISSUES</h6>

                  <p>
                    {{ overall_score <= 4 ? "High" : "Low" }} possibility of gum
                    health issues
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>  -->
      </div>
      <div class="col-lg-6">
        <div class="tile tile-small">
          <div class="tile-header mb-3 mb-lg-4 pb-lg-1">
            <h3 class="d-flex align-items-center">
              <i class="sh-credit-card-fill"></i> Pay
            </h3>
          </div>
          <div class="tile-body">
            <div class="payment-options-list d-flex flex-column">
              <div class="payment-option">
                <!-- <div class="payment-header d-flex align-items-center">
                  <h4 class="d-flex flex-column">
                    Pay with Razorpay UPI <span>Google Pay, PhonePe, Paytm & more</span>
                  </h4>
                </div> -->

                <div class="payment-body">
                  <form>
                    <!--<div
                      class="radio-button-group termsAndConditionsDiv d-flex flex-column"
                    >
                      <div class="form-check">
                        <input
                          class="form-check-input form-control"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          class="form-check-label d-flex align-items-center"
                          for="flexRadioDefault1"
                        >
                          <img
                            src="assets/images/google_pay.svg"
                            class="img-fluid"
                            alt="Google Pay"
                          />
                          <span>Google Pay</span>
                        </label>
                      </div> -->
                    <!-- <div class="form-check">
                        <input
                          class="form-check-input form-control"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked
                        />
                        <label
                          class="form-check-label d-flex align-items-center"
                          for="flexRadioDefault2"
                        >
                          <i class="sh-wallet"></i>
                          <span>Pay with UPI</span>
                        </label>
                        <div class="upi-id-block mt-3">
                          <input
                            type="text"
                            class="form-control mb-0"
                            placeholder="Enter UPI ID/VPA"
                          />
                        </div>
                      </div> 
                    </div> --->
                    <div class="text-center mt-2 mt-sm-3 mb-3 mb-sm-4 pb-sm-2">
                      <button
                        id="rzp-button1"
                        type="submit"
                        (click)="makeCCAVENUEPay()"
                        class="btn btn-primary btn-rounded-corner btn-sm mx-auto w-auto"
                      >
                        Pay ₹{{ payment_amount }}
                      </button>

                      <!-- <button
                        id="rzp-button1"
                        type="submit"
                        (click)="makePayment()"
                        class="btn btn-primary btn-rounded-corner btn-sm mx-auto w-auto"
                      >
                        Pay ₹{{ payment_amount }}
                      </button> -->
                    </div>
                  </form>
                </div>
              </div>
              <!-- <div class="payment-option">
                <a
                  href="javascript:void(0)"
                  class="payment-header_action pb-0"
                  (click)="isExpand2 = !isExpand2"
                >
                  <div class="payment-header d-flex align-items-center">
                    <i class="sh-credit-card"></i>
                    <h4 class="d-flex flex-column">
                      Credit/Debit Card
                      <span>Visa, Mastercard, Rupay & more</span>
                    </h4>
                  </div>
                </a>
                <div class="payment-body" *ngIf="isExpand2">
                  <form>
                    <div class="we-accept d-flex align-items-center">
                      <span>WE ACCEPT</span>
                      <ul class="d-flex align-items-center">
                        <li>
                          <img
                            src="assets/images/rupay.svg"
                            class="img-fluid"
                            alt="Google Pay"
                          />
                        </li>
                        <li>
                          <img
                            src="assets/images/visa.svg"
                            class="img-fluid"
                            alt="Google Pay"
                          />
                        </li>
                        <li>
                          <img
                            src="assets/images/mastercard.svg"
                            class="img-fluid"
                            alt="Google Pay"
                          />
                        </li>
                        <li>
                          <img
                            src="assets/images/american-express.svg"
                            class="img-fluid"
                            alt="Google Pay"
                          />
                        </li>
                      </ul>
                    </div>
                    <div class="upi-id-block w-100 mt-3">
                      <div class="row form-row">
                        <div class="col-12 form-col">
                          <input
                            type="text"
                            id="cardNumber"
                            [(ngModel)]="cardDetails.cardNumber"
                            class="form-control"
                            placeholder="Enter Card Number"
                          />
                        </div>
                        <div class="col-6 form-col">
                          <input
                            type="text"
                            id="expiryDate"
                            [(ngModel)]="cardDetails.expiryDate"
                            class="form-control"
                            placeholder="Expiry (MM/YY)"
                          />
                        </div>
                        <div class="col-6 form-col">
                          <input
                            type="text"
                            id="cvv"
                            [(ngModel)]="cardDetails.cvv"
                            class="form-control"
                            placeholder="CVV"
                          />
                        </div>
                        <div class="col-12 form-col">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Name On Card"
                          />
                        </div>
                        <div class="col-12 form-col">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="India"
                          />
                        </div>
                        <div class="col-12">
                          <div
                            class="checkbox-group termsAndConditionsDiv d-flex flex-column"
                          >
                            <div class="form-check">
                              <input
                                class="form-check-input form-control"
                                type="checkbox"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked
                              />
                              <label
                                class="form-check-label d-flex align-items-center"
                                for="flexRadioDefault1"
                              >
                                Save this card as per <b>RBI Guidelines</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center mt-0 mt-sm-4 pt-2">
                      <button
                        type="submit"
                        (click)="makePayment()"
                        class="btn btn-primary btn-rounded-corner btn-sm mx-auto w-auto"
                      >
                        Verify & Pay ₹699
                      </button>
                    </div>
                  </form>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="tile tile-small">
          <div class="tile-header mb-3 mb-lg-4 pb-lg-1">
            <h3 class="d-flex align-items-center">
              <i class="sh-credit-card-fill"></i> Payment Summary
            </h3>
          </div>
          <div class="tile-body">
            <table class="w-100 payment-summary">
              <tr>
                <td class="w-50">Subtotal</td>
                <td class="w-50 text-right">₹{{ payment_amount }}</td>
              </tr>
              <!-- <tr>
                                <td class="w-50">Discount</td>
                                <td class="w-50 text-right discount-text">- ₹126</td>
                            </tr> -->
              <tr class="total">
                <td class="w-50">You Pay</td>
                <td class="w-50 text-right">₹{{ payment_amount }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<form
  #form
  ngNoForm
  id="nonseamless"
  method="post"
  name="redirect"
  [action]="ccavenueRedirectionUrl"
>
  <input
    type="hidden"
    id="encRequest"
    name="encRequest"
    [ngModel]="encRequest"
  />
  <input
    type="hidden"
    name="access_code"
    id="access_code"
    [ngModel]="accessCode"
  />
</form>

<div class="loader-section" *ngIf="showLoadingForm">
  <div class="loader_wrapper">
    <div class="loading_div">
      <div class="circle-chekmark analyze_loader upload-image_loader">
        <div class="loding-circle" *ngIf="!isDataLoaded">
          <div class="circle-chekmark-border"></div>
          <svg width="200" height="200" class="circle-svg">
            <circle
              fill="none"
              stroke="#f5821e"
              stroke-width="18"
              cx="100"
              cy="100"
              r="90"
              class="circle"
              stroke-linecap="round"
              transform="rotate(-90 100 100) "
            />
          </svg>
        </div>
        <div class="loading-arrow" *ngIf="!isDataLoaded">
          <i class="sh-arrow-up"></i>
        </div>
        <div class="zoomout-circle_tick" *ngIf="isDataLoaded">
          <div class="circle-chekmark-dark"></div>
          <svg width="400" height="400" class="tick-svg">
            <polyline
              fill="none"
              stroke="#ffffff"
              stroke-width="50"
              points="88,214 173,284 304,138"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="tick"
            />
          </svg>
        </div>
      </div>
      <div class="art_intelli_para_sec">
        <p
          class="text-center"
          [ngClass]="{ 'text-green': isDataLoaded === true }"
        >
          {{ !isDataLoaded ? analyzingText : successText }}
        </p>
      </div>
    </div>
  </div>
</div>
