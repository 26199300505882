import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { Services } from '../helpers/Services';

@Component({
  selector: 'app-analysed-results',
  templateUrl: './analysed-results.component.html',
  styleUrls: ['./analysed-results.component.css']
})
export class AnalysedResultsComponent implements OnInit {
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  input: any;
  user_email: any;
  user_details: any;
  // detaileddata: any;
  ohrdata;
  defaultImage: string = "assets/images/tooth_loader.svg";
  first_name: any;
  // created_date: any;
  responseData: any;
  @Input('parentData') public detaileddata;
  @Input('parentData2') public created_date;
  constructor(private httpClient: HttpClient, private router: Router) {
    this.appservices = new Services;
  }
  ngOnInit(): void {
    window.scrollTo(0,0);
    $(window).scrollTop(0);
    this.first_name = this.appservices.getSession("first_name", false);
  }

}
