import { Component, OnInit, HostListener } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { environment } from "src/environments/environment";
import { Services } from "../../Services";
import { HelperServiceService } from "../../services/helper-service.service";
declare var gtag;
declare const fbq;
@Component({
  selector: "app-report-shared-popup",
  templateUrl: "./report-shared-popup.component.html",
  styleUrls: ["./report-shared-popup.component.css"],
})
export class ReportSharedPopupComponent implements OnInit {
  appServices: Services;
  constructor(
    private bsModalRef: BsModalRef,
    private helperServiceService: HelperServiceService
  ) {
    this.appServices = new Services();
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(e: KeyboardEvent) {
    this.helperServiceService.downloadOptionRequest.next(0);
    this.bsModalRef.hide();
  }

  ngOnInit(): void {
    this.appServices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_success_report_sent_successfully",
      true
    );
  }

  closeModal() {
    this.helperServiceService.downloadOptionRequest.next(0);
    this.bsModalRef.hide();
  }

  onContactUs() {
    this.appServices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_success_report_sent_successfully_contact_us_whatsapp_clicked",
      true
    );
    window.open(environment.CLIENT_WHATSAPP_CONTACT, "_blank");
  }
}
