<!-- <div class="dynamic_wrapper"> -->
<!-- <div class="inner_dynamic_sec text-center"> -->
<!-- <div class="loading_div" *ngIf="!flag">
      <div class="img_section text-center position-relative">
        <div class="loader_img_sec">
          <img src="assets/images/loader.gif" />
        </div>
        <div class="analyzing_text"><p class="font_14">Analyzing...</p></div>
      </div>
      <div class="art_intelli_para_sec">
        <p class="please_wait_text">Please Wait</p>
        <p class="font_w_500">Validating your access token</p>
      </div>
    </div> -->
<!-- <div *ngIf="flag">
      <div class="smilo_img">
        <img
          src="assets/images/smilo_logo.png"
          alt="smilo"
          class="powered_by_con"
        />
      </div>
      <div class="pr-5 pl-5">
        <div class="title_container">
          <h4 class="font-weight-bold my-3">Design your smile with Smilo</h4>
        </div>
        <div class="content_container">
          <p class="mb-3">
            Now design your own beautiful smile rather than imagining it. Smilo,
            your virtual dental assistant is here at your convenience for all
            dental concerns
          </p>
        </div>
        <div class="video_sec position-relative">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/Gdh1pBTK7W4"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="mt-3 mb-2">
          <p class="text_color font_16 font_w_600">
            Get the untapped power of your smile
          </p>
        </div>
        <div class="button_section">
          <div class="text-center">
            <button class="btn button_links btn_width_1">GET STARTED</button>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div> -->
<!-- </div> -->
<!-- </div> -->
<div class="loader-section" *ngIf="!flag">
  <div class="loader_wrapper">
    <div class="loading_div">
      <div class="circle-chekmark analyze_loader">
        <div class="loding-circle">
          <div class="circle-chekmark-border"></div>
          <svg width="200" height="200" class="circle-svg">
            <circle
              fill="none"
              stroke="#0074C8"
              stroke-width="18"
              cx="100"
              cy="100"
              r="90"
              class="circle"
              stroke-linecap="round"
              transform="rotate(-90 100 100) "
            />
          </svg>
        </div>
        <div class="zoomin-circle_tick">
          <div class="circle-chekmark-dark"></div>
          <svg width="400" height="400" class="tick-svg">
            <polyline
              fill="none"
              stroke="#ffffff"
              stroke-width="50"
              points="88,214 173,284 304,138"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="tick"
            />
          </svg>
        </div>
      </div>
      <div class="art_intelli_para_sec">
        <p class="text-center">
          Please wait while we are validating your access token
        </p>
      </div>
    </div>
  </div>
</div>
