import { Component, OnInit } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { HttpClient } from '@angular/common/http';
// import { Router, ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Services } from '../helpers/Services';

@Component({
  selector: 'app-ohr-email-success',
  templateUrl: './ohr-email-success.component.html',
  styleUrls: ['./ohr-email-success.component.css']
})
export class OhrEmailSuccessComponent implements OnInit {
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  bannerTitleText: string | JSON;
  responsedata: any;
  // constructor(private formBuilder: FormBuilder, private httpClient: HttpClient, private router: Router) {
    constructor(private router: Router) {
    this.appservices = new Services;
  }
  ngOnInit(): void {
    this.bannerTitleText = this.appservices.getSession("bannerTitleText", false);
    // console.log(this.bannerTitleText)
  }
  onBackToTest() {
    this.router.navigate(['upload-smile-photo']);
  }
}