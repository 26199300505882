<div class="dynamic_wrapper">
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="dms_content">
            <div class="global-container">
                <div class="card login-form"  [ngClass]="signInForm" >
                <div class="card-body">
                    <!-- <img class="justify-content-center" class="img-fluid"  src="../../assets/images/smilo_logo.png">  -->
                    <h3 class="card-title text-center">Please enter your login details below</h3>                     
                    <div class="card-text">
                        <!--
                        <div class="alert alert-danger alert-dismissible fade show" role="alert">Incorrect username or password.</div> -->
                        <form autocomplete="off" [formGroup]="loginForm" class="contact_info_form" (ngSubmit)="onSubmit(loginForm,'login')">
                            <!-- to error: add class "has-danger" -->
                            <div class="form-group">
                                <label for="emailAddress">Email address</label>
                                <input type="email" formControlName="user_email" readonly class="form-control form-control-sm" id="user_email" aria-describedby="emailHelp">
                            </div>
                            <div class="form-group">
                                <label for="emailPassword">Password</label>
                                <!-- <a href="#" style="float:right;font-size:12px;">Forgot password?</a> -->
                                <input type="password" (keyup.enter)="onSubmit(loginForm,'login')" autofocus formControlName="user_password" class="form-control form-control-sm" maxlength="20" id="user_password">
                                <div class="err-msg" *ngIf="isSubmitted===true&&loginForm.get('user_password').hasError('required')">
                                    Please enter the password
                                </div>
                                <div class="err-msg" *ngIf="!loginForm.get('user_password').hasError('required')&&loginForm.get('user_password').hasError('minlength')">
                                    Password should be Min 6 characters
                                </div>
                                <div class="err-msg" *ngIf="!loginForm.get('user_password').hasError('required')&&loginForm.get('user_password').hasError('maxlength')">
                                    Password should be Max 20 characters
                                </div>                     
                            </div>
                            <div class="form-group">
                                <a href="javascript:;" class="forgot-passowrd-text" (click)="show_forgot_password_form()">Forgot Password?</a>
                            </div>
                            <div [ngClass]="showErrMsg" class="text-center err-msg">
                                {{errMsg}}
                            </div>
                            <button type="submit" [disabled]="submitBtnDisabled" class="btn button_links btn-primary btn-block">{{btnText}}</button>
                            <button (click)="backClicked()" class="btn go_back_btn btn-block">Go Back</button>
                            
                            <!-- <div class="sign-up">
                                Don't have an account? <a href="#">Create One</a>
                            </div> -->
                        </form>
                    </div>
                </div>
            </div>


            <div class="card forgot-password-form" [ngClass]="displayForgotPasswordForm">
                <div class="card-body">
                    <!-- <img class="justify-content-center" class="img-fluid"  src="../../assets/images/smilo_logo.png">  -->
                    <h3 class="card-title text-center">Reset Password</h3>         
                    <div class="card-text">
                        <!--
                        <div class="alert alert-danger alert-dismissible fade show" role="alert">Incorrect username or password.</div> -->
                        <form autocomplete="off" [formGroup]="forgotPasswordForm" class="contact_info_form" (ngSubmit)="onSubmit(forgotPasswordForm,'forgot_passowrd')">
                            <!-- to error: add class "has-danger" -->
                            <div class="form-group">
                                <label for="emailAddress">Email address</label>
                                <input type="email" formControlName="user_email" readonly class="form-control form-control-sm" id="user_email" aria-describedby="emailHelp">
                            </div>
                            <div class="form-group">
                                <a href="javascript:;" (click) = "show_login_form()" >Back to Login</a>
                            </div> 
                            <div [ngClass]="showErrMsg" class="text-center err-msg">
                                {{reset_password_errMsg}}
                            </div>
                            <div [ngClass]="showsuccessMsg" class="text-center success-msg">
                                {{reset_password_successMsg}}
                            </div>
                            <button type="submit" [disabled]="forgotPasswordBtnDisabled" class="btn button_links btn-primary btn-block">{{forgotPasswordBtntext}}</button>
                            <!-- <div class="sign-up">
                                Don't have an account? <a href="#">Create One</a>
                            </div> -->
                        </form>
                    </div>
                </div>
            </div>


            </div>
        </div>
    </div>
</div>            