import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
// import { Router, ActivatedRoute } from '@angular/router';
import { Router} from '@angular/router';
import { Services } from '../helpers/Services';

@Component({
  selector: 'app-email-success',
  templateUrl: './email-success.component.html',
  styleUrls: ['./email-success.component.css']
})
export class EmailSuccessComponent implements OnInit {
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  bannerTitleText: any = 'Design My Smile';
  responsedata: any;
  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient, private router: Router) {
    this.appservices = new Services;
  }
  ngOnInit(): void {
    // this.bannerTitleText = this.appservices.getSession("bannerTitleText", false);
    // console.log(this.bannerTitleText)    
    this.appservices.setSession("bannerTitleText", this.bannerTitleText, false);
  }
  onBackToTest() {
    this.router.navigate(['upload-smile-photo']);
  }
}
