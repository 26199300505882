import { HttpClient } from "@angular/common/http";
import { Component, OnInit, HostListener } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { HelperServiceService } from "../../services/helper-service.service";
import { Services } from "../../Services";
import { Router } from "@angular/router";
declare var gtag;
declare const fbq;
@Component({
  selector: "app-download-report-options",
  templateUrl: "./download-report-options.component.html",
  styleUrls: ["./download-report-options.component.css"],
})
export class DownloadReportOptionsComponent implements OnInit {
  appServices: Services;
  option: number = 1;
  oral_unique_id: any = "";
  user_details: any;
  errorMessage: string = "";
  isInProcess: boolean = false;
  constructor(
    private bsModalRef: BsModalRef,
    private helperServiceService: HelperServiceService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.appServices = new Services();
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(e: KeyboardEvent) {
    this.helperServiceService.downloadOptionRequest.next(0);
    this.bsModalRef.hide();
  }

  ngOnInit(): void {
    let data = localStorage.getItem("Input_form_data");
    if (data) {
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
    }
  }

  closeModal() {
    this.helperServiceService.downloadOptionRequest.next(0);
    this.bsModalRef.hide();
  }

  onOptionConfirm() {
    this.errorMessage = "";
    this.isInProcess = true;
    this.appServices.setSession("selected_download_option", this.option, true);
    if (this.user_details) {
      this.errorMessage = "";
      if (this.option === 1) {
        this.appServices.sendAnalyticsData(
          gtag,
          fbq,
          "smilo_event",
          "sa_wg_payment_success_download_report_option_email_clicked",
          true
        );
        let paramArray: any[] = [];
        let paramsJson: any;
        paramArray["oral_unique_id"] = this.appServices.getSession(
          "ohr_unique_key",
          false
        );
        paramArray["practice_id"] = this.appServices.getSession(
          "practice_id",
          false
        );
        paramArray["email"] = this.user_details.email;
        paramArray["first_name"] = this.user_details.firstName;
        paramArray["last_name"] = this.user_details.lastName;

        paramsJson = this.appServices.generteJSONFromArray(paramArray);
        console.log(
          "🚀 ~ file: otp-verification-popup.component.ts:88 ~ OtpVerificationPopupComponent ~ onOtpSubmit ~ paramsJson:",
          paramsJson
        );
        this.appServices
          .callJSONAPI(
            this.appServices.SEND_EMAIL_OHR_REPORT,
            paramsJson,
            "post",
            this,
            ""
          )
          .subscribe((data: any) => {
            if (data.status) {
              this.isInProcess = false;
              this.closeModal();
              setTimeout(() => {
                this.helperServiceService.downloadOptionRequest.next(
                  this.option
                );
              }, 500);
            } else {
              this.errorMessage = "Something went wrong. Please try later!";
            }
          });
      } else {
        if (this.option === 2) {
          this.appServices.sendAnalyticsData(
            gtag,
            fbq,
            "smilo_event",
            "sa_wg_payment_success_download_report_option_sms_clicked",
            true
          );
        } else {
          this.appServices.sendAnalyticsData(
            gtag,
            fbq,
            "smilo_event",
            "sa_wg_payment_success_download_report_option_whatsapp_clicked",
            true
          );
        }
        this.isInProcess = false;
        this.closeModal();
        setTimeout(() => {
          this.helperServiceService.downloadOptionRequest.next(this.option);
        }, 500);
      }
    } else {
      this.errorMessage = "User data not found";
    }
  }
}
