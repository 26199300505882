import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { GetStartedComponent } from "./get-started/get-started.component";
import { ContactInformationComponent } from "./contact-information/contact-information.component";
import { EmailConfirmationComponent } from "./email-confirmation/email-confirmation.component";
import { OralHygieneComponent } from "./oral-hygiene/oral-hygiene.component";
import { OralHealthScorePreferenceComponent } from "./oral-health-score-preference/oral-health-score-preference.component";
import { UploadTeethPhotosComponent } from "./upload-teeth-photos/upload-teeth-photos.component";
import { ViewReportComponent } from "./view-report/view-report.component";
import { AnalyzingYourImagesComponent } from "./analyzing-your-images/analyzing-your-images.component";
import { OralHealthScoreComponent } from "./oral-health-score/oral-health-score.component";
import { SmileDesignPreferenceComponent } from "./smile-design-preference/smile-design-preference.component";
import { UploadSmilePhotoComponent } from "./upload-smile-photo/upload-smile-photo.component";
import { SelectGumColorComponent } from "./select-gum-color/select-gum-color.component";
import { SmileBeforeAfterPictureComponent } from "./smile-before-after-picture/smile-before-after-picture.component";
import { InvalidAccessComponent } from "./invalid-access/invalid-access.component";
import { EmailSuccessComponent } from "./email-success/email-success.component";
import { ProcessOhrImagesComponent } from "./process-ohr-images/process-ohr-images.component";
import { MedicalQuestionsComponent } from "./medical-questions/medical-questions.component";
import { SignoutComponent } from "./signout/signout.component";
import { OhrEmailSuccessComponent } from "./ohr-email-success/ohr-email-success.component";
import { PracticeImageComponent } from "./practice-images/practice-images.component";

import { PatientImageUploadedComponent } from "./patient-image-uploaded/patient-image-uploaded.component";

import { PaymentSuccessfulComponent } from "./payment-successful/payment-successful.component";

import { PaymentAccessReportComponent } from "./payment-access-report/payment-access-report.component";

import { PaymentOralHealthScoreComponent } from "./payment-oral-health-score/payment-oral-health-score.component";
import { PaymentOralHealthScoreCompletedComponent } from "./payment-oral-health-score-completed/payment-oral-health-score-completed.component";

import { MakeStripePaymentComponent } from "./make-stripe-payment/make-stripe-payment.component";

import { UserProfileComponent } from "./user-profile/user-profile.component";

import { LoginComponent } from "./login/login.component";

import { PurposeOfVisitComponent } from "./purpose-of-visit/purpose-of-visit.component";
import { SelectServiceComponent } from "./select-service/select-service.component";
import { NoInternetComponent } from "./no-internet/no-internet.component";
import { GettingStartPageWidgetComponent } from "./getting-start-page-widget/getting-start-page-widget.component";
import { ConsentAgreementComponent } from "./consent-agreement/consent-agreement.component";
import { InputUserContactComponent } from "./input-user-contact/input-user-contact.component";

const routes: Routes = [
  {
    path: "",
    component: GetStartedComponent,
    pathMatch: "full",
    data: { title: "Get Started" },
  },
  {
    path: "get-started/:token",
    component: GetStartedComponent,
    data: { title: "Get Started" },
  },
  {
    path: "get-started",
    component: GetStartedComponent,
    data: { title: "Get Started" },
  },
  {
    path: "getting-started",
    component: GettingStartPageWidgetComponent,
    data: { title: "Getting Started Page Widget" },
  },
  {
    path: "consent-agreement",
    component: ConsentAgreementComponent,
    data: { title: "Consent and Agreement Page Widget" },
  },
  {
    path: "invalid-access",
    component: InvalidAccessComponent,
    data: { title: "Invalid Access::Invalid Access" },
  },
  {
    path: "contact-information",
    component: ContactInformationComponent,
    data: { title: "Contact details::Contact details" },
  },
  {
    path: "email-confirmation",
    component: EmailConfirmationComponent,
    data: { title: "Email Confirmation::Email Confirmation" },
  },
  {
    path: "oral-hygiene",
    component: OralHygieneComponent,
    data: { title: "OHR - Questionnaire::OHR - Questionnaire" },
  },
  {
    path: "oral-health-score-preference",
    component: OralHealthScorePreferenceComponent,
    data: { title: "Select AI option::Select AI option" },
  },
  {
    path: "upload-teeth-photos",
    component: UploadTeethPhotosComponent,
    data: { title: "Upload your teeth images::OHR - Upload teeth images" },
  },
  {
    path: "get-user-details",
    component: InputUserContactComponent,
    data: { title: "Get user details" },
  },
  {
    path: "product-discount",
    loadChildren: () =>
      import("./product-discount/product-discount.module").then(
        (m) => m.ProductDiscountModule
      ),
  },
  {
    path: "oral-health-score",
    component: OralHealthScoreComponent,
    data: { title: "OHR Rating::OHR - Traffic Light Rating" },
  },
  {
    path: "smile-design-preference",
    component: SmileDesignPreferenceComponent,
    data: { title: "Select your preference::DMS - Customize inputs" },
  },
  {
    path: "upload-smile-photo",
    component: UploadSmilePhotoComponent,
    data: { title: "Design My Smile::DMS - Upload image" },
  },
  {
    path: "select-gum-color",
    component: SelectGumColorComponent,
    data: { title: "Select your preference::DMS - Customize inputs" },
  },
  {
    path: "smile-before-after",
    component: SmileBeforeAfterPictureComponent,
    data: { title: "Before & After Your Photo::DMS - Before and After" },
  },
  {
    path: "email-success",
    component: EmailSuccessComponent,
    data: { title: "DMS Email Success::DMS - Report emailed" },
  },
  {
    path: "ohr-email-success",
    component: OhrEmailSuccessComponent,
    data: { title: "OHR Email Success::OHR - Report emailed" },
  },
  {
    path: "process-ohr-images",
    component: ProcessOhrImagesComponent,
    data: { title: "OHR Results::OHR - AI analysis of input images" },
  },
  // { path: 'medical-questions', component: MedicalQuestionsComponent,data : {title: 'Free Consultation::Start page'}},
  // { path: 'select-service', component: MedicalQuestionsComponent,data : {title: 'Free Consultation::Start page'}},
  {
    path: "practice-images",
    component: PracticeImageComponent,
    data: { title: "Patient Image::Patient Images" },
  },
  {
    path: "patient-image-uploaded",
    component: PatientImageUploadedComponent,
    data: { title: "Patient Image Uploaded::Patient Image Uploaded" },
  },
  {
    path: "payment-successful",
    component: PaymentSuccessfulComponent,
    data: { title: "Payment Successful::Payment Successful" },
  },

  {
    path: "payment-access-report",
    component: PaymentAccessReportComponent,
    data: { title: "Payment Access Report::OHR - RAG paywall" },
  },
  {
    path: "payment-process",
    loadChildren: () =>
      import("./payment-process/payment-process.module").then(
        (m) => m.PaymentProcessModule
      ),
  },

  {
    path: "payment-oral-health-score",
    component: PaymentOralHealthScoreComponent,
    data: { title: "Payment Oral Health Score::OHR - RAG page" },
  },
  {
    path: "payment-oral-health-score-completed",
    component: PaymentOralHealthScoreCompletedComponent,
    data: {
      title: "Payment Oral Health Score Completed::OHR - Payment completed",
    },
  },

  {
    path: "make-stripe-payment",
    component: MakeStripePaymentComponent,
    data: { title: "Make Stripe Payment::Make stripe payment" },
  },

  {
    path: "user-profile",
    component: UserProfileComponent,
    data: { title: "user-profile::user-profile" },
  },

  {
    path: "login",
    component: LoginComponent,
    data: { title: "login::Start page" },
  },

  {
    path: "purpose-of-visit",
    component: PurposeOfVisitComponent,
    data: { title: "Select purpose of visit::Select purpose of visit" },
  },

  {
    path: "select-service",
    component: SelectServiceComponent,
    data: { title: "Select service::Get Started" },
  },

  {
    path: "no-internet",
    component: NoInternetComponent,
    data: { title: "No Internet::No Internet" },
  },

  {
    path: "signout",
    component: SignoutComponent,
    data: { title: "Sign out::Sign out" },
  },
  {
    path: 'dental-appointment',
    loadChildren: () =>
      import('./dental-appointment/dental-appointment.module').then((m) => m.DentalAppointmentModule),
    data: { title: "Dental Appointment::Dental Appointment" }
  },
  {
    path: "**",
    redirectTo: "",
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [BrowserModule, RouterModule],
})
export class AppRoutingModule { }
