<div class="section_wrapper pt-0 position-relative" *ngIf="!showLoadingForm">
  <div class="section-banner_wrapper position-relative">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <a href="javascript:void(0)" class="back-btn" (click)="onBack()">
            <i class="sh-chevron-left"></i> <span>back</span>
          </a>
          <div class="wrapper-container">
            <div
              class="banner_wrap d-flex align-items-center justify-content-between justify-content-lg-center"
            >
              <div class="banner-content">
                <h1>
                  Uploaded<br />
                  Successfully
                </h1>
                <p class="mb-0 d-block d-sm-none">
                  Your smile pictures have<br />
                  been successfully uploaded.
                </p>
              </div>
              <div class="banner-image">
                <img
                  src="assets/images/upload-successfully.png"
                  class="img-fluid d-none d-sm-block"
                  alt=""
                />
                <img
                  src="assets/images/upload-successfully-mobile.png"
                  class="img-fluid d-block d-sm-none"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="wrapper-container">
          <div class="dynamic_wrapper2">
            <div class="vs-messages"></div>
            <div class="dms_wrapper4">
              <!-- <app-back-to-home></app-back-to-home> -->
              <div class="dms_content2">
                <div class="showContactForm">
                  <form
                    autocomplete="off"
                    [formGroup]="InputForm"
                    class="contact_info_form"
                    (ngSubmit)="onContactFormSubmit()"
                  >
                    <h3 class="sub_head text-lg-center">Enter Your Details</h3>
                    <p class="text-lg-center">
                      Please enter your details so we can<br />
                      share your personalized report with you
                    </p>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group position-relative min_height">
                          <label class="mb-2">First Name*</label>
                          <input
                            (keyup)="onEnterData($event)"
                            type="text"
                            formControlName="firstName"
                            maxlength="31"
                            class="first_name form-control"
                            (keyup)="nameLength('first_name')"
                            placeholder="First Name"
                            [ngClass]="{
                              'is-invalid': isSubmitted && ci.firstName.errors
                            }"
                            trim="blur"
                            value="{{ firstName | titlecase }}"
                            appInputRestriction
                          />
                          <div
                            *ngIf="isSubmitted && ci.firstName.errors"
                            class="invalid-feedback"
                          >
                            <div
                              *ngIf="ci.firstName.errors.required"
                              class="text-left"
                            >
                              First Name is required
                            </div>
                            <div
                              *ngIf="ci.firstName.errors.minlength"
                              class="text-left"
                            >
                              First Name should contain at least 3 characters.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group position-relative min_height">
                          <label class="mb-2">Last Name*</label>
                          <input
                            (keyup)="onEnterData($event)"
                            type="text"
                            formControlName="lastName"
                            maxlength="31"
                            class="last_name form-control"
                            (keyup)="nameLength('last_name')"
                            placeholder="Last Name"
                            [ngClass]="{
                              'is-invalid': isSubmitted && ci.lastName.errors
                            }"
                            trim="blur"
                            value="{{ lastName | titlecase }}"
                            appInputRestriction
                          />
                          <div
                            *ngIf="isSubmitted && ci.lastName.errors"
                            class="invalid-feedback"
                          >
                            <div
                              *ngIf="ci.lastName.errors.required"
                              class="text-left"
                            >
                              Last Name is required
                            </div>
                            <div
                              *ngIf="ci.lastName.errors.minlength"
                              class="text-left"
                            >
                              Last Name should contain at least 3 characters.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group position-relative">
                          <label class="mb-2">Email Address*</label>
                          <input
                            (keyup)="onEnterData($event)"
                            maxlength="50"
                            type="email"
                            formControlName="email"
                            class="form-control name_holder"
                            placeholder="Email Address"
                            [ngClass]="{
                              'is-invalid': isSubmitted && ci.email.errors
                            }"
                            trim="blur"
                          />
                          <div
                            *ngIf="isSubmitted && ci.email.errors"
                            class="invalid-feedback"
                          >
                            <div
                              *ngIf="ci.email.errors.required"
                              class="text-left"
                            >
                              Email Address is required
                            </div>
                            <div
                              *ngIf="ci.email.errors.pattern"
                              class="text-left"
                            >
                              Email Address is invalid.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div
                          class="form-group mobile-number-group position-relative"
                        >
                          <label class="mb-2">Mobile Number*</label>
                          <input
                            (keyup)="onEnterData($event)"
                            type="tel"
                            (keydown.space)="$event.preventDefault()"
                            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            formControlName="phoneNumber"
                            maxlength="15"
                            (countryChange)="countryChange($event)"
                            (intlTelInputObject)="telInputObject($event)"
                            placeholder="Mobile Number"
                            ng2TelInput
                            class="phone-format form-control"
                            id="phoneNumber"
                            [ngClass]="{
                              'is-invalid': isSubmitted && ci.phoneNumber.errors
                            }"
                            trim="blur"
                          />
                          <button
                            *ngIf="isOtpVerificationProcessExist"
                            type="button"
                            class="btn btn-primary btn-icon-left"
                            (click)="openModalVerifyOtp(otpVerificationPopup)"
                            [ngClass]="isOtpSuccess ? 'btn-verified' : ''"
                          >
                            <i class="sh-tick-circle"></i>
                            {{ isOtpSuccess ? "Verified" : "Verify Mobile" }}
                          </button>
                          <!-- Display when button is Verified -->
                          <!-- <button
                    type="button"
                    class="btn btn-primary btn-icon-left btn-verified"
                    (click)="openModalVerifyOtp(otpVerificationPopup)"
                  >
                    <i class="sh-tick-circle"></i>
                    Verified
                  </button> -->
                          <div
                            *ngIf="isSubmitted && ci.phoneNumber.errors"
                            class="invalid-feedback"
                          >
                            <div
                              *ngIf="ci.phoneNumber.errors.required"
                              class="text-left"
                            >
                              Mobile Number is required
                            </div>
                            <div
                              *ngIf="ci.phoneNumber.errors.pattern"
                              class="text-left"
                            >
                              Please enter 10 digit Mobile Number.
                            </div>
                          </div>
                          <div
                            *ngIf="
                              isSubmitted &&
                              isOtpVerificationProcessExist &&
                              !isOtpSuccess &&
                              !ci.phoneNumber.errors &&
                              !isOtpReceive
                            "
                            class="invalid-feedback"
                          >
                            <div class="text-left">
                              Please verify your Mobile Number.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                        *ngIf="display_branches_div"
                        class="form-group position-relative"
                      >
                        <span class="practice-location-span">
                          Dental Practice near to your location
                        </span>
                        <select
                          name="userSelectedPractice"
                          id="userSelectedPractice"
                          class="form-control"
                          (change)="onSelectPractice($event)"
                        >
                          <option value="">Please select any branch</option>
                          <option value="" [selected]="true">
                            Global Branch
                          </option>
                          <option
                            *ngFor="let method of practiceBranches"
                            [attr.data-isocode]="method.address"
                            [value]="method.id"
                            [selected]="method.id == preSelectedBranch"
                          >
                            {{ method.name }}
                          </option>
                        </select>
                      </div> -->
                    <div
                      class="action-button form-button-container d-flex justify-content-center flex_start"
                    >
                      <div
                        class="pt-sm-5 mt-sm-3 w-100 d-flex justify-content-center"
                      >
                        <button
                          [disabled]="contactFormBtn"
                          type="submit"
                          class="btn btn-primary btn-rounded-corner mx-0"
                        >
                          <span class="save_continue_btn_span">Submit</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="showLoadingForm">
  <div class="img_section text-center position-relative">
    <div class="analyzing_text">
      <div class="title_sec mt-4">
        <h4 class="thank_you_head"><b>Thank you!</b></h4>
      </div>
    </div>
    <div class="loader_img_sec">
      <img src="assets/images/loader.gif" />
    </div>
    <div class="analyzing_text mb-4">
      <p>{{ analyzingText }}</p>
    </div>
  </div>
</div> -->
<div class="loader-section" *ngIf="showLoadingForm">
  <div class="loader_wrapper">
    <div class="loading_div">
      <div class="circle-chekmark analyze_loader upload-image_loader">
        <div class="loding-circle" *ngIf="!isDataLoaded">
          <div class="circle-chekmark-border"></div>
          <svg width="200" height="200" class="circle-svg">
            <circle
              fill="none"
              stroke="#f5821e"
              stroke-width="18"
              cx="100"
              cy="100"
              r="90"
              class="circle"
              stroke-linecap="round"
              transform="rotate(-90 100 100) "
            />
          </svg>
        </div>
        <div class="loading-arrow" *ngIf="!isDataLoaded">
          <i class="sh-arrow-up"></i>
        </div>
        <div class="zoomout-circle_tick" *ngIf="isDataLoaded">
          <div class="circle-chekmark-dark"></div>
          <svg width="400" height="400" class="tick-svg">
            <polyline
              fill="none"
              stroke="#ffffff"
              stroke-width="50"
              points="88,214 173,284 304,138"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="tick"
            />
          </svg>
        </div>
      </div>
      <div class="art_intelli_para_sec">
        <p class="text-center">
          {{ !isDataLoaded ? analyzingText : successText }}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Start OTP Verificaiton Modal -->
<ng-template #otpVerificationPopup>
  <div class="modal-body">
    <div class="model_section p-3">
      <div class="cancel_button_sec text-right">
        <button type="button" class="close close_button" (click)="closeModal()">
          <span class="cancel">×</span>
        </button>
      </div>
      <div class="otp-verification pt-2">
        <h3 class="text-center mt-4">OTP Verification</h3>
        <span class="mo-num"
          >We have send the OTP on your +{{ dialCode }} {{ phoneNumber }}</span
        >
        <form class="otp-form" autocomplete="off">
          <div class="otp-form-group d-flex justify-content-center">
            <div class="form-group mb-0">
              <input
                type="text"
                (keydown.space)="$event.preventDefault()"
                (keydown)="onKeyOtp($event, 0)"
                (keyup)="setFocus(one.value, 0)"
                maxlength="1"
                class="form-control"
                name="otpNum0"
                [value]="digitOne"
                #one
              />
            </div>
            <div class="form-group mb-0">
              <input
                type="text"
                (keydown.space)="$event.preventDefault()"
                (keydown)="onKeyOtp($event, 1)"
                (keyup)="setFocus(two.value, 1)"
                maxlength="1"
                class="form-control"
                name="otpNum1"
                [value]="digitTwo"
                #two
              />
            </div>
            <div class="form-group mb-0">
              <input
                type="text"
                (keydown.space)="$event.preventDefault()"
                (keydown)="onKeyOtp($event, 2)"
                (keyup)="setFocus(three.value, 2)"
                maxlength="1"
                class="form-control"
                name="otpNum2"
                [value]="digitThree"
                #three
              />
            </div>
            <div class="form-group mb-0">
              <input
                type="text"
                (keydown.space)="$event.preventDefault()"
                (keydown)="onKeyOtp($event, 3)"
                (keyup)="setFocus(four.value, 3)"
                maxlength="1"
                class="form-control"
                name="otpNum3"
                [value]="digitFour"
                #four
              />
            </div>
          </div>

          <div class="d-flex flex-column align-items-center">
            <p>{{ timer | formatTime }}</p>
            <p *ngIf="isTimerOver">
              Didn't received OTP Code?
              <a (click)="onResendCode()" href="javascript:void(0)"
                >Resend Code</a
              >
            </p>
            <span class="text-danger" *ngIf="isInValidOtp"
              >Please enter valid OTP</span
            >
          </div>
          <div
            class="button-group d-flex align-items-center justify-content-center"
          >
            <button
              type="button"
              class="btn btn-outline btn-rounded-corner"
              (click)="closeModal('otp')"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary btn-rounded-corner"
              (click)="onVerifyOtp()"
            >
              Verify OTP
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-- End OTP Verificaiton Modal -->
