import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductDiscountCardComponent } from '../helpers/common-components/product-discount-card/product-discount-card.component';
import { AppointmentCardComponent } from '../helpers/common-components/appointment-card/appointment-card.component';
import { BuyReportCardComponent } from '../helpers/common-components/buy-report-card/buy-report-card.component';



@NgModule({
  declarations: [
    ProductDiscountCardComponent,
    AppointmentCardComponent,
    BuyReportCardComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ProductDiscountCardComponent,
    AppointmentCardComponent,
    BuyReportCardComponent,
    CommonModule
  ]
})
export class SharedModule { }
