import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HelperServiceService {
  downloadOptionRequest = new BehaviorSubject(0);

  constructor() {}
}
