<div class="dynamic_wrapper">
    <div class="vs-messages"></div>
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="dms_content blue_backgound">
            <div class="payment_success_div">
                <div class="payment_success_section position-relative white_backgound">
                    <div class="analyzing_text">
                        <div class="title_sec  text-center mt-4">
                            <h2 class="thank_you_head"><b>Payment Successful</b></h2>
                        </div>
                        <!-- <p>This is the first step you are taking towards a healthy mouth and life.</p> -->
                    </div>
                    <div class="loader_img_sec">
                        <!-- <img src="assets/images/loader.gif" />  -->
                        <h3 class="text-center"> Hey {{first_name}} {{last_name}}! </h3>
                        <h4 class="text-center mt-2"> Thank you for joining with Smilo.ai </h4>
                        <p class="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    </div>
                    <div class="analyzing_text mt-5 mb-4">
                        <button class="btn button_links btn_width_2 mr-3 mb-3" (click)="redirectOhrImages()" >View My OHR Report</button>
                    </div>
                </div>
            </div>
            <div class="sec_inner_content1 p-0">
               
            </div>
        </div>
    </div>
</div>
