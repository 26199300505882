import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { Services } from "../../Services";

@Component({
  selector: "app-buy-report-card",
  templateUrl: "./buy-report-card.component.html",
  styleUrls: ["./buy-report-card.component.css"],
})
export class BuyReportCardComponent implements OnInit {
  appServices: Services;
  paymentAmount: any = "";
  isExpand: boolean = false;
  imageSelectionCount: any = "5";
  @Output() onBuyReportClick: EventEmitter<void> = new EventEmitter();
  constructor(private router: Router) {
    this.appServices = new Services();
  }

  ngOnInit(): void {
    this.imageSelectionCount = this.appServices.getSession(
      "images_selection_count",
      false
    );
    this.paymentAmount = this.appServices.getSession("payment_amount", false);
  }

  onGetNow() {
    this.onBuyReportClick.emit();
    this.router.navigate(["payment-process"]);
  }
}
