<div class="modal-header border-bottom-0">
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="closeModal()"
  >
    <i class="sh-cancel"></i>
  </button>
</div>

<!-- Download Report Modal -->
<div class="modal-body">
  <div class="report-modal-wrapper">
    <div class="report-modal-header text-center">
      <h2 class="mb-1">Download Your Report</h2>
      <p>
        Select how you would like to get a copy of your<br />
        report and we'll do the rest
      </p>
    </div>
    <div class="tile tile-small">
      <div class="tile-header mb-2">
        <h3 class="d-flex align-items-center">
          <i class="sh-folder-download"></i> Download Options
        </h3>
      </div>
      <div class="tile-body mt-5">
        <div class="download-options">
          <form>
            <div
              class="radio-button-group termsAndConditionsDiv d-flex flex-column"
            >
              <div class="form-check">
                <input
                  class="form-check-input form-control"
                  type="radio"
                  id="flexRadioDefault1"
                  name="flexRadioDefault"
                  [(ngModel)]="option"
                  [value]="1"
                />
                <label
                  class="form-check-label d-flex align-items-center"
                  for="flexRadioDefault1"
                >
                  <i class="sh-mail"></i>
                  <span>Email ID</span>
                </label>
              </div>
              <!-- <div class="form-check">
                <input
                  class="form-check-input form-control"
                  type="radio"
                  id="flexRadioDefault2"
                  name="flexRadioDefault"
                  [(ngModel)]="option"
                  [value]="2"
                />
                <label
                  class="form-check-label d-flex align-items-center"
                  for="flexRadioDefault2"
                >
                  <i class="sh-phone"></i>
                  <span>Phone Number</span>
                </label>
              </div> -->
              <div class="form-check">
                <input
                  class="form-check-input form-control"
                  type="radio"
                  id="flexRadioDefault3"
                  name="flexRadioDefault"
                  [(ngModel)]="option"
                  [value]="3"
                />
                <label
                  class="form-check-label d-flex align-items-center"
                  for="flexRadioDefault3"
                >
                  <i class="sh-whatsapp"></i>
                  <span>WhatsApp</span>
                </label>
              </div>
            </div>
            <div class="error-text">{{ errorMessage }}</div>
            <div class="button-group text-center mt-2 mt-sm-3">
              <button
                type="submit"
                class="btn btn-primary btn-rounded-corner btn-lg"
                [disabled]="isInProcess"
                (click)="onOptionConfirm()"
              >
                {{ isInProcess ? "Please wait" : "Confirm" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
