<ng-container *ngIf="domain=='get.smilo.ai'">
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.google.com/url?q=https://www.googletagmanager.com/ns.html?id%3DGTM-WZQ5GPT&source=gmail-imap&ust=1660775474000000&usg=AOvVaw1mm2RKsHrJZ3Ea1vaJhEmH"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->
</ng-container>

<div class="width alert alert-danger text-center my-2" *ngIf="noInternetConnection">You are currently offline. Please check your internet settings.</div>
<router-outlet (activate)="onActivate($event)"></router-outlet>

<!-- <span  class="text-body text-center"></span> -->

<!-- <div *ngIf="noInternetConnection" class="no_internet alert alert-danger alert-dismissible mx-3 px-0">
    <h6>
        <span class="text-body text-center">Oops something went wrong. Please retry!!</span>
    </h6>
</div> -->