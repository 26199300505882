<div>
  <div class="next-card-header">
    <div
      class="next-card-top d-flex align-items-center justify-content-between"
    >
      <div class="card-content">
        <h4>Get A Complete Oral Health Check</h4>
        <!-- <h4>Request A Dental Appointment</h4> -->
        <p>
          Get an instant personalized oral health check for
          {{
            isDaburFlow ? "dental cavities" : "dental decay/tooth sensitivity"
          }}
          and {{ isDaburFlow ? "gum disease" : "gum problems" }} right from your
          smartphone.
        </p>
        <!-- <p>Secure your next appointment with ease & enhance your smile.</p> -->
        <button
          class="btn btn-primary btn-rounded-corner btn-icon-right btn-xs mt-2"
          (click)="OnFiveImageFlow()"
        >
          Get Started <i class="icon_arrow icon-right-arrow"></i>
        </button>
      </div>
      <div class="card-image">
        <img
          src="assets/images/product-discount.png"
          class="img-fluid d-none d-sm-block"
          alt=""
        />
        <img
          src="assets/images/product-discount-mobile.png"
          class="img-fluid d-block d-sm-none"
          alt=""
        />
      </div>
    </div>
    <div class="next-card-bottom">
      <a
        href="javascript:void(0)"
        class="d-flex align-items-center justify-content-center"
        (click)="isExpand = !isExpand"
        ><b class="mr-1">Learn More</b> About Getting A Oral Health Check
        <i
          class="ml-2"
          [ngClass]="!isExpand ? 'sh-angle-down' : 'sh-angle-up'"
        ></i
      ></a>
    </div>
  </div>
  <div class="next-card-body" *ngIf="isExpand">
    <h4>All Benefits</h4>
    <div class="card-detail-list d-flex flex-column">
      <div
        class="card-detail-item d-flex align-items-center justify-content-between"
      >
        <div class="content">
          <h5 class="mb-2">Your Teeth Checked in Just 3 Minutes</h5>
          <p>
            Get a quick online dental check from your smartphone by uploading a
            few mouth selfies.
          </p>
        </div>
        <div class="icon d-flex align-items-center justify-content-center">
          <i class="sh-calendar"></i>
        </div>
      </div>
      <div
        class="card-detail-item d-flex align-items-center justify-content-between"
      >
        <div class="content">
          <h5 class="mb-2">Ultimate Convenience</h5>
          <p>Available wherever you are anywhere anytime no waiting times.</p>
        </div>
        <div class="icon d-flex align-items-center justify-content-center">
          <i class="sh-badge-discount"></i>
        </div>
      </div>
      <div
        class="card-detail-item d-flex align-items-center justify-content-between"
      >
        <div class="content">
          <h5 class="mb-2">
            Fast and Pain Free Way to Look After Your Oral Health
          </h5>
          <p>
            No sharp needles or instruments. No travel costs or professional
            fees.
          </p>
        </div>
        <div class="icon d-flex align-items-center justify-content-center">
          <i class="sh-shopping-basket-heart"></i>
        </div>
      </div>
    </div>
    <button
      class="btn btn-primary btn-rounded-corner btn-icon-right btn-lg mx-auto mt-3"
      (click)="OnFiveImageFlow()"
    >
      Start Your Dental Check <i class="icon_arrow icon-right-arrow"></i>
    </button>
  </div>
</div>
