<div class="dynamic_wrapper">
    <div class="vs-messages"></div>
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="dms_content">

            <div class="loading_div analysing_sec" *ngIf="!flag">
                <div class="img_section text-center position-relative">
                    <div class="loader_img_sec mt-4">
                        <img src="assets/images/loader.gif" />
                    </div>
                    <div class="analyzing_text mb-4">
                        <p class="thank_you_head">Please Wait OHR Calculation is in progress</p>
                    </div>
                </div>
            </div>

            <div *ngIf="flag" class="sec_inner_content1 p-0">
                <div class="title_sec bg-grey max_width_800 br_10">
                    <div class="custom_header_content">
                        <div class="text-center max_width_550">
                            <h3 class="sub_head mb-0" *ngIf="payment_type==''">THANK YOU</h3>
                            <h3 class="sub_head mb-0" *ngIf="payment_type!=''">Payment Confirmed!</h3>
                            <p class="mt-2 font_18">Here is your detailed report. A copy of this report has also been sent to your registered email.</p>
                            <!-- <p class="color_grey mt-2 font_14" *ngIf="payment_type!=''">Your payment is successful. Here is your detail report results. We have sent your report to registered email address<a href="mailto:{{user_email}}">({{user_email}})</a> To view report, please use your login details which Smilo.ai sent while you access widget first time. </p>
                            <p class="color_grey mt-2 font_14" *ngIf="payment_type==''">Here is your detail report results. We have sent your report to registered email address<a href="mailto:{{user_email}}">({{user_email}})</a> To view report, please use your login details which Smilo.ai sent while you access widget first time.</p> -->
                            <!-- <p class="color_grey mt-4 font_14"> Calculated on {{created_date}}</p> -->
                        </div>
                    </div>
                    <div class="teeth_div">
                        <ngx-slick-carousel class="teeth_carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)">
                            <div ngxSlickItem *ngFor="let data of process_images;" class="slide">
                                <p class="text-center teeth_carousel_title font_14 my-2"> </p>
                                <!-- <p class="text-center teeth_carousel_title font_14 my-2"><b>{{getTitle(data.title,data.output_image)}}</b></p> -->
                                <div class="teeth_img teeth_hover pointer_link mt-3">
                                    <img *ngIf="data.output_image" [defaultImage]="defaultImage" [lazyLoad]="data.output_image" class="score_one" [errorImage]="defaultImage">
                                    <img *ngIf="!data.output_image" [defaultImage]="defaultImage" [lazyLoad]="data.image" class="score_one" [errorImage]="defaultImage">
                                </div>
                            </div>
                        </ngx-slick-carousel>
                        <div class="d-flex align-items-center justify-content-center height_50 mt-3">
                            <div class="d-flex align-items-center justify-content-center mr-xl-4 mr-sm-4">
                                <span class="font_responsive font_14">Dental decay</span>
                                <span class="blue_color custom_box"></span>
                            </div>
                            <div class="d-flex align-items-center justify-content-center">
                                <span class="font_responsive font_14">Gum disease</span>
                                <span class="yellow_color custom_box"></span>
                            </div>
                        </div>
                    </div>
                    <p class="mt-2 text-center font_14">Scroll through your photos or tap 'Start Again' to continue.</p>

                    <div *ngIf="displaySettings; else dontShowCTA">

                        <div class="d-flex justify-content-between flex_direction continue_btn_div px_20">
                            <a href="{{button_url}}" class="btn button_links btn_width_2 continue_btn btn_color mx-0 mt-4 position-relative ctabtn" target="_blank"><span class="text-center">{{button_text}}</span></a>
                            <button type="submit" [disabled]="startAgainBtn" (click)="onSubmit(is_images_invalid)" class="btn button_links btn_width_2 continue_btn btn_color mx-0 mt-4 position-relative">{{startAgainBtntext}}<span class="position-absolute icon-right-arrow ml-3 font_14"></span></button>
                        </div>
                    </div>
                    <ng-template #dontShowCTA>
                        <div class="d-flex justify-content-end continue_btn_div px_20">
                            <button type="submit" [disabled]="startAgainBtn" (click)="onSubmit(is_images_invalid)" class="btn button_links btn_width_2 continue_btn btn_color mx-0 mt-4 position-relative">{{startAgainBtntext}}<span class="position-absolute icon-right-arrow ml-3 font_14"></span></button>
                        </div>
                    </ng-template>
                </div>
            </div>


            <div *ngIf="flag&&redirectToDMSDiv" class="sec_inner_content1 p-0 mt-3">
                <div class="max_width_800">
                    <div class="custom_footer_content">
                        <div class="text-center">

                            <div class="row">
                                <div class="col-sm-12 col-md-2 col-lg-2">
                                    <img height="100" class="dms_img" width="auto" src="assets/images/med_2.png" />
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <p class="mt-2 dms-text"><b>Interested in a new smile?</b></p> <br>
                                    <p class="mt-2 dms-text">Click a selfie and design your own smile in seconds.</p> 
                                    <p class="dms-text">Get your before and after instantly!</p> 
                                </div>
                                <div class="col-sm-12 col-md-4 col-lg-4">
                                    <button type="submit"  (click)="redirectToDMS()" class="btn button_links btn_width_2 continue_btn btn_color mx-0 mt-4 position-relative"> Get Started </button>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>        

        </div>
    </div>
</div>