import { environment } from "src/environments/environment";

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Services } from "../helpers/Services";
declare var gtag;
declare const fbq;

@Component({
  selector: "app-consent-agreement",
  templateUrl: "./consent-agreement.component.html",
  styleUrls: ["./consent-agreement.component.css"],
})
export class ConsentAgreementComponent implements OnInit {
  appServices: Services;
  ENVIRONMENT = environment;
  checkBoxValue: boolean = true;
  isDaburFlow: boolean = false;
  constructor(private router: Router) {
    this.appServices = new Services();
  }

  ngOnInit(): void {
    this.isDaburFlow =
      this.appServices.getSession("isDaburFlow", false) == "true";
    console.log(
      "🚀 ~ ConsentAgreementComponent ~ ngOnInit ~ this.isDaburFlow:",
      this.isDaburFlow
    );
    this.appServices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_consent_page_opened",
      true
    );
    this.appServices.sendPageViewAnalyticsData(
      gtag,
      "",
      this.router.url,
      "OHR – Consent and agreement page"
    );
  }

  onClickContinue() {
    this.appServices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_consent_page_continue_clicked",
      true
    );
    this.router.navigate(["upload-teeth-photos"]);
  }
  onBack() {
    this.appServices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_consent_page_back_clicked",
      true
    );
    this.router.navigate(["./purpose-of-visit"]);
  }
  isCheckboxChecked(event: any) {
    this.checkBoxValue = false;
    if (event === "on") {
      this.checkBoxValue = true;
    }
  }
  goToLink(url: string, option: number) {
    if (option === 1) {
      this.appServices.sendAnalyticsData(
        gtag,
        fbq,
        "smilo_event",
        "sa_wg_consent_clicked",
        true
      );
    }
    if (option === 2) {
      this.appServices.sendAnalyticsData(
        gtag,
        fbq,
        "smilo_event",
        "sa_wg_terms_conditions_clicked",
        true
      );
    }
    if (option === 3) {
      this.appServices.sendAnalyticsData(
        gtag,
        fbq,
        "smilo_event",
        "sa_wg_privacy_clicked",
        true
      );
    }
    if (option === 4) {
      this.appServices.sendAnalyticsData(
        gtag,
        fbq,
        "smilo_event",
        "sa_wg_disclaimer_clicked",
        true
      );
    }
    window.open(url, "_blank");
    return false;
  }
}
