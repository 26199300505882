import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Services } from '../helpers/Services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConnectionService } from 'ng-connection-service';
import * as $ from 'jquery';

import { environment } from '../../environments/environment';

import { Subscription } from 'rxjs';

import {MessageService} from '../profile-pic-service.service'
 
declare var gtag


@Component({
  selector: 'app-back-to-home',
  templateUrl: './back-to-home.component.html',
  styleUrls: ['./back-to-home.component.css']
})
export class BackToHomeComponent implements OnInit {
  @Input() settingsData:any;
  appservices: Services;
  modalRef: BsModalRef;
  isConnected = true;
  noInternetConnection: boolean = false;
  url: any;
  bannerTitleText: any;
  displayIcons: boolean = true;

  displayHeader: any = '';

  displaySettings:boolean = false;
  cta_type:any = '';
  button_text:any = '';
  button_url:any = '';

  uploadImageBtnSelected:any = 'false';
  redirectLink:any = '';
  user_details:any = '';

  user_name: any = '';
  user_email: any = '';
  profile_image:any = 'assets/images/default-profile.png';

  subscription: Subscription;
  messages: any[] = [];

  isUserLoggedIn:any = 'false';

  bypass_select_service:any ;
  widget_service_type:any ;

  displayHomeIcon:boolean = true;

  get_contact_information_later:any = '';
  move_home_btn:boolean = true; 

  display_home_profile_icon:boolean = true;

  constructor(private router: Router, private modalService: BsModalService, private connectionService: ConnectionService,private messageService: MessageService) {
    this.appservices = new Services;

    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message) {
        // console.log(" Msg Recieved : " + message.backgroundProcessing);
        // this.messages.push(message);
        if(!this.appservices.isEmpty(message.backgroundProcessing))
        {
          this.move_home_btn = true;
          if(message.backgroundProcessing==true)
          {
            this.move_home_btn = false;
          }
        }
        else 
        {
          this.profile_image = message.text;
          this.user_name  = message.first_name+" "+message.last_name;
          if(!this.appservices.isEmpty(message.email))
          this.user_email = message.email;
        }
      } else {
        // clear messages when empty message received
        // this.messages = [];
      }
    });

  }
  

  onBackToHome(move_home) {
    // console.log(" move_home " + move_home );
    if(!move_home) return false;
    this.bypass_select_service = this.appservices.getSession("bypass_select_service",false);
    this.widget_service_type   = this.appservices.getSession("widget_button_type", false);
    // console.log( "  this.bypass_select_service : " + this.bypass_select_service + "  this.widget_service_type : " + this.widget_service_type);
    if( (this.widget_service_type!="2")&&(this.bypass_select_service!='0')) 
    {
      if(this.widget_service_type=="0")
      {
        this.appservices.setSession("uploadImageBtnSelected",false ,false);  
        this.router.navigate(['oral-health-score-preference']);
      }
      else if(this.widget_service_type=="1")
      {
        this.appservices.setSession("uploadImageBtnSelected",true ,false); 
        this.router.navigate(['contact-information']);
      }
    }
    else 
    {
      (this.widget_service_type=="2")
      this.router.navigate(['get-started/'+this.appservices.getSession("token", false)]);
    }    
  }

  redirectToHome(move_home)
  {
    // console.log(" move_home " + move_home );
    if(!move_home) return false;
    // console.log(" redirectToHome : ");
    this.router.navigate([this.redirectLink]);
  }

  onBack() {
    let url = this.router.url;
    if (url.search('/upload-smile-photo') >= 0 || url.search('/upload-teeth-photos') >= 0) {
      this.router.navigate(['oral-health-score-preference']);
    }
    else if (url.search('/select-gum-color') >= 0) {
      this.router.navigate(['upload-smile-photo']);
    }
    else if (url.search('/smile-before-after') >= 0) {
      this.router.navigate(['select-gum-color']);
    }
    else if (url.search('/email-success') >= 0) {
      this.router.navigate(['smile-before-after']);
    }
    else if (url.search('/process-ohr-images') >= 0) {
      this.router.navigate(['upload-teeth-photos']);
    }
    else if (url.search('/oral-hygiene') >= 0) {
      this.router.navigate(['process-ohr-images']);
    }
    else if (url.search('/oral-health-score') >= 0) {
      this.router.navigate(['oral-hygiene']);
    }
    else if (url.search('/ohr-email-success') >= 0) {
      this.router.navigate(['oral-health-score']);
    }
  }

  user_profile_view()
  {
    if(this.get_contact_information_later=='yes') return false;
    // console.log(" user_profile_view clicked ");
    this.isUserLoggedIn = this.appservices.getSession("isUserLoggedIn", false);
    // console.log(" this.isUserLoggedIn ");
    if(this.isUserLoggedIn==='true')
    {
      this.router.navigate(['user-profile']);
    }
    else 
    {
      this.router.navigate(['login']);
    }
    
  }

  ngOnInit(): void {
    // console.log("Back to home");
    window.scrollTo(0,0);
    $(window).scrollTop(0);
    this.displayHeader = this.appservices.getSession("bannerTitleText", false);
    // console.log(this.displayHeader)

    this.cta_type = this.appservices.getSession("cta_type",false);
    this.button_text = this.appservices.getSession("button_text",false);
    this.button_url = this.appservices.getSession("button_url",false);

    this.url = this.router.url;

    this.get_contact_information_later =  this.appservices.getSession("get_contact_information_later",false);

    // if (JSON.parse(localStorage.getItem('Input_form_data')) == null) {

    // console.log(" this.url.search('/no-internet')  : " + this.url.search('/no-internet') );

    if(this.url.search('/no-internet') < 0)
    {
      if(this.get_contact_information_later=='yes'&&((localStorage.getItem('Input_form_data')) == null || (localStorage.getItem('Input_form_data')) == '' ))
      {
        this.user_name  = 'Guest User';       
      }
      else 
      {
        // console.log(" localStorage.getItem('Input_form_data')) : " + localStorage.getItem('Input_form_data') );
        if ( (localStorage.getItem('Input_form_data')) == null || (localStorage.getItem('Input_form_data')) == '' ) {         
          this.router.navigate(['get-started/'+this.appservices.getSession("token", false)]);
        }
        else 
        {
          this.user_details = JSON.parse(localStorage.getItem('Input_form_data'));

          
          this.user_name  = this.appservices.getSession("first_name",false)+" "+this.appservices.getSession("last_name",false);
          this.user_email = this.appservices.getSession("email",false);
          if(this.appservices.getSession("profile_picture",false)!='')
          {
            this.profile_image = this.appservices.getSession("profile_picture",false);
          }  
        }
      }
    }

    if((this.cta_type!=undefined&&this.cta_type!='')&&(this.button_text!=undefined&&this.button_text!='')&&(this.button_url!=undefined&&this.button_url!=''))
    {
      this.displaySettings = true;
    }

    if (this.url.search('/practice-images') >= 0)
    { 
      this.uploadImageBtnSelected = this.appservices.getSession("uploadImageBtnSelected", false);      
     // console.log(" this.uploadImageBtnSelected : " + this.uploadImageBtnSelected);

     // console.log(" this.token : " + this.appservices.getSession("token", false));
      if(this.uploadImageBtnSelected=='true')
      {        
        // this.redirectLink = 'get-started/'+this.appservices.getSession("token", false);
        this.redirectLink = 'contact-information';
      }
      
    }
    if (this.url.search('/contact-information') >= 0 || this.url.search('/email-confirmation') >= 0 || this.url.search('/medical-questions') >= 0) {
      this.displayIcons = false;
    }

    if (this.url.search('/oral-health-score-preference') >= 0 )
    {
      this.displayHomeIcon = false;
    }

    if (this.url.search('/upload-teeth-photos') >= 0 || this.url.search('/payment-access-report') >= 0 || this.url.search('/make-stripe-payment') >= 0  || this.url.search('/process-ohr-images') >= 0 || this.url.search('/oral-hygiene') >= 0 || this.url.search('/no-internet') >= 0   )
    {
      this.display_home_profile_icon = false;
    }

    
    
  }

}
