import { HelperServiceService } from "./../../helpers/services/helper-service.service";
import { DownloadReportOptionsComponent } from "./../../helpers/common-components/download-report-options/download-report-options.component";
import { ViewProcessedReportComponent } from "./../../helpers/common-components/view-processed-report/view-processed-report.component";
import { Component, OnInit } from "@angular/core";
import { Services } from "src/app/helpers/Services";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { HttpClient } from "@angular/common/http";
import { ReportSharedPopupComponent } from "src/app/helpers/common-components/report-shared-popup/report-shared-popup.component";
import { Subscription } from "rxjs";
import { OtpVerificationPopupComponent } from "src/app/helpers/common-components/otp-verification-popup/otp-verification-popup.component";
declare var gtag;
declare const fbq;
@Component({
  selector: "app-payment-success",
  templateUrl: "./payment-success.component.html",
  styleUrls: ["./payment-success.component.css"],
})
export class PaymentSuccessComponent implements OnInit {
  report_id: any = "";
  practice_id: any = "";
  user_id: any = "";
  report_type: any = " ";
  payment_amount: any = "";
  apirequest: any = [];
  widget_flow_type: any = "";
  isProductDiscountCard: boolean = false;
  isRequestForAppointmentCard: boolean = false;
  isPaymentSuccessful: boolean = false;
  paymentId: any = "";
  appServices: Services;
  slideConfig = {
    swipe: false,
    swipeToSlide: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow:
      "<div onclick=\"Window?.myComponent?.checkingImageExist('right',this)\" class='nav-btn icon-right-arrow next-slide blue_color font_30'></div>",
    prevArrow:
      "<div onclick=\"Window?.myComponent?.checkingImageExist('left',this)\" class='nav-btn icon-left-arrow prev-slide blue_color font_30'></div>",
    infinite: false,
  };
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    keyboard: false,
    class: "modal-lg",
  };
  inputFormData: {} = {};
  config2 = {
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    keyboard: false,
    class: "modal-md",
  };
  modalRef: BsModalRef;
  session_id: any = "";
  get_contact_information_later: any = "";
  guest_user_id: any = "";
  showLoadingForm: boolean = true;
  isDataLoaded: boolean = false;
  analyzingText: any = "Please wait";
  successText: string = "Successfully Done";
  imageSelectionCount: any = "5";
  private _subscriptions = new Subscription();
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private helperServiceService: HelperServiceService,
    private httpClient: HttpClient,
    private route: ActivatedRoute
  ) {
    this.appServices = new Services();
  }

  ngOnInit(): void {
    this.imageSelectionCount = this.appServices.getSession(
      "images_selection_count",
      false
    );
    this.report_id = this.appServices.getSession("ohr_unique_key", false);
    this.widget_flow_type = this.appServices.getSession(
      "widget_flow_type",
      false
    );
    this.practice_id = this.appServices.getSession("practice_id", false);
    this.get_contact_information_later = this.appServices.getSession(
      "get_contact_information_later",
      false
    );
    this.guest_user_id = this.appServices.getSession("guest_user_id", false);
    this.isRequestForAppointmentCard =
      this.appServices.getSession("is_request_for_appointment", false) ===
      "true";
    this.isProductDiscountCard =
      this.appServices.getSession("is_request_for_coupan_code", false) ===
      "true";
    this.isPaymentSuccessful =
      this.appServices.getSession("payment_successful", false) === "true";
    if (
      this.appServices.isEmpty(this.widget_flow_type) ||
      this.appServices.isEmpty(this.report_id)
    ) {
      this.router.navigate([
        "get-started/" + this.appServices.getSession("token", false),
      ]);
    }
    this.route.queryParams.subscribe((params) => {
      this.session_id = params.payment_transaction_id;

      console.log(
        "🚀 ~ file: payment-success.component.ts:78 ~ PaymentSuccessComponent ~ this.route.queryParams.subscribe ~ this.session_id:",
        this.session_id
      );
      if (!this.session_id) {
        this.router.navigate([
          "get-started/" + this.appServices.getSession("token", false),
        ]);
      }
      if (!this.appServices.isEmpty(this.session_id)) {
        // this.callStripeDetails();
        this.appServices.setSession("session_id", this.session_id, false);
        this.callCCAvenueDetails();
      }
    });
    this._subscriptions.add(
      this.helperServiceService.downloadOptionRequest.subscribe(
        (downloadOptionResponse) => {
          if (
            downloadOptionResponse &&
            (downloadOptionResponse === 1 || downloadOptionResponse === 4)
          ) {
            const bsModalRef = this.modalService.show(
              ReportSharedPopupComponent,
              Object.assign(this.config2, {
                class:
                  "modal-dialog-centered report-modal report-shared modal-md",
              })
            );
          }
          if (
            downloadOptionResponse &&
            (downloadOptionResponse === 2 || downloadOptionResponse === 3)
          ) {
            this.appServices.sendAnalyticsData(
              gtag,
              fbq,
              "smilo_event",
              "sa_wg_payment_success_page_otp_requested",
              true
            );
            const bsModalRef = this.modalService.show(
              OtpVerificationPopupComponent,
              Object.assign(this.config2, {
                class:
                  "modal-dialog-centered report-modal report-shared otp-modal modal-md",
              })
            );
          }
        }
      )
    );

    this.appServices.setSession(
      "current_url",
      "payment-process/payment-success",
      false
    );
    this.appServices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_success_page_opened",
      true
    );
    this.appServices.sendPageViewAnalyticsData(
      gtag,
      "",
      this.router.url,
      "Payment Process – Payment Success Page Opened"
    );
  }

  callStripeDetails() {
    this.apirequest = [];
    this.apirequest["payment_amount"] = this.appServices.getSession(
      "payment_amount",
      false
    );
    this.apirequest["report_id"] = this.report_id;
    this.apirequest["report_type"] = "ohr";
    this.apirequest["selected_branch_id"] = this.appServices.getSession(
      "selected_branch_id",
      false
    );
    this.apirequest["widget_token"] = this.appServices.getSession(
      "token",
      false
    );
    this.apirequest["session_id"] = this.session_id;
    this.apirequest["get_contact_information_later"] =
      this.get_contact_information_later;
    this.apirequest["guest_user_id"] = this.guest_user_id;
    let report_input = this.appServices.generteJSONFromArray(this.apirequest);
    this.appServices
      .callJSONAPI(
        this.appServices.GET_STRIPE_PAYMENT_DETAILS,
        report_input,
        "post",
        this,
        ""
      )
      .subscribe((data: any) => {
        if (data.status_code != "1" && data.status_code != "3") {
          this.appServices.sendAnalyticsData(
            gtag,
            fbq,
            "smilo_event",
            "sa_wg_payment_fail",
            true
          );
          this.router.navigate([
            "get-started/" + this.appServices.getSession("token", false),
          ]);
        } else {
          this.appServices.setSession("payment_successful", "true", false);
          if (
            this.get_contact_information_later == "yes" &&
            !this.appServices.isEmpty(data.result.user_id)
          ) {
            let full_name = "";
            this.appServices.sendAnalyticsData(
              gtag,
              fbq,
              "smilo_event",
              "sa_wg_payment_success",
              true
            );
            if (!this.appServices.isEmpty(data.result.user_access_token)) {
              this.appServices.setSession(
                "user_access_token",
                data.result.user_access_token,
                false
              );
              this.appServices.setSession("is_logged_in", "true", false);
              this.appServices.setSession("isUserLoggedIn", "false", false);
              if (!this.appServices.isEmpty(data.result.first_name)) {
                this.appServices.setSession(
                  "first_name",
                  data.result.first_name,
                  false
                );
              }

              // console.log(" data.result.last_name : " + data.result.last_name);
              if (!this.appServices.isEmpty(data.result.last_name)) {
                this.appServices.setSession(
                  "last_name",
                  data.result.last_name,
                  false
                );
                full_name = data.result.first_name + data.result.last_name;
                this.appServices.setSession("full_name", full_name, false);
              }
              // console.log(" data.result.email : " + data.result.email);
              if (!this.appServices.isEmpty(data.result.email)) {
                this.appServices.setSession("email", data.result.email, false);
              }
              // console.log(" data.result.user_id : " + data.result.user_id);
              if (!this.appServices.isEmpty(data.result.user_id)) {
                this.appServices.setSession(
                  "user_id",
                  data.result.user_id,
                  false
                );
              }
              if (!this.appServices.isEmpty(data.result.phone)) {
                this.appServices.setSession(
                  "phoneNumber",
                  data.result.phone,
                  false
                );
              }
              if (!this.appServices.isEmpty(data.result.dial_code)) {
                this.appServices.setSession(
                  "dial_code",
                  data.result.dial_code,
                  false
                );
              }
              if (!this.appServices.isEmpty(data.result.profile_picture)) {
                this.appServices.setSession(
                  "profile_picture",
                  data.result.profile_picture,
                  false
                );
              }
              if (!this.appServices.isEmpty(data.result.city)) {
                this.appServices.setSession("city", data.result.city, false);
              }
              if (!this.appServices.isEmpty(data.result.state_id)) {
                this.appServices.setSession(
                  "state_id",
                  data.result.state_id,
                  false
                );
              }
              if (!this.appServices.isEmpty(data.result.country_id)) {
                this.appServices.setSession(
                  "country_id",
                  data.result.country_id,
                  false
                );
              }
              // console.log(" data.result.user.dial_country_code : " + data.result.user.dial_country_code);
              if (!this.appServices.isEmpty(data.result.dial_country_code)) {
                this.appServices.setSession(
                  "country_name",
                  data.result.dial_country_code,
                  false
                );
              }

              this.inputFormData = {
                firstName: data.result.first_name,
                lastName: data.result.last_name,
                email: data.result.email,
                phoneNumber: data.result.phone,
              };
              localStorage.setItem(
                "Input_form_data",
                JSON.stringify(this.inputFormData)
              );
            }
          } else {
          }
        }
      });
  }

  slickInit(e: any) {}

  openModalViewReport() {
    this.appServices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_success_view_report_popup_opened",
      true
    );
    const bsModalRef = this.modalService.show(
      ViewProcessedReportComponent,
      Object.assign(this.config, {
        class: "modal-dialog-centered report-modal modal-lg",
      })
    );
  }

  openModalDownloadReport() {
    this.appServices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_success_download_report_popup_opened",
      true
    );
    const bsModalRef = this.modalService.show(
      DownloadReportOptionsComponent,
      Object.assign(this.config, {
        class: "modal-dialog-centered report-modal modal-lg",
      })
    );
  }

  onBack() {
    this.router.navigate(["./payment-access-report"]);
  }

  onBookNow() {
    this.appServices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_success_dental_appointment_book_open",
      true
    );
  }

  onProductDiscountClick() {
    this.appServices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_success_product_discount_open",
      true
    );
  }

  callCCAvenueDetails() {
    this.apirequest = [];
    let report_input: any = "";
    this.apirequest["practice_id"] = this.practice_id;
    this.apirequest["report_id"] = this.report_id;
    this.apirequest["payment_transactions_id"] = this.session_id;
    report_input = this.appServices.generteJSONFromArray(this.apirequest);
    console.log(
      "🚀 ~ file: payment-success.component.ts:404 ~ PaymentSuccessComponent ~ callCCAvenueDetails ~ report_input:",
      report_input
    );
    this.appServices
      .callJSONAPI(
        this.appServices.CHECK_CCAVENUE_PAYMENT,
        report_input,
        "post",
        this,
        ""
      )
      .subscribe((data: any) => {
        console.log(
          "🚀 ~ file: payment-success.component.ts:414 ~ PaymentSuccessComponent ~ .subscribe ~ data:",
          data
        );
        if (data.status) {
          this.isDataLoaded = true;
          this.appServices.setSession("payment_successful", "true", false);
          this.appServices.sendAnalyticsData(
            gtag,
            fbq,
            "smilo_event",
            "sa_wg_payment_success",
            true
          );
          setTimeout(() => {
            this.showLoadingForm = false;
          }, 2000);
        } else {
          this.router.navigate([
            "get-started/" + this.appServices.getSession("token", false),
          ]);
        }
        // if (data.status_code != "1" && data.status_code != "3") {
        //   this.appServices.sendAnalyticsData(
        //     gtag,
        //     fbq,
        //     "smilo_event",
        //     "sa_wg_payment_fail",
        //     true
        //   );
        //   this.router.navigate([
        //     "get-started/" + this.appServices.getSession("token", false),
        //   ]);
        // } else {
        //   this.appServices.setSession("payment_successful", "true", false);
        //   if (
        //     this.get_contact_information_later == "yes" &&
        //     !this.appServices.isEmpty(data.result.user_id)
        //   ) {
        //     let full_name = "";
        //     this.appServices.sendAnalyticsData(
        //       gtag,
        //       fbq,
        //       "smilo_event",
        //       "sa_wg_payment_success",
        //       true
        //     );
        //     if (!this.appServices.isEmpty(data.result.user_access_token)) {
        //       this.appServices.setSession(
        //         "user_access_token",
        //         data.result.user_access_token,
        //         false
        //       );
        //       this.appServices.setSession("is_logged_in", "true", false);
        //       this.appServices.setSession("isUserLoggedIn", "false", false);
        //       if (!this.appServices.isEmpty(data.result.first_name)) {
        //         this.appServices.setSession(
        //           "first_name",
        //           data.result.first_name,
        //           false
        //         );
        //       }

        //       // console.log(" data.result.last_name : " + data.result.last_name);
        //       if (!this.appServices.isEmpty(data.result.last_name)) {
        //         this.appServices.setSession(
        //           "last_name",
        //           data.result.last_name,
        //           false
        //         );
        //         full_name = data.result.first_name + data.result.last_name;
        //         this.appServices.setSession("full_name", full_name, false);
        //       }
        //       // console.log(" data.result.email : " + data.result.email);
        //       if (!this.appServices.isEmpty(data.result.email)) {
        //         this.appServices.setSession("email", data.result.email, false);
        //       }
        //       // console.log(" data.result.user_id : " + data.result.user_id);
        //       if (!this.appServices.isEmpty(data.result.user_id)) {
        //         this.appServices.setSession(
        //           "user_id",
        //           data.result.user_id,
        //           false
        //         );
        //       }
        //       if (!this.appServices.isEmpty(data.result.phone)) {
        //         this.appServices.setSession(
        //           "phoneNumber",
        //           data.result.phone,
        //           false
        //         );
        //       }
        //       if (!this.appServices.isEmpty(data.result.dial_code)) {
        //         this.appServices.setSession(
        //           "dial_code",
        //           data.result.dial_code,
        //           false
        //         );
        //       }
        //       if (!this.appServices.isEmpty(data.result.profile_picture)) {
        //         this.appServices.setSession(
        //           "profile_picture",
        //           data.result.profile_picture,
        //           false
        //         );
        //       }
        //       if (!this.appServices.isEmpty(data.result.city)) {
        //         this.appServices.setSession("city", data.result.city, false);
        //       }
        //       if (!this.appServices.isEmpty(data.result.state_id)) {
        //         this.appServices.setSession(
        //           "state_id",
        //           data.result.state_id,
        //           false
        //         );
        //       }
        //       if (!this.appServices.isEmpty(data.result.country_id)) {
        //         this.appServices.setSession(
        //           "country_id",
        //           data.result.country_id,
        //           false
        //         );
        //       }
        //       // console.log(" data.result.user.dial_country_code : " + data.result.user.dial_country_code);
        //       if (!this.appServices.isEmpty(data.result.dial_country_code)) {
        //         this.appServices.setSession(
        //           "country_name",
        //           data.result.dial_country_code,
        //           false
        //         );
        //       }

        //       this.inputFormData = {
        //         firstName: data.result.first_name,
        //         lastName: data.result.last_name,
        //         email: data.result.email,
        //         phoneNumber: data.result.phone,
        //       };
        //       localStorage.setItem(
        //         "Input_form_data",
        //         JSON.stringify(this.inputFormData)
        //       );
        //     }
        //   } else {
        //   }
        // }
      });
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
