<!-- <p (click)="onClickContinue()">consent-agreement works!</p> -->

<div class="section_wrapper select-reason_wrapper position-relative">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <a href="javascript:void(0)" class="back-btn" (click)="onBack()">
          <i class="sh-chevron-left"></i> <span>back</span>
        </a>
        <div class="wrapper-container">
          <div class="section-heading mb-lg-5 text-lg-center">
            <h1 class="mb-1">Consent & Agree</h1>
            <p>
              Your pictures are processed securely and confidentially. We value
              your privacy and protect your data.
            </p>
          </div>
          <div class="consent_wrap d-flex flex-wrap flex-lg-nowrap">
            <div
              class="consent-card position-relative d-flex flex-lg-column justify-content-between w-100"
            >
              <div
                class="card-detail d-flex flex-wrap align-items-center align-items-lg-start flex-lg-column w-100"
              >
                <i
                  class="sh-tooth align-self-end mr-2 mr-lg-0 mb-2 mb-lg-4"
                ></i>
                <h3 class="mb-2">
                  How does it<br />
                  work?
                </h3>
                <p class="mb-lg-2">
                  Your pictures are processed securely and confidentially so
                  that you can securely assess your Dental Health<br />
                  with AI Technology
                </p>
              </div>
            </div>
            <div
              class="consent-card position-relative d-flex flex-lg-column justify-content-between w-100"
            >
              <div
                class="card-detail d-flex flex-wrap align-items-center align-items-lg-start flex-lg-column w-100"
              >
                <i class="sh-lock align-self-end mr-2 mr-lg-0 mb-2 mb-lg-4"></i>
                <h3 class="mb-2">
                  Who can view<br />
                  my details?
                </h3>
                <p class="mb-lg-2">
                  We value your privacy and protect<br />
                  your data. Only you and Authorized Dental Professionals have
                  access to your data.
                </p>
              </div>
            </div>
            <div
              class="consent-card position-relative d-flex flex-lg-column justify-content-between w-100"
            >
              <div
                class="card-detail d-flex flex-wrap align-items-center align-items-lg-start flex-lg-column w-100"
              >
                <i
                  class="sh-shield-check align-self-end mr-2 mr-lg-0 mb-2 mb-lg-4"
                ></i>
                <h3 class="mb-2">
                  Is it<br />
                  secure?
                </h3>
                <p class="mb-lg-2">
                  Yes! We use advanced security measures to keep your
                  Information secure.
                </p>
              </div>
            </div>
          </div>
          <div class="action-button">
            <div class="mt-sm-4 termsAndConditionsDiv">
              <div class="center-block">
                <div
                  class="checkbox_rounded d-flex align-items-lg-center justify-content-center"
                >
                  <span class="position-relative">
                    <input
                      id="agree"
                      type="checkbox"
                      name="agree"
                      [checked]="checkBoxValue"
                      (change)="
                        isCheckboxChecked($event.target.checked ? 'on' : 'off')
                      "
                    />

                    <label class="checkbox_label" for="agree"></label>
                  </span>

                  <span class="check_text agree_text font_16 ml-2"
                    ><span
                      >By clicking "Continue," you confirm that you are over 18
                      years of age and agree to Smilo.ai’s </span
                    ><a
                      (click)="
                        goToLink(
                          isDaburFlow
                            ? ENVIRONMENT.DABUR_CONSENT_FORM
                            : ENVIRONMENT.CONSENT_FORM,
                          1
                        )
                      "
                      target="_blank"
                      [href]="
                        isDaburFlow
                          ? ENVIRONMENT.DABUR_CONSENT_FORM
                          : ENVIRONMENT.CONSENT_FORM
                      "
                      >Consent Form</a
                    >{{ isDaburFlow ? " and " : "," }}
                    <a
                      *ngIf="isDaburFlow"
                      [href]="ENVIRONMENT.DABUR_DISCLAIMER_PAGE"
                      (click)="goToLink(ENVIRONMENT.DABUR_DISCLAIMER_PAGE, 4)"
                      target="_blank"
                      >Disclaimer</a
                    >{{ isDaburFlow ? "." : "" }}

                    <a
                      *ngIf="!isDaburFlow"
                      [href]="
                        isDaburFlow
                          ? ENVIRONMENT.DABUR_TERMS
                          : ENVIRONMENT.TERMS
                      "
                      (click)="
                        goToLink(
                          isDaburFlow
                            ? ENVIRONMENT.DABUR_TERMS
                            : ENVIRONMENT.TERMS,
                          2
                        )
                      "
                      target="_blank"
                      >Terms and Conditions</a
                    >{{ isDaburFlow ? "" : ", and" }}

                    <a
                      *ngIf="!isDaburFlow"
                      [href]="
                        isDaburFlow
                          ? ENVIRONMENT.DABUR_PRIVACY
                          : ENVIRONMENT.PRIVACY
                      "
                      target="_blank"
                      (click)="
                        goToLink(
                          isDaburFlow
                            ? ENVIRONMENT.DABUR_PRIVACY
                            : ENVIRONMENT.PRIVACY,
                          3
                        )
                      "
                      >Privacy Policy</a
                    >{{ isDaburFlow ? "" : "." }}</span
                  >
                </div>

                <!-- <div
                    class="error font_14 checkbox_rounded d-flex align-items-center justify-content-center">

                    <div class="text-left termsandconditionstext font_16 mt-1 mb-1">

                      Please Accept Terms and Conditions

                    </div>

                  </div> -->
              </div>
            </div>
            <div class="pt-sm-5 mt-sm-3">
              <button
                type="submit"
                [disabled]="!checkBoxValue"
                class="btn btn-primary btn-rounded-corner btn-icon-right btn-lg mx-auto"
                [ngClass]="!checkBoxValue ? 'disabled' : ''"
                (click)="onClickContinue()"
              >
                <span class="save_continue_btn_span">Continue</span
                ><i class="icon_arrow icon-right-arrow"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
