<div class="dynamic_wrapper">
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="dms_content">
            <div class="text-center">
                <div class="top_process_section">
                    <div class="container_content_sec">
                        <div class="inner_container_content">
                            <div class="loader_img_sec">
                                <img src="assets/images/email_graphic.png" />
                            </div>
                            <div class="title_sec mb-4">
                                <h4 class="sub_head">We have sent the report to your email</h4>
                            </div>
                            <div class="explain_content mb-4">
                                <p class="text-center my-2 agree_text Nunito-Italic"><i><b>Note:</b> While we make every effort to continually update the accuracy of our Artificial Intelligence engine, you should always verify this report with a visit to a Registered Dentist. Also, the results of this Smilo.ai report rely on you taking accurate images as explained in the instructions available in this App, including ensuring that your images are not blurred, contain sufficient light and all your teeth and gums are fully visible across the images.</i></p>
                                <p class="Nunito-Bold">Click the button below to design your future smile</p>
                            </div>
                            <div class="mail_address_content">
                                <div class="text-center">
                                    <button type="submit" class="btn_width_1 btn_width btn button_links mr-1 coninue_btn btn_color" (click)="onBackToTest()">Try your future smile now</button>
                                </div>
                            </div>
                            <app-footer></app-footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>