import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
// import { Router, ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { Services } from "../helpers/Services";
import * as $ from "jquery";
// import { formatDate } from '@angular/common';
// import { DataExchange } from "aws-sdk/clients/all";
 
import { environment } from "src/environments/environment";

declare var gtag
declare const fbq;


@Component({
  selector: 'app-select-service',
  templateUrl: './select-service.component.html',
  styleUrls: ['./select-service.component.css']
})
export class SelectServiceComponent implements OnInit {
  static httpClient: any;
  appservices: Services;
  apirequest: any[] = [];
  responsedata: any;
  flag: boolean = false;
  InputForm: FormGroup;
  today = new Date();
  questions_array: any[] = [];
  questions_data: any = [];
  form: FormGroup;
  first_question_type: any;
  isRadioButtonChecked: boolean = false;
  defaultImage: string = "assets/images/loader_large.gif";
  // staticImage: string = "assets/images/homepage_background.png";
  staticImage1: string = "assets/images/toothache.png";
  staticImage2: string = "assets/images/cosmetic.png";
  staticImage3: string = "assets/images/ohr-new.png";
  staticImage: string = "assets/images/home-bg.png";
  // staticImage1: string = "assets/images/med_1_white.png";
  // staticImage2: string = "assets/images/med_2_white.png";
  // staticImage3: string = "assets/images/med_3_white.png";
  medicalQuestionSelectedOption:any = '';
  fb_pixel_id:any = '';
  gtm_id:any = '';
  button_text_details:any = '';

  continueBtnDisabled:boolean = true;

  widget_button_type:any = '';

  widget_benefit_one:any = '';
  widget_benefit_two:any = '';
  widget_benefit_three:any = '';
  widget_service_type:any ;
  bypass_select_service:any;

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private router: Router     
  ) {
    this.appservices = new Services();
  }
  ngOnInit(): void {
    // console.log(this.appservices.getSession("widget_button_type", false)); 
    this.bypass_select_service = this.appservices.getSession("bypass_select_service",false);
    this.widget_service_type   = this.appservices.getSession("widget_button_type", false);
    
    // console.log(" this.bypass_select_service : " + this.bypass_select_service + " this.widget_service_type " + this.widget_service_type);
    
    if( (this.widget_service_type!="2")&&(this.bypass_select_service!='0')) 
    {
      this.widget_service_type = this.appservices.getSession("widget_button_type", false);
      if(this.widget_service_type=="0")
      {
        this.appservices.setSession("uploadImageBtnSelected",false ,false);  
        this.router.navigate(['purpose-of-visit']);
      }
      else if(this.widget_service_type=="1")
      {
        this.appservices.setSession("uploadImageBtnSelected",true ,false); 
        this.router.navigate(['contact-information']);
      }
    }

    localStorage.setItem('Input_form_data', "");
    this.appservices.setSession("user_selected_practice_branch","", false);
    this.fb_pixel_id = this.appservices.getSession("fb_pixel_id", false);
    this.gtm_id      = this.appservices.getSession("gtm_id", false);
    this.button_text_details = this.appservices.getSession("button_text_details", false);
    if(this.appservices.isEmpty(this.button_text_details)){
      this.router.navigate(['oral-health-score-preference']);
    }else{
      this.button_text_details = JSON.parse(this.button_text_details);
      this.widget_button_type  = this.appservices.getSession("widget_button_type", false);
      // console.log(this.button_text_details[0]);
      var sessionData;
      var question_type;
      this.form = this.formBuilder.group({
        dateFull: new FormControl(this.today),
        time: null,
      });
      if (this.appservices.hasSession("questions_array")) {
        this.first_question_type = this.appservices.getSession("first_question_type", false);
        this.questions_data = this.appservices.getSession("questions_array", false);
        this.medicalQuestionSelectedOption = '';
        // this.medicalQuestionSelectedOption = this.appservices.getSession("medicalQuestionSelectedOption",false);
        sessionData = JSON.parse(this.questions_data);
        question_type = this.first_question_type;
      }
      $(document).on("keydown", "form", function (event) {
        return event.key != "Enter";
      });
      $('.sec_inner_content').hide();
      this.InputForm = this.formBuilder.group({
        questions: ["", [Validators.required]],
      });
      this.getQuestionsList();
      let that = this;
      /* setTimeout(function () {
        if (sessionData) {
         // console.log('#' + question_type)
          for (let i = 0; i < sessionData.length; i++) {
            let selector = '.oral_hygiene_btn_' + i + ' .' + sessionData[i].answer;
            i == 0 ? $('#' + question_type).prop('checked', true) : '';
            if (i == 0 && question_type == 'radio3') {
              $(".text_box").removeClass('visibility_hidden').show();
              $(".input_text_field").val(sessionData[i].answer);
            }
          }
        }
      }, 2000); */
  
      this.widget_benefit_one = this.appservices.getSession("widget_benefit_one", false);
      this.widget_benefit_two = this.appservices.getSession("widget_benefit_two", false);
      this.widget_benefit_three = this.appservices.getSession("widget_benefit_three", false);
    }
    
  }

  onCheckboxChange(e, type) {
    // console.log(e, type);
    // console.log(" $(e.target).closest('.medical-questions-options-div') " + $(e.target).closest('.medical-questions-options-div').html() );
    $(".medical-questions-options-div").removeClass('medical-questions-selected-option');
    $(e.target).closest('.medical-questions-options-div').addClass('medical-questions-selected-option');
    this.appservices.setSession("medicalQuestionSelectedOption",type ,false);
    this.medicalQuestionSelectedOption = type;    
    this.first_question_type = type;
    this.isRadioButtonChecked = true;
    //if (e.target.checked && type == "radio3") {
    /* if (type == "radio3") {
      // console.log(" radio 3 ");
      $(".text_box").removeClass('visibility_hidden').show();
      $(".other_reasons").addClass('custom');
      this.continueBtnDisabled = true;
    }
    else {
      // console.log(" radio 1,2");
      // $(".text_box").hide();
      // $(".input_text_field").val('');
      $(".text_box").addClass('visibility_hidden');
      $(".other_reasons").removeClass('custom');
      // this.isRadioButtonChecked = true;
      this.continueBtnDisabled = false;
    } */ 
    $(".text_box").addClass('visibility_hidden');
    $(".other_reasons").removeClass('custom');
    // this.isRadioButtonChecked = true;
    this.continueBtnDisabled = false;
  }
  getFirstQuestionAnswer(type) {
    // console.log(type, $(".input_text_field").val())
    if (type == "radio1") {
      return 'Tooth ache / Broken teeth';
    }
    else if (type == "radio2") {
      return 'Cosmetic Treatment';
    }
    else if (type == "radio3" && $(".input_text_field").val() != '') {
      // console.log($(".input_text_field").val())
      // return $(".input_text_field").val();
      return 'General Checkup';
    }
  }
  getQuestionsList() {
    this.appservices.callJSONAPI(this.appservices.MEDICAL_QUESTIONNAIRE + "?offset=0&limit=100", "", "get", this, "127.0.0.1").subscribe(
      (data: any) => {
        this.flag = true;
        if (data.status) {
          $('.sec_inner_content').show();
          this.responsedata = data.result.questions;
        } else {
        }
      },
      (error) => { }
    );
  }
  length(classname) {
    this.continueBtnDisabled = true;
    if ($("." + classname).val().length >= 5) {
      this.continueBtnDisabled = false;
    }
    if ($("." + classname).val().length > 100) {
      $("." + classname).val($("." + classname).val().substr(0, 100));
    }
  }
  saveQuestions() {
    this.getQuestionsArray();
    this.apirequest = [];
    this.apirequest["widget_token"] = this.appservices.getSession("token", false);
    this.apirequest["questions"] = this.questions_array;
    this.appservices.setSession("questions_array", this.questions_array, true);
    this.appservices.setSession("first_question_type", this.first_question_type, false);
    let input = this.appservices.generteJSONFromArray(this.apirequest);
    this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_select_visit',true);    
     
    // console.log(input);
    this.router.navigate(['contact-information']);
  }
  getQuestionsArray() {
    this.questions_array = [];
    this.responsedata.map((item, index) => {
      return {
        question_id: item.question_id,
        answer: index == 0 ? this.getFirstQuestionAnswer(this.first_question_type) : '',
      }
    }).forEach(item => this.questions_array.push(item));
  }
  onContinue() {
    // console.log(this.first_question_type, !this.getFirstQuestionAnswer(this.first_question_type))
    if (!this.getFirstQuestionAnswer(this.first_question_type)) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.appservices.serviceMessages('vs-messages', 'Please answer the question', 'danger');
    }
    else {
      $('.coninue_btn').html('Submitting...').attr('disabled', true);
      this.appservices.serviceMessages('vs-messages', '', '')
      this.saveQuestions();
    }
  }

  onAnswer(event, rowid, id, answer) {
    let rootElements = document.getElementsByClassName('oral_hygiene_btn_' + id)[0];
    let buttonElements = rootElements.getElementsByClassName("custom_btn");
    for (var i = 0; i < buttonElements.length; i++) {
      buttonElements[i].classList.remove("active");
    }
    let element = <HTMLInputElement>event.target;
    if (element.classList.contains("active"))
      element.classList.remove("active");
    else element.classList.add("active");


  }
  get oh() {
    return this.InputForm.controls;
  }
  onStart(uploadImages:boolean){    
    this.appservices.sendAnalyticsData(gtag, fbq, 'smilo_event', 'sa_wg_start_now',false);
    this.appservices.sendPageViewAnalyticsData(gtag, '', this.router.url, 'Purpose of visit');    
    
    if(uploadImages==false)
    {
      this.appservices.setSession("uploadImageBtnSelected",false ,false); 
      // $('.medical_questions').show();
      // $('.home_page').hide();
      this.router.navigate(['purpose-of-visit']);
    }
    if(uploadImages==true)
    {
      this.appservices.setSession("uploadImageBtnSelected",true ,false); 
      this.router.navigate(['contact-information']);
    }  
  }
}