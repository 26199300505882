import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from "@angular/core";
// import { ConnectionService } from 'ng-connection-service';
import { HttpClient } from "@angular/common/http";
import { Services } from "../helpers/Services";
import { LocationStrategy } from "@angular/common";
// import { Router, ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
// import { iif } from 'rxjs';
import { Utilities } from "../helpers/utilities";
import { environment } from "../../../src/environments/environment";

declare var Stripe: any;
declare var gtag;
declare const fbq;

@Component({
  selector: "app-make-stripe-payment",
  templateUrl: "./make-stripe-payment.component.html",
  styleUrls: ["./make-stripe-payment.component.css"],
  providers: [Services],
})
export class MakeStripePaymentComponent implements OnInit {
  stripe;
  cardElement;

  @ViewChild("paymentBtn") paymentBtn: ElementRef;

  paymentHandler: any = null;
  appservices: Services;
  apputilities: Utilities;
  static httpClient: any;
  widget_token: any = "";
  payment_amount: any = "";
  apirequest: any = [];
  api_key: any = "";
  user_email: any = "";
  user_details: any = "";
  submittedForm: boolean = false;
  selected_branch_id: any = "";
  report_id: any = "";
  paymentInProgress: boolean = true;
  errorMsg: string = "Error in Payment Please wait while we reconnect you";

  widget_flow_type: any = "";

  bannerTitleText: any = "Oral Health Rating";

  get_contact_information_later: any = "";
  guest_user_id: any = "";

  constructor(
    private httpClient: HttpClient,
    private location: LocationStrategy,
    private router: Router
  ) {
    this.appservices = new Services();
    // preventing back button in browser implemented by "Samba Siva"
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  async ngOnInit() {
    // console.log('sa_wg_payment_payment_loading');

    this.get_contact_information_later = this.appservices.getSession(
      "get_contact_information_later",
      false
    );
    this.guest_user_id = this.appservices.getSession("guest_user_id", false);
    this.appservices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_payment_payment_loading",
      true
    );
    this.appservices.setSession("bannerTitleText", this.bannerTitleText, false);
    this.widget_flow_type = this.appservices.getSession(
      "widget_flow_type",
      false
    );
    this.report_id = this.appservices.getSession("ohr_unique_key", false);
    // if(this.appservices.isEmpty(this.widget_flow_type)||this.appservices.isEmpty(this.report_id))
    // {

    //   this.router.navigate(['get-started/'+this.appservices.getSession("token", false)]);
    // }
    // else
    // {

    this.widget_token = this.appservices.getSession("token", false);
    this.payment_amount = this.appservices.getSession("payment_amount", false);
    this.api_key = this.appservices.getSession("api_key", false);
    this.selected_branch_id = this.appservices.getSession(
      "selected_branch_id",
      false
    );

    if (localStorage.getItem("Input_form_data") != "") {
      this.user_details = JSON.parse(localStorage.getItem("Input_form_data"));
      this.user_email = this.user_details.email;
    }

    this.payment();

    this.apirequest = [];

    this.apirequest["payment_amount"] = this.payment_amount;
    if (environment.ENV != "Production")
      this.apirequest["base_path"] = environment.REDIRECT_URL;
    else this.apirequest["base_path"] = window.location.origin + "/";
    //console.log("base_path" + window.location.origin+"/");
    this.apirequest["api_key"] = this.api_key;
    this.apirequest["selected_branch_id"] = this.selected_branch_id;
    this.apirequest["widget_token"] = this.widget_token;
    this.apirequest["user_email"] = this.user_email;
    this.apirequest["currency"] = "INR";
    this.apirequest["report_id"] = this.report_id;
    this.apirequest["report_type"] = "ohr";
    this.apirequest["get_contact_information_later"] =
      this.get_contact_information_later;
    this.apirequest["guest_user_id"] = this.guest_user_id;

    // this.input = this.appservices.generteJSONFromArray(this.apirequest);
    let report_input = this.appservices.generteJSONFromArray(this.apirequest);

    this.appservices
      .callJSONAPI(
        this.appservices.INITIATE_STRIPE_PAYMENT,
        report_input,
        "post",
        this,
        ""
      )
      .subscribe((data: any) => {
        //console.log(" INITIATE_STRIPE_PAYMENT " + data.result.id);

        if (data.status === true) {
          // this.sendPayment(data.payment_intent);
          this.stripe
            .redirectToCheckout({
              // Make the id field from the Checkout Session creation API response
              // available to this file, so you can provide it as argument here
              // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
              sessionId: data.result.id,
            })
            .then(function (result) {
              // console.log(" result  : " + result);
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `result.error.message`.
            });
        }
      });
    // await this.checkPaymentprocessed();

    // await this.invokeStripe(this.payment_amount,this.api_key);
    // setTimeout(() => {
    //   // this.paymentBtn.nativeElement.click();
    //   this.initializePayment();
    //   }, 1000);
    //}
  }

  payment() {
    this.stripe = Stripe(this.api_key);
    var elements = this.stripe.elements();
    var style = {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        lineHeight: "40px",
        fontWeight: 300,
        fontFamily: "Helvetica Neue",
        fontSize: "50px",
        "::placeholder": {
          color: "#CFD7E0",
        },
      },
    };

    // this.cardElement = elements.create('card', { style: style });
    this.cardElement = elements.create("card", {
      hidePostalCode: false,
      style: style,
    });

    // this.cardElement.mount('#card-element');
  }
  sendPayment(payment_intent) {
    this.stripe
      .confirmCardPayment(
        payment_intent, //use your client secret
        {
          payment_method: { card: this.cardElement },
        }
      )
      .then(function (result) {
        if (result.error) {
          // Display error.message in your UI.
          //  console.log(result.error, ' ==== error');
        } else {
          //  console.log('success ==== ', result);
          // The payment has succeeded
          // Display a success message
        }
      });
  }

  initializePayment() {
    // console.log("initializePayment :  ");

    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: this.api_key,
      locale: "auto",
      token: function (stripeToken: any) {
        // console.log( " stripeToken.id : " + stripeToken.id)
        if (stripeToken.id != "") {
          processPayment(stripeToken);
        }
        // alert('Stripe token generated!');
      },
    });

    const processPayment = (stripeToken: any) => {
      // console.log("After make payment has been clicked ");
      this.apirequest = [];
      this.apirequest["widget_token"] = this.widget_token;
      this.apirequest["stripe_token"] = stripeToken;
      this.apirequest["payment_amount"] = this.payment_amount;
      this.apirequest["selected_branch_id"] = this.selected_branch_id;
      this.apirequest["report_id"] = this.report_id;
      this.apirequest["report_type"] = "ohr";
      this.apirequest["get_contact_information_later"] =
        this.get_contact_information_later;
      this.apirequest["guest_user_id"] = this.guest_user_id;
      let input = this.appservices.generteJSONFromArray(this.apirequest);
      this.submittedForm = true;
      // console.log(input);
      this.appservices
        .callJSONAPI(
          this.appservices.MAKE_STRIPE_PAYMENT,
          input,
          "post",
          this,
          ""
        )
        .subscribe((data: any) => {
          // console.log(data);
          if (data.status && data.status_code == "1") {
            // console.log(" data.result.customer_email : " + data.result.customer_email);
            // console.log(" data.result.customer_name : " + data.result.customer_name);

            this.appservices.setSession("payment_successful", "true", false);
            // this.router.navigate(['payment-oral-health-score-completed']);
          } else {
            this.errorMsg = data.message;
            this.paymentInProgress = false;
            setTimeout(() => {
              this.router.navigate(["payment-access-report"]);
            }, 5000);
          }
        });
    };

    paymentHandler.open({
      name: "Smilo",
      description: "OHR Detailed Report",
      amount: this.payment_amount * 100,
      currency: "INR",
      image: "assets/images/favicon.png",
      email: this.user_email,
      closed: () => {
        this.handleCloseBtnFunction();
      },
    });
  }

  async invokeStripe(paymentAmount: any, api_key: any) {
    if (!window.document.getElementById("stripe-script")) {
      const script = window.document.createElement("script");
      script.id = "stripe-script";
      script.type = "text/javascript";
      script.src = "https://checkout.stripe.com/checkout.js";
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: api_key,
          locale: "auto",
          token: function (stripeToken: any) {
            //  console.log(stripeToken)
            alert("Payment has been successfull!");
          },
        });
      };
      window.document.body.appendChild(script);
    }
  }

  handleCloseBtnFunction() {
    if (this.submittedForm === false) {
      this.router.navigate(["payment-access-report"]);
    }
  }

  async checkPaymentprocessed() {
    this.apirequest = [];
    this.apirequest["report_id"] = this.report_id;
    this.apirequest["report_type"] = "ohr";
    let input = this.appservices.generteJSONFromArray(this.apirequest);
    this.appservices
      .callJSONAPI(
        this.appservices.CHECK_PAYMENT_PROCESSED,
        input,
        "post",
        this,
        ""
      )
      .subscribe((data: any) => {
        // console.log(data);
        if (data.status && data.status_code == "0") {
          return false;
        }
      });
  }
}
