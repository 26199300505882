import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  ViewChild,
  AfterViewInit,
  OnInit,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";
import {
  StyleRenderer,
  WithStyles,
  lyl,
  ThemeRef,
  ThemeVariables,
} from "@alyle/ui";
import { LyDialogRef, LY_DIALOG_DATA } from "@alyle/ui/dialog";
import { STYLES as SLIDER_STYLES } from "@alyle/ui/slider";
import { Services } from "../../helpers/Services";
import { Router, ActivatedRoute } from "@angular/router";
//import { ImageAnnotatorClient } from "@google-cloud/vision";
import {
  ImgResolution,
  LyImageCropper,
  ImgCropperConfig,
  ImgCropperEvent,
  ImgCropperErrorEvent,
  ImgCropperLoaderConfig,
} from "@alyle/ui/image-cropper";

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES);
  const slider = ref.selectorsOf(SLIDER_STYLES);
  return {
    cropper: lyl`{
      // max-width: 400px
      height: 400px
     }`,
    sliderContainer: lyl`{
      position: relative
      ${slider.root} {
        position: absolute
        left: 0
        right: 0
        margin: auto
        top: -32px
      }
    }`,
    slider: lyl`{
     padding: 1em
    }`,
  };
};

@Component({
  selector: "app-cropper-dialog",
  templateUrl: "./cropper-dialog.component.html",
  styleUrls: ["./cropper-dialog.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StyleRenderer],
})
/*export class CropperDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}*/
export class CropperDialogComponent implements WithStyles, AfterViewInit {
  appservices: Services;
  //private client: ImageAnnotatorClient;
  private imagePath: string;
  mouths: any[];

  readonly classes = this.sRenderer.renderSheet(STYLES);
  scale: number;
  @ViewChild(LyImageCropper, { static: true }) cropper: LyImageCropper;
  myConfig: ImgCropperConfig = {
    width: 200,
    height: 200,
    type: "image/png",
    keepAspectRatio: true,
    output: {
      width: 700,
      height: 700,
    },
    resizableArea: true,
  };
  currentOhrImageCoordinates: any;
  currentOhrImage: any;
  detectionClass: any;
  detectionMessage: any;
  imageLoaded: any = "visibility_none";
  loadingContentMsg: string = " Please wait while the image is being loaded.";
  heightIssue: boolean = false;
  uploadedImageHeight: any;
  uploadedImageWidth: any;
  currentSelectedRow: any;
  constructor(
    @Inject(LY_DIALOG_DATA) private event: Event,
    readonly sRenderer: StyleRenderer,
    public dialogRef: LyDialogRef,
    private router: Router,
    private route: ActivatedRoute,
    private _cd: ChangeDetectorRef
  ) {
    this.appservices = new Services();
    if (router.url.search("/upload-teeth-photos") >= 0) {
      this.myConfig = {
        width: 340,
        height: 190,
        keepAspectRatio: true,
        resizableArea: true,
        type: "image/png",
      };
    } else if (router.url.search("/upload-smile-photo") >= 0) {
      this.myConfig = {
        width: 340,
        height: 190,
        keepAspectRatio: true,
        // width: 500, height: 350, keepAspectRatio: true,
        resizableArea: true,
        type: "image/png",
        output: {
          width: 700,
          height: 700,
        },
      };
    }
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(e: KeyboardEvent) {
    console.log("Back button pressed");
    this.dialogRef.close();
    // window.location.reload();
  }

  ngOnInit(): void {
    // console.log(" ngOnInit " + this.router.url );
    if (this.router.url == "/upload-teeth-photos") {
      this.currentOhrImage = this.appservices.getSession(
        "currentOhrImage",
        false
      );
      this.currentOhrImageCoordinates = this.appservices.getSession(
        "currentOhrImageCoordinates",
        false
      );

      this.uploadedImageHeight = this.appservices.getSession(
        "uploadedImageHeight",
        false
      );
      this.uploadedImageWidth = this.appservices.getSession(
        "uploadedImageWidth",
        false
      );
    }
    // console.log(" this.currentOhrImageCoordinates : " + this.currentOhrImageCoordinates);
    // console.log(" this.currentOhrImage : " + this.currentOhrImage);
  }
  /**
   * ngAfterViewInit - Load image when dialog animation has finished
   * @since   1.0
   * @returns {String}  base64
   */
  // ngAfterViewInit() {
  //   // Load image when dialog animation has finished
  //   this.dialogRef.afterOpened.subscribe((res) => {
  //     this.cropper.selectInputEvent(this.event);
  //   });
  // }

  ngAfterViewInit() {
    // Load image when dialog animation has finished
    // console.log(" ngAfterViewInit ");

    // console.log(" this.currentOhrImageCoordinates : " + this.currentOhrImageCoordinates);

    if (this.router.url != "/upload-teeth-photos") {
      this.dialogRef.afterOpened.subscribe(() => {
        this.cropper.selectInputEvent(this.event);
      });
      return;
    }

    if (
      this.currentOhrImageCoordinates != "" &&
      this.currentOhrImageCoordinates != undefined
    )
      this.currentOhrImageCoordinates = JSON.parse(
        this.currentOhrImageCoordinates
      );

    //console.log(" this.currentOhrImageCoordinates.status : " + this.currentOhrImageCoordinates.message);

    if (
      this.currentOhrImageCoordinates == "" ||
      !this.currentOhrImageCoordinates.status
    ) {
      //this.detectionClass = "warning-msg dialogue_box_msg";
      this.detectionClass = this.currentOhrImageCoordinates.message;
      // this.detectionMessage = "Sorry! We could not able to identify face / tooth part, please move the cropping part manually.";
      this.dialogRef.afterOpened.subscribe(() => {
        this.cropper.selectInputEvent(this.event);
      });
    }
    // alert(screen.width + "  " + screen.height);
    else {
      // console.log(screen.width + "  " + screen.height);
      //this.detectionClass = "success-msg dialogue_box_msg";
      this.detectionClass = this.currentOhrImageCoordinates.message;
      // this.detectionMessage = "We have identified the tooth part, Please feel free to move the cropping part.";
      // console.log(" ngAfterViewInit this.currentOhrImage : " + this.currentOhrImage);

      var areaWidth = 0;
      var areaHeight = 0;
      var xOrigin = 0;

      var result = this.currentOhrImageCoordinates.result;
      // console.log(" result : " + result);
      var leftMouth = result["mouth"]["left"];
      var rightMouth = result["mouth"]["right"];
      var centerMouth = result["mouth"]["center"];

      var upperLips = result["lips"]["upper"];
      var lowerLips = result["lips"]["lower"];
      // {"status":true,"status_code":1,"message":"Rated successfully","result":{"left":{"x":276.2168,"y":268.22836,"z":9.379003},"right":{"x":360.3122,"y":275.71198,"z":33.404},"center":{"x":328.3161,"y":282.15622,"z":5.635602}}}

      var centerMinusLeftX = centerMouth.x - leftMouth.x;
      var centerMinusLeftY = centerMouth.y - leftMouth.y;
      var centerMinusRightX = rightMouth.x - centerMouth.x;
      var centerMinusRightY = rightMouth.y - centerMouth.y;

      // console.log(" centerMinusLeftX : " + centerMinusLeftX + " centerMinusLeftY : " + centerMinusLeftY + " centerMinusRightX : " + centerMinusRightX + " centerMinusRightY : " + centerMinusRightY ) ;

      areaWidth = rightMouth.x - leftMouth.x;
      areaHeight = lowerLips.y - upperLips.y;
      // console.log(" areaWidth : " + areaWidth + " areaHeight : " + areaHeight + " screen.width : " + screen.width );
      if (areaWidth < 200 || areaHeight < 200) {
        areaWidth += 20;
        areaHeight += 20;
        this.heightIssue = true;
      }

      // if(areaWidth>=300)
      // {
      //   areaWidth = 300;
      // }

      if (screen.width > 450) {
        xOrigin = 10;
      }

      // if(screen.width>400&&screen.width<767)
      // {
      //   areaWidth = 175;
      //   areaHeight = 175;
      //   xOrigin = 50;
      // }

      // if(screen.width>767&&screen.width<1024)
      // {
      //   areaWidth = 200;
      //   areaHeight = 200;
      //   xOrigin = 75;
      //   if(centerMinusLeftX>=125||centerMinusRightX>=125)
      //   {
      //     xOrigin = 50;
      //     areaWidth = 350;
      //     areaHeight = 350;
      //   }
      // }

      // if(screen.width>=1024)
      // {
      //   if(centerMinusLeftX>=125||centerMinusRightX>=125)
      //   {
      //     xOrigin = 70;
      //     areaWidth = 300;
      //     areaHeight = 300;
      //   }
      // }

      // var width = Math.sqrt( Math.pow(rightMouth.x - leftMouth.x, 2) + Math.pow(rightMouth.y - leftMouth.y, 2));

      var localURL = this.currentOhrImage.toString();

      let scale = 1;

      // console.log(" this.uploadedImageWidth : " + this.uploadedImageWidth);

      if (Number(this.uploadedImageWidth) > 1000) {
        scale = 0.5;
        if (screen.width < 450) {
          scale = 0.25;
          areaWidth = areaWidth / 3;
          areaHeight = areaHeight / 3;
          // if(Number(areaWidth)>200)
          // {
          //     areaWidth = 150;
          //     areaHeight = 75;
          // }
        } else {
          areaWidth = areaWidth / 2;
          areaHeight = areaHeight / 2;
        }
      }

      // console.log(" areaWidth : " + areaWidth + " areaHeight : " + areaHeight + " scale : " + scale  );

      const config: ImgCropperLoaderConfig = {
        //scale: 0.745864772531767,
        scale: scale,
        xOrigin: centerMouth.x - xOrigin,
        yOrigin: centerMouth.y,
        areaWidth: areaWidth,
        areaHeight: areaHeight,
        rotation: 0,
        originalDataURL: JSON.parse(localURL),
      };
      this._cd.detectChanges();
      setTimeout(() => {
        this.cropper.loadImage(config);
      }, 100);
    }

    if (this.router.url != "/upload-teeth-photos") {
      this.detectionMessage = "";
      this.detectionClass = "";
    }

    this._cd.detectChanges();
  }

  onReady(e: ImgCropperEvent) {
    let url = this.router.url;
    if (url.search("/upload-teeth-photos") >= 0 && e.width >= 700) {
      this.myConfig = {
        width: 700,
        height: 700,
        keepAspectRatio: false,
        resizableArea: true,
        type: "image/png",
        output: {
          width: 700,
          height: 700,
        },
      };
    } else if (url.search("/upload-smile-photo") >= 0 && e.width >= 700) {
      this.myConfig = {
        width: 700,
        height: 700,
        keepAspectRatio: true,
        resizableArea: true,
        type: "image/png",
        // output: ImgResolution.OriginalImage
        output: {
          width: 700,
          height: 700,
        },
      };
    }
  }

  /**
   * onCropped - Method after cropped Image finished
   * @since   1.0
   * @returns {String}
   */
  onCropped(e: ImgCropperEvent) {
    // console.log(e)
    // this.showDebugMessage('cropped img: '+ e);
  }
  // async detectMouth() {
  //   try {
  //     const mouthAnnotations = await this.visionService.detectMouth(this.imagePath);
  //     this.mouths = mouthAnnotations;
  //   } catch (error) {
  //     console.error('Error during mouth detection:', error);
  //   }
  // }
  /**
   * onLoaded - Method  Image loaded for crop
   * @since   1.0
   * @returns {String}
   */
  onLoaded(e: ImgCropperEvent) {
    // console.log(e);
    let imgObj = { width: e.width, height: e.height };
    this.appservices.setSession("imgObj", imgObj, true);
    this._cd.detectChanges();
  }
  /**
   * onError - Method  Image cropped error triggered
   * @since   1.0
   * @returns {String}
   */
  onError(e: ImgCropperErrorEvent) {
    //console.log(e)
    console.warn(`'${e.name}' is not a valid image`, e);
    this.dialogRef.close();
  }

  onCancel() {
    this.cropper.clean();
    this.dialogRef.close();
  }
}
