import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

declare var gtag;
declare const fbq;

@Component({
  selector: "app-appointment-card",
  templateUrl: "./appointment-card.component.html",
  styleUrls: ["./appointment-card.component.css"],
})
export class AppointmentCardComponent implements OnInit {
  isExpand: boolean = false;
  @Output() bookNowClick: EventEmitter<void> = new EventEmitter();
  constructor(private router: Router) {}

  ngOnInit(): void {}

  onExpand() {}

  onBookNow() {
    this.bookNowClick.emit();
    this.router.navigate(["dental-appointment"]);
  }
}
