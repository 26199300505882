<!-- <div class="dynamic_wrapper d-none">
    <div class="inner_dynamic_sec text-center">
        <app-back-to-home></app-back-to-home>
        <div class="top_process_section">
            <div class="d-flex justify-content-center line_section">
                <span class="icon-tick-fill1 icon_green"></span>
                <img src="assets/images/line.png" class="line_cross m-2 p-1">
                <span class="icon-tick-fill1 icon_green"></span>
                <img src="assets/images/line.png" class="line_cross m-2 p-1">
                <span class="icon-tick-fill1 icon_blue"></span>
            </div>
            <div class="container_content_sec">
                <div class="inner_container_content">
                    <div class="sec_inner_content p-3">
                        <div class="vs-messages mb-2"></div>
                        <div class="title_sec">
                            <h4 class="score_head text-capitalize">Your Oral Health Rating <span class="icon-info1 info_icon ml-2 btn p-0" type="button" (click)="openModal(template)"></span></h4>
                            <ng-template #template>
                                <div class="modal-body">
                                    <div class="model_section p-3">
                                        <div class="cancel_button_sec text-right">
                                            <a class="close close_button" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"> <span class="icon-fill-close1"></span> </a>
                                        </div>
                                        <div class="model_content_section">
                                            <h3 class="font-weight-bold"> Ranking Guidelines</h3>
                                            <p class="text-left mx-2 my-3">The color coded ranking is given based on the AI engine's analysis of the input images for the presence of dental decay and gum disease.</p>
                                            <div class="ranking_content_sec text-left">
                                                <div class="ranking_status good">
                                                    <div class="d-flex">
                                                        <div class="good_ranking">
                                                            <p class="color_green icon_teeth"><span class="icon-tooth1"></span></p>
                                                        </div>
                                                        <div class="ml-2">
                                                            <p class="color_green font_w_600">Good</p>
                                                            <span class="color_grey points_cal">8-10 Points</span>
                                                        </div>
                                                    </div>
                                                    <p class="ranking_guideline_para">{{app_details.report_info_descriptions1}}</p>
                                                </div>
                                                <div class="ranking_status good">
                                                    <div class="d-flex">
                                                        <div class="good_ranking">
                                                            <p class="color_orange icon_teeth"><span class="icon-tooth1"></span></p>
                                                        </div>
                                                        <div class="ml-2">
                                                            <p class="color_orange font_w_600">Moderate</p>
                                                            <span class="color_grey points_cal">5-7 Points</span>
                                                        </div>
                                                    </div>
                                                    <p class="ranking_guideline_para">{{app_details.report_info_descriptions2}}</p>
                                                </div>
                                                <div class="ranking_status good">
                                                    <div class="d-flex">
                                                        <div class="good_ranking">
                                                            <p class="color_red icon_teeth"><span class="icon-tooth1"></span></p>
                                                        </div>
                                                        <div class="ml-2">
                                                            <p class="color_red font_w_600">Severe</p>
                                                            <span class="color_grey points_cal">1-4 Points</span>
                                                        </div>
                                                    </div>
                                                    <p class="ranking_guideline_para">{{app_details.report_info_descriptions3}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="date_time_section">
                            <p class="text-center date_time_sec">Calculated on {{created_date}}</p>
                        </div>
                        <div class="img_section text-center position-relative">
                            <div class="loader_img_sec" id="score_board">
                                <img id="oralscore_graphic" [src]="appservices.getImage(overall_score)" alt="score_img" />
                            </div>
                            <div class="total_oral_score">
                                <span class="white text-center" id="total_oralscore">{{overall_score}}</span>
                                <span class="white text-center points_text">POINTS</span>
                            </div>
                            <p id="color_ranking" [ngClass]="appservices.getColor(overall_score)">{{appservices.getText(overall_score)}}</p>
                        </div>
                        <div class="details_sec"> -->
<!--p class="text-center my-2">Smilo.ai our Al powered technology has analysed the photos of your teeth and gums and has provided the Oral Health Rating shown above. This rating is provided for identification and awareness only.lt is not diagnostic and should be verified by your dentist. See our recommendations below for more personalised information. </p>
                            <p class="font-italic text-body pb-2 font_style_italic font_family"><i>Smilo.ai rating is for awareness only. Please check with your dentist for a confirmed diagnosis.</i></p>
                            <p class="text-center my-2"><b>Note:</b> While we make every effort to continually update the accuracy of our Artificial Intelligence functions, you should always verify this report with a visit to a registered Dentist. Also, the results of this Oral Report rely on you taking accurate images as explained in the Instructions available in this App, including ensuring that your images are not blurred, contain enough light and that all of your teeth and gums are fully visible across the images.</p-->
<!-- <p class="font-italic agree_text pb-2 font_style_italic font_family"><i><span style="color:#1c69b1">Smilo.ai rating is for awareness only. Any information or result provided by Smilo.ai is indicative only and not a diagnosis. Please check with your dentist for further recommendations.</span></i></p>    
                        </div>
                        <div class="text-center mt-3">
                            <button class="btn button_links btn_width_1" (click)="reportSend(template1)">Email the report</button>
                        </div>
                    </div>
                    <app-footer></app-footer>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="dynamic_wrapper ">
    <div class="row mx-0 ohr_bg">
        <div class="col-lg-6 col-md-12 text-center ohs_content">
            <h3 class="sub_head mb-0">Your Oral Health <br>Rating is <br></h3>
            <p [ngClass]="appservices.getColor(overall_score)" class="font_30 ranking_text mt-3">{{OHRText}}</p>
            <!-- <img src="{{OHRImage}}" class="ohr-result-image" > -->
            <p class="blue_color font_14 my-3"><a href="javascript:;" (click)="openModal(template)">Explain the ranking</a></p>
            <div class="grey_color font_14 max_width_500">
                <p>The smilo.ai rating is for awarness only.</p>
                <p>Any information or report provided by Smilo.ai is indicative only and not a diagnosis. Please check with your dentist for further recommendations.</p>
            </div>
            <div class="my-4"><span class="accepted_txt">Calculated on {{created_date}}</span></div>

           <!-- <p class="blue_color font_14 my-3"> <a  href="javascript:;" (click)="reportSend(template1)"> To view detail report, Please subscribe now </a> </p>  -->

            <div *ngIf="((cta_type!==''&&button_text!==''&&button_url!==''));then three_btn_content else two_btn_content"></div>
            <ng-template #three_btn_content>
                <div class="text-center mt-3 group_btn">
                     <button class="btn button_links btn_width_2 mr-3 mb-3" (click)="reportSend(template1)">Email me a report</button>  
                    <!-- <div *ngIf="makePaymentButton">                        
                        <button class="btn button_links btn_width_2 mr-3 mb-3" (click)="makePayment()">Make Payment</button>
                    </div>  -->
                     <div class="d-flex book_start_btn">
                        <!-- <button class="btn btn_width_2 start_btn" (click)="redirectOHR()">Book an Appoinment</button>  -->
                        <a href="{{button_url}}" class="btn start_btn btn_width_2 ctabtn" target="_blank">{{button_text}}</a>
                        <button class="btn btn_width_2 start_btn" (click)="redirectOHR()">Start again</button>
                    </div>
                </div>
            </ng-template>
            <ng-template #two_btn_content>
                <div class="text-center mt-3">
                    <button class="btn btn_width_2 start_btn mr-3 mb-3" (click)="redirectOHR()">Start again</button>
                    <!-- <div *ngIf="makePaymentButton">
                        <button class="btn button_links btn_width_2 mr-3 mb-3"  (click)="makePayment()" >Make Payment</button>
                    </div> -->
                    <button class="btn button_links btn_width_2 mr-3 mb-3" (click)="reportSend(template1)">Email me a report</button>  
                </div>
            </ng-template>

          <!--   -->

        </div>
        <div class="col-lg-6 col-md-12 px-0 ohs_image">
            <div class="oral_score">
                <img [defaultImage]="defaultImage1" [lazyLoad]="staticImage" [errorImage]="defaultImage" alt="Oral Score">
            </div>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-body">
        <div class="model_section p-3">
            <div class="cancel_button_sec text-right">
                <!-- <a class="close close_button" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"> <span class="icon-fill-close1"></span> </a> -->
                <button type="button" class="close close_button" (click)="modalRef.hide()"><span>×</span></button>
            </div>
            <div class="model_content_section">
                <h3 class="font-weight-bold"> Ranking Guidelines</h3>
                <p class="text-left mx-2 my-3">The color coded ranking is given based on the AI engine's analysis of the input photos for the presence of dental decay and gum disease.</p>
                <div class="ranking_content_sec text-left">
                    <div class="ranking_status good">
                        <div class="d-flex">
                            <div class="good_ranking">
                                <!-- <p class="color_green icon_teeth"><span class="icon-tooth1"></span></p> -->
                                <img src="assets/images/excellent_icon.png">
                            </div>
                            <div class="ml-2">
                                <p class="color_green font_w_600">Good</p>
                                <span class="color_grey points_cal">8-10 Points</span>
                            </div>
                        </div>
                        <p class="ranking_guideline_para">{{app_details.report_info_descriptions1}}</p>
                    </div><br>
                    <div class="ranking_status amber">
                        <div class="d-flex">
                            <div class="amber_ranking">
                                <!-- <p class="color_orange icon_teeth"><span class="icon-tooth1"></span></p> -->
                                <img src="assets/images/amber_icon.png">
                            </div>
                            <div class="ml-2">
                                <p class="color_orange font_w_600">Moderate</p>
                                <span class="color_grey points_cal">5-7 Points</span>
                            </div>
                        </div>
                        <p class="ranking_guideline_para">{{app_details.report_info_descriptions2}}</p>
                    </div><br>
                    <div class="ranking_status poor">
                        <div class="d-flex">
                            <div class="poor_ranking">
                                <!-- <p class="color_red icon_teeth"><span class="icon-tooth1"></span></p> -->
                                <img src="assets/images/poor_icon.png">
                            </div>
                            <div class="ml-2">
                                <p class="color_red font_w_600">Severe</p>
                                <span class="color_grey points_cal">1-4 Points</span>
                            </div>
                        </div>
                        <p class="ranking_guideline_para">{{app_details.report_info_descriptions3}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #template1>
    <div class="modal-body p-0">
        <div class="model_section">
            <div class="vs-messages-model text-center mb-2"></div>
            <div class="rating_section">
                <div class="text-center">
                    <h3 class="sub_head rating_head">Enjoying the <br> Virtual Consult?</h3>
                    <p class="grey_color font_14">Tap an emoji to rate us</p>
                </div>
                <hr>
                <div class="model_content_section">
                    <div class="d-flex align-items-center justify-content-around flex-wrap">

                        <div class="pointer_link text-center half_opacity rating_sub" (click)="onSubmit(template1,'rating_section','Poor',1)">
                            <div class="rating">
                                <img src="assets/images/better.png" alt="better">
                            </div>
                            <p class="blue_color font_10 mt-1">Could be better</p>
                        </div>

                        <div class="pointer_link text-center half_opacity rating_sub" (click)="onSubmit(template1,'rating_section','Great',4)">
                            <div class="rating">
                                <img src="assets/images/good.png" alt="good">
                            </div>
                            <p class="blue_color font_10 mt-1">Good</p>
                        </div>

                        <div class="pointer_link text-center half_opacity rating_sub border-right-0" (click)="onSubmit(template1,'rating_section','Excellent',5)">
                            <div class="rating">
                                <img src="assets/images/amazing.png" alt="amazing">
                            </div>
                            <p class="blue_color font_10 mt-1">Amazing</p>
                        </div>
                    </div>
                    <!-- <div class="text-center mt-3 pt-3">
                        <button class="btn button_links btn-block mr-1 submit_btn btn_color mb-3" (click)="onSubmitRating(template1,'rating_section')">Submit</button>
                        <span class="mt-3 pointer_link agree_text" (click)="navigate('rating_skip',template1)">Skip</span>
                    </div> -->
                </div>
            </div>
            <div class="feedback_section display_none">
                <div class="text-center">
                    <h4 class="font-weight-bold mb-2">How can we improve?</h4>
                    <h4 class="">Please share your ideas and suggestions</h4>
                </div>
                <div class="model_content_section mt-3">
                    <textarea placeholder="Type your suggestions" class="firstletter_capital form-control" rows="5" id="feedback" maxlength="500"></textarea>
                </div>
                <div class="text-center mt-3 pt-3">
                    <button class="btn button_links btn-block mr-1 submit_btn btn_color mb-3" (click)="onSubmit(template1,'feedback_section','','1')">Submit</button>
                    <span class="mt-3 pointer_link agree_text" (click)="navigate()">Skip</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>