<!-- <header class="header">
  <nav class="navbar container navbar-dark bg-blue">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarToggleExternalContent"
      aria-controls="navbarToggleExternalContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
      <span class="navbar-toggler-icon"></span>
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand mx-auto" href="#"
      ><img src="assets/images/site_logo.png" alt="Smilo" />
    </a>

    <div
      class="navbar-collapse collapse offcanvas-collapse"
      id="navbarToggleExternalContent"
    >
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarToggleExternalContent"
        aria-controls="navbarToggleExternalContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="sh-cancel"></i>
      </button>
      <div class="navbar-scroll">
        <ul class="navbar-nav w-100">
          <li class="nav-item active">
            <a class="nav-link" href="#">Blog</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Contact Us</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="userprofile_wrapper">
      <ul class="navbar-nav flex-row align-items-center">
        <li class="nav-item">
          <a class="nav-link" href="#">
            <i class="sh-phone"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">
            <i class="sh-user"></i>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header> -->

<div class="section_wrapper pt-0 position-relative">
  <!-- Banner -->
  <div class="section-banner_wrapper position-relative">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div
            class="banner_wrap d-flex align-items-center justify-content-between justify-content-lg-center"
          >
            <div class="banner-content">
              <h1>
                What’s Next
                <!-- Oral Health <br />
                Report -->
              </h1>
            </div>
            <div class="banner-image">
              <img
                src="assets/images/rag-report.png"
                class="img-fluid d-none d-sm-block"
                alt=""
              />
              <img
                src="assets/images/rag-report-mobile.png"
                class="img-fluid d-block d-sm-none"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Banner -->
  <div class="rag-report-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="tile">
            <div class="section-heading text-center">
              <h3 class="mb-3">
                {{
                  imageSelectionCount == 1
                    ? "Your Smile Check Rating"
                    : "Your Oral Health Rating"
                }}
              </h3>
            </div>
            <!-- report graph -->
            <div class="reportscore-card">
              <div class="reportscore-lt">
                <div class="img-block">
                  <img
                    [src]="
                      overall_score <= 4
                        ? 'assets/images/severe_g.png'
                        : overall_score >= 5 && overall_score <= 7
                        ? 'assets/images/poor_g.png'
                        : overall_score >= 8 && this.overall_score <= 10
                        ? 'assets/images/good_g.png'
                        : ''
                    "
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <!-- <div class="reportscore-rt">
                <div
                  class="rating-card"
                  [ngClass]="
                    overall_score <= 4
                      ? 'poor-card'
                      : overall_score >= 5 && overall_score <= 7
                      ? 'poor-card'
                      : overall_score >= 8 && this.overall_score <= 10
                      ? 'good-card'
                      : ''
                  "
                >
                  <div class="ratingteeth">
                    <i class="sh-tooth-decay"></i>
                  </div>
                  <div class="ratinginfo">
                    <h6>DENTAL DECAY</h6>
                    <p>
                      {{
                        overall_score >= 8 && overall_score <= 10
                          ? "Low possibility"
                          : "Possibility"
                      }}
                      of dental decay
                    </p>
                  </div>
                </div>
                <div
                  class="rating-card"
                  [ngClass]="overall_score <= 4 ? 'severe-card' : 'good-card'"
                >
                  <div class="ratingteeth">
                    <i class="sh-tooth-gum"></i>
                  </div>
                  <div class="ratinginfo">
                    <h6>GUM ISSUES</h6>
                    <p>
                      {{ overall_score <= 4 ? "High" : "Low" }} possibility of
                      gum health issues
                    </p>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="text-center reportsocre-info">
              <p>{{ rating_explanation }}</p>
              <!-- <p class="bold">
                {{ rating_explanation }}
              </p> -->
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-6">
          <div class="tile">
            <div class="section-heading text-center">
              <h3 class="mb-1">What’s Next?</h3>
              <p>Discover our services to improve your Oral Health</p>
              <!-- <p>Discover our other services to enhance your experience</p> -->
            </div>
            <div class="next_wrapper">
              <!-- Request A Dental Appointment Card -->
              <app-appointment-card
                *ngIf="!isDaburFlow && isRequestForAppointmentCard"
                class="next-card"
                (bookNowClick)="onBookNow()"
              ></app-appointment-card>
              <!-- Get Product Discounts -->
              <app-product-discount-card
                *ngIf="!isDaburFlow && isProductDiscountCard"
                class="next-card"
                (productDiscountClick)="onProductDiscountClick()"
              ></app-product-discount-card>
              <!-- For 1 image flow redirect to whatsapp page -->
              <app-whatsapp-chat-dentist-card
                *ngIf="!isDaburFlow && !isRequestForAppointmentCard"
                class="next-card"
              ></app-whatsapp-chat-dentist-card>
              <!-- For 1 image flow redirect to 5 image flow -->
              <app-five-image-flow-navigation-card
                *ngIf="!isDaburFlow && imageSelectionCount == 1"
                class="next-card"
              ></app-five-image-flow-navigation-card>
              <!-- Buy An Oral Health Report -->
              <app-buy-report-card
                *ngIf="!isDaburFlow && isBuyReportCard && !isPaymentSuccessFul"
                class="next-card"
                (buyReportClick)="onBuyReportClick()"
              ></app-buy-report-card>
              <!-- Download RAG report direct from RAG report page -->
              <app-download-report-card
                *ngIf="isDaburFlow || (!isDaburFlow && !isBuyReportCard)"
                class="next-card"
              ></app-download-report-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="dynamic_wrapper">
  <div class="vs-messages"></div>
  <div class="dms_wrapper">
    <app-back-to-home></app-back-to-home>
    <div class="dms_content">
      <div class="sec_inner_content1 p-0">
        <div class="title_sec bg-grey max_width_800 br_10">
          <div class="d-flex align-items-center ohr_payment_header_div">
            <div class="w-100">
              <div class="row">
                <div
                  _ngcontent-jpu-c108=""
                  class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 left-container visibility_block"
                  ng-reflect-ng-class="visibility_block"
                >
                  <h4 class="ohr_heading font-weight-700 mb-3 text-center \">
                    <b>{{ payment_title }}</b>
                  </h4>

                  <p class="mb-2 text-center ohr_heading_sub_title">
                    {{ payment_sub_title }}
                  </p>

                  <div
                    class="canvas-container d-flex align-items-center justify-content-center"
                  >
                    <canvas id="canvas" #graphCanvas></canvas>
                    <img
                      [ngClass]="meterClassName"
                      id="needleImage"
                      src="assets/images/Needle.png"
                      alt="{{ OHRText }}"
                    />
                    <div class="smiley_image">
                      <div class="dot">
                        <img
                          class="smiley_img"
                          src="{{ OHRImage }}"
                          alt="{{ OHRText }}"
                        />
                      </div>
                    </div>
                    <p
                      class="rating_txt font_16 mt-2 text-center"
                      [ngClass]="appservices.getColor(overall_score)"
                    >
                      <b>{{ OHRText }}</b>
                    </p>
                  </div>

                  <p class="mb-2 text-center payment_rating_explanation">
                    {{ rating_explanation }}
                  </p>
                </div>

                <div
                  class="canvas-title col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                >
                  <h4 class="ohr_heading font-weight-700 mb-2 text-center">
                    <b>{{ benefits_title }}</b>
                  </h4>
                  <p class="text-center ohr_heading_sub_title mb-2">
                    {{ benefits_sub_title }}
                  </p>
                  <div class="sample_img_div">
                    <img src="assets/images/Slide1@2x.png" />
                    <div class="text-center">
                      <a
                        href="assets/images/OHR-Sample Reports.pdf"
                        download=""
                        target="_blank"
                        class="pointer_link sample_report_link"
                      >
                        Download Sample Report</a
                      >
                    </div>
                  </div>

                  <div
                    class="col-12 col-sm-12 mt-2 d-lg-block p-0 right_container_payment_div"
                  >
                    <div
                      _ngcontent-tjd-c108=""
                      class="d-md-flex align-items-center justify-content-center"
                    >
                      <p class="text-center mr-3 mb-2">
                        ₹ <span class="font_24"><b>99</b></span
                        >/Report
                      </p>
                    </div>

                    <div class="text-center">
                      <button
                        (click)="makePayment()"
                        class="btn button_links continue_btn btn_color mx-0 mb-0 mb-md-2 justify-content-center align-items-center"
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="m-0" />

        <div class="sec_inner_content1 p-0 mt-3 d-sm-block">
          <div class="title_sec bg-grey max_width_800 br_10">
            <div class="d-flex ohr_payment_header_div custom_height">
              <div class="w-100">
                <div class="mx-auto">
                  <div class="row">
                    <div
                      class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3"
                    >
                      <b>Benefits</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                      <div
                        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 img_container right_border_line"
                        *ngIf="benefits_one != ''"
                      >
                        <div class="benefits_img_wrapper">
                          <img src="assets/images/discount.png" />
                          <p class="benefits_text text-md-center mt-0 mt-md-2">
                            B1
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 right_border_line"
                        *ngIf="benefits_two != ''"
                      >
                        <div class="benefits_img_wrapper">
                          <img src="assets/images/b2.png" />
                          <p class="benefits_text text-md-center mt-0 mt-md-2">
                            B2
                          </p>
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 right_border_line"
                        *ngIf="benefits_three != ''"
                      >
                        <div class="benefits_img_wrapper">
                          <img src="assets/images/B3.png" />
                          <p class="benefits_text text-md-center mt-0 mt-md-2">
                            B3
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0 order-lg-last order-xl-last mb-3"
                    >
                      <div
                        class="d-md-flex align-items-center justify-content-center"
                      >
                        <p class="text-center mr-3 mb-2">
                          ₹
                          <span class="font_24"> <b>99</b> </span>/Report
                        </p>
                      </div>

                      <div class="text-center">
                        <button
                          class="btn button_links continue_btn btn_color mx-0 mb-0 mb-md-2 justify-content-center align-items-center"
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
