<div class="loader-section" *ngIf="showLoadingForm">
  <div class="loader_wrapper">
    <div class="loading_div">
      <div class="circle-chekmark analyze_loader upload-image_loader">
        <div class="loding-circle" *ngIf="!isDataLoaded">
          <div class="circle-chekmark-border"></div>
          <svg width="200" height="200" class="circle-svg">
            <circle
              fill="none"
              stroke="#f5821e"
              stroke-width="18"
              cx="100"
              cy="100"
              r="90"
              class="circle"
              stroke-linecap="round"
              transform="rotate(-90 100 100) "
            />
          </svg>
        </div>
        <div class="loading-arrow" *ngIf="!isDataLoaded">
          <i class="sh-arrow-up"></i>
        </div>
        <div class="zoomout-circle_tick" *ngIf="isDataLoaded">
          <div class="circle-chekmark-dark"></div>
          <svg width="400" height="400" class="tick-svg">
            <polyline
              fill="none"
              stroke="#ffffff"
              stroke-width="50"
              points="88,214 173,284 304,138"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="tick"
            />
          </svg>
        </div>
      </div>
      <div class="art_intelli_para_sec">
        <p
          class="text-center"
          [ngClass]="{ 'text-green': isDataLoaded === true }"
        >
          {{ !isDataLoaded ? analyzingText : successText }}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- <div class="dynamic_wrapper">
    <div class="vs-messages"></div>
    <div class="dms_wrapper">
       <app-back-to-home></app-back-to-home>
       <div class="dms_content">
          <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="showLoadingForm">
             <div class="img_section text-center position-relative">
                <div class="analyzing_text">
                   <div class="title_sec mt-4">
                      <h4 class="thank_you_head"><b>Thank you!</b></h4>
                   </div>
                </div>
                <div class="loader_img_sec">
                   <img src="assets/images/loader.gif" />
                </div>
                <div class="analyzing_text mb-4">
                   <p>{{analyzingText}}</p>
                </div>
             </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="showContactForm" >
             <form autocomplete="off" [formGroup]="InputForm" class="contact_info_form" (ngSubmit)="onContactFormSubmit()">
             <h3 class="sub_head contact-info-sub-head mb-3">Please enter your email address so we can send you your personalised report</h3>
             <div class="form-group position-relative min_height">
                <input (keyup)="onEnterData($event)" type="text" formControlName="firstName" maxlength="31" class="first_name form-control" (keyup)="nameLength('first_name')" placeholder="First Name" [ngClass]="{ 'is-invalid': isSubmitted && ci.firstName.errors }" trim="blur" value="{{ firstName | titlecase }}" appInputRestriction />
                <div *ngIf="isSubmitted && ci.firstName.errors" class="invalid-feedback">
                   <div *ngIf="ci.firstName.errors.required" class="text-left">First name is required</div>
                   <div *ngIf="ci.firstName.errors.minlength" class="text-left">
                      First Name should contain at least 3 characters.
                   </div>
                </div>
             </div>
             <div class="form-group position-relative min_height">
                <input (keyup)="onEnterData($event)" type="text" formControlName="lastName" maxlength="31" class="last_name form-control" (keyup)="nameLength('last_name')" placeholder="Last Name" [ngClass]="{ 'is-invalid': isSubmitted && ci.lastName.errors }" trim="blur"  value="{{ lastName | titlecase }}" appInputRestriction />
                <div *ngIf="isSubmitted && ci.lastName.errors" class="invalid-feedback">
                   <div *ngIf="ci.lastName.errors.required" class="text-left">Last name is required</div>
                   <div *ngIf="ci.lastName.errors.minlength" class="text-left">
                      Last Name should contain at least 3 characters.
                   </div>
                </div>
             </div>
             <div class="form-group position-relative">
                <input (keyup)="onEnterData($event)" maxlength="50" type="email" formControlName="email" class="form-control name_holder" placeholder="Email Address" [ngClass]="{ 'is-invalid': isSubmitted && ci.email.errors }" trim="blur" />
                <div *ngIf="isSubmitted && ci.email.errors" class="invalid-feedback">
                   <div *ngIf="ci.email.errors.required" class="text-left">Email Address is required</div>
                   <div *ngIf="ci.email.errors.pattern" class="text-left">
                      Email Address is invalid.
                   </div>
                </div>
             </div>
             <div class="form-group position-relative">
                <input (keyup)="onEnterData($event)" type="tel" (keydown.space)="$event.preventDefault();" onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" formControlName="phoneNumber"
                maxlength="15" (countryChange)="countryChange($event)" (intlTelInputObject)="telInputObject($event)" placeholder="Mobile Number (Optional)" ng2TelInput class="phone-format form-control" id="phoneNumber" [ngClass]="{ 'is-invalid': isSubmitted && ci.phoneNumber.errors }"
                trim="blur">
                <div *ngIf="isSubmitted && ci.phoneNumber.errors" class="invalid-feedback">
                   <div *ngIf="ci.phoneNumber.errors.required" class="text-left">Required</div>
                </div>
             </div>
             <div *ngIf="display_branches_div" class="form-group position-relative">
                <span class="practice-location-span"> Dental Practice near to your location </span>
                <select name="userSelectedPractice" id="userSelectedPractice" class="form-control" (change)="onSelectPractice($event)" >
                   <option value="">Please select any branch</option>
                   <option value="" [selected]="true" >Global Branch</option>
                   <option *ngFor="let method of practiceBranches" [attr.data-isocode]="method.address" [value]="method.id" [selected]="method.id == preSelectedBranch" >{{ method.name }}</option>
                </select>
             </div>
             <div class="mt-35">
                <div class="checkbox_rounded d-flex align-items-center ml-2">
                   <input id="agree" type="checkbox" name="agree"  formControlName="termsAndConditions" [checked]="checkboxChecked"  [(ngModel)]="checkboxChecked" required >
                   <label for="agree"></label>
                   <span class="check_text agree_text ml-2"><span class="grey_color"> I have read and agree to Smilo.ai's </span><a target="_blank" href="{{consent_form}}">Consent Form</a>, <a target="_blank" href="{{terms_site}}">Terms and Conditions</a>, and <a target="_blank" href="{{privacy_site}}">Privacy Policy</a></span>
                </div>
                <div *ngIf="isSubmitted && termsAndConditions.invalid" class="error font_14">
                   <div class="text-left">Please Accept Terms and Conditions</div>
                </div>
                <div class="d-flex justify-content-end flex_start">
                   <button [disabled]="contactFormBtn" type="submit" class="my-4 btn btn_width_2 button_links coninue_btn btn_color mx-0"><span class="save_continue_btn_span">Next</span><span class="icon_arrow icon-right-arrow ml-3 font_12"></span></button>
                </div>
             </div>
             </form>
          </div>
          <div class="sec_inner_content1 p-0">
             <div id="steps">
                <div id="circles" class="circle-steps">
                   <div data-num="1" id="num1"></div>
                   <div data-num="2" class="disabled" id="num2"></div>
                   <div data-num="3" class="disabled" id="num3"></div>
                </div>
             </div>
             <form autocomplete="off" [formGroup]="uploadImageInputForm" (ngSubmit)="onSubmit()">
             <div class="row main_row m-0">
                <div class="col-lg-12 col-md-12 col-sm-12 text-center col_1">
                   <h3 class="sub_head mb-2 media_head">Please upload at least 3 pictures of your teeth</h3>
                   <h3 class="sub_head mb-4 responsive_head">Please upload at least 3 pictures of your teeth</h3>
                   <div class="grey_color">
                      <p class="mb-2 font_14">Our AI engine will analyse your picture and give you personalised insights based on the input.</p>
                   </div>
                </div>
             </div>
             <div class="row">
                <div class="image-container">
                   <ngx-slick-carousel class="teeth_carousel" #slickModal="slick-carousel"  [config]="slideConfig" (init)="slickInit($event)">
                   <div ngxSlickItem *ngFor="let data of responseData; let i = index" class="slide">
                      <p class="text-center teeth_carousel_title font_14 my-2"><b>Upload Front View</b></p>
                      <p class="text-center teeth_carousel_title font_10 my-2"> hellow  hellow  hellow  hellow  hellow  hellow  hellow  </p>
                      <div class="teeth_img teeth_hover pointer_link mt-3">
                         <img *ngIf="data.url" [defaultImage]="defaultImage" [lazyLoad]="data.url" class="score_one opac" [errorImage]="defaultImage">
                         <img *ngIf="!data.url" [defaultImage]="defaultImage" [lazyLoad]="data.url" class="score_one opac" [errorImage]="defaultImage">
                         <label class="teeth_img_sec  image_upload{{i}} cursor_pointer" for="image_upload{{i}}"></label>
                         <div class="file_upload">
                            <input type="file" formControlName="file{{i}}" accept="image/*" class="file" (change)="openCropperDialog($event,i,data.name)" id="image_upload{{i}}" hidden />
                         </div>
                      </div>
                   </div>
                   </ngx-slick-carousel>
                   <div class="upload-img-btn">
                      <button class="upload-teeth-continue-btn btn button_links btn_width_2 coninue_btn btn_color mx-0 mt-3 justify-content-center align-items-center"><span class="save_continue_btn_span">Upload Picture</span><span class="icon_arrow icon-right-arrow ml-3 font_12"></span></button>
                   </div>
                </div>
             </div>
             <div class="row">
                <div class="select_btn apply_btn text-center media_button">
                   <button disabled class="upload-teeth-continue-btn btn button_links btn_width_2 coninue_btn btn_color mx-0 mt-3 justify-content-center align-items-center"><span class="save_continue_btn_span">Get your results</span><span class="icon_arrow icon-right-arrow ml-3 font_12"></span></button>
                </div>
             </div>
             </form>
          </div>
       </div>
    </div>
 </div>
 <ng-template #template2>
    <div class="modal-body">
       <div class="model_section p-4">
          <div class="cancel_button_sec text-right mt-4">
             <button type="button" class="close close_button" (click)="closeModal()"><span>×</span></button>
             <img src="{{displayImage}}">
             <h5 class="text-center mt-3">{{displayTitle}}</h5>
          </div>
       </div>
    </div>
 </ng-template> -->

<div class="select-reason_wrapper position-relative sec_inner_content1">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <a href="javascript:void(0)" class="back-btn" (click)="onBack()">
          <i class="sh-chevron-left"></i> <span>back</span>
        </a>
        <div class="wrapper-container">
          <div class="section-heading text-lg-center sec_inner_content1">
            <h1 class="mb-1">
              {{
                imageSelectionCount == 1
                  ? "Upload Your Image"
                  : "Upload Your Images"
              }}
            </h1>
            <p class="pera_p_lr">
              {{
                imageSelectionCount == 1
                  ? "Please upload 1 selfie of your teeth using a smartphone for best results"
                  : "Please upload at least 5 selfies of your teeth using a smartphone for best results"
              }}
            </p>
          </div>
          <!-- Prog Steps -->
          <div
            *ngIf="imageSelectionCount != 1"
            class="prog-steps sec_inner_content1"
          >
            <ul class="list-unstyled">
              <li
                [ngClass]="{
                  active: currentIndex > 1,
                  activebrd: currentIndex == 1
                }"
              >
                <a href="javascript:void(0)">1</a>
              </li>
              <li
                class="activebrd"
                [ngClass]="{
                  active: currentIndex > 2,
                  activebrd: currentIndex == 2
                }"
              >
                <a href="javascript:void(0)">2</a>
              </li>
              <li
                [ngClass]="{
                  active: currentIndex > 3,
                  activebrd: currentIndex == 3
                }"
              >
                <a href="javascript:void(0)">3</a>
              </li>
              <li
                [ngClass]="{
                  active: currentIndex > 4,
                  activebrd: currentIndex == 4
                }"
              >
                <a href="javascript:void(0)">4</a>
              </li>
              <li
                [ngClass]="{
                  active: currentIndex > 5,
                  activebrd: currentIndex == 5
                }"
              >
                <a href="javascript:void(0)">5</a>
              </li>
              <li
                [ngClass]="{
                  active: currentIndex > 6,
                  activebrd: currentIndex == 6
                }"
              >
                <a href="javascript:void(0)">6</a>
              </li>
            </ul>
          </div>
          <!-- Prog Steps -->

          <div *ngIf="imageSelectionCount != 1" class="dynamic_wrapper2">
            <div class="vs-messages"></div>
            <div class="dms_wrapper4">
              <!-- <app-back-to-home></app-back-to-home> -->
              <div class="dms_content2">
                <div class="sec_inner_content1 p-0">
                  <!-- <div id="steps">
                    <div id="circles" class="circle-steps">
                      <div data-num="1" id="num1"></div>
                      <div data-num="2" class="disabled" id="num2"></div>
                      <div data-num="3" class="disabled" id="num3"></div>
                    </div>
                  </div> -->

                  <form
                    autocomplete="off"
                    [formGroup]="uploadImageInputForm"
                    (ngSubmit)="onSubmit()"
                  >
                    <div class="row main_row m-0">
                      <div
                        class="col-lg-12 col-md-12 col-sm-12 text-center col_1"
                      >
                        <!-- <h3 *ngIf="!isUserFromIndia" class="sub_head mb-2 media_head">
                            Please upload at least 3 pictures of your teeth
                          </h3> -->

                        <!-- New Comments -->
                        <!-- <h3 class="sub_head mb-2 media_head">
                          Please upload 5 selfies of your teeth using smartphone for the
                          best result
                        </h3> -->
                        <!-- New Comments -->

                        <!-- <h3
                *ngIf="!isUserFromIndia"
                class="sub_head mb-2 responsive_head"
              >
                Please upload at least 3 pictures of your teeth
              </h3> -->

                        <!-- New Comments -->
                        <!-- <h3 class="sub_head mb-2 responsive_head">
                          Please upload 5 selfies of your teeth using smartphone for the
                          best result
                        </h3>
                        <div class="grey_color">
                          <p class="mb-2 font_14">
                            Our AI engine will analyse your picture and give you
                            personalised insights based on the input.
                          </p>
                        </div> -->
                        <!-- New Comments -->
                      </div>
                    </div>

                    <div class="row position-relative image-outer-container">
                      <!-- <div class="error_message_row position-absolute">
                        <div
                          class="mb-3 col-12 col-sm-12 col-md-12 col-lg-12 errorImageDiv display_none"
                        >
                          <div
                            class="error mt-3 font_14 checkbox_rounded d-flex align-items-center justify-content-center"
                          >
                            <div class="text-left">
                              Please upload your picture
                            </div>
                          </div>
                        </div>
                      </div> -->

                      <!-- Skip Button  -->
                      <!-- <div class="position-absolute skip-btn-placement">
                        <a
                          [ngClass]="displaySkipBtn"
                          href="javascript:;"
                          (click)="moveCarousel('right')"
                          class="float-right skip-btn"
                        >
                          <span class="ml-2">Skip</span>
                        </a>
                      </div> -->
                      <!-- Skip Button  -->

                      <!-- Teeth Image Slider -->
                      <div class="col-lg-12 p-0">
                        <div class="image-container">
                          <ngx-slick-carousel
                            class="teeth_carousel upload_teeth_carousel"
                            #slickModal="slick-carousel"
                            [config]="slideConfig"
                            (init)="slickInit($event)"
                          >
                            <div
                              ngxSlickItem
                              *ngFor="let data of responseData; let i = index"
                              class="slide"
                            >
                              <div
                                class="teeth_col position-relative"
                                class="upload_inner_section{{ i }}"
                              >
                                <!-- Teeth inner title -->
                                <div class="teeth_innertitle">
                                  <div
                                    class="teeth_innertitle_lt teeth_carousel_title"
                                  >
                                    <div class="teethview_step">
                                      {{ data.pageNumber }}
                                    </div>
                                    <div class="teethveiw_title">
                                      {{ data.title }}
                                    </div>
                                  </div>
                                  <div
                                    class="teeth_innertitle_rt teeth_carousel_title"
                                  >
                                    {{ data.content }}
                                  </div>
                                </div>
                                <!-- Teeth inner title -->

                                <div
                                  id="pointer_link{{ i }}"
                                  class="teeth_img_x teeth_hover"
                                >
                                  <!-- <span
                                    class="upload_preview_span{{ i }}"
                                  ></span> -->

                                  <div class="imageupload-wrapper">
                                    <div class="imgupload_lt">
                                      <div class="imgupload_title">
                                        SAMPLE PICTURE
                                      </div>
                                      <div class="imgupload_sample">
                                        <!-- defaul sample image -->
                                        <img
                                          *ngIf="data.url"
                                          (load)="updateImgAfterLoad()"
                                          [ngClass]="applyStyle"
                                          [defaultImage]="defaultImage"
                                          [lazyLoad]="data.url"
                                          class="score_one img_{{ i }} opac1"
                                          [errorImage]="defaultImage"
                                        />
                                        <img
                                          *ngIf="!data.url"
                                          (load)="updateImgAfterLoad()"
                                          [ngClass]="applyStyle"
                                          [defaultImage]="defaultImage"
                                          [lazyLoad]="data.url"
                                          class="score_one img_{{ i }} opac1"
                                          [errorImage]="defaultImage"
                                        />
                                        <!-- defaul sample image -->
                                      </div>
                                    </div>

                                    <div class="imgupload_rt">
                                      <div class="imgupload_title">
                                        UPLOAD YOUR PICTURE
                                      </div>
                                      <div id="overlay1{{ i }}"></div>
                                      <p class="please_wait_msg{{ i }}"></p>
                                      <!-- Upload teeh image -->
                                      <div
                                        class="upload_image_div"
                                        id="upload_image_div{{ i }}"
                                      >
                                        <label
                                          class="btn align-items-center image_upload{{
                                            i
                                          }} upload_here_btn cursor_pointer"
                                          for="image_upload{{ i }}"
                                        >
                                          <img
                                            class="upload_image_icon"
                                            src="assets/images/upload-image-gallery.svg"
                                            alt=""
                                          />
                                          <!-- <span class="d-block click-here-span">Click here to upload your  picture</span> -->
                                        </label>
                                        <div class="file_upload">
                                          <input
                                            type="file"
                                            formControlName="file{{ i }}"
                                            accept="image/*"
                                            class="file"
                                            onclick="this.value=null;"
                                            (change)="
                                              openCropperDialog(
                                                $event,
                                                i,
                                                data.name
                                              )
                                            "
                                            id="image_upload{{ i }}"
                                            hidden
                                          />
                                        </div>
                                      </div>
                                      <!-- Upload teeh image -->

                                      <!-- Edit uploaded image -->
                                      <div
                                        class="avatar-preview222 upload_image_div upload-preview  cropping avatar_preview{{
                                          i
                                        }}"
                                        *ngIf="data.base64 != ''"
                                      >
                                        <img
                                          src="{{ data.base64 }}"
                                          [ngClass]="{ base64Image: i != '5' }"
                                          id="uploadImage{{ i }}"
                                          alt=""
                                        />

                                        <div class="uploadicon_action">
                                          <label
                                            for="image_upload_delete_image{{
                                              i
                                            }}"
                                            class="icon_btn icon_deletebtn"
                                            id="image_upload_delete{{ i }}"
                                            (click)="
                                              onDeleteClick(data, i, $event)
                                            "
                                          >
                                            <i class="sh-delete"></i>
                                          </label>
                                          <label
                                            for="image_upload{{ i }}"
                                            class="icon_btn icon_editbtn"
                                          >
                                            <i class="sh-edit"></i>
                                          </label>
                                        </div>
                                      </div>
                                      <!-- Edit uploaded image -->
                                    </div>
                                  </div>

                                  <div
                                    class="after_image_upload_div display_none"
                                    id="after_image_upload_div{{ i }}"
                                  >
                                    <span class="after_image_upload_span">
                                    </span>
                                  </div>
                                  <!-- <img *ngIf="data.url"  (load)="updateImgAfterLoad()" [ngClass]="applyStyle"  [defaultImage]="defaultImage" [lazyLoad]="defaultImage" class="score_one img_{{i}} opac" [errorImage]="defaultImage">
                            <img *ngIf="!data.url"  (load)="updateImgAfterLoad()" [ngClass]="applyStyle"  [defaultImage]="defaultImage" [lazyLoad]="defaultImage" class="score_one img_{{i}} opac" [errorImage]="defaultImage"> -->
                                  <!-- <label class="teeth_img_sec btn image_upload{{i}} cursor_pointer  " ></label> -->

                                  <!-- <label  [ngClass]="uploadImgClass === 'v1' ? 'teeth_img_sec' : 'teeth_img_sec_v2'" class="btn image_upload{{i}} cursor_pointer" for="image_upload{{i}}"><span class="d-block">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Click here to upload</span> <span>your picture</span></label>                             
                            <div class="file_upload mt-3">
                                <input type="file" formControlName="file{{i}}" accept="image/*" class="file" (change)="openCropperDialog($event,i,data.name)" id="image_upload{{i}}" hidden />
                            </div> -->
                                </div>
                                <div
                                  class="after_image_upload_text_div"
                                  id="after_image_upload_text_div{{ i }}"
                                ></div>
                              </div>
                            </div>
                          </ngx-slick-carousel>
                          <div class="upload-img-btn"></div>

                          <!-- Next Prev button  -->
                          <div class="uploadactiontool_wrapper">
                            <div class="uploadaction_tool">
                              <div class="prev_next_div">
                                <!-- <button (click)="moveCarousel('left')" disabled class="float-right btn_width_3 prev-btn btn button_links btn_color mx-0 mt-0 justify-content-center align-items-center"><span class="icon_arrow icon-left-arrow font_12"></span><span class="ml-3">Previous</span></button> -->
                                <a
                                  [ngClass]="displayPreviousBtn"
                                  href="javascript:void(0);"
                                  disabled
                                  (click)="moveCarousel('left')"
                                  class="prev-btn btn btn-outline btn-rounded-corner disabled"
                                >
                                  Previous
                                  <!-- <span class="icon_arrow icon-left-arrow font_12"></span> -->
                                </a>
                              </div>
                              <div
                                class="save_button_div"
                                (click)="checkImageUploaded()"
                              >
                                <!-- <button (click)="moveCarousel('right')" disabled class="float-left next-btn btn button_links btn_color mx-0 mt-0 justify-content-center align-items-center"><span>Next</span><span class="icon_arrow icon-right-arrow ml-3 font_12"></span></button> -->
                                <!-- <button disabled
                                    class="upload-teeth-continue-btn btn button_links btn_width_3 coninue_btn btn_color mx-0 justify-content-center align-items-center">
                                    <span class="save_continue_btn_span">Save & Continue</span><span
                                      class="icon_arrow icon-right-arrow ml-2 font_12"></span>
                                      </button> -->
                                <button
                                  disabled
                                  class="upload-teeth-continue-btn btn btn-primary coninue_btn btn-rounded-corner"
                                >
                                  <span class="save_continue_btn_span"
                                    >Save & Continue</span
                                  >
                                  <!-- <span class="icon_arrow icon-right-arrow ml-2 font_12"></span> -->
                                </button>
                              </div>
                            </div>
                          </div>
                          <!-- Next Prev button  -->
                        </div>
                      </div>
                      <!-- Teeth Image Slider -->
                    </div>
                    <!-- <div class="row mt-4 mt-md-5 mt-lg-5 visibility_block" *ngIf="ohr_flow=='2'">
                      <div class="col-lg-12 col-md-12 col-sm-12 termsAndConditionsDiv "> 
                          <div class="center-block" style="margin:0px auto">
                            <div class="checkbox_rounded d-flex align-items-center justify-content-center" >
                                <input id="agree" type="checkbox" name="agree" (click)="onAgree($event)">
                                <label for="agree"></label>
                                <span class="check_text agree_text ml-2"><span class="grey_color"> I have read and agree to Smilo.ai's </span><a target="_blank" href="{{consent_form}}">Consent Form</a>, <a target="_blank" href="{{terms_site}}">Terms and Conditions</a>, and <a target="_blank" href="{{privacy_site}}">Privacy Policy</a></span>
                            </div>
                            <div *ngIf="termsAndConditions=='0'" class="error font_14 checkbox_rounded d-flex align-items-center justify-content-center">
                                <div class="text-left">Please Accept Terms and Conditions</div>
                            </div>
                          </div>
                      </div> 
                    </div>      -->

                    <!-- <div class="error mt-3 font_14 checkbox_rounded d-flex align-items-center justify-content-center">
                          <div class="text-left">Please upload the image</div>
                      </div> -->
                    <!-- [ngClass]="{'mt-4': ohr_flow != '2'}" -->
                    <!-- <div class="row mt-md-2 mt-lg-3">          
                          <div class="col-lg-12 select_btn apply_btn text-center"  >
                            <button disabled class="upload-teeth-continue-btn btn button_links btn_width_2 coninue_btn btn_color mx-0 mt-5 justify-content-center align-items-center"><span class="save_continue_btn_span">Save and Continue</span><span class="icon_arrow icon-right-arrow ml-3 font_12"></span></button>
                        </div>
                    </div> -->
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="imageSelectionCount == 1" class="dynamic_wrapper2">
            <div class="vs-messages"></div>
            <div class="dms_wrapper4">
              <div class="dms_content2">
                <div class="sec_inner_content1 p-0">
                  <form
                    autocomplete="off"
                    [formGroup]="uploadImageInputForm"
                    (ngSubmit)="onSubmit()"
                  >
                    <div class="row position-relative image-outer-container">
                      <!-- Teeth Image Slider -->
                      <div class="col-lg-12 p-0">
                        <div class="image-container">
                          <ngx-slick-carousel
                            class="teeth_carousel upload_teeth_carousel"
                            #slickModal="slick-carousel"
                            [config]="slideConfig"
                            (init)="slickInit($event)"
                          >
                            <div
                              ngxSlickItem
                              *ngFor="
                                let data of responseDataSingleImage;
                                let i = index
                              "
                              class="slide"
                            >
                              <div
                                class="teeth_col position-relative"
                                class="upload_inner_section{{ i }}"
                              >
                                <!-- Teeth inner title -->
                                <div class="teeth_innertitle">
                                  <div
                                    class="teeth_innertitle_lt teeth_carousel_title"
                                  >
                                    <!-- <div class="teethview_step">
                                      {{ data.pageNumber }}
                                    </div> -->
                                    <div class="teethveiw_title">
                                      {{ data.title }}
                                    </div>
                                  </div>
                                  <div
                                    class="teeth_innertitle_rt teeth_carousel_title"
                                  >
                                    {{ data.content }}
                                  </div>
                                </div>
                                <!-- Teeth inner title -->

                                <div
                                  id="pointer_link{{ i }}"
                                  class="teeth_img_x teeth_hover"
                                >
                                  <!-- <span
                                    class="upload_preview_span{{ i }}"
                                  ></span> -->

                                  <div class="imageupload-wrapper">
                                    <div class="imgupload_lt">
                                      <div class="imgupload_title">
                                        SAMPLE PICTURE
                                      </div>
                                      <div class="imgupload_sample">
                                        <!-- defaul sample image -->
                                        <img
                                          *ngIf="data.url"
                                          (load)="updateImgAfterLoad()"
                                          [ngClass]="applyStyle"
                                          [defaultImage]="defaultImage"
                                          [lazyLoad]="data.url"
                                          class="score_one img_{{ i }} opac1"
                                          [errorImage]="defaultImage"
                                        />
                                        <img
                                          *ngIf="!data.url"
                                          (load)="updateImgAfterLoad()"
                                          [ngClass]="applyStyle"
                                          [defaultImage]="defaultImage"
                                          [lazyLoad]="data.url"
                                          class="score_one img_{{ i }} opac1"
                                          [errorImage]="defaultImage"
                                        />
                                        <!-- defaul sample image -->
                                      </div>
                                    </div>

                                    <div class="imgupload_rt">
                                      <div class="imgupload_title">
                                        UPLOAD YOUR PICTURE
                                      </div>
                                      <div id="overlay1{{ i }}"></div>
                                      <p class="please_wait_msg{{ i }}"></p>
                                      <!-- Upload teeh image -->
                                      <div
                                        class="upload_image_div"
                                        id="upload_image_div{{ i }}"
                                      >
                                        <label
                                          class="btn align-items-center image_upload{{
                                            i
                                          }} upload_here_btn cursor_pointer"
                                          for="image_upload{{ i }}"
                                        >
                                          <img
                                            class="upload_image_icon"
                                            src="assets/images/upload-image-gallery.svg"
                                            alt=""
                                          />
                                        </label>
                                        <div class="file_upload">
                                          <input
                                            type="file"
                                            formControlName="file{{ i }}"
                                            accept="image/*"
                                            class="file"
                                            onclick="this.value=null;"
                                            (change)="
                                              openCropperDialogForSingleImage(
                                                $event,
                                                i,
                                                data.name
                                              )
                                            "
                                            id="image_upload{{ i }}"
                                            hidden
                                          />
                                        </div>
                                      </div>
                                      <!-- Upload teeh image -->

                                      <!-- Edit uploaded image -->
                                      <div
                                        class="avatar-preview222 upload_image_div upload-preview  cropping avatar_preview{{
                                          i
                                        }}"
                                        *ngIf="data.base64 != ''"
                                      >
                                        <img
                                          src="{{ data.base64 }}"
                                          [ngClass]="{ base64Image: i != '5' }"
                                          id="uploadImage{{ i }}"
                                          alt=""
                                        />

                                        <div class="uploadicon_action">
                                          <label
                                            for="image_upload_delete_image{{
                                              i
                                            }}"
                                            class="icon_btn icon_deletebtn"
                                            id="image_upload_delete{{ i }}"
                                            (click)="
                                              onDeleteClick(data, i, $event)
                                            "
                                          >
                                            <i class="sh-delete"></i>
                                          </label>
                                          <label
                                            for="image_upload{{ i }}"
                                            class="icon_btn icon_editbtn"
                                          >
                                            <i class="sh-edit"></i>
                                          </label>
                                        </div>
                                      </div>
                                      <!-- Edit uploaded image -->
                                    </div>
                                  </div>

                                  <div
                                    class="after_image_upload_div display_none"
                                    id="after_image_upload_div{{ i }}"
                                  >
                                    <span class="after_image_upload_span">
                                    </span>
                                  </div>
                                </div>
                                <div
                                  class="after_image_upload_text_div"
                                  id="after_image_upload_text_div{{ i }}"
                                ></div>
                              </div>
                            </div>
                          </ngx-slick-carousel>
                          <div class="upload-img-btn"></div>

                          <!-- Next Prev button  -->
                          <div class="uploadactiontool_wrapper">
                            <div class="uploadaction_tool">
                              <div class="prev_next_div">
                                <!-- <a
                                  [ngClass]="displayPreviousBtn"
                                  href="javascript:void(0);"
                                  disabled
                                  (click)="moveCarousel('left')"
                                  class="prev-btn btn btn-outline btn-rounded-corner disabled"
                                >
                                  Previous
                                </a> -->
                              </div>
                              <div
                                class="save_button_div"
                                (click)="checkImageUploaded()"
                              >
                                <button
                                  disabled
                                  class="upload-teeth-continue-btn btn btn-primary coninue_btn btn-rounded-corner"
                                >
                                  <span class="save_continue_btn_span"
                                    >Get your Results</span
                                  >
                                </button>
                              </div>
                            </div>
                          </div>
                          <!-- Next Prev button  -->
                        </div>
                      </div>
                      <!-- Teeth Image Slider -->
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="bg_blue pt-0 position-relative">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="wrapper-container"> -->
<!-- <div class="vs-messages"></div> -->
<!-- <div class="loading_div analysing_sec">
            <div class="img_section text-center position-relative">
              <div class="analyzing_text">
                <div class="title_sec mt-4">
                  <h4 class="thank_you_head"><b>Thank you!</b></h4>
                </div>
              </div>
              <div class="loader_img_sec">
                <img src="assets/images/loader.gif" />
              </div>
              <div class="analyzing_text mb-4">
                <p>Smilo.ai is analysing the pictures you have submitted</p>
              </div>
            </div>
          </div> -->
<!-- <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="showLoadingForm">
            <div class="img_section text-center position-relative">
              <div class="analyzing_text">
                <div class="title_sec mt-4">
                  <h4 class="thank_you_head"><b>Thank you!</b></h4>
                </div>
              </div>
              <div class="loader_img_sec">
                <img src="assets/images/loader.gif" />
              </div>
              <div class="analyzing_text mb-4">
                <p>{{ analyzingText }}</p>
              </div>
            </div>
          </div> -->
<!-- </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <ng-template #template2>
  <div class="modal-body">
    <div class="model_section p-4">
      <div class="cancel_button_sec text-right mt-4">
        <button type="button" class="close close_button" (click)="closeModal()">
          <span>×</span>
        </button>
        <img src="{{ displayImage }}" />
        <h5 class="text-center mt-3">{{ displayTitle }}</h5>
      </div>
    </div>
  </div>
</ng-template> -->
<!-- Start OTP Verificaiton Modal -->
<!-- <ng-template #otpVerificationPopup>
  <div class="modal-body">
    <div class="model_section p-3">
      <div class="cancel_button_sec text-right">
        <button type="button" class="close close_button" (click)="closeModal()">
          <span class="cancel">×</span>
        </button>
      </div>
      <div class="otp-verification pt-2">
        <h3 class="text-center mt-4">OTP Verification</h3>
        <span class="mo-num"
          >We have send the OTP on your +{{ dialCode }} {{ phoneNumber }}</span
        >
        <form class="otp-form" autocomplete="off">
          <div class="otp-form-group d-flex justify-content-center">
            <div class="form-group mb-0">
              <input
                type="text"
                (keydown.space)="$event.preventDefault()"
                (keydown)="onKeyOtp($event, 0)"
                (keyup)="setFocus(one.value, 0)"
                maxlength="1"
                class="form-control"
                name="otpNum0"
                [value]="digitOne"
                #one
              />
            </div>
            <div class="form-group mb-0">
              <input
                type="text"
                (keydown.space)="$event.preventDefault()"
                (keydown)="onKeyOtp($event, 1)"
                (keyup)="setFocus(two.value, 1)"
                maxlength="1"
                class="form-control"
                name="otpNum1"
                [value]="digitTwo"
                #two
              />
            </div>
            <div class="form-group mb-0">
              <input
                type="text"
                (keydown.space)="$event.preventDefault()"
                (keydown)="onKeyOtp($event, 2)"
                (keyup)="setFocus(three.value, 2)"
                maxlength="1"
                class="form-control"
                name="otpNum2"
                [value]="digitThree"
                #three
              />
            </div>
            <div class="form-group mb-0">
              <input
                type="text"
                (keydown.space)="$event.preventDefault()"
                (keydown)="onKeyOtp($event, 3)"
                (keyup)="setFocus(four.value, 3)"
                maxlength="1"
                class="form-control"
                name="otpNum3"
                [value]="digitFour"
                #four
              />
            </div>
          </div>

          <div class="d-flex flex-column align-items-center">
            <p>{{ timer | formatTime }}</p>
            <p *ngIf="isTimerOver">
              Didn't received OTP Code?
              <a (click)="onResendCode()" href="javascript:void(0)"
                >Resend Code</a
              >
            </p>
            <span class="text-danger" *ngIf="isInValidOtp"
              >Please enter valid OTP</span
            >
          </div>
          <div
            class="button-group d-flex align-items-center justify-content-center"
          >
            <button
              type="button"
              class="btn btn-outline btn-rounded-corner"
              (click)="closeModal('otp')"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary btn-rounded-corner"
              (click)="onVerifyOtp()"
            >
              Verify OTP
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template> -->
<!-- End OTP Verificaiton Modal -->

<!-- <app-input-user-contact
  *ngIf="showContactForm"
  [isAllApiDone]="isAllApiDone"
></app-input-user-contact> -->
<div
  class="section_wrapper pt-0 position-relative"
  *ngIf="!showLoadingForm && showContactForm"
>
  <div class="section-banner_wrapper position-relative">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!-- <a
            href="javascript:void(0)"
            class="back-btn"
            (click)="onBackToUploadImages()"
          >
            <i class="sh-chevron-left"></i> <span>back</span>
          </a> -->
          <div class="wrapper-container">
            <div
              class="banner_wrap d-flex align-items-center justify-content-between justify-content-lg-center"
            >
              <div class="banner-content">
                <h1>
                  Uploaded<br />
                  Successfully
                </h1>
                <p class="mb-0 d-block d-sm-none">
                  Your smile pictures have<br />
                  been successfully uploaded.
                </p>
              </div>
              <div class="banner-image">
                <img
                  src="assets/images/upload-successfully.png"
                  class="img-fluid d-none d-sm-block"
                  alt=""
                />
                <img
                  src="assets/images/upload-successfully-mobile.png"
                  class="img-fluid d-block d-sm-none"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="wrapper-container">
          <div class="dynamic_wrapper2">
            <div class="vs-messages"></div>
            <div class="dms_wrapper4">
              <!-- <app-back-to-home></app-back-to-home> -->
              <div class="dms_content2">
                <div class="showContactForm">
                  <form
                    autocomplete="off"
                    [formGroup]="InputForm"
                    class="contact_info_form"
                    (ngSubmit)="onContactFormSubmit()"
                  >
                    <h3 class="sub_head text-lg-center">Enter Your Details</h3>
                    <p class="text-lg-center">
                      Please enter your details so we can<br />
                      share your personalized report with you
                    </p>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group position-relative min_height">
                          <label class="mb-2">First Name*</label>
                          <input
                            (keyup)="onEnterData($event)"
                            type="text"
                            formControlName="firstName"
                            maxlength="31"
                            class="first_name form-control"
                            (keyup)="nameLength('first_name')"
                            placeholder="First Name"
                            [ngClass]="{
                              'is-invalid': isSubmitted && ci.firstName.errors
                            }"
                            trim="blur"
                            value="{{ firstName | titlecase }}"
                            appInputRestriction
                          />
                          <div
                            *ngIf="isSubmitted && ci.firstName.errors"
                            class="invalid-feedback"
                          >
                            <div
                              *ngIf="ci.firstName.errors.required"
                              class="text-left"
                            >
                              First Name is required
                            </div>
                            <div
                              *ngIf="ci.firstName.errors.minlength"
                              class="text-left"
                            >
                              First Name should contain at least 3 characters.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group position-relative min_height">
                          <label class="mb-2">Last Name*</label>
                          <input
                            (keyup)="onEnterData($event)"
                            type="text"
                            formControlName="lastName"
                            maxlength="31"
                            class="last_name form-control"
                            (keyup)="nameLength('last_name')"
                            placeholder="Last Name"
                            [ngClass]="{
                              'is-invalid': isSubmitted && ci.lastName.errors
                            }"
                            trim="blur"
                            value="{{ lastName | titlecase }}"
                            appInputRestriction
                          />
                          <div
                            *ngIf="isSubmitted && ci.lastName.errors"
                            class="invalid-feedback"
                          >
                            <div
                              *ngIf="ci.lastName.errors.required"
                              class="text-left"
                            >
                              Last Name is required
                            </div>
                            <div
                              *ngIf="ci.lastName.errors.minlength"
                              class="text-left"
                            >
                              Last Name should contain at least 3 characters.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group position-relative">
                          <label class="mb-2">Email Address*</label>
                          <input
                            (keyup)="onEnterData($event)"
                            maxlength="50"
                            type="email"
                            formControlName="email"
                            class="form-control name_holder"
                            placeholder="Email Address"
                            [ngClass]="{
                              'is-invalid': isSubmitted && ci.email.errors
                            }"
                            trim="blur"
                          />
                          <div
                            *ngIf="isSubmitted && ci.email.errors"
                            class="invalid-feedback"
                          >
                            <div
                              *ngIf="ci.email.errors.required"
                              class="text-left"
                            >
                              Email Address is required
                            </div>
                            <div
                              *ngIf="ci.email.errors.pattern"
                              class="text-left"
                            >
                              Email Address is invalid.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div
                          class="form-group mobile-number-group position-relative"
                        >
                          <label class="mb-2">Mobile Number*</label>
                          <input
                            (keyup)="onEnterData($event)"
                            type="tel"
                            (keydown.space)="$event.preventDefault()"
                            onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            formControlName="phoneNumber"
                            maxlength="10"
                            (countryChange)="countryChange($event)"
                            (intlTelInputObject)="telInputObject($event)"
                            placeholder="Mobile Number"
                            ng2TelInput
                            class="phone-format form-control"
                            id="phoneNumber"
                            [ngClass]="{
                              'is-invalid': isSubmitted && ci.phoneNumber.errors
                            }"
                            trim="blur"
                          />
                          <button
                            *ngIf="isOtpVerificationProcessExist"
                            type="button"
                            class="btn btn-primary btn-icon-left"
                            (click)="openModalVerifyOtp(otpVerificationPopup)"
                            [ngClass]="isOtpSuccess ? 'btn-verified' : ''"
                          >
                            <i class="sh-tick-circle"></i>
                            {{ isOtpSuccess ? "Verified" : "Verify Mobile" }}
                          </button>
                          <!-- Display when button is Verified -->
                          <!-- <button
                    type="button"
                    class="btn btn-primary btn-icon-left btn-verified"
                    (click)="openModalVerifyOtp(otpVerificationPopup)"
                  >
                    <i class="sh-tick-circle"></i>
                    Verified
                  </button> -->
                          <div
                            *ngIf="isSubmitted && ci.phoneNumber.errors"
                            class="invalid-feedback"
                          >
                            <div
                              *ngIf="ci.phoneNumber.errors.required"
                              class="text-left"
                            >
                              Mobile Number is required
                            </div>
                            <div
                              *ngIf="ci.phoneNumber.errors.pattern"
                              class="text-left"
                            >
                              Please enter 10 digit Mobile Number.
                            </div>
                          </div>
                          <div
                            *ngIf="
                              isSubmitted &&
                              isOtpVerificationProcessExist &&
                              !isOtpSuccess &&
                              !ci.phoneNumber.errors &&
                              !isOtpReceive
                            "
                            class="invalid-feedback"
                          >
                            <div class="text-left">
                              Please verify your Mobile Number.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                        *ngIf="display_branches_div"
                        class="form-group position-relative"
                      >
                        <span class="practice-location-span">
                          Dental Practice near to your location
                        </span>
                        <select
                          name="userSelectedPractice"
                          id="userSelectedPractice"
                          class="form-control"
                          (change)="onSelectPractice($event)"
                        >
                          <option value="">Please select any branch</option>
                          <option value="" [selected]="true">
                            Global Branch
                          </option>
                          <option
                            *ngFor="let method of practiceBranches"
                            [attr.data-isocode]="method.address"
                            [value]="method.id"
                            [selected]="method.id == preSelectedBranch"
                          >
                            {{ method.name }}
                          </option>
                        </select>
                      </div> -->
                    <div
                      class="action-button form-button-container d-flex justify-content-center flex_start"
                    >
                      <div
                        class="pt-sm-5 mt-sm-3 w-100 d-flex justify-content-center"
                      >
                        <button
                          [disabled]="contactFormBtn"
                          type="submit"
                          class="btn btn-primary btn-rounded-corner mx-0"
                        >
                          <span class="save_continue_btn_span">Submit</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Start OTP Verificaiton Modal -->
<ng-template #otpVerificationPopup>
  <div class="modal-body">
    <div class="model_section p-3">
      <div class="cancel_button_sec text-right">
        <button type="button" class="close close_button" (click)="closeModal()">
          <span class="cancel">×</span>
        </button>
      </div>
      <div class="otp-verification pt-2">
        <h3 class="text-center mt-4">OTP Verification</h3>
        <span class="mo-num"
          >We have send the OTP on your +{{ dialCode }} {{ phoneNumber }}</span
        >
        <form class="otp-form" autocomplete="off">
          <div class="otp-form-group d-flex justify-content-center">
            <div class="form-group mb-0">
              <input
                type="text"
                (keydown.space)="$event.preventDefault()"
                (keydown)="onKeyOtp($event, 0)"
                (keyup)="setFocus(one.value, 0)"
                maxlength="1"
                class="form-control"
                name="otpNum0"
                [value]="digitOne"
                #one
              />
            </div>
            <div class="form-group mb-0">
              <input
                type="text"
                (keydown.space)="$event.preventDefault()"
                (keydown)="onKeyOtp($event, 1)"
                (keyup)="setFocus(two.value, 1)"
                maxlength="1"
                class="form-control"
                name="otpNum1"
                [value]="digitTwo"
                #two
              />
            </div>
            <div class="form-group mb-0">
              <input
                type="text"
                (keydown.space)="$event.preventDefault()"
                (keydown)="onKeyOtp($event, 2)"
                (keyup)="setFocus(three.value, 2)"
                maxlength="1"
                class="form-control"
                name="otpNum2"
                [value]="digitThree"
                #three
              />
            </div>
            <div class="form-group mb-0">
              <input
                type="text"
                (keydown.space)="$event.preventDefault()"
                (keydown)="onKeyOtp($event, 3)"
                (keyup)="setFocus(four.value, 3)"
                maxlength="1"
                class="form-control"
                name="otpNum3"
                [value]="digitFour"
                #four
              />
            </div>
          </div>

          <div class="d-flex flex-column align-items-center">
            <p>{{ timer | formatTime }}</p>
            <p *ngIf="isTimerOver">
              Didn't received OTP Code?
              <a (click)="onResendCode()" href="javascript:void(0)"
                >Resend Code</a
              >
            </p>
            <span class="text-danger" *ngIf="isInValidOtp"
              >Please enter valid OTP</span
            >
          </div>
          <div
            class="button-group d-flex align-items-center justify-content-center"
          >
            <button
              type="button"
              class="btn btn-outline btn-rounded-corner"
              (click)="closeModal('otp')"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary btn-rounded-corner"
              (click)="onVerifyOtp()"
            >
              Verify OTP
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-- End OTP Verificaiton Modal -->
