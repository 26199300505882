<div class="dynamic_wrapper d-none">
    <div class="inner_dynamic_sec text-center">
        <app-back-to-home></app-back-to-home>
        <div class="top_process_section">
            <div class="text-center d-flex justify-content-center pt-3">
                <span class="icon-tick-fill1 color_green tick_icon"></span> <img src="assets/images/line.png" class="line_cross m-2 p-1"> <span class="icon-tick-fill1 color_green tick_icon"></span> <img src="assets/images/line.png" class="line_cross m-2 p-1">
                <span class="icon-tick-fill1 tick_icon "></span>
            </div>
        </div>
        <div class="container_content_sec">
            <div class="inner_container_content">
                <div class="sec_inner_content">
                    <div class="col-sm-12 col-md-12 form_div p-0">
                        <div class="img_section text-center">
                            <div class="title_sec mb-3">
                                <h4 class="heading">Upload a photo of you smiling</h4>
                                <p class="agree_text d-flex align-items-center justify-content-center">Please click on<span class="icon-info1 info_icon p-0 font_18 mx-1 cursor_pointer" (click)="openModal(template)"></span>icon for instructions.</p>
                                <ng-template #template>
                                    <div class="modal-body">
                                        <div class="row justify-content-end px-0 mx-0">
                                            <div class="model_section p-2">
                                                <div class="cancel_button_sec text-right">
                                                    <!-- <a class="close close_button" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"> <span class="icon-fill-close1"></span> </a> -->
                                                    <button type="button" class="close close_button" (click)="modalRef.hide()"><span>×</span></button>
                                                </div>
                                                <div class="model_content_section p-2">
                                                    <div class="head_img_sec">
                                                        <div class="d-flex">
                                                            <div class="img_poto_smile">
                                                                <img src="assets/images/sample.png" alt="before_look">
                                                            </div>
                                                            <h4 class="title_poto font-weight-bold align-self-center font_16" style="width:30%">Preview of Sample Picture</h4>
                                                        </div>
                                                        <div class="head_pera text-left">
                                                            <P class="text_inner font-weight-bold py-3">Instructions to follow:</P>
                                                        </div>
                                                        <div class="inner_points text-left px-3">
                                                            <ul class="instruction_points ">
                                                                <li class="mb-3">
                                                                    Take a close-up shot of your face.
                                                                </li>
                                                                <li class="mb-3">
                                                                    Give us a big smile and show your teeth.
                                                                </li>
                                                                <li class="mb-3">
                                                                    Use a high quality smart phone camera.
                                                                </li>
                                                                <li class="mb-3">
                                                                    Make sure the environment has good lighting.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                            <div class="row inner_row">
                                <form autocomplete="off" [formGroup]="InputForm" (ngSubmit)="onSubmit()" class="w-100">
                                    <div class="col-sm-12 p-0">
                                        <div class="vs-messages mb-2 alert_msg_max_width"></div>
                                        <div class="upload_preview position-relative d-block cursor_pointer" id="upload_new_profile" [ngClass]="{ 'is-invalid': submitted && f.cover_image.errors }">
                                            <label class="icon-Upload icon_plus" for="image_upload" ></label>
                                            <div id="overlay1"></div>
                                            <span class="upload_preview_span"></span>
                                            <p class="please_wait_msg"></p>
                                            <div class="upload_inner_section">
                                                <div class="avatar-preview cropping smile_uploaded" *ngIf="cropped">
                                                    <img [src]="cropped" class="cropImage">
                                                    <label for="image_upload" class="icon_edit"><i class="icon-edit11 font_16"></i></label>
                                                </div>
                                                <label for="image_upload" class="icon_edit"><i class="icon-edit11 font_16"></i></label>
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && f.cover_image.errors" class="invalid-feedback">
                                            <div *ngIf="f.cover_image.errors.required">Please upload the image to continue</div>
                                        </div>
                                        <div class="file_upload">
                                            <input type="file" class="file" formControlName="cover_image" id="image_upload" accept=".png, .jpg, .jpeg" #fileInput (change)="openCropperDialog($event)" hidden />
                                        </div>
                                        <div class="uplaod_text_sec text-center">
                                            <p class="mt-2 font_w_500">For the best results, please upload a close-up picture of a huge teeth-showing smile</p>
                                        </div>
                                        <div class="d-flex mt-2 align-items-center justify-content-center">
                                            <div class="sample_preview ml-2">
                                                <img src="assets/images/sample_img.png" alt="sample_picture">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center col-sm-12 mt-4 pt-2 image_uploaded smile_uploaded" *ngIf="cropped">
                                        <button class="btn button_links text-white save_continue_btn btn_width_1 pointer_events_none btn_color disabled">Uploading...</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <app-footer></app-footer>
            </div>
        </div>
    </div>
</div>

<div class="dynamic_wrapper">
    <div class="dms_wrapper">
        <app-back-to-home></app-back-to-home>
        <div class="dms_content">
            <!-- <div class="processing">
                <img src="assets/images/step1.png" alt="image">
            </div> -->
            <div id="steps">
                <div id="circles" class="circle-steps">
                    <div data-num="1" id="num1"></div>
                    <div data-num="2" id="num2" class="disabled"></div>
                    <div data-num="3" id="num3" class="disabled"></div>
                </div>
            </div>
            <form autocomplete="off" [formGroup]="InputForm" (ngSubmit)="onSubmit()" class="w-100">
                <div class="row">
                    <div class="col-md-6">
                        <div class="text-center">
                            <h3 class="sub_head mb-4">Upload A Picture Of Your Smile</h3>
                            <div class="grey_color font_14 media_text">
                                <p>Use your smart phone camera in selfie mode.</p>
                                <p>Give us a big smile showing us your front teeth.</p>
                                <p>Ensure you are well lit (avoid strong light behind you) as your selfie has to be good.</p>                                 
                                <p class="my-3">The photo must be of your whole face.</p>
                            </div>
                            <div class="grey_color font_14 responsive_text">
                                <p>Use a smart phone camera in selfie mode.</p><p>Give us a big smile showing us your teeth. Ensure you are well lit (avoid strong light behind you) as your selfie has to be good.</p><p>The photo must be of your whole face.</p>
                            </div>
                            <div class="mb-2"><span class="accepted_txt">Accepted file types JPG, PNG & HEIC. Max 5mb file size. Min 700x700px</span></div>
                            <div class="d-flex justify-content-center flex_start select_btn media_button1">
                                <button class="btn button_links save_continue_btn btn_width_2 coninue_btn btn_color mx-0 mt-3 pointer_events_none disabled d-flex justify-content-center align-items-center" *ngIf="cropped"><span class="save_continue_btn_span">Uploading...</span><span class="icon_arrow icon-right-arrow ml-3"></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-0">
                        <div class="">
                            <label class="icon-camera icon_upload" for="image_upload_1"></label> 
                            <div class="cursor_pointer" id="upload_new_profile" [ngClass]="{ 'is-invalid': submitted && f.cover_image.errors }">                                 
                                <span class="icon_upload_text"> Upload Photo </span>
                                <!-- <label class="icon-Upload icon_plus" for="image_upload_1" ></label> -->
                                <div class="upload_smile" *ngIf="!cropped">
                                    <!-- <img src="assets/images/upload_smile.png" alt="Picture"> -->
                                    <img [defaultImage]="defaultImage" [lazyLoad]="staticImage" [errorImage]="defaultImage" alt="Picture">
                                </div>
                                <div class="file_upload">
                                    <input type="file" class="file" formControlName="cover_image" id="image_upload_1" accept=".png, .jpg, .jpeg" #fileInput (change)="openCropperDialog($event)" hidden />
                                </div>
                                <div id="overlay"></div>
                                <span class="upload_preview_span"></span>
                                <p class="please_wait_msg"></p>
                                <div class="upload_inner_section">
                                    <div class="avatar-preview cropping smile_uploaded" *ngIf="cropped">
                                        <img [src]="cropped" class="cropImage">
                                        <label for="image_upload_1" class="icon_edit"><i class="icon-edit11 font_16"></i></label>
                                    </div>
                                    <label for="image_upload_1" class="icon_edit"><i class="icon-edit11 font_16"></i></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex_start select_btn responsive_button1">
                    <button class="btn button_links save_continue_btn btn_width_2 coninue_btn btn_color mt-3 pointer_events_none disabled d-flex justify-content-center align-items-center" *ngIf="cropped"><span class="save_continue_btn_span">Uploading...</span><span class="icon_arrow icon-right-arrow ml-3"></span></button>
                </div>
            </form>
        </div>
    </div>
    
</div>