import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Services } from "../../Services";
declare var gtag;
declare const fbq;
@Component({
  selector: "app-whatsapp-chat-dentist-card",
  templateUrl: "./whatsapp-chat-dentist-card.component.html",
})
export class WhatsappChatDentistCardComponent implements OnInit {
  isExpand: boolean = false;
  appServices: Services;

  constructor() {
    this.appServices = new Services();
  }

  ngOnInit(): void {}

  onBookNow() {
    this.appServices.sendAnalyticsData(
      gtag,
      fbq,
      "smilo_event",
      "sa_wg_contact_dentist_whatsapp_clicked",
      true
    );
    window.open(environment.SENSODYNE_WHATSAPP_CONTACT, "_blank");
  }
}
